<template>
  <div id="work-order-payment-history">
    <div class="is-size-4 header">{{ header }}</div>
    <div v-if="$asyncComputed.history.updating">
      <b-progress></b-progress>
    </div>
    <div v-else-if="!history.length">
      <b-message>No {{ header }}</b-message>
    </div>
    <div v-else class="line-items">
      <list :data="history" :config="{ ...listConfig, listItemClassAdder }"></list>
    </div>
  </div>
</template>

<script>
import { toUpper } from 'lodash';

import WorkordersService from '@/service/workorders.service';

import List from '@/components/list/list.vue';

export default {
  name: 'WorkorderPaymentHistory',
  components: {
    List,
  },
  props: {
    workOrderId: {
      type: String,
      required: true,
    },
    listItemClassAdder: {
      type: Function,
      default: () => undefined,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      header: 'Payment History',
      listConfig: {
        paginationPerPage: this.pageSize,
        labelField: 'workOrderNumber',
        labelLabel: 'WO#',
        labelOnClick: row => {
          if (row.workOrderId === this.workOrderId) {
            return;
          }

          const routeData = this.$router.resolve({
            name: 'manage-work-order',
            params: { workOrderId: row.workOrderId },
          });
          window.open(routeData.href, '_blank');
        },
        infoField: row =>
          `${this.formatMoney(row.customerPaymentAmount)} -- ${toUpper(row.methodOfPayment ?? 'N/A')} -- ${
            row.customerPaymentComment ?? 'N/A'
          } -- ${this.formatDate(row.createdAt)}`,
        infoLabel: 'Payment, Payment Type, Comment, Date',
      },
    };
  },
  asyncComputed: {
    history: {
      async get() {
        return await WorkordersService.getPaymentHistory(this.workOrderId);
      },
      default: [],
    },
  },
  methods: {
    formatMoney(value) {
      return ((Number(value) ?? 0) / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    formatDate(date) {
      return date
        ? date.toLocaleString('en-US', {
            timeZone: 'America/Denver',
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : 'Unknown';
    },
  },
};
</script>
