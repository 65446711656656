import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const initialState = () => {
  return {
    activeEventsModel: { startDate: null, endDate: null, employeeId: null, events: [] },
    startEvents: [],
    endEvents: [],
    employeeId: null,
    startDate: null,
    endDate: null,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
