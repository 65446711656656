<template>
  <b-modal
    id="customer-job-site-modal"
    v-model="isActive"
    trap-focus
    :full-screen="isMobile"
    aria-role="dialog"
    aria-modal
    :destroy-on-hide="false"
  >
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head">Add new job site</header>
        <section class="modal-card-body">
          <div v-if="addingJobSite">
            <div>Adding the new job site</div>
            <b-progress></b-progress>
          </div>
          <div v-else-if="$asyncComputed.customer.updating">
            <div>Loading customer information</div>
            <b-progress></b-progress>
          </div>
          <flowright-address v-else v-model="newJobSite"></flowright-address>
        </section>
        <footer class="modal-card-foot">
          <b-button size="is-small" label="Close" type="is-danger" @click="$emit('close')"></b-button>
          <b-button
            size="is-small"
            label="Add"
            type="is-success"
            @click="addJobSite"
            :disabled="!newJobSiteIsValid"
          ></b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { identity, uniqBy } from 'lodash';

import CustomerService from '@/service/customer.service';

import FlowrightAddress from '@/components/address/FlowrightAddress.vue';

export default {
  name: 'CustomerJobSiteModal',
  components: {
    FlowrightAddress,
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  asyncComputed: {
    customer: {
      get() {
        return CustomerService.get(this.customerId);
      },
      default: {},
    },
  },
  data() {
    return {
      addingJobSite: false,
      newJobSite: this.buildNewJobSite(),
    };
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
    newJobSiteIsValid() {
      return this.newJobSite.city && this.newJobSite.state && this.newJobSite.zip && this.newJobSite.street;
    },
  },
  methods: {
    buildNewJobSite() {
      return {
        street: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
      };
    },
    addJobSite() {
      this.addingJobSite = true;
      this.newJobSite.id = uuidv4();
      this.newJobSite.address = [
        this.newJobSite.street,
        this.newJobSite.address2 ? `Unit #${this.newJobSite.address2}` : null,
        this.newJobSite.city,
        this.newJobSite.state,
        this.newJobSite.zip,
      ]
        .filter(identity)
        .join(' ');
      const customer = {
        ...this.customer,
        jobSites: uniqBy([...this.customer.jobSites, this.newJobSite], it =>
          it.address ? it.address.toLowerCase() : true
        ),
      };
      CustomerService.update(customer.id, customer)
        .then(() => {
          this.$asyncComputed.customer.update();
          this.$emit('jobSiteAdded', this.newJobSite);
          this.newJobSite = this.buildNewJobSite();
        })
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to add a job site, ${e.message}`,
            type: 'is-danger',
          })
        )
        .finally(() => {
          this.addingJobSite = false;
        });
    },
  },
};
</script>
