import { init } from '@/service/generic.service';

const methods = init({
  collectionPath: 'catalogItemDetails',
});

const TYPE_DEFINITIONS = {
  fpv: {
    fields: [
      {
        label: 'Diameter',
        fieldKey: 'diameter',
        key: 'diameters',
      },
      {
        label: 'Angle',
        fieldKey: 'angle',
        key: 'angles',
      },
      {
        label: 'Length',
        fieldKey: 'length',
        key: 'lengths',
      },
      {
        label: 'Material',
        fieldKey: 'material',
        key: 'materials',
      },
    ],
    defaultValue: {
      diameters: [],
      angles: [],
      lengths: [],
      materials: [],
    },
  },
  fixture: {
    fields: [
      {
        label: 'Brand',
        fieldKey: 'brand',
        key: 'brands',
      },
      {
        label: ' Style (Handle)',
        fieldKey: 'handle',
        key: 'handles',
      },
      {
        label: 'Finish',
        fieldKey: 'finish',
        key: 'finishes',
      },
    ],
    defaultValue: {
      brands: [],
      handles: [],
      finishes: [],
    },
  },
  appliance: {
    fields: [
      {
        label: 'Brand',
        fieldKey: 'brand',
        key: 'brands',
      },
      {
        label: 'Power',
        fieldKey: 'power',
        key: 'powers',
      },
      {
        label: 'Spec',
        fieldKey: 'spec',
        key: 'specs',
      },
      {
        label: 'Category',
        fieldKey: 'category',
        key: 'categories',
      },
    ],
    defaultValue: {
      brands: [],
      powers: [],
      specs: [],
      categories: [],
    },
  },
};

export default {
  list: methods.list,
  getById: methods.getById,
  update: methods.update,
  create: methods.create,
  TYPE_DEFINITIONS,
};
