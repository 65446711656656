import TimeOffEditPage from '@/pages/TimeOffEditPage';
import TimeOffPage from '@/pages/TimeOffPage';

export default [
  {
    component: TimeOffPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Time Off List Page',
    },
    name: 'time-off',
    path: '/time-off',
  },
  {
    component: TimeOffEditPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Time Off Edit Page',
    },
    name: 'time-off-edit',
    path: '/time-off/:timeOffId',
  },
];
