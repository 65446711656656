export const navigation = [
  {
    label: 'Employees',
    icon: 'user-tie',
    isActive: 'employee',
    route: 'employees',
  },
  {
    label: 'Customers & Book WO',
    icon: 'user',
    isActive: 'customer',
    route: 'customers',
  },
  {
    type: 'divider',
  },
  {
    label: 'Catalogs',
    icon: 'book',
    isActive: new RegExp(/catalog$/),
    route: 'catalog',
  },
  {
    label: 'Catalog Products',
    icon: 'faucet',
    isActive: 'catalog-product',
    route: 'catalog-product',
  },
  {
    label: 'Catalog Items',
    icon: 'list-ul',
    isActive: 'catalog-item',
    route: 'catalog-item',
  },
  {
    type: 'divider',
  },
  {
    label: 'Work Orders',
    icon: 'tools',
    isActive: 'work-orders',
    route: 'work-orders',
  },
  {
    label: 'On Call',
    icon: 'phone',
    isActive: 'on-call-work-orders',
    route: 'on-call-work-orders',
    permissions: ['on-call-work-order:view'],
  },
  {
    label: 'Projects',
    icon: 'project-diagram',
    isActive: /project[s]/,
    route: 'projects',
  },
  {
    label: 'Accounting Workflows',
    icon: 'file-invoice-dollar',
    isActive: 'accountingreport',
    route: 'accounting-report',
  },
  {
    label: 'Dispatch Workflows',
    icon: 'snowplow',
    isActive: 'dispatchworkflows',
    route: 'dispatch-workflows',
  },
  {
    label: 'Time Commitment',
    icon: 'calendar-week',
    isActive: 'time-commitment',
    route: 'time-commitment',
  },
  {
    label: 'Admin Panel',
    icon: 'toolbox',
    isActive: 'admin-panel',
    route: 'admin-panel',
  },
  {
    type: 'divider',
  },
  {
    label: 'Time Off Requests',
    icon: 'clock',
    isActive: 'time-off',
    route: 'time-off',
  },
  {
    label: 'DFR Manager',
    icon: 'business-time',
    isActive: 'dfr',
    route: 'dfr',
  },
  {
    type: 'divider',
  },
  {
    label: 'Reporting',
    icon: 'chart-bar',
    isActive: 'reporting',
    route: 'reporting',
  },
  {
    type: 'divider',
  },
  {
    label: 'Help',
    icon: 'question-circle',
    isActive: 'help',
    route: 'help',
  },
];
