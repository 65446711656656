<template>
  <div id="app-refresh"></div>
</template>

<script>
import { listen } from '@/service/appRefresh.service';

export default {
  name: 'AppRefresh',
  data() {
    return {
      currentTime: {
        time: null,
      },
      ref: null,
    };
  },
  mounted() {
    if (this.ref) return;
    this.ref = listen(snap => {
      const value = snap.val();
      if (!this.currentTime?.time) {
        this.currentTime = { ...value };
      } else if (this.currentTime?.time < value?.time) {
        this.openNotification();
      }
    });
  },
  methods: {
    openNotification() {
      return this.$buefy.snackbar.open({
        message: 'There is a new version of the application available',
        type: 'is-success',
        actionText: 'Reload',
        indefinite: true,
        onAction() {
          location.reload();
        },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
