import { uniqBy } from 'lodash';

export default {
  setStartEvents: (state, payload) => {
    state.startEvents = payload;
  },
  setEndEvents: (state, payload) => {
    state.endEvents = payload;
  },
  setStartDate: (state, payload) => {
    state.startDate = payload;
  },
  setEndDate: (state, payload) => {
    state.endDate = payload;
  },
  setEmployeeId: (state, payload) => {
    state.employeeId = payload;
  },
  setActiveEventsModel: state => {
    state.activeEventsModel = {
      startDate: state.startDate,
      endDate: state.endDate,
      employeeId: state.employeeId,
      events: uniqBy([...state.startEvents, ...state.endEvents], 'id'),
    };
  },
};
