<template>
  <div id="project-edit">
    <nav class="breadcrumb">
      <ul>
        <li>
          <router-link class="p-0 m-0" to="/projects"><a>Projects</a></router-link>
        </li>
        <li class="is-active">
          <router-link class="p-0 m-0" :to="`/projects/${projectId}`">
            <a>{{ isNew ? 'New' : project.name }}</a>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="project" v-if="project && projectWorkOrders">
      <div v-if="$asyncComputed.project.updating">
        <b-progress></b-progress>
      </div>
      <div v-else-if="isError">
        <b-message type="is-danger">{{ errorMessage }}</b-message>
      </div>
      <form class="block" v-else @submit.prevent="save">
        <project-mlh-report v-if="!isNew && projectId" :project-id="projectId"></project-mlh-report>
        <div class="mt-5 mb-5 columns is-mobile">
          <div class="column">
            <b-field>
              <b-switch v-model="project.isPrevailingWage">Prevailing Wage</b-switch>
            </b-field>
          </div>
        </div>
        <div class="mb-5 is-size-4">General</div>
        <div class="columns is-multiline">
          <div class="column is-one-third">
            <b-field label="Name">
              <b-input v-model="project.name" required></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Code">
              <b-input v-model="project.code" required :disabled="!isNew"></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Status">
              <b-select v-model="project.status" required expanded>
                <option v-for="s in projectStatuses" :key="s.key" :value="s.value">{{ s.value }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-half">
            <customer-autocomplete
              :initial-search="project.customer.name"
              @selected="customer => (project.customer = customer)"
            ></customer-autocomplete>
          </div>
          <div class="column is-half">
            <b-field label="Company" expanded>
              <b-select v-model="project.company" expanded required>
                <option v-for="c in companies" :key="c.code" :value="c.code">{{ c.code }} - {{ c.company }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field grouped>
              <b-field label="MLH Budget">
                <b-input
                  v-model="project.mlhBudget"
                  :step="0.1"
                  :loading="isSaving"
                  type="number"
                  required
                  expanded
                  :disabled="!isNew && !editMlhBudget"
                ></b-input>
                <b-button class="is-success" v-if="!isNew" :loading="isSaving" @click="updateProjectBudget">
                  {{ editMlhBudget ? 'Update' : 'Change' }}
                </b-button>
              </b-field>
            </b-field>
          </div>
        </div>
        <div class="mb-5 is-size-4">Assignment</div>
        <div class="columns is-multiline">
          <div class="column is-half">
            <b-field label="Start Date">
              <b-datetimepicker
                v-model="project.startDate"
                icon="calendar"
                placeholder="Click to select..."
                :timepicker="{ incrementMinutes: 5 }"
                :max-datetime="project.endDate"
              ></b-datetimepicker>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="End Date">
              <b-datetimepicker
                v-model="project.endDate"
                icon="calendar"
                placeholder="Click to select..."
                :timepicker="{ incrementMinutes: 5 }"
                :min-datetime="project.startDate"
              ></b-datetimepicker>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Project Foreman">
              <b-select v-model="project.foreman" expanded :loading="loadingForemen">
                <option v-for="(foreman, i) in foremen" :key="i" :value="foreman">
                  {{ foreman.lastName }} {{ foreman.firstName }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="isSaving">
          <div class="column">
            <div>Saving changes...</div>
            <b-progress></b-progress>
          </div>
        </div>
        <div class="columns" v-else>
          <div class="column">
            <project-work-assignments :project="project"></project-work-assignments>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Description">
              <b-input type="textarea" v-model="project.description" :loading="isSaving"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Documentation Link">
              <b-input type="input" expanded v-model="project.documentationLink" :loading="isSaving"></b-input>
              <p class="control">
                <a class="button" :href="project.documentationLink" target="_blank" v-if="project.documentationLink">
                  <b-icon icon="link"></b-icon>
                  <span>Open link</span>
                </a>
              </p>
            </b-field>
          </div>
        </div>
        <project-comments :work-orders="projectWorkOrders"></project-comments>
        <project-totals :work-orders="projectWorkOrders"></project-totals>
        <project-cost-codes-list
          class="my-5"
          v-if="!$asyncComputed.project.updating && project.company"
          :project="project"
          :loading="$asyncComputed.project.updating"
          :cost-code-selection-confirmation-function="confirmCostCodeSelection"
          @updated="updatedCostCodes"
          @selection-changed="updatedSelection"
        ></project-cost-codes-list>
        <div v-else>Please select a company</div>
        <div class="buttons is-pulled-right">
          <b-button @click="goBack">Cancel</b-button>
          <button class="button is-success" type="submit" :disabled="!validateProject">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { forEach, isEqual, keyBy, map, omit, pick } from 'lodash';

import { db } from '@/service/firebase';
import ProjectService from '@/service/project.service';
import WorkOrderService from '@/service/workorders.service';

import CustomerAutocomplete from '@/components/customers/CustomerAutocomplete.vue';
import ProjectMlhReport from '@/components/projects/ProjectMlhReport.vue';
import ProjectTotals from '@/components/projects/ProjectTotals.vue';
import ProjectCostCodesList from '@/components/workorders/ProjectCostCodesList.vue';
import ProjectComments from '@/components/projects/ProjectComments.vue';
import ProjectWorkAssignments from '@/components/projects/ProjectWorkAssignments.vue';

export default {
  name: 'ProjectEditPage',
  components: {
    CustomerAutocomplete,
    ProjectTotals,
    ProjectComments,
    ProjectCostCodesList,
    ProjectMlhReport,
    ProjectWorkAssignments,
  },
  data() {
    return {
      editMlhBudget: false,
      projectStatuses: ProjectService.PROJECT_STATUS,
      startLimitUnsub: null,
      endLimitUnsub: null,
      startPollerTechs: {},
      endPollerTechs: {},
      foremenUnsub: null,
      loadingForemen: false,
      foremen: [],
      errorMessage: null,
      isError: false,
      isSaving: false,
    };
  },
  asyncComputed: {
    projectWorkOrders: {
      get() {
        return !this.isNew && this.projectId ? WorkOrderService.getWorkOrdersByProject(this.projectId) : [];
      },
      default: [],
    },
    companies: {
      async get() {
        return WorkOrderService.projectCompanies();
      },
      default: [],
    },
    project: {
      async get() {
        const defaultStatus = ProjectService.DEFAULT_STATUS;
        if (this.isNew) {
          return {
            name: null,
            costCodes: [],
            activeCostCodes: [],
            mlhBudget: 0,
            code: null,
            status: defaultStatus.value,
            customer: { name: null },
          };
        }

        const { value: project } = await Promise.allSettled([ProjectService.getById(this.projectId)])
          .then(([p]) => {
            p.mlhBudget = Number(p.mlhBudget ?? 0);
            if (!p.status) p.status = defaultStatus.value;
            return p;
          })
          .catch(() => {
            this.error = true;
            this.errorMessage = `Unknown project id: ${this.projectId}`;
            return {};
          });

        if (!project.activeCostCodes) {
          project.activeCostCodes = project.costCodes?.filter(it => it.selected)?.map(it => it.id) ?? [];
        }

        return project;
      },
      default: {
        name: null,
        costCodes: [],
        activeCostCodes: [],
        code: null,
        status: null,
        mlhBudget: 0,
        customer: { name: null },
      },
    },
  },
  computed: {
    isDeleted() {
      return !!this.project.deleted;
    },
    validateProject() {
      return (
        this.project.customer?.id &&
        this.project.code &&
        this.project.name &&
        this.project.startDate &&
        this.project.endDate &&
        this.project.company
      );
    },
    projectId() {
      return this.$route.params.projectId;
    },
    isNew() {
      return this.projectId === 'new';
    },
  },
  watch: {
    'project.company': {
      handler(val, old) {
        if (!old) return;
        if (!val) return;
        this.project.costCodes = [];
      },
    },
    'project.activeCostCodes': {
      async handler(value, old) {
        if (isEqual(value, old)) return;

        forEach(this.projectWorkOrders, wo => {
          wo.costCodes = map(wo.costCodes, cc => this.handleCostCodesUpdate(cc, value));
        });

        const batch = db.batch();
        forEach(this.projectWorkOrders, it =>
          batch.set(
            WorkOrderService.dbRef.doc(it.id),
            { costCodes: it.costCodes.map(cc => omit(cc, 'audit')) },
            { merge: true }
          )
        );

        await batch.commit();
        await ProjectService.update({
          id: this.projectId,
          activeCostCodes: value ?? [],
        });
      },
    },
    'project.isPrevailingWage': {
      async handler(value) {
        this.isSaving = true;
        await Promise.all(
          this.projectWorkOrders?.map(it => WorkOrderService.mergeUpdate(it.id, { isPrevailingWage: value }))
        )
          .catch(e =>
            this.$buefy.notification.open({
              message: `Failed to update work assignments flag based on the project, ${e.message}`,
              type: 'is-danger',
            })
          )
          .finally(() => {
            this.isSaving = false;
          });
      },
    },
  },
  async mounted() {
    this.getAvailableForemen();
  },
  methods: {
    async confirmCostCodeSelection(cc, selectedValue) {
      let result = true;
      const hasWoWithCc = this.projectWorkOrders?.find(wo => {
        if (!wo.date) {
          return true;
        }

        const costCodesMap = keyBy(wo.costCodes, 'id');
        const costCodeAsWorkOrder = costCodesMap[cc.id];
        return (
          !!costCodeAsWorkOrder?.startDate &&
          dayjs(costCodeAsWorkOrder.startDate).isAfter(dayjs(new Date())) &&
          costCodeAsWorkOrder.selected
        );
      });

      if (!selectedValue && hasWoWithCc) {
        result = (
          await this.$buefy.dialog.confirm({
            message: `Are you sure you want to deactivate the "${cc.name}" cost code? This may result in tech/foreman unassignment for some work assignments that use the cost code!`,
            closeOnConfirm: true,
            confirmText: 'Deactivate',
            type: 'is-danger',
          })
        ).result;
      }
      return result;
    },
    handleCostCodesUpdate(cc, activeCostCodes) {
      const clone = { ...cc };
      const activeCostCodesSet = new Set(activeCostCodes);
      const ccIsHistoryNow = clone.startDate && dayjs(clone.startDate).isBefore(dayjs(new Date()));
      clone.selectedInProject = activeCostCodesSet.has(clone.id) ?? false;
      if (!ccIsHistoryNow) {
        clone.selected = clone.selectedInProject ? clone.selected : false;
        if (!clone.selected) {
          clone.foreman = {};
          clone.assignedTechs = [];
          clone.assignedTechsEmails = [];
        }
      }
      return clone;
    },
    getAvailableForemen() {
      this.loadingForemen = true;
      if (this.foremenUnsub) {
        this.foremenUnsub();
      }
      this.foremenUnsub = db
        .collection('employees')
        .where('isForeman', '==', true)
        .where('status', '==', 'active')
        .orderBy('lastName')
        .orderBy('firstName')
        .onSnapshot(
          docs => {
            this.foremen = docs.docs.map(it => ({
              id: it.id,
              ...pick(it.data(), 'firstName', 'lastName', 'email', 'scheduleType', 'primaryManagerEmail'),
            }));
            this.loadingForemen = false;
          },
          e => {
            this.loadingForemen = false;
            console.error(e.message);
            this.$buefy.notification.open({
              message: e.message,
              type: 'is-danger',
            });
          }
        );
    },
    async goBack() {
      await this.$router.push({ name: 'projects' });
    },
    notify(message, type = 'is-success') {
      this.$buefy.notification.open({
        message,
        type,
        indefinite: type !== 'is-success',
        closable: true,
      });
    },
    async updateProjectBudget() {
      const switchFunction = () => (this.editMlhBudget = !this.editMlhBudget);
      if (!this.editMlhBudget) {
        this.$buefy.dialog.confirm({
          message: 'Are you sure you want to update the budget hours?',
          onConfirm: switchFunction,
        });
      } else {
        this.isSaving = true;
        await ProjectService.update({
          id: this.project.id,
          mlhBudget: Number(this.project.mlhBudget),
        })
          .then(() => this.$asyncComputed.project.update())
          .finally(() => {
            this.isSaving = false;
          });
        switchFunction();
      }
    },
    save() {
      if (!this.validateProject) {
        this.notify({
          message: 'Please input a name, code customer and company to continue',
          type: 'is-warning',
        });
        return;
      }
      this.isSaving = true;
      return ProjectService.update(this.project)
        .then(() => this.notify('Successfully 1d Project'))
        .then(this.goBack)
        .catch(e => this.notify(e.message, 'is-danger'))
        .finally(() => (this.isSaving = false));
    },
    updatedCostCodes(costCodes) {
      this.project.costCodes = costCodes?.map(it =>
        pick(it, ['id', 'duration', 'name', 'catalogType', 'selected', 'status'])
      );
    },
    updatedSelection(change) {
      const allCostCodes = new Set([...this.project.activeCostCodes, change.id]);
      if (!change.isSelected) {
        allCostCodes.delete(change.id);
      }

      this.project.activeCostCodes = [...allCostCodes];
    },
  },
};
</script>

<style scoped lang="scss">
.cost-codes-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cost-code {
    width: 450px;
  }
}
</style>
