import '@/scss/main.scss';
import Vuex from 'vuex';
import Vue from 'vue';
import _ from 'lodash';
import Buefy from 'buefy';
import VueSignaturePad from 'vue-signature-pad';
import AsyncComputed from 'vue-async-computed';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueMobileDetection from 'vue-mobile-detection';
import InstantSearch from 'vue-instantsearch';

import { auth, db } from '@/service/firebase';
import AddressService from '@/service/address.service';
import WebsocketService from '@/service/websocket.service';

import filters from './filters';
import router from './router';
import App from './App.vue';

Vue.use(Vuex);

import store from '@/store';

Vue.config.productionTip = false;

library.add(fas);
AddressService.loadGoogle();
Vue.component('VueFontawesome', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  defaultFieldLabelPosition: 'on-border',
  defaultProgrammaticPromise: true,
  defaultDatepickerMobileNative: false,
  defaultTimepickerMobileNative: false,
});
Vue.use(AsyncComputed);
Vue.use(VueSignaturePad);
Vue.use(VueMobileDetection);
Vue.use(InstantSearch);

_.each(filters, f => Vue.filter(f.name, f.fn));

Vue.prototype.$db = db;
Vue.prototype.$_ = _;

auth.onAuthStateChanged(async user => {
  if (user) {
    await store.dispatch('auth/loginCache', user);
    await WebsocketService.init();
  }
  await store.dispatch('features/getFeatures');

  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app');
});
