<template>
  <div class="block" id="time-off-edit">
    <nav class="breadcrumb is-mobile" v-if="showBreadCrumbs">
      <ul>
        <li>
          <router-link class="p-0 m-0" to="/time-off"><a>Time Off Requests</a></router-link>
        </li>
        <li class="is-active">
          <router-link class="p-0 m-0" to="/time-off/undefined">
            <a>{{ timeOffToEdit.id ? `manage` : 'new' }}</a>
          </router-link>
        </li>
      </ul>
    </nav>
    <div v-if="!timeOffIsReadyForEdit">
      <div>Loading</div>
      <b-progress></b-progress>
    </div>
    <template v-else>
      <div class="columns">
        <div class="column">
          <div class="header">{{ isNew ? 'New' : 'Manage' }} Time Off Request</div>
        </div>
        <div class="mt-5 column is-2">
          <b-tag class="is-pulled-right" :type="statusType" size="is-large" rounded>
            {{ timeOffToEdit.status | capitalize }}
          </b-tag>
        </div>
      </div>
      <form class="block">
        <div class="columns">
          <div class="column">
            <b-field label="Employee" expanded>
              <b-select
                v-model="timeOffToEdit.employeeId"
                expanded
                :disabled="!isNew"
                required
                :loading="$asyncComputed.employees.updating"
              >
                <option v-for="employee in employees" :key="employee.id" :value="employee.id">
                  {{ getEmployeeName(employee) }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Manager">
              <b-select
                v-model="timeOffToEdit.managerId"
                expanded
                :disabled="isFinalized"
                :loading="$asyncComputed.managers.updating"
                required
              >
                <option v-for="employee in managers" :key="employee.id" :value="employee.id">
                  {{ getEmployeeName(employee) }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Employee Reason">
              <b-select
                v-model="timeOffToEdit.employeeReason"
                expanded
                required
                :disabled="!userCanEdit"
                :loading="$asyncComputed.employeeReasons.updating"
              >
                <option v-for="t in employeeReasons" :value="t.name" :key="t.name">{{ t.name }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="descriptionIsVisible">
          <div class="column">
            <b-field label="Employee's description for Time Off request">
              <b-input
                required
                type="textarea"
                v-model="timeOffToEdit.employeeDescription"
                :loading="saving"
                :disabled="!userCanEdit"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Official Reason">
              <b-select
                v-model="timeOffToEdit.officialReason"
                expanded
                :disabled="!canEditAfterRequest"
                :loading="$asyncComputed.officialReasons.updating"
              >
                <option v-for="t in officialReasons" :value="t.name" :key="t.name">{{ t.name }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Reviewer">
              <b-select v-model="timeOffToEdit.reviewerId" expanded disabled>
                <option v-for="employee in employees" :key="employee.id" :value="employee.id">
                  {{ getEmployeeName(employee) }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="my-4 is-size-3">Date and Time</div>
        <div class="columns">
          <div class="column">
            <b-field label="Start">
              <b-datetimepicker
                v-model="timeOffToEdit.startDate"
                icon="calendar"
                placeholder="Click to select..."
                editable
                :min-datetime="isSuperUser ? '' : new Date()"
                :max-datetime="timeOffToEdit.endDate"
                :disabled="!userCanEdit"
                required
                :timepicker="{ incrementMinutes: 5 }"
              ></b-datetimepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field label="End">
              <b-datetimepicker
                v-model="timeOffToEdit.endDate"
                icon="calendar"
                editable
                placeholder="Click to select..."
                :min-datetime="timeOffToEdit.startDate"
                :disabled="!userCanEdit"
                required
                :timepicker="{ incrementMinutes: 5 }"
              ></b-datetimepicker>
            </b-field>
          </div>
        </div>
        <div class="my-4 is-size-3">Conflicting Work Orders</div>
        <div v-if="$asyncComputed.workOrders.updating">
          <div>Loading</div>
          <b-progress></b-progress>
        </div>
        <div class="container" v-else-if="!workOrders.length">
          <b-message class="m-4">No Conflicting Work Orders</b-message>
        </div>
        <div class="line-items" v-else>
          <div
            class="p-3 m-0 columns is-gapless is-vcentered"
            v-for="(wo, index) in workOrders"
            :key="wo.id"
            :class="{ 'is-even': index % 2 === 0, 'is-odd': index % 2 !== 0 }"
          >
            <div class="line-item-details column is-12">
              <div class="line-item-info">
                <div>
                  <span class="field-name">Work Order Id</span>
                  <router-link
                    class="p-0 pr-5 m-0"
                    :to="{ name: 'manage-work-order', params: { workOrderId: wo.id } }"
                    target="_blank"
                  >
                    <a>{{ wo.workOrderNumber }}</a>
                    <b-icon class="ml-1" icon="external-link-alt" size="is-small"></b-icon>
                  </router-link>
                </div>
                <div>
                  <span class="field-name">Dates</span>
                  <span>{{ mapDate(wo.startDate) }}</span>
                </div>
                <div>
                  <span class="field-name">Info</span>
                  <span>{{ wo.jobInfo }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons is-pulled-left">
          <b-button class="is-normal" @click="goBack">Back</b-button>
        </div>
        <div class="buttons is-pulled-right" v-if="!loadingData">
          <b-button
            v-if="(isReady || isApproved || isPending) && (canEditAfterRequest || isTimeOffRequester)"
            type="is-warning"
            @click="confirm(cancelled)"
            :disabled="!timeOffIsReadyForEdit || saving"
          >
            Cancel
          </b-button>
          <b-button
            v-if="!isFinalized && (isTimeOffRequester || isSuperUser) && !isPending"
            type="is-primary"
            @click="confirm(ready)"
            :disabled="!timeOffIsReadyForEdit || saving || !canSubmit"
          >
            {{ isNew ? 'Submit' : 'Update' }}
          </b-button>
          <b-button
            v-if="!isFinalized && canEditAfterRequest && !isNew"
            type="is-danger"
            @click="confirm(denied, loggedInUser.id)"
            :disabled="!timeOffIsReadyForEdit || saving"
          >
            Deny
          </b-button>
          <b-button
            v-if="canEditAfterRequest && isReady"
            type="is-primary is-light"
            @click="confirm(pending, loggedInUser.id)"
            :disabled="!timeOffIsReadyForEdit || saving"
          >
            Pending
          </b-button>
          <b-button
            v-if="!isFinalized && canEditAfterRequest && !isNew"
            type="is-success"
            @click="confirm(approved, loggedInUser.id)"
            :disabled="!timeOffIsReadyForEdit || saving"
          >
            Approve
          </b-button>
          <b-button
            v-if="canEditAfterRequest && isFinalized"
            type="is-primary"
            @click="confirm(timeOffToEdit.status, loggedInUser.id)"
          >
            Modify
          </b-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { isEmpty, omit } from 'lodash';
import { mapGetters } from 'vuex';
import { TimeOffStatus } from '@newmoon-org/shared';
import { WORK_ORDER_STATES } from '@newmoon-org/types';

import EmployeesService from '@/service/employees.service';
import WorkOrderService from '@/service/workorders.service';
import TimeOffService from '@/service/timeoff.service';

import schedulerMixin from '@/mixins/scheduler';

export default {
  name: 'TimeOffEditPage',
  mixins: [schedulerMixin],
  props: {
    componentTimeOffId: {
      type: String,
      default: null,
    },
    showBreadCrumbs: {
      type: Boolean,
      default: true,
    },
    cancelAction: {
      type: Function,
      default: null,
    },
    postSaveAction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      saving: false,
      timeOffToEdit: { ...TimeOffService.DEFAULT_TIME_OFF },
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'hasPermission']),
    descriptionIsVisible() {
      return this.canEditAfterRequest || this.loggedInUser.id === this.timeOffToEdit.employeeId || this.isNew;
    },
    loadingData() {
      return (
        this.$asyncComputed.employees.updating ||
        this.$asyncComputed.managers.updating ||
        this.$asyncComputed.employeeReasons.updating ||
        this.$asyncComputed.officialReasons.updating ||
        this.$asyncComputed.workOrders.updating
      );
    },
    affectedWorkOrderIds() {
      return this.affectedSchedules
        .filter(it => it.employeeId === this.timeOffToEdit.employeeId && it.workOrderId)
        .map(it => it.workOrderId);
    },
    userCanEdit() {
      return this.isNew || (this.isTimeOffRequester && !this.isFinalized) || this.canEditAfterRequest;
    },
    isNew() {
      return this.timeOffId === 'new';
    },
    isApproved() {
      return this.timeOff.status === 'approved';
    },
    isReady() {
      return this.timeOff.status === this.ready;
    },
    isPending() {
      return this.timeOff.status === this.pending;
    },
    ready() {
      return TimeOffStatus.Ready;
    },
    cancelled() {
      return TimeOffStatus.Cancelled;
    },
    pending() {
      return TimeOffStatus.Pending;
    },
    approved() {
      return TimeOffStatus.Approved;
    },
    denied() {
      return TimeOffStatus.Denied;
    },
    isFinalized() {
      return !['new', this.ready, this.pending].includes(this.timeOffToEdit.status);
    },
    isMobile() {
      return this.$isMobile();
    },
    timeOffId() {
      return this.componentTimeOffId ?? this.$route.params.timeOffId ?? 'new';
    },
    timeOffIsReadyForEdit() {
      return !isEmpty(this.timeOffToEdit);
    },
    searchQuery() {
      return this.$route.query.searchQuery;
    },
    statusType() {
      switch (this.timeOffToEdit.status.toLowerCase()) {
        case this.ready:
          return '';
        case this.pending:
          return 'is-primary is-light';
        case this.approved:
          return 'is-success';
        case this.denied:
          return 'is-danger';
        case this.cancelled:
          return 'is-warning';
        default:
          return 'is-primary';
      }
    },
    loggedInUserRole() {
      return this.loggedInUser?.workflowFunction?.name?.toLowerCase();
    },
    loggedInUser() {
      return this.user?.employee;
    },
    isTimeOffRequester() {
      return this.loggedInUser.id === this.timeOffToEdit.employeeId;
    },
    canEditAfterRequest() {
      return this.hasPermission('timeOff:update') || this.hasPermission('timeOff:admin');
    },
    isSuperUser() {
      return this.hasPermission('timeOff:admin');
    },
    canSubmit() {
      const { employeeId, managerId, employeeReason, employeeDescription, startDate, endDate } = this.timeOffToEdit;

      return !!(employeeId && managerId && employeeReason && employeeDescription && startDate && endDate);
    },
  },
  watch: {
    'timeOffToEdit.employeeId': {
      handler() {
        this.handleDynamicProsLoads(
          this.timeOffToEdit.startDate,
          this.timeOffToEdit.endDate,
          this.timeOffToEdit.employeeId
        );
        if (this.timeOffId === 'new' && this.timeOffToEdit.employeeId !== null) {
          const employee = this.employees.find(it => it.id === this.timeOffToEdit.employeeId);
          this.timeOffToEdit.managerId = employee?.managerId;
          const employeeName = `${employee.firstName} ${employee.lastName}`;
          this.timeOffToEdit = { ...this.timeOffToEdit, employeeName };
        }
      },
      immediate: true,
    },
    'timeOffToEdit.startDate': {
      handler() {
        this.handleDynamicProsLoads(
          this.timeOffToEdit.startDate,
          this.timeOffToEdit.endDate,
          this.timeOffToEdit.employeeId
        );
      },
      immediate: true,
    },
    'timeOffToEdit.endDate': {
      handler() {
        this.handleDynamicProsLoads(
          this.timeOffToEdit.startDate,
          this.timeOffToEdit.endDate,
          this.timeOffToEdit.employeeId
        );
      },
      immediate: true,
    },
  },
  asyncComputed: {
    timeOff: {
      async get() {
        if (!this.timeOffId || this.timeOffId === 'new') {
          return Promise.resolve({ name: 'new' });
        }

        try {
          const timeOff = await TimeOffService.getById(this.timeOffId);
          this.timeOffToEdit = { ...this.timeOffToEdit, ...timeOff };
          return Promise.resolve(timeOff);
        } catch (e) {
          this.notify(`There was an error loading the timeoff`, 'is-error');
          return Promise.resolve({});
        }
      },
      default: {},
    },
    employees: {
      async get() {
        return EmployeesService.getEmployees();
      },
      default: [],
    },
    managers: {
      async get() {
        return EmployeesService.getManagers();
      },
      default: [],
    },
    workOrders: {
      async get() {
        return this.affectedWorkOrderIds.length > 0
          ? WorkOrderService.getChunkedDBResults(this.affectedWorkOrderIds).then(r =>
              r.filter(it => it.state !== WORK_ORDER_STATES.FINISHED)
            )
          : [];
      },
      default: [],
    },
    officialReasons: {
      async get() {
        return TimeOffService.getOfficialReasons();
      },
      default: [],
    },
    employeeReasons: {
      async get() {
        return TimeOffService.getEmployeeReasons();
      },
      default: [],
    },
  },
  methods: {
    mapDate(val) {
      return val?.seconds
        ? new Date(val.seconds * 1000).toLocaleString('en-US', {
            timeZone: 'America/Denver',
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : val;
    },
    getEmployeeName(employee) {
      return `${employee.firstName} ${employee.lastName}`;
    },
    confirm(newStatus, reviewerId = null) {
      if (newStatus === this.cancelled) {
        this.$buefy.dialog.confirm({
          type: 'is-warning',
          message: 'Are you sure you want to cancel this time off request?',
          onConfirm: () => this.save(newStatus, reviewerId),
        });
      } else if (newStatus === this.ready) {
        if (!this.canSubmit) {
          this.$buefy.dialog.alert('Missing required fields');
        } else {
          this.save(newStatus);
        }
      } else if ([this.denied, this.approved, this.pending].includes(newStatus)) {
        if (
          [this.pending, this.approved].includes(newStatus) &&
          (!this.timeOffToEdit.startDate || !this.timeOffToEdit.endDate)
        ) {
          this.$buefy.dialog.alert(`Missing date range for the ${this.pending}/${this.approved} time off.`);
          return;
        }

        if (this.timeOffToEdit.officialReason === null) {
          this.$buefy.dialog.alert('Missing required fields');
        } else {
          this.save(newStatus, reviewerId);
        }
      }
    },
    async save(newStatus, reviewerId = null) {
      this.saving = true;
      const timeOff = { ...this.timeOffToEdit, reviewerId };
      await (this.timeOffId === 'new'
        ? TimeOffService.create({
            ...omit(timeOff, ['id']),
            createdAt: new Date(),
            status: 'ready',
          })
        : TimeOffService.update(this.timeOffId, {
            ...timeOff,
            updatedAt: new Date(),
            status: newStatus,
          })
      ).catch(e =>
        this.notify(`There was an error Saving Time Off ${this.timeOffToEdit.id}, ${e.message}`, 'is-danger')
      );

      this.notify('Saved Time Off');
      this.saving = false;
      if (!this.postSaveAction) {
        await this.goBack();
      } else {
        this.postSaveAction();
      }
    },
    notify(message, type = 'is-success') {
      this.$buefy.notification.open({ message, type });
    },
    async goBack() {
      if (!this.cancelAction) {
        await this.$router.go(-1);
      } else {
        this.cancelAction();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bulma/sass/utilities/_all';

#time-off-edit.block {
  .line-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .line-items {
    margin-bottom: 15px;
    border: #ddd solid 1px;
    border-radius: 5px;

    .is-odd {
      background-color: #eff1fa;
    }

    .line-item-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .line-item-actions {
        flex-direction: row;
        display: flex;
      }
    }
  }

  .field-name {
    font-weight: bold;
    margin-right: 1em;
  }
}
</style>
