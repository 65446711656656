<template>
  <div id="work-order-details">
    <div id="pdf-content">
      <template v-if="$asyncComputed.workOrder.updating">
        <div>Loading</div>
        <b-progress></b-progress>
      </template>
      <template v-else>
        <div class="columns is-vcentered">
          <div class="column">
            <h2 class="title .mb-4">WO# {{ workOrder.workOrderNumber }}</h2>
          </div>
          <div class="column is-narrow">
            <div class="buttons display-only">
              <b-button
                size="is-small"
                icon-left="file-pdf"
                label="Download PDF (external)"
                @click="createExternalPDF"
              ></b-button>
              <b-button
                size="is-small"
                icon-left="file-pdf"
                label="Download PDF (internal)"
                @click="createInternalPDF"
              ></b-button>
            </div>
          </div>
        </div>
        <div
          class="box"
          v-for="group in groupedData"
          :key="group.label"
          :class="[group.isInternalOnly ? 'is-internal-only' : '']"
        >
          <div class="subtitle">{{ group.label }}</div>
          <div class="columns is-multiline">
            <div
              class="column"
              v-for="field in group.fields"
              :key="`${group.label}-${field.label}`"
              :class="[`is-${field.columnSize || 6}`, field.isInternalOnly ? 'is-internal-only' : '']"
            >
              <strong>{{ field.label }}</strong>
              <div>{{ field.value }}</div>
            </div>
          </div>
        </div>
        <div class="box products">
          <div class="subtitle">Products</div>
          <template v-if="workOrder?.lineItems?.length === 0">
            <div class="is-italic">No Products.</div>
          </template>
          <template v-else>
            <table class="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Product</th>
                  <th class="has-text-right">Quantity</th>
                  <th v-if="!workOrder.isTaxOverride" class="has-text-right">Non-Taxable</th>
                  <th v-if="!workOrder.isTaxOverride" class="has-text-right">Taxable</th>
                  <th v-if="workOrder.isTaxOverride" class="has-text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <details-product-row
                  v-for="(lineItem, idx) in workOrder.lineItems"
                  :key="idx"
                  :line-item="lineItem"
                  tr-class=""
                  :ignore-taxes="workOrder.isTaxOverride"
                ></details-product-row>
              </tbody>
            </table>
          </template>
        </div>
        <div class="box is-internal-only">
          <work-order-maintenance-product :work-order="workOrder" :is-read-only="true"></work-order-maintenance-product>
        </div>
        <div class="is-internal-only comments-box box">
          <div class="subtitle">Comments</div>
          <comments-view
            :key="workOrderId"
            :association-id="workOrderId"
            :comment-type="commentType"
            :displayable-types="displayableCommentTypes"
            title=""
            :view-only="true"
          ></comments-view>
        </div>
        <div class="payments-and-totals">
          <div class="columns">
            <div class="column">
              <div class="box">
                <div class="subtitle">Payments</div>
                <template v-if="customerPaymentsOverZero > 0">
                  <table class="table is-fullwidth">
                    <tr v-for="payment in customerPaymentsOverZero" :key="payment.createdAt.seconds">
                      <th>{{ payment.methodOfPayment?.toUpperCase() }}</th>
                      <td>{{ (payment.customerPaymentAmount / 100) | money }}</td>
                    </tr>
                  </table>
                </template>
                <template v-else>
                  <div class="is-italic">No payments recorded.</div>
                </template>
              </div>
            </div>
            <div class="column is-internal-only">
              <div class="box">
                <div class="subtitle">Acceptances</div>
                <work-order-signatures
                  :work-order="workOrder"
                  :show-header="false"
                  :show-verbal-as-text="true"
                ></work-order-signatures>
              </div>
            </div>
            <div class="column">
              <div class="box">
                <div class="subtitle">Totals</div>
                <table class="table is-fullwidth is-hoverable">
                  <tr v-if="!workOrder.isTaxOverride">
                    <td>Non Taxable:</td>
                    <td>{{ (total.nonTaxable / 100) | money }}</td>
                  </tr>
                  <tr v-if="!workOrder.isTaxOverride">
                    <td>Taxable:</td>
                    <td>{{ (total.taxable / 100) | money }}</td>
                  </tr>
                  <tr>
                    <td>Discount:</td>
                    <td>{{ (total.discount / 100) | money }}</td>
                  </tr>
                  <tr>
                    <td>Payments:</td>
                    <td>({{ (total.payments / 100) | money }})</td>
                  </tr>
                  <tr v-if="!workOrder.isTaxOverride">
                    <td>Tax:</td>
                    <td>{{ (total.tax / 100) | money }}</td>
                  </tr>
                  <tr class="has-background-light has-text-weight-bold">
                    <td>Total:</td>
                    <td>{{ (total.total / 100) | money }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import { mapGetters } from 'vuex';
import { WorkOrderService as workOrderShared, CommentType } from '@newmoon-org/shared';
import { identity, first, last, sortBy, keyBy, uniqBy, flatten, isEmpty } from 'lodash';

import CustomerService from '@/service/customer.service';
import CompanyService from '@/service/company.service';
import WorkordersService from '@/service/workorders.service';
import EmployeesService from '@/service/employees.service';
import CatalogService from '@/service/catalog.service';

import DetailsProductRow from '@/components/workorders/DetailsProductRow.vue';
import WorkOrderMaintenanceProduct from '@/components/workorders/WorkOrderMaintenanceProduct.vue';
import WorkOrderSignatures from '@/components/workorders/WorkOrderSignatures.vue';

import CommentsView from '@/pages/CommentsView.vue';

export default {
  name: 'WorkOrderDetails',
  components: {
    CommentsView,
    DetailsProductRow,
    WorkOrderMaintenanceProduct,
    WorkOrderSignatures,
  },
  computed: {
    ...mapGetters('auth', ['user']),
    workOrderId() {
      return this.$route.params.workOrderId;
    },
    commentType() {
      return CommentType.ACCOUNTING_WORKFLOW;
    },
    displayableCommentTypes() {
      return [CommentType.DISPATCH_WORKFLOW, CommentType.ACCOUNTING_WORKFLOW, CommentType.TECH];
    },
    createdAt() {
      return this.convertToDate(this?.workOrder?.createdAt);
    },
    startDate() {
      return this.convertToDate(this.workOrder.startDate);
    },
    endDate() {
      return this.convertToDate(this.workOrder.endDate);
    },
    companyName() {
      return this.workOrder?.company?.company;
    },
    customerName() {
      return this?.workOrder?.customer?.displayAsName;
    },
    customerAuthorizationContact() {
      const c = this.workOrder?.authorizationContact;
      const list = [
        `${c?.name}${c?.propertyRelation ? ` (${c?.propertyRelation})` : ''}`,
        c?.address,
        c?.email,
        c?.phone,
      ].filter(identity);
      return c?.name ? list.join(', ') : null;
    },
    billingAddress() {
      return this?.workOrder?.customer?.billing?.address;
    },
    jobSite() {
      return this?.workOrder?.jobSite?.address;
    },
    total() {
      return workOrderShared.getWorkOrderCost({ ...this.workOrder });
    },
    workHours() {
      const startDate = dayjs(
        first(sortBy(this.workOrder?.costCodes, 'startDate'))?.startDate ?? this.workOrder?.startDate
      );
      const endDate = dayjs(last(sortBy(this.workOrder?.costCodes, 'endDate'))?.endDate ?? this.workOrder?.endDate);
      return `${startDate?.format('MM/DD/YY h:mm A') ?? 'Unknown'} - ${
        endDate?.format('MM/DD/YY h:mm A') ?? 'Unknown'
      }`;
    },
    assignedTechs() {
      return uniqBy(
        [
          ...flatten(this.workOrder.costCodes?.map(it => [...(it.assignedTechs ?? []), it.foreman])).filter(
            it => !isEmpty(it)
          ),
          ...(this.workOrder.assignedTechs ?? []),
        ],
        'id'
      );
    },
    assignedTechsString() {
      const employeesMap = keyBy(this.assignedEmployees, 'id');
      const techs = this.assignedTechs.map(it => {
        const attrs = [
          employeesMap[it.id]?.code ?? null,
          it.email ?? null,
          it.isPrimary ? 'primary' : null,
          employeesMap[it.id]?.isForeman ? 'fmn' : null,
          employeesMap[it.id]?.manager ? 'mgr' : null,
        ].filter(identity);
        return `${it.lastName} ${it.firstName} (${attrs.join('/')})`;
      });
      return techs.join(', ') || 'No assigned techs';
    },
    customerPaymentsOverZero() {
      return this.workOrder.payments.filter(payment => payment.customerPaymentAmount > 0);
    },
    statusData() {
      return {
        label: 'Status',
        isInternalOnly: true,
        fields: [
          {
            label: 'Status',
            value: this.$options.filters.titleCase(this.workOrder?.status || 'Not Set'),
            columnSize: 4,
          },
          {
            label: 'State',
            value: this.$options.filters.titleCase(this.workOrder?.state),
            columnSize: 4,
          },
          {
            label: 'Prevailing Wage',
            value: this.workOrder.isPrevailingWage ? 'Yes' : 'No',
            columnSize: 4,
          },
        ],
      };
    },
    administrationData() {
      return {
        label: 'Administration',
        fields: [
          {
            label: 'Company',
            value: this.companyName,
            columnSize: 4,
          },
          {
            label: 'Created At',
            value: this.createdAt,
            columnSize: 4,
          },
          {
            label: 'Created By',
            value: this.createdBy,
            columnSize: 4,
            isInternalOnly: true,
          },
        ],
      };
    },
    customerInfoData() {
      return {
        label: 'Customer Info',
        fields: [
          {
            label: 'Customer',
            value: this.customerName,
          },
          {
            label: 'Billing Address',
            value: this.billingAddress,
          },
        ],
      };
    },
    generalInfoData() {
      return {
        label: 'General Info',
        fields: [
          {
            label: 'Job Site',
            value: this.jobSite,
          },
          {
            label: 'Service Zone',
            value: this.serviceZone.name,
            isInternalOnly: true,
          },
          {
            label: 'Contact',
            value: this.customerAuthorizationContact,
            isInternalOnly: true,
          },
          this.workOrder.isTaxOverride
            ? null
            : {
                label: 'Tax Rate',
                value: this.workOrder.taxRate ?? 'Unknown',
                isInternalOnly: true,
              },
          this.workOrder.isTaxOverride
            ? null
            : {
                label: 'Tax Jurisdiction',
                value: this.workOrder.taxJurisdictionCode ?? 'Unknown',
                isInternalOnly: true,
              },
          {
            label: 'Problem Type',
            value: this.$options.filters.titleCase(this.workOrder?.catalog?.name || 'unknown'),
            isInternalOnly: true,
          },
          {
            label: 'Service Reason',
            value: this.$options.filters.titleCase(this.workOrder.appointmentReason || 'unknown'),
            isInternalOnly: true,
          },
          {
            label: 'Maintenance',
            value: this.workOrder.isMaintanenceRequired ? 'Yes' : 'No',
            isInternalOnly: true,
          },
          {
            label: 'Frequency',
            value: this.workOrder.maintenanceFrequency || 'N/A',
            isInternalOnly: true,
          },
          {
            label: 'Work Hours',
            value: this.workHours,
            isInternalOnly: true,
          },
          {
            label: 'Technicians',
            value: this.assignedTechsString,
            isInternalOnly: true,
          },
          {
            label: 'Job Info',
            value: this.workOrder.jobInfo,
          },
          {
            label: 'Job Hours',
            value: this.startDate,
          },
          {
            label: 'Note to Tech',
            value: this.workOrder.techNotes,
            isInternalOnly: true,
          },
        ].filter(identity),
      };
    },
    groupedData() {
      return [this.administrationData, this.statusData, this.customerInfoData, this.generalInfoData];
    },
  },
  asyncComputed: {
    workOrder() {
      return CompanyService.getAll().then(async companies => {
        return WorkordersService.getById(this.workOrderId).then(async r => {
          const company = companies.find(c => c.code === r.company);
          r.company = company;
          r.customer = await CustomerService.get(r.customer?.id);
          r.payments = r.payments ?? [];
          r.catalog = r.catalogTypeId ? await CatalogService.getTypeById(r.catalogTypeId) : {};
          return r;
        });
      });
    },
    createdBy: {
      get() {
        return this.workOrder?.createdBy
          ? EmployeesService.get(this.workOrder.createdBy).then(
              it => `${it.firstName} ${it.lastName}${it.code ? ` (${it.code})` : ''}`
            )
          : 'Unknown';
      },
      default: {
        firstName: 'Unknown',
        lastName: 'Unknown',
      },
    },
    serviceZone: {
      async get() {
        return this.workOrder?.serviceZone?.id ? await CatalogService.getById(this.workOrder.serviceZone.id) : {};
      },
    },
  },
  methods: {
    convertToDate(date) {
      return date
        ? date.toLocaleString('en-US', {
            timeZone: 'America/Denver',
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : '';
    },
    createExternalPDF() {
      this.createPDF(false);
    },
    createInternalPDF() {
      this.createPDF(true);
    },
    createPDF(isInternal) {
      const { workOrderNumber } = this.workOrder;
      const woHtml = document.getElementById('pdf-content');
      if (!isInternal) {
        woHtml.querySelectorAll('.is-internal-only').forEach(el => (el.style.display = 'none'));
      }
      woHtml.querySelectorAll('.display-only').forEach(el => (el.style.display = 'none'));
      woHtml.classList.add('printing');

      const doc = new jsPDF({ unit: 'pt' });
      doc.html(woHtml, {
        margin: 20,
        width: 550,
        windowWidth: 1000,
        callback: function (pdf) {
          pdf.save(`Work_Order_${workOrderNumber}.pdf`);
          woHtml.classList.remove('printing');
          woHtml.querySelectorAll('.display-only').forEach(el => (el.style.display = ''));
          woHtml.querySelectorAll('.is-internal-only').forEach(el => (el.style.display = ''));
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
#pdf-content {
  &.printing {
    font-family: Arial, Helvetica, sans-serif;

    .box {
      border: 1px solid #eee;
      padding-top: 0.25em;

      .subtitle {
        margin-bottom: 1em;
      }
    }

    .column {
      padding-top: 0;
    }
  }

  .box {
    .subtitle {
      border-bottom: 1px solid #eee;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }
  }
}
</style>
