import search, { SearchClient, SearchIndex } from 'algoliasearch';

const API_ID = process.env.VUE_APP_ALOGLIA_API_ID as string;
const API_KEY = process.env.VUE_APP_ALGOLIA_API_KEY as string;

export default {
  get: getSearchIndex,
};

export function getSearchIndex(indexName: string): SearchIndex {
  const searchClient = search(API_ID, API_KEY);
  return searchClient.initIndex(indexName);
}

export function getSearchClient(): SearchClient {
  return search(API_ID, API_KEY);
}
