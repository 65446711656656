<template>
  <div class="project-cost-codes-list">
    <div class="my-4 cost-codes-header">
      <div class="title is-5">Cost Codes</div>
    </div>
    <div class="is-loading" v-if="$asyncComputed.costCodesData.updating">
      <div>Loading Cost Codes</div>
      <b-progress></b-progress>
    </div>
    <b-message v-if="!costCodesData.length" has-icon>No Cost Codes</b-message>
    <template v-else>
      <b-pagination
        class="mb-5"
        v-model="currentPage"
        :total="costCodesData.length"
        :per-page="pageSize"
        is-simple="false"
        range-after="3"
        range-before="3"
        order="is-centered"
        size="is-small"
        icon-prev="chevron-left"
        icon-next="chevron-right"
      ></b-pagination>
      <div class="cost-codes">
        <project-cost-code-list-card
          class="mb-2"
          v-for="(cc, idx) in currentPageData"
          :key="idx"
          :cost-code="cc"
          :selected="cc.selected"
          @selection-changed="selectionChanged"
          :selection-confirmation-function="costCodeSelectionConfirmationFunction"
        ></project-cost-code-list-card>
      </div>
      <b-pagination
        class="mt-5"
        v-model="currentPage"
        :total="costCodesData.length"
        :per-page="pageSize"
        is-simple="false"
        range-after="3"
        range-before="3"
        order="is-centered"
        size="is-small"
        icon-prev="chevron-left"
        icon-next="chevron-right"
      ></b-pagination>
    </template>
  </div>
</template>

<script>
import { pick, orderBy, chunk } from 'lodash';

import CatalogService from '@/service/catalog.service';

import ProjectCostCodeListCard from '@/components/workorders/ProjectCostCodeListCard.vue';

export default {
  name: 'ProjectCostCodesList',
  components: {
    ProjectCostCodeListCard,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    costCodeVisibilityFunction: {
      type: Function,
      default: () => {
        return true;
      },
    },
    costCodeSelectionConfirmationFunction: {
      type: Function,
      default: async () => true,
    },
  },
  emits: ['updated', 'selection-changed'],
  asyncComputed: {
    costCodesData: {
      get() {
        const fields = [
          'id',
          'name',
          'costCodeNumber',
          'area',
          'productArea',
          'category',
          'productCategory',
          'catalogType',
        ];
        return CatalogService.getCostCodes(this.project.company).then(r => r.map(it => pick(it, fields)));
      },
      default: [],
    },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    costCodesSorted() {
      const selectionsSet = new Set(this.project.activeCostCodes);
      const costCodesWithSelection = this.costCodesData.map(it => ({
        ...it,
        selected: selectionsSet.has(it.id),
      }));
      return orderBy(costCodesWithSelection, ['selected', 'name'], ['desc', 'asc']);
    },
    currentPageData() {
      return chunk(this.costCodesSorted, this.pageSize)[this.currentPage - 1] ?? [];
    },
  },
  methods: {
    selectionChanged(change) {
      this.$emit('selection-changed', change);
    },
    updated(costCode) {
      this.$emit(
        'updated',
        this.costCodesData.map(it => (it.id === costCode.id ? costCode : it))
      );
    },
  },
};
</script>

<style scoped lang="scss">
.project-cost-codes-list {
  .is-loading {
    width: 100%;
  }

  .cost-codes-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .cost-codes {
    width: 100%;

    .is-odd {
      background-color: #eff1fa;
    }
  }
}
</style>
