<template>
  <div id="schedules-admin-page">
    <template v-if="!!editId">
      <schedule-edit-page :schedule-id="editId" @close="doneEditing"></schedule-edit-page>
    </template>
    <template v-else>
      <div class="columns">
        <div class="column">
          <b-tooltip class="is-pulled-right" label="Add a new Schedule" position="is-left">
            <b-button class="is-primary" icon-right="plus" @click="editId = 'new'"></b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="table">
        <b-table :data="schedules" striped :loading="isLoading" paginated>
          <b-table-column label="Active?" field="status" v-slot="props" sortable>
            <b-icon
              :type="props.row.status === 'active' ? `is-success` : `is-danger`"
              :icon="props.row.status === 'active' ? `check` : `times`"
              size="is-small"
            ></b-icon>
          </b-table-column>
          <b-table-column label="Name" field="name" v-slot="props" sortable searchable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Code" field="code" v-slot="props" sortable searchable>
            {{ props.row.code }}
          </b-table-column>
          <b-table-column label="Repeats From" field="repeatFromDate" v-slot="props">
            {{ props.row.repeatFromDate | formatSimpleDate('Week of ', 'Always') }}
          </b-table-column>
          <b-table-column label="Last Updated" field="updateDate" v-slot="props" sortable>
            {{ props.row.updateDate | formatDate }}
          </b-table-column>
          <b-table-column label="Actions" field="actions" v-slot="props" width="150">
            <div class="buttons">
              <b-tooltip label="Edit" position="is-top" type="is-primary">
                <b-button
                  class="mr-1"
                  type="is-primary"
                  size="is-small"
                  icon-right="edit"
                  @click="editId = props.row.id"
                ></b-button>
              </b-tooltip>
              <b-tooltip label="Delete" position="is-top" type="is-danger">
                <b-button
                  class="mr-1"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                  @click="remove(props.row.id)"
                ></b-button>
              </b-tooltip>
            </div>
          </b-table-column>
        </b-table>
      </div>
    </template>
  </div>
</template>

<script>
import { flow } from 'lodash';
import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';

import SchedulesService from '@/service/schedules.service';

import ScheduleEditPage from '@/pages/ScheduleEditPage.vue';

export default {
  name: 'SchedulesAdminPage',
  components: {
    ScheduleEditPage,
  },
  data() {
    return {
      isLoading: false,
      editId: null,
      schedules: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      SchedulesService.dbRef.onSnapshot(r => {
        this.schedules = r.docs.map(
          flow(conversionServiceShared.mapDoc, it =>
            conversionServiceShared.mapDates(it, ['createDate', 'updateDate', 'repeatFromDate'])
          )
        );
        this.isLoading = false;
      });
    },
    doneEditing() {
      this.editId = null;
    },
    async remove(id) {
      await SchedulesService.remove(id);
    },
  },
};
</script>
