import axios from 'axios';
import loadScript from 'load-script';

import { getLogger } from '@/service/logger.service';

const log = getLogger('addressService');

const key = process.env.VUE_APP_GOOGLE_API_KEY;

export default {
  getStateByZipcode,
  loadGoogle,
};

function loadGoogle() {
  return loadScript(`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`, () => {
    log.info('loaded places api');
  });
}

function getStateByZipcode(code) {
  return axios.get(`http://api.zippopotam.us/us/${code}`);
}
