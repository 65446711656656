import { capitalize } from 'lodash';

export default {
  name: 'titleCase',
  fn(value) {
    return value
      ?.split(' ')
      ?.reduce((acc, v) => `${acc}${capitalize(v)} `, '')
      ?.trim();
  },
};
