<template>
  <div class="relative h-auto z-[89]">
    <div class="flex flex-col gap-4 mb-2 grow md:flex-row">
      <type-picker v-if="!hidePeriodSelection" @change="changeFrequency" :selected-value="frequencyType"></type-picker>
      <div class="flex flex-row grow-0 gap-x-4" v-if="frequencyType === 'day-time' && !hidePeriodSelection">
        <span class="frequency-group">
          <b-field label="From">
            <b-datetimepicker placeholder="Select" v-model="dayFrom" :max-datetime="dayTo"></b-datetimepicker>
          </b-field>
        </span>
        <span class="frequency-group">
          <b-field label="To">
            <b-datetimepicker placeholder="Select" v-model="dayTo" :min-datetime="dayFrom"></b-datetimepicker>
          </b-field>
        </span>
      </div>
      <div class="flex flex-row grow-0 gap-x-4" v-if="frequencyType === 'month-year' && !hideToFromDate">
        <span class="flex flex-row items-center gap-x-2">
          <label class="font-semibold">From:</label>
          <year-picker class="" :selected-value="yearFrom" @change="changeYearFrom"></year-picker>
          <month-picker class="" :selected-value="monthFrom" @change="changeMonthFrom"></month-picker>
        </span>
        <span class="flex flex-row items-center gap-x-2">
          <label class="font-semibold">To:</label>
          <year-picker class="" :selected-value="yearTo" @change="changeYearTo" :min-year="yearFrom"></year-picker>
          <month-picker
            class=""
            :selected-value="monthTo"
            @change="changeMonthTo"
            :min-month="yearTo > yearFrom ? 0 : monthFrom"
          ></month-picker>
        </span>
      </div>
      <div class="search-by" v-if="!customPreappliedFilter">
        <b-field label="Search By">
          <b-select placeholder="Select" v-model="searchType" expanded>
            <option v-for="type in searchOptions" :key="type.key" :value="type.key">
              {{ type.text }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="grow" v-if="!customPreappliedFilter && !['skill', 'company'].includes(searchType)">
        <b-field label="Search">
          <b-input type="search" v-model="searchTerm"></b-input>
        </b-field>
      </div>
      <div class="search" v-else-if="!customPreappliedFilter && searchType === 'skill'">
        <b-field label="Skill">
          <b-select placeholder="Select" v-model="skillSearch" expanded>
            <option v-for="skill in skills" :key="skill.id" :value="skill.id">
              {{ skill.text }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="search" v-else-if="!customPreappliedFilter && searchType === 'company'">
        <b-field label="Company">
          <b-select placeholder="Select" v-model="companySearch" expanded>
            <option v-for="company in companies" :key="company.id" :value="company.id">
              {{ company.code }} - {{ company.company }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="hour-format" v-if="frequencyType === 'day-time'">
        <b-field label="Format">
          <b-select placeholder="Select" v-model="hourFormat" expanded>
            <option v-for="format in ['12hr', '24hr']" :key="format" :value="format">
              {{ format }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <b-pagination
      :total="total"
      :is-simple="false"
      v-model="currentPage"
      :range-after="rangeAfter"
      :range-before="rangeBefore"
      :order="paginationOrder"
      :size="paginationSize"
      :per-page="pageSize"
      :icon-prev="prevIcon"
      :icon-next="nextIcon"
    ></b-pagination>
    <template v-if="loadingData">
      <div>Loading</div>
      <b-progress></b-progress>
    </template>
    <div class="gantt-container" v-else :style="`maxHeight: ${height}px;`">
      <div class="gantt-row-resource gantt-header"></div>
      <div
        class="gantt-times gantt-header"
        v-if="frequencyType === 'day-time'"
        :style="{
          gridTemplateColumns: 'repeat(monthRange.length, 1fr)',
          gridColumns: 'auto',
        }"
      >
        <template v-for="(day, index) in dayRange">
          <gantt-row-period :key="index" :day="day" :type="frequencyType" :format="hourFormat"></gantt-row-period>
        </template>
      </div>
      <div
        class="gantt-times"
        v-else-if="frequencyType === 'month-year'"
        :style="{
          gridTemplateColumns: 'repeat(`${monthRange}`, 1fr)',
          gridColumns: 'auto',
        }"
      >
        <template v-for="(range, index) in monthRange">
          <gantt-row-period
            :key="index + yearFrom"
            :year="yearFrom"
            :month="monthFrom - 1 + range"
            :type="frequencyType"
          ></gantt-row-period>
        </template>
      </div>
      <template v-for="row in currentEmployeesPage">
        <div class="gantt-resource labelled" :key="`${row.id}-resource`">
          <template v-if="!((primaryActionText && onRowClickPrimary) || (secondaryActionText && onRowClickSecondary))">
            <tech-display :tech="row"></tech-display>
          </template>
          <b-tooltip v-else :triggers="['click']" :auto-close="['outside', 'escape', 'inside']" position="is-right">
            <template #content>
              <div class="row-actions">
                <b-button v-if="onRowClickPrimary" @click="onRowClickPrimary(row)" type="is-primary">
                  {{ primaryActionText }}
                </b-button>
                <b-button class="ml-4" v-if="onRowClickSecondary" @click="onRowClickSecondary" type="is-primary">
                  {{ secondaryActionText }}
                </b-button>
              </div>
            </template>
            <tech-display :tech="row"></tech-display>
          </b-tooltip>
        </div>

        <div
          :key="`${row.id}-day`"
          class="gantt-times"
          v-if="frequencyType === 'day-time'"
          :style="{
            gridTemplateColumns: 'repeat(monthRange.length, 1fr)',
            gridColumns: 'auto',
          }"
        >
          <div v-for="(day, index) in dayRange" :key="index">
            <span :style="{ zIndex: `${500 - index}` }">
              <gantt-task-map
                :row="assembleTech(row)"
                :day="day"
                :type="frequencyType"
                :work-day="workdaysMap[day.getTime()][row.scheduleId]"
              ></gantt-task-map>
            </span>
          </div>
        </div>
        <div
          :key="`${row.id}-month`"
          class="gantt-times"
          v-else-if="frequencyType === 'month-year'"
          :style="{
            gridTemplateColumns: 'repeat(`${monthRange}`, 1fr)',
            gridColumns: 'auto',
          }"
        >
          <template v-for="(range, index) in monthRange">
            <gantt-task-map
              :key="index + yearFrom"
              :row="row"
              :year="yearFrom"
              :month="monthFrom - 1 + range"
              :type="frequencyType"
            ></gantt-task-map>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  chunk,
  first,
  groupBy,
  identity,
  intersectionBy,
  keyBy,
  last,
  isEmpty,
  sortBy,
  values,
  keys,
  reduce,
  flatten,
} from 'lodash';
import { DateService } from '@newmoon-org/shared';

import CompanyService from '@/service/company.service';
import WorkOrdersService from '@/service/workorders.service';
import CentralSchedulerService from '@/service/centralScheduler.service';
import { storage } from '@/service/firebase';
import EmployeesService from '@/service/employees.service';
import CatalogTypeService from '@/service/catalogType.service';
import CatalogService from '@/service/catalog.service';

import MonthPicker from '@/components/timeCommitment/ganttchart/monthPicker/MonthPicker.vue';
import GanttRowPeriod from '@/components/timeCommitment/ganttchart/rowPeriod/GanttRowPeriod.vue';
import GanttTaskMap from '@/components/timeCommitment/ganttchart/taskMap/GanttTaskMap.vue';
import TechDisplay from '@/components/timeCommitment/ganttchart/TechDisplay.vue';
import TypePicker from '@/components/timeCommitment/ganttchart/typePicker/TypePicker.vue';
import YearPicker from '@/components/timeCommitment/ganttchart/yearPicker/YearPicker.vue';

import schedulerMixin from '@/mixins/scheduler';

const { getNumberOfHoursBetweenDates, getWorkDayForDate } = DateService;

const DEFAULT_SERVICE_ZONE_COLOR = '#004e7c';
const DEFAULT_TIME_OFF_COLOR = '#004e7c';

export default {
  name: 'GanttChart',
  components: {
    TypePicker,
    YearPicker,
    MonthPicker,
    GanttRowPeriod,
    GanttTaskMap,
    TechDisplay,
  },
  mixins: [schedulerMixin],
  props: {
    hidePeriodSelection: {
      type: Boolean,
      default: false,
    },
    hideToFromDate: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: [String, Date, Number],
      default: () => {
        return new Date(dayjs().startOf('day').valueOf());
      },
    },
    searchOptions: {
      type: Array,
      default: () => [
        { key: 'tech', text: 'Tech' },
        { key: 'skill', text: 'Skill' },
        { key: 'company', text: 'Company' },
        { key: 'wo', text: 'Work Order #' },
        { key: 'foremen', text: 'Foremen Only' },
        { key: 'manager', text: 'Managers Only' },
        { key: 'managerOrForeman', text: 'Managers and Foremen' },
      ],
    },
    customPreappliedFilter: {
      type: Function,
      default: null,
    },
    customSearchType: {
      type: String,
      default: '',
    },
    customSearchFunction: {
      type: Function,
      default: null,
    },
    onRowClickPrimary: {
      type: Function,
      default: null,
    },
    onRowClickSecondary: {
      type: Function,
      default: null,
    },
    primaryActionText: {
      type: String,
      default: null,
    },
    secondaryActionText: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    const today = new Date();

    return {
      pageSize: 20,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      currentPage: 1,
      rangeBefore: 3,
      rangeAfter: 3,
      paginationSize: 'is-small',
      paginationOrder: 'is-centered',
      hourFormat: '12hr',
      searchType: this.customSearchType || 'tech',
      frequencyType: 'day-time',
      yearFrom: today.getFullYear(),
      yearTo: today.getFullYear(),
      dayFrom: new Date(dayjs(this.startDate).startOf('day').valueOf()),
      dayTo: new Date(dayjs().add(1, 'week').endOf('day').valueOf()),
      monthFrom: today.getMonth(),
      monthTo: today.getMonth(),
      tasks: [],
      searchTerm: '',
      skillSearch: null,
      companySearch: null,
      pageHeight: window.innerHeight - window.innerHeight * 0.15,
      serviceZoneMap: {},
    };
  },
  asyncComputed: {
    workOrdersMap: {
      async get() {
        const workOrders = await WorkOrdersService.getChunkedDBResults(this.workOrderIds);
        const serviceZoneIds = workOrders.map(it => it.serviceZone?.id).filter(identity);
        const serviceZones = await CatalogService.getChunkedDBResults(serviceZoneIds);
        this.serviceZoneMap = keyBy(serviceZones, 'id');
        return keyBy(workOrders, 'id');
      },
      default: [],
    },
    serviceZoneMap: {
      get() {
        return CatalogService.getChunkedDBResults(this.serviceZoneIds).then(serviceZones => keyBy(serviceZones, 'id'));
      },
      default: [],
    },
    companies: {
      get() {
        return CompanyService.getAllActive('Active').then(r => sortBy(r, 'code'));
      },
      default: [],
    },
    skills: {
      get() {
        return CatalogTypeService.getAll().then(data =>
          data.map(it => ({
            text: it.name,
            key: it.name,
            value: it.value,
            id: it.id,
          }))
        );
      },
      default: [],
    },
    employees: {
      get() {
        return this.$asyncComputed.skills.success && this.$asyncComputed.companies.success
          ? EmployeesService.getAllActive().then(data => {
              const companiesMap = keyBy(this.companies, 'id');
              const skillsNamedMap = keyBy(this.skills, 'value');
              return Promise.all(
                sortBy(data, ['lastName', 'firstName']).map(async it => ({
                  id: it.id,
                  label: `${it.lastName} ${it.firstName}`,
                  skills: it.skills,
                  skillIds: it.skillIds ?? it.skills?.map(i => skillsNamedMap[i]?.id)?.filter(identity),
                  companies: it.companies?.map(c => companiesMap[c.id]) ?? [],
                  profileImgPath: it.profileImgPath,
                  profileImgUrl:
                    it.profileImgUrl ?? it.profileImgPath
                      ? await storage.ref(it.id).child(it.profileImgPath).getDownloadURL()
                      : null,
                  nickname: it.nickName ?? '',
                  scheduleId: it.schedule?.id,
                  isForeman: it.isForeman ?? false,
                  isManager: it.manager ?? false,
                  tasks: [],
                }))
              );
            })
          : Promise.resolve([]);
      },
      default: [],
    },
    events: {
      get() {
        return Promise.all([this.getStartDateEvents(), this.getEndDateEvents()]).then(([before, after]) => {
          const result = intersectionBy(before, after, e => e.id);
          return result;
        });
      },
      default: [],
    },
  },
  computed: {
    workOrderEventsMap() {
      const workOrderEvents = this.events?.filter(it => !!it.workOrderId) ?? [];
      const employeeWorkOrders = groupBy(workOrderEvents, 'employeeId');
      const buildSpecialKey = it => `${it.workOrderId}-${it.costCodeId ?? 'n/a'}`;
      return reduce(
        keys(employeeWorkOrders),
        (acc, employeeId) => {
          const eventsGroup = groupBy(
            employeeWorkOrders[employeeId].map(it => ({
              ...it,
              specialKey: buildSpecialKey(it),
            })),
            'specialKey'
          );
          const distinctWos =
            values(eventsGroup)
              .map(multiDayWo => {
                const sorted = sortBy(multiDayWo, it => it.startTime);
                const start = first(sorted);
                const end = last(sorted);
                return {
                  id: start.id,
                  costCodeId: start.costCodeId,
                  employeeId: start.employeeId,
                  label: this.getWorkOrderEventLabel(start),
                  color: this.getServiceZoneColor(start),
                  workOrderId: start.workOrderId,
                  company: this.getWorkOrderCompanyName(start.workOrderId),
                  isStartedAheadOfTime: start.isStartedAheadOfTime,
                  isHealthAndSafety: start.isHealthAndSafety,
                  time: {
                    ...start,
                    start: dayjs(start.startTime).valueOf(),
                    end: dayjs(end.endTime).valueOf(),
                  },
                };
              })
              .filter(it =>
                this.searchType === 'wo' && this.searchTerm ? it.label.includes(this.searchTerm.toLowerCase()) : true
              ) ?? [];
          return { ...acc, [employeeId]: distinctWos };
        },
        {}
      );
    },
    timeOffEventsMap() {
      const timeOffs = this.events?.filter(it => !!it.timeOffId) ?? [];
      const employeeTimeOffs = groupBy(timeOffs, 'employeeId');
      return reduce(
        keys(employeeTimeOffs),
        (acc, employeeId) => {
          const eventsGroup = groupBy(employeeTimeOffs[employeeId], 'timeOffId');
          const distinctTimeOffs = values(eventsGroup).map(multiDayTimeOff => {
            const sorted = sortBy(multiDayTimeOff, it => it.startTime);
            const start = first(sorted);
            const end = last(sorted);
            return {
              id: start.id,
              employeeId: start.employeeId,
              label: 'Time Off',
              timeOffId: start.timeOffId,
              color: DEFAULT_TIME_OFF_COLOR,
              time: {
                ...start,
                duration: getNumberOfHoursBetweenDates(start.startTime, end.endTime),
                startTime: start.startTime,
                endTime: end.endTime,
                start: dayjs(start.startTime).valueOf(),
                end: dayjs(end.endTime).valueOf(),
              },
            };
          });
          return { ...acc, [employeeId]: distinctTimeOffs };
        },
        {}
      );
    },
    workOrderIds() {
      return this.events?.map(it => it.workOrderId)?.filter(identity) ?? [];
    },
    dateFilterStart() {
      switch (this.frequencyType) {
        case 'day-time':
          return new Date(dayjs(this.dayFrom).subtract(1, 'day').valueOf());
        case 'month-year':
          return new Date(dayjs(new Date(this.yearFrom, this.monthFrom)).startOf('month').subtract(1, 'day').valueOf());
        default:
          return new Date(dayjs(this.dayFrom).subtract(1, 'day').startOf('day').valueOf());
      }
    },
    dateFilterEnd() {
      switch (this.frequencyType) {
        case 'day-time':
          return new Date(dayjs(this.dayTo).add(1, 'day').valueOf());
        case 'month-year':
          return new Date(dayjs(new Date(this.yearTo, this.monthTo)).endOf('month').add(1, 'day').valueOf());
        default:
          return new Date(dayjs(this.dayTo).add(1, 'day').endOf('day').valueOf());
      }
    },
    loadingData() {
      return (
        this.$asyncComputed.employees.updating ||
        this.$asyncComputed.skills.updating ||
        this.$asyncComputed.companies.updating ||
        this.$asyncComputed.workOrdersMap.updating
      );
    },
    height() {
      return this.maxHeight ? this.maxHeight : this.pageHeight;
    },
    total() {
      return this.filteredEmployees.length;
    },
    workdaysMap() {
      const days = {};
      let index = dayjs(this.dayFrom);
      const end = dayjs(this.dayTo);
      while (!index.isAfter(end)) {
        days[index.valueOf()] = Object.values(this.scheduleDefinitions)
          .map(val => ({
            id: val.id,
            workDay: getWorkDayForDate(val, index.toDate()),
          }))
          .reduce((acc, day) => {
            return {
              ...acc,
              [day.id]: day.workDay,
            };
          }, {});
        index = index.add(1, 'd');
      }
      return days;
    },
    foremanEmployees() {
      return this.employees.filter(r => r.isForeman);
    },
    managerEmployees() {
      return this.employees.filter(r => r.isManager);
    },
    managersOrForemen() {
      return this.employees.filter(r => r.isManager || r.isForeman);
    },
    currentEmployeesPage() {
      return chunk(this.filteredEmployees, this.pageSize)[this.currentPage - 1] ?? [];
    },
    currentEmployeeIds() {
      return this.currentEmployeesPage.map(it => it.id);
    },
    filteredEmployees() {
      if (this.searchTerm || ['skill', 'company'].includes(this.searchType)) {
        const term = this.searchTerm.toLowerCase();
        if (this.customSearchType && this.customSearchFunction) {
          return this.customSearchFunction(this.employees, term);
        }

        if (this.searchType === 'tech') {
          return this.employees.filter(r => r.label.toLowerCase().includes(term));
        }

        if (this.searchType === 'skill' && this.skillSearch) {
          return this.employees.filter(r => r.skillIds.includes(this.skillSearch));
        }

        if (this.searchType === 'foremen') {
          return this.foremanEmployees.filter(r => r.label.toLowerCase().includes(term));
        }

        if (this.searchType === 'manager') {
          return this.managerEmployees.filter(r => r.label.toLowerCase().includes(term));
        }

        if (this.searchType === 'managerOrForeman') {
          return this.managersOrForemen.filter(r => r.label.toLowerCase().includes(term));
        }

        if (this.searchType === 'company' && this.companySearch) {
          return this.employees.filter(r => !!r.companies?.find(it => it.id === this.companySearch));
        }
      }

      if (this.searchType === 'foremen') {
        return this.foremanEmployees;
      }

      if (this.searchType === 'manager') {
        return this.managerEmployees;
      }

      if (this.searchType === 'managerOrForeman') {
        return this.managersOrForemen;
      }

      if (this.customPreappliedFilter) {
        return this.customPreappliedFilter(this.employees);
      }

      return this.employees;
    },
    dayRange() {
      const start = dayjs(this.dayFrom);
      const difference = dayjs(this.dayTo).diff(start, 'day');
      const range = [];
      for (let i = 0; i <= difference; i++) {
        range.push(new Date(this.dayFrom.getFullYear(), this.dayFrom.getMonth(), this.dayFrom.getDate() + i));
      }
      return range;
    },
    monthRange() {
      const yearsToMonths = (Math.max(this.yearTo, this.yearFrom) - this.yearFrom) * 12;
      const monthlyRange = this.monthTo + 1 - (this.monthFrom + 1);
      const totalMonths = monthlyRange + yearsToMonths + 1;
      return totalMonths;
    },
  },
  watch: {
    yearFrom() {
      this.resetPagination();
    },
    yearTo() {
      this.resetPagination();
    },
    monthFrom() {
      this.resetPagination();
    },
    monthTo() {
      this.resetPagination();
    },
    startDate() {
      this.resetPagination();
      this.dayFrom = new Date(dayjs(this.startDate).startOf('day').valueOf());
      this.dayTo = new Date(dayjs(this.startDate).add('1', 'day').valueOf());
    },
    dayFrom() {
      this.resetPagination();
    },
    dayTo() {
      this.resetPagination();
    },
  },
  methods: {
    assembleTech(tech) {
      const tasks = [...(this.workOrderEventsMap[tech.id] ?? []), ...(this.timeOffEventsMap[tech.id] ?? [])];
      return {
        ...tech,
        tasks,
      };
    },
    getWorkOrderCompanyName(workOrderId) {
      return this.companies.find(c => c.code === this.workOrdersMap[workOrderId]?.company)?.company;
    },
    getStartDateEvents() {
      return !isEmpty(this.currentEmployeeIds)
        ? Promise.all(
            chunk(this.currentEmployeeIds, 10).map(it =>
              CentralSchedulerService.dbRef
                .where('employeeId', 'in', it)
                .where('startTime', '>=', this.dateFilterStart)
                .where('startTime', '<=', this.dateFilterEnd)
                .get()
                .then(qs => qs.docs.map(r => CentralSchedulerService.mapDocWithDates(r)))
            )
          ).then(r => flatten(r))
        : Promise.resolve([]);
    },
    getEndDateEvents() {
      return !isEmpty(this.currentEmployeeIds)
        ? Promise.all(
            chunk(this.currentEmployeeIds, 10).map(it =>
              CentralSchedulerService.dbRef
                .where('employeeId', 'in', it)
                .where('endTime', '>=', this.dateFilterStart)
                .where('endTime', '<=', this.dateFilterEnd)
                .get()
                .then(qs => qs.docs.map(r => CentralSchedulerService.mapDocWithDates(r)))
            )
          ).then(r => flatten(r))
        : Promise.resolve([]);
    },
    getWorkOrderEventLabel(woEvent) {
      const wo = this.workOrdersMap[woEvent.workOrderId];
      const pr = wo?.project?.code ? `PR# ${wo.project.code}/` : '';
      const cc = woEvent?.costCodeId ? `CC: ${wo?.costCodes?.find(cc => cc.id === woEvent?.costCodeId)?.name}/` : '';
      const woLabel = `WO# ${wo?.workOrderNumber}`;
      return `${pr}${cc}${woLabel}`;
    },
    getServiceZoneColor(woEvent) {
      const wo = this.workOrdersMap[woEvent.workOrderId];
      const serviceZone = this.serviceZoneMap[wo?.serviceZone?.id];
      return serviceZone?.color ?? DEFAULT_SERVICE_ZONE_COLOR;
    },
    resetPagination() {
      this.currentPage = 1;
    },
    changeFrequency(type) {
      this.frequencyType = type;
    },
    changeYearFrom(year) {
      const yearInt = parseInt(year, 10);
      this.yearFrom = yearInt;
      if (yearInt > this.yearTo) {
        this.yearTo = yearInt;
      }
    },
    changeMonthFrom(month) {
      this.monthFrom = parseInt(month, 10);
    },
    changeYearTo(year) {
      this.yearTo = parseInt(year, 10);
    },
    changeMonthTo(month) {
      this.monthTo = parseInt(month, 10);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~bulma/sass/utilities/_all';

.gantt-container {
  display: grid;
  grid-template-columns: 200px 1fr;
  overflow: auto;
  position: absolute;
  user-select: none;
  left: 0;
  right: 0;

  @media (max-width: 768px) {
    grid-template-columns: 90px 1fr;
  }

  .gantt-row-resource {
    background-color: #f5f5fa;
    color: #fff;
    border-bottom: 1px solid #33333333;
    z-index: 500;

    &.gantt-header {
      position: sticky;
      top: 0;
      z-index: 508;
    }
  }

  .gantt-times {
    display: flex;
    flex-wrap: nowrap;

    &.gantt-header {
      position: sticky;
      top: 0;
      z-index: 508;
    }
  }

  .gantt-resource {
    background-color: #f5f5fa;
    min-height: 50px;
    border-top: 1px solid #66666622;
    border-bottom: 1px solid #33333322;
    border-left: 1px solid #33333322;
    border-right: 1px solid #33333322;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 509;

    & + .gantt-times {
      min-height: 50px;
    }

    &.labelled {
      position: sticky;
      left: 0;
      z-index: 504;

      .b-tooltip {
        width: 100%;
      }
    }
  }
}

.row-actions {
  button {
    font-size: 12px;
  }
}
</style>
