import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';
import { padStart } from 'lodash';

import { db } from '@/service/firebase';
import { init } from '@/service/generic.service';

const DEFAULT_TIME_OFF = {
  id: null,
  employeeId: null,
  status: 'new',
  employeeDescription: null,
  managerId: null,
  reviewerId: null,
  startDate: null,
  endDate: null,
  officialReason: null,
  employeeReason: null,
  createdAt: null,
  updatedAt: null,
};

const OFFICIAL_REASONS = [
  'PTO',
  'Workers Comp',
  'VLOA',
  'Unpaid Time Off',
  'FMLA',
  'Sick',
  'COVID Sick',
  'Training (U)',
];

const TO_TYPES = ['Unpaid', 'Paid - Sick', 'Paid - Non Sick/PTO'];

const methods = init({
  collectionPath: 'timeOffs',
  algoliaIndex: 'timeOffs',
  defaultOrderBy: 'status',
  dateFields: ['startDate', 'endDate', 'createdAt', 'updatedAt'],
});

export default {
  DEFAULT_TIME_OFF,
  OFFICIAL_REASONS,
  TO_TYPES,
  list: methods.list,
  dbRef: methods.dbRef,
  dateFields: methods.dateFields,
  getById: methods.getById,
  create,
  update: methods.update,
  getOfficialReasons,
  getEmployeeReasons,
};

async function getOfficialReasons() {
  return await db
    .collection('toOfficialReasons')
    .orderBy('name')
    .where('status', '==', 'Active')
    .where('type', '==', 'official')
    .get()
    .then(r => r.docs.map(conversionServiceShared.mapDoc));
}

async function getEmployeeReasons() {
  return await db
    .collection('toOfficialReasons')
    .orderBy('name')
    .where('status', '==', 'Active')
    .where('type', '==', 'employee')
    .get()
    .then(r => r.docs.map(conversionServiceShared.mapDoc));
}

async function create(timeOff) {
  try {
    await db.runTransaction(async t => {
      const id = methods.dbRef.doc().id;
      timeOff.timeOffNumber = await createTimeOffId(t);
      timeOff.createdAt = new Date();
      await t.set(methods.dbRef.doc(id), methods.mapDataDates(timeOff, false));
      return { id, ...timeOff };
    });
  } catch (e) {
    console.log(e);
  }
}

async function createTimeOffId(transaction) {
  const count = await getCount(transaction);
  const prepend = new Date().getFullYear().toString().slice(-2); // gets 21 for 2021
  return `${prepend}${padStart(count, 6, 0)}`;
}

async function getCount(t) {
  const docRef = db.collection('timeoffCounter').doc('counter');
  const doc = await t.get(docRef);
  const currentCount = doc.data().count;
  await t.set(docRef, { count: currentCount + 1 });
  return currentCount;
}
