<template>
  <div id="dfr-page">
    <div class="header-container">
      <div class="header">DFR Management</div>
    </div>
    <b-tabs v-model="activeTab" type="is-boxed">
      <b-tab-item label="Entries">
        <dfr-entries-list></dfr-entries-list>
      </b-tab-item>
      <b-tab-item label="Reporting" v-if="isDfrAggregateEnabled && canSeeAggregations">
        <dfr-aggregate></dfr-aggregate>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DfrEntriesList from '@/components/dfr/DfrEntriesList.vue';
import DfrAggregate from '@/components/dfr/DfrAggregate.vue';

export default {
  name: 'DFRPage',
  components: {
    DfrAggregate,
    DfrEntriesList,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('auth', ['user']),
    loggedInUser() {
      return this.user?.employee ?? {};
    },
    isDfrAggregateEnabled() {
      return this.isFeatureEnabled('dfrAggregate');
    },
    canSeeAggregations() {
      return true;
      // NOTE: Probably need some fine grain access control here
      // return ['hr', 'developer', 'global administrator', 'administrator']
      //   .includes(this.loggedInUser.workflowFunction?.name?.toLowerCase());
    },
  },
  methods: {},
};
</script>
