import { init } from '@/service/generic.service';

const methods = init({
  collectionPath: 'permissions',
});

export default {
  dbRef: methods.dbRef,
  getAllActive: methods.getAllActive,
  getById: methods.getById,
  create: methods.create,
  update: methods.update,
  remove: methods.remove,
  getChunkedDBResults: methods.getChunkedDBResults,
};
