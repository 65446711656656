<template>
  <div id="catalog">
    <div class="header-container">
      <div class="header">Catalogs</div>
      <b-tooltip label="Create a new Catalog" position="is-left">
        <b-button class="is-primary" icon-right="plus" @click="create"></b-button>
      </b-tooltip>
    </div>
    <div class="is-loading" v-if="isLoading">
      <b-progress></b-progress>
    </div>
    <div class="table" v-else>
      <b-table :data="catalogs" :loading="isLoading">
        <b-table-column label="Name" field="name" v-slot="props">
          <router-link :to="{ name: 'catalog-edit', params: { catalogId: props.row.id } }">
            {{ props.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column label="Number of Products" field="count" v-slot="props">{{ props.row.count }}</b-table-column>
        <b-table-column label="WO Status" field="woStatus" v-slot="props">
          <b-icon
            :type="props.row.woStatus ? `is-success` : `is-danger`"
            :icon="props.row.woStatus ? `check` : `times`"
            size="is-small"
          ></b-icon>
        </b-table-column>
        <b-table-column label="Actions" field="actions" v-slot="props" width="150">
          <div class="buttons">
            <b-tooltip label="Edit" position="is-left">
              <b-button class="mr-1 is-small is-primary" icon-right="edit" @click="edit(props.row)"></b-button>
            </b-tooltip>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { db } from '@/service/firebase';
import CatalogService from '@/service/catalog.service';

export default {
  name: 'CatalogsPage',
  data() {
    return {
      isLoading: true,
      isEditModalActive: false,
      isCreate: false,
      isDeleting: false,
      selectedCompanies: [],
      selectedCatalog: {
        companies: [],
      },
    };
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
  },
  asyncComputed: {
    catalogs: {
      async get() {
        this.isLoading = true;
        const types = await db
          .collection('catalogTypes')
          .get()
          .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        return Promise.all(
          types.map(type =>
            CatalogService.list({
              searchFilter: `catalogType.id:"${type.id}"`,
            }).then(r => {
              return { ...type, count: r?.nbHits };
            })
          )
        ).finally(() => (this.isLoading = false));
      },
      default: [],
    },
  },
  methods: {
    create() {
      this.$router.push({
        name: 'catalog-edit',
        params: {
          catalogId: 'new',
        },
      });
    },
    edit(row) {
      this.$router.push({
        name: 'catalog-edit',
        params: {
          catalogId: row.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
#catalog {
  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .card {
    min-width: 300px;
    max-width: 400px;
  }

  .card-title {
    font-size: 24px;
  }

  .companies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .company {
      padding: 10px;
      min-width: 100px;
    }
  }
}
</style>
