<template>
  <div class="algolia-list">
    <div class="search-header">
      <div class="search-input">
        <b-field label="Search">
          <b-input v-model="query" :expanded="true" @input="searchDebounce" placeholder="Search"></b-input>
        </b-field>
      </div>
      <div class="hits-counter">{{ hitsCounter }}</div>
    </div>
    <div class="mobile-facet-toggle">
      <b-button type="is-primary" @click="facetColumnVisible = !facetColumnVisible">
        {{ facetColumnVisible ? 'Apply' : 'Filters' }}
      </b-button>
    </div>
    <div class="columns" v-if="currentResults">
      <div class="column is-one-fifth" v-if="hasFacets" :class="{ 'is-hidden-mobile': !facetColumnVisible }">
        <algolia-toggle-facet
          v-for="facet in augmentedFacets.filter(f => f.type === 'toggle')"
          :facet="facet"
          :key="facet.facet"
          @filter-change="onFacetChange"
        ></algolia-toggle-facet>
        <algolia-multi-value-facet
          v-for="facet in augmentedFacets.filter(f => f.type === 'multiValue')"
          :facet="facet"
          :key="facet.facet"
          @facet-change="onFacetChange"
        ></algolia-multi-value-facet>
      </div>
      <div class="column" :class="{ 'is-hidden-mobile': facetColumnVisible }">
        <algolia-list-table
          :attributes="config.attributes"
          :results="currentResults"
          :actions="config.actions"
          :hydrate="isHydrate"
          @on-page-change="onPageChange"
        ></algolia-list-table>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import {
  chain,
  debounce,
  get,
  identity,
  isArray,
  isBoolean,
  keyBy,
  reduce,
} from 'lodash';

import { getSearchClient } from '@/service/algolia';
import { db } from '@/service/firebase';
import { getChunkedResults } from '@/service/generic.service';
import { getLogger } from '@/service/logger.service';

import AlgoliaListTable from '@/components/list/views/AlgoliaListTable.vue';
import AlgoliaMultiValueFacet
  from '@/components/list/views/AlgoliaMultiValueFacet.vue';
import AlgoliaToggleFacet from '@/components/list/views/AlgoliaToggleFacet.vue';

const log = getLogger('Algolia List Refresh');
let algoliaClient;

export default {
  name: 'AlgoliaList',
  components: {
    AlgoliaListTable,
    AlgoliaMultiValueFacet,
    AlgoliaToggleFacet,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    searchFunction: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      query: '',
      searchDebounce: debounce(this.search, 300),
      currentResults: {},
      isSearching: false,
      facetResults: {},
      currentFilters: {},
      currentFacets: {},
      currentPage: 0,
      facetOrder: [],
      hitsCounter: '',
      subscribe: () => undefined,
      facetColumnVisible: false,
    };
  },
  computed: {
    hasFacets() {
      return true;
    },
    facetKeys() {
      if (!this.hasFacets) return null;
      return reduce(this.config.facets, (keys, facetDefinitions) => {
        return [...keys, ...facetDefinitions.map(f => f.facet)];
      }, []);
    },
    augmentedFacets() {
      if (!this.hasFacets) return [];
      return reduce(this.config.facets, (facets, facetDefinitions, facetType) => {
        return [...facets, ...facetDefinitions.map(fd => ({
          ...fd,
          result: { ...get(this.facetResults, fd.facet) },
          type: facetType,
        }))];
      }, []);
    },

    isHydrate() {
      return !!this.config.hydrate;
    },
  },
  async mounted() {
    this.refresh();
    algoliaClient = getSearchClient();
    this.augmentedFacets.forEach(f => {
      if (f.default) {
        this.onFacetChange({
          key: f.facet,
          value: f.defaultValue,
          type: f.type,
        }, false);
      }
    });
    await this.search(this.query);
  },
  destroyed() {
    this.subscribe();
  },
  methods: {
    onPageChange({ page }) {
      this.currentPage = page;
      this.search(this.query);
    },
    refresh() {
      this.subscribe = db.collection('settings').onSnapshot(sn => {
        sn.docChanges().forEach(async (change) => {
          if (change.type === 'modified') {
            log.info(`Modified index: ${change.doc.data()}`);
            const updatedAt = get(change.doc.data(), `algolia.indexes.${this.config.indexName}.lastUpdated`);
            if (!this.lastUpdated || this.lastUpdated !== updatedAt) {
              log.info('refreshing');
              algoliaClient.clearCache();
              await this.search(this.query);
            }
            this.lastUpdated = updatedAt;
          }
        });
      });
    },
    onFacetChange(facetEvent, search = true) {
      switch (facetEvent.type) {
        case 'toggle': {
          const {
            currentFacets,
          } = handleToggleEvent(this.currentFacets, this.facetOrder, facetEvent);
          this.currentFacets = currentFacets;
          break;
        }
        case 'multiValue': {
          const {
            currentFacets,
            facetOrder,
          } = handleMultiValueEvent(this.currentFacets, this.facetOrder, facetEvent);
          this.currentFacets = currentFacets;
          this.facetOrder = facetOrder;
          break;
        }
        default: {
          throw new Error(`handler for facet type ${facetEvent.type} doesnt exist`);
        }
      }

      if (search) this.search(this.query);

      function handleMultiValueEvent(currentFacets, facetOrder, event) {
        if (!currentFacets[event.key]) currentFacets[event.key] = [];

        if (currentFacets[event.key].includes(event.value)) {
          const index = currentFacets[event.key].indexOf(event.value);
          currentFacets[event.key].splice(index, 1);
          facetOrder.splice(facetOrder.indexOf(event.key), 1);
        } else {
          currentFacets[event.key].push(event.value);
          facetOrder.push(event.key);
        }
        return {
          currentFacets: { ...currentFacets },
          facetOrder: [...facetOrder],
        };
      }

      function handleToggleEvent(currentFacets, facetOrder, event) {
        currentFacets[event.key] = event.value;
        return {
          currentFacets,
        };
      }
    },
    async search(query) {
      try {
        this.isSearching = true;
        const filters = buildFacetFilter(this.currentFacets);
        const facetRequests = buildFacetRequests(this.currentFacets, query, this.config.indexName);
        const results = await algoliaClient.multipleQueries([{
          indexName: this.config.indexName,
          facets: this.facetKeys,
          filters,
          page: this.currentPage,
          query,
        }, ...facetRequests]);
        const { result, facetResults } = formatResults(results);
        this.hitsCounter = `${result.nbHits} results in ${result.processingTimeMS}ms`;
        this.currentResults = this.isHydrate ? await this.hydrate(result) : result;
        this.facetResults = buildFacetResults(this.facetResults, result.facets, facetResults);

      } catch (e) {
        console.log(e);
      } finally {
        this.isSearching = false;
      }

      function formatResults({ results }) {
        if (results.length > 1) {
          const [result, ...facetResults] = results;
          return {
            result,
            facetResults,
          };
        }
        const [result] = results;
        return {
          result,
        };
      }

      function buildFacetRequests(currentFacets, query, indexName) {
        if (!currentFacets) return [];
        return Object.keys(currentFacets).map((facetKey) => {
          const facetMap = { ...currentFacets };
          delete facetMap[facetKey];
          const filters = buildFacetFilter(facetMap);
          return {
            facets: [facetKey],
            indexName,
            filters,
            query,
          };
        });
      }

      function buildFacetFilter(currentFacets) {
        if (!currentFacets) return;
        return chain(currentFacets)
          .map((facetKeys, facet) => {
            // toggle facets are booleans
            if (isBoolean(facetKeys)) return `${facet}:${facetKeys}`;
            // facets are generally arrays of values you care about
            if (isArray(facetKeys)) return facetKeys.map((value) => `${facet}:'${value}'`).join(' OR ');
          })
          .filter(identity)
          .value()
          .join(' AND ');
      }

      function buildFacetResults(currentResults, queryResults, overrideResults) {
        if (!overrideResults) return queryResults;
        return overrideResults.reduce((facetMap, { facets }) => ({
          ...facetMap,
          ...(facets ?? {}),
        }), queryResults);
      }
    },
    async hydrate(result) {
      const ids = result.hits.map(h => h.objectID);
      const dbRef = db.collection(this.config.indexName);
      const dbResults = await getChunkedResults(dbRef, ids);
      const dbResultsById = keyBy(dbResults, 'id');
      return {
        ...result,
        hits: result.hits.map(h => ({
          ...h,
          ...dbResultsById[h.objectID],
        })),
      };
    },
  },
};
</script>

<style scoped lang="scss">
.algolia-list {
  .hits-counter {
    display: flex;
    flex-direction: row-reverse;
    font-size: 12px;
    padding: 5px;
  }
}
</style>
