<template>
  <div class="algolia-facet">
    <div class="facet-header">{{ facet.displayName }}</div>
    <div class="facet-results" v-if="hasFacets">
      <div class="facet-result" v-for="(facetResult, index) in orderedResults" :key="index">
        <b-field>
          <b-checkbox size="is-small" @input="changeFacet(facetResult)" v-model="facets[facetResult.key]">
            <span class="result-key">{{ facetResult.key }}</span>
            <span class="hits">{{ facetResult.numberOfHits }}</span>
          </b-checkbox>
        </b-field>
      </div>
      <div class="show-more" v-if="canShowMore">
        <a class="is-text" @click="toggleShowMore">Show {{ isShowingMore ? 'Less' : 'More' }}</a>
      </div>
    </div>
    <div class="facet-results" v-else>
      <div class="no-results">No Results</div>
    </div>
  </div>
</template>

<script>
import { chain, map } from 'lodash';

export default {
  name: 'AlgoliaMultiValueFacet',
  props: {
    facet: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      howManyToShow: 5,
      isShowingMore: false,
      facets: this.facet.default ? { [this.facet.defaultValue]: true } : {},
    };
  },
  computed: {
    facetResultArray() {
      if (!this.facet.result) return [];

      return map(this.facet.result, (v, k) => {
        return {
          key: k,
          numberOfHits: v,
        };
      });
    },
    orderedResults() {
      const missingKeys = Object.keys(this.facets).filter(
        k => this.facets[k] && this.facetResultArray.findIndex(r => r.key === k) === -1
      );

      return chain([...this.facetResultArray, ...missingKeys.map(k => ({ key: k, numberOfHits: 0 }))])
        .sortBy(['numberOfHits'])
        .reverse()
        .take(this.howManyToShow)
        .value();
    },
    canShowMore() {
      return this.orderedResults.length > 4;
    },
    hasFacets() {
      return this.orderedResults.length > 0;
    },
  },
  methods: {
    toggleShowMore() {
      this.howManyToShow = this.isShowingMore ? this.howManyToShow - 5 : this.howManyToShow + 5;
      this.isShowingMore = !this.isShowingMore;
    },
    changeFacet(result) {
      this.$emit('facet-change', {
        key: this.facet.facet,
        value: result.key,
        type: 'multiValue',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.algolia-facet {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .facet-header {
    flex-grow: 1;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .facet-results {
    .result-key {
      font-size: 14px;
      margin-right: 5px;
    }

    .hits {
      border-radius: 99999px;
      border: 1px solid rgb(90, 94, 154);
      padding: 0 5px;
      font-size: 12px;
      background: rgb(245, 245, 250);
    }

    .show-more {
      margin-top: 2px;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .no-results {
    font-size: 13px;
  }
}
</style>
