import MLHReportPage from '@/pages/MLHReportPage';

export default [
  {
    component: MLHReportPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'MLH Page',
    },
    name: 'mlh',
    path: '/mlh',
  },
];
