import { DfrTaskFilter } from '@newmoon-org/shared';

import { db, storage } from './firebase';

const DB_REF = 'tasks';

export default {
  generateHref,
  generateFilterableReport,
  generateDfrReport,
  getByType,
};

function generateHref(ref) {
  return async () => {
    return await storage.ref(`tasks/reports-${ref.id}.csv`).getDownloadURL();
  };
}

function generateDfrReport(filters: DfrTaskFilter) {
  return new Promise(resolve => {
    const data = {
      filters,
      result: {},
      status: 'new',
      type: 'csvDfrReport',
      createdAt: new Date(),
    };
    const doc = db.collection(DB_REF).doc();
    return doc.set(data).then(() => resolve({ doc, getDownloadUrl: generateHref(doc) }));
  });
}

function generateFilterableReport(collectionName, filters) {
  return new Promise(resolve => {
    const data = {
      status: 'new',
      collection: collectionName,
      filters,
      type: 'csvFilterableReport',
      createdAt: new Date(),
    };

    const doc = db.collection(DB_REF).doc();
    return doc.set(data).then(() => resolve({ doc, getDownloadUrl: generateHref(doc) }));
  });
}

async function getByType(type, limit = 10) {
  return db
    .collection(DB_REF)
    .where('type', '==', type)
    .orderBy('createdAt', 'desc')
    .limit(limit)
    .get()
    .then(sn => sn.docs.map(d => ({ ...d.data(), id: d.id })));
}
