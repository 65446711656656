<template>
  <div class="p-3 product">
    <div class="p-0 m-0 columns is-fullwidth">
      <div class="p-0 mb-4 column is-flex is-flex-direction-row is-justify-content-space-between">
        <div class="is-size-5 has-text-weight-bold">{{ lineItem.product.name }}</div>
        <b-tooltip v-if="lineItem.product.trainingLink" label="Training Link">
          <b-button class="ml-2" icon-left="info" size="is-small" @click="goToTraining"></b-button>
        </b-tooltip>
      </div>
      <div class="column is-pulled-right is-one-fifth" v-if="!(isDiscount || isAssessmentFee) && isEditable">
        <b-field class="is-pulled-right">
          <b-switch v-model="lineItem.isOptional">Optional</b-switch>
        </b-field>
      </div>
    </div>
    <div v-if="!(isDiscount || isAssessmentFee)">
      <div v-if="isEditable">
        <div class="columns">
          <div class="column is-one-third" v-if="lineItem.product.productArea">
            <b-field label="Area Of The Property">
              <b-input
                v-model="lineItem.product.productArea.label"
                type="text"
                required="true"
                expanded
                :disabled="isViewOnly"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-one-third" v-else>
            <b-field label="Area Of The Property">
              <b-input v-model="lineItem.product.productArea" type="text" placeholder="" expanded disabled></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Category">
              <b-input v-model="lineItem.product.productCategory" type="text" expanded :disabled="isViewOnly"></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Item">
              <b-input
                v-model="lineItem.product.catalogType.name"
                type="text"
                expanded
                :disabled="isViewOnly"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <b-field label="Expected Duration In Hours">
              <b-input
                v-model="lineItem.product.duration"
                step="0.1"
                type="number"
                @input="onChange"
                expanded
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Quantity">
              <b-input
                v-model="lineItem.quantity"
                step="0.05"
                type="number"
                expanded
                @input="onChange"
                :disabled="isViewOnly"
              ></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="field-group">
        <div class="field-title">Specific Language</div>
        <div class="field-data">
          {{ lineItem.product.specificLanguage ? lineItem.product.specificLanguage : 'None' }}
        </div>
      </div>
      <div class="field-group">
        <div class="field-title">Warranty Status</div>
        <div class="field-data">{{ lineItem.product.warrantyStatus ? lineItem.product.warrantyStatus : 'None' }}</div>
      </div>
    </div>
    <div class="my-3 columns" v-if="isDiscount && isEditable">
      <div class="column is-half"></div>
      <div class="column is-half">
        <b-field label="Quantity">
          <b-input v-model="lineItem.quantity" step="1" type="number" min="0" expanded @input="onChange"></b-input>
        </b-field>
      </div>
    </div>
    <div class="pb-2 columns">
      <div class="column" v-if="isAddable">
        <b-button class="is-centered is-text" @click="remove">Remove</b-button>
      </div>
      <template v-if="showProductPrice">
        <div class="column" v-if="isDiscount">
          <div class="product-totals">
            <div class="total">
              <div class="total-label">Discount Price</div>
              <div class="total-value">({{ markdownAmount | money }})</div>
            </div>
            <div class="total">
              <div class="total-label">Discount %</div>
              <div class="total-value">({{ markdownPercentage }}%)</div>
            </div>
          </div>
        </div>
        <div class="column" v-else-if="isAssessmentFee && !ignoreTaxes">
          <div class="product-totals">
            <div class="total" v-if="!ignoreTaxes">
              <div class="total-label">Non Taxable</div>
              <div class="total-value">{{ (lineItem.product.assessmentFeeCost / 100) | money }}</div>
            </div>
          </div>
        </div>
        <div class="column" v-else>
          <div class="product-totals">
            <div class="total" v-if="!isEditable">
              <div class="total-label">Quantity</div>
              <div class="total-value">{{ lineItem.quantity }}</div>
            </div>
            <div class="total" v-if="!ignoreTaxes">
              <div class="total-label">Non Taxable</div>
              <div class="total-value">{{ (total.markupNonTaxable / 100) | money }}</div>
            </div>
            <div class="total" v-if="!ignoreTaxes">
              <div class="total-label">Taxable</div>
              <div class="total-value">{{ (total.markupTaxable / 100) | money }}</div>
            </div>
            <div class="total" v-if="ignoreTaxes">
              <div class="total-label">Total</div>
              <div class="total-value">{{ (total.total / 100) | money }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { CatalogService as catalogShared } from '@newmoon-org/shared';

import CatalogItemsService from '@/service/catalog-items.service';

export default {
  name: 'Product',
  props: {
    ignoreTaxes: {
      type: Boolean,
      default: false,
    },
    lineItem: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isAddable: {
      type: Boolean,
      default: true,
    },
    showProductPrice: {
      type: Boolean,
      default: false,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    total() {
      const { priceToCustomerCost } = catalogShared.getCatalogProductPrice(
        this.lineItem.product,
        this.lineItem.quantity
      );
      return {
        markupTaxable: priceToCustomerCost?.markupTaxable ?? 0,
        markupNonTaxable: priceToCustomerCost?.markupNonTaxable ?? 0,
        total: priceToCustomerCost.total,
      };
    },
    markdownPercentage() {
      return (this.lineItem.product.markdownPercentage ?? 0) * this.discountQuantity;
    },
    markdownAmount() {
      return (this.lineItem.product.markdownCost * this.discountQuantity) / 100;
    },
    isDiscount() {
      return ['warranty-discount', 'discount'].includes(this.lineItem.product.specification);
    },
    isAssessmentFee() {
      return this.lineItem.product.specification === 'assessment-fee';
    },
    discountQuantity() {
      return this.lineItem.quantity ?? 1;
    },
  },
  async mounted() {
    if (!this.lineItem?.product) return;
    this.lineItem.product.items = await CatalogItemsService.getItemsForProduct(this.lineItem?.product);
  },
  methods: {
    goToTraining() {
      window.open(this.lineItem.product.trainingLink, '_blank');
    },
    onChange() {
      this.$emit('quantityChange', { ...this.lineItem });
    },
    async remove() {
      const { result } = await this.$buefy.dialog.confirm({
        message: `Remove product ${this.lineItem.product.name}, are you sure?`,
        closeOnConfirm: true,
      });

      if (result) {
        this.$emit('remove', { ...this.lineItem });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.field-group {
  display: flex;
  flex-direction: row;
  margin: 0.75rem 0 0.75rem 0;
}

.field-title {
  font-weight: 600;
  font-size: 1rem;
  margin-right: 0.75rem;
  min-width: 150px;
}

.field-data {
  font-size: 1rem;
}

.total {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.total-label {
  min-width: 200px;
  font-size: 14px;
}

.total-value {
  min-width: 100px;
  margin-left: 1em;
  font-size: 14px;
}
</style>
