import Vue from 'vue';
import Router from 'vue-router';
import { isEmpty } from 'lodash';

import store from '@/store';
import catalogRoutes from '@/router/catalog';
import customerRoutes from '@/router/customers';
import loginRoutes from '@/router/login';
import workorderRoutes from '@/router/workorders';
import employeesRoutes from '@/router/employees';
import accountingreportRoutes from '@/router/accountingreport';
import dispatchWorkflowsRoutes from '@/router/dispatchWorkflows';
import timeCommitmentRoutes from '@/router/timeCommitment';
import adminPanelRoutes from '@/router/adminPanel';
import projectBookingRoutes from '@/router/project';
import timeOffRoutes from '@/router/timeOff';
import dfrRoutes from '@/router/dfr';
import mlhRoutes from '@/router/mlh';
import reportingRoutes from '@/router/reporting';
import helpRoutes from '@/router/help';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...catalogRoutes,
    ...customerRoutes,
    ...loginRoutes,
    ...workorderRoutes,
    ...employeesRoutes,
    ...accountingreportRoutes,
    ...dispatchWorkflowsRoutes,
    ...timeCommitmentRoutes,
    ...adminPanelRoutes,
    ...projectBookingRoutes,
    ...timeOffRoutes,
    ...dfrRoutes,
    ...mlhRoutes,
    ...reportingRoutes,
    ...helpRoutes,
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let currentUser = store.getters['auth/user'];
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (to.path === '/') return next('work-orders');
  if (to.path === from.path) return;
  if (!requiresAuth) return next();
  if (requiresAuth && !currentUser?.firebaseUser) return next('login');
  if (isEmpty(currentUser.employee) || !canViewPage(currentUser, to)) return next('missing-access');

  return next();
});

function canViewPage(currentUser, goingToPage) {
  let hasPermission = store.getters['auth/hasPermission'];
  const excludedPageViewsAsNames = getExcludedPageViewsAsNames(currentUser);

  // TODO Remove this system in favor of permissions on routes
  const legacyCanAccessPage = () => {
    return isEmpty(excludedPageViewsAsNames) || !excludedPageViewsAsNames.includes(goingToPage?.name?.toLowerCase());
  };

  const hasPermissionToViewPage = () => {
    const permissions = goingToPage?.meta?.permissions;
    if (!permissions) return true;
    return permissions.find(p => hasPermission(p));
  };

  return legacyCanAccessPage() && hasPermissionToViewPage();
}

function getExcludedPageViewsAsNames(currentUser) {
  return currentUser?.employee?.role?.noViewPages?.map(it => it.name.toLowerCase()) || [];
}

Vue.prototype.$store = store;

export default router;
