<template>
  <div class="my-2 work-order-payment-submission">
    <div class="mt-3 mb-4 is-size-6 has-text-weight-bold">New Payment</div>
    <div v-if="isLoading">
      <b-progress></b-progress>
    </div>
    <div class="payment-submission-form" v-else>
      <b-field label="Method of Payment">
        <b-select v-model="methodOfPayment" expanded>
          <option v-for="paymentMethod in paymentMethods" :value="paymentMethod.value" :key="paymentMethod.value">
            {{ paymentMethod.label }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Customer Payment Amount">
        <b-input v-model="customerPaymentAmount" expanded></b-input>
      </b-field>
      <b-field label="Comment">
        <textarea class="textarea" v-model="customerPaymentComment"></textarea>
      </b-field>
      <div class="is-flex is-flex-direction-row is-justify-content-flex-end">
        <b-button class="is-success" @click="submit">Submit Payment</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import WorkordersService, { WORK_ORDER_PAYMENT_METHODS } from '@/service/workorders.service';

export default {
  name: 'WorkOrderPaymentSubmission',
  props: {
    workOrderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      paymentMethods: WORK_ORDER_PAYMENT_METHODS,
      methodOfPayment: null,
      customerPaymentAmount: 0,
      customerPaymentComment: null,
    };
  },
  computed: {
    isValid() {
      return !(!this.methodOfPayment || !this.customerPaymentAmount);
    },
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        this.$buefy.notification.open({
          message: 'Please fill out all the required Payment fields, (method of payment, payment amount)',
          type: 'is-info',
        });
        return;
      }

      this.isLoading = true;
      const payment = {
        methodOfPayment: this.methodOfPayment,
        customerPaymentAmount: this.customerPaymentAmount * 100,
        customerPaymentComment: this.customerPaymentComment,
        createdAt: new Date(),
      };

      try {
        let { payments } = await WorkordersService.getById(this.workOrderId);
        if (!payments) payments = [];
        payments.push(payment);

        await WorkordersService.mergeUpdate(this.workOrderId, { payments });
        this.$emit('newPayment', payment);
        this.reset();
      } catch (e) {
        //ignore
      } finally {
        this.isLoading = false;
      }
    },
    reset() {
      this.methodOfPayment = null;
      this.customerPaymentAmount = 0;
      this.customerPaymentComment = null;
    },
  },
};
</script>

<style scoped></style>
