<template>
  <div class="my-2 work-order-payments">
    <div class="is-size-4 header">Payments</div>
    <div class="line-items">
      <list :data="payments" :config="listConfig"></list>
    </div>
    <work-order-payment-submission
      v-if="canEdit"
      @newPayment="reloadWorkOrder"
      :work-order-id="workOrder.id"
    ></work-order-payment-submission>
  </div>
</template>

<script>
import { isDate, isNumber, isString, toUpper } from 'lodash';

import WorkordersService from '@/service/workorders.service';

import List from '@/components/list/list.vue';
import WorkOrderPaymentSubmission from '@/components/workorders/WorkOrderPaymentSubmission.vue';

export default {
  name: 'WorkOrderPayments',
  components: { List, WorkOrderPaymentSubmission },
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      payments: [],
      listConfig: {
        emptyText: 'No Payments',
        labelField: row => `${this.formatMoney(row.customerPaymentAmount / 100)}`,
        labelLabel: 'Payment Amount',
        infoFields: [
          {
            infoLabel: 'Payment Type',
            infoField: row => toUpper(row.methodOfPayment),
          },
          {
            infoLabel: 'Comments',
            infoField: row => `${row.customerPaymentComment ?? 'None'}`,
          },
          {
            infoLabel: 'Date',
            infoField: r => this.convertToDate(r.createdAt),
          },
        ],
        paginationPerPage: 5,
        listActions: [],
      },
    };
  },
  mounted() {
    this.payments = this.workOrder.payments ?? [];
    if (this.canEdit) {
      this.listConfig.listActions.push({
        action: row => this.removePayment(row),
        icon: 'trash',
        style: 'is-danger',
      });
    }
  },
  methods: {
    async reloadWorkOrder() {
      const { payments } = await WorkordersService.getById(this.workOrder.id);
      this.payments = payments;
      this.$emit('newPayment', { payments });
    },
    formatMoney(value) {
      return (Number(value) ?? 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    convertToDate(date) {
      const formatDate = date?.seconds
        ? new Date(date.seconds * 1000)
        : isNumber(date) || isString(date)
        ? new Date(date)
        : isDate(date)
        ? date
        : null;
      return formatDate
        ? formatDate.toLocaleString('en-US', {
            timeZone: 'America/Denver',
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : 'Unknown';
    },
    async removePayment({ index }) {
      this.workOrder.payments.splice(index, 1);
      const payments = [...this.workOrder.payments];
      await WorkordersService.mergeUpdate(this.workOrder.id, { payments });
      await this.reloadWorkOrder();
    },
  },
};
</script>
