<template>
  <div id="roles-admin-page">
    <div class="text-center">
      <p class="mt-1 text-sm text-gray-500">Access Levels has moved</p>
      <div class="mt-5">
        <a class="button" type="primary" :href="href" target="_blank">Access Levels</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RolesAdminPage',
  data() {
    return {
      href: `${process.env.VUE_APP_NUXT_BASE_URL}/admin?tab=Roles`,
    };
  },
};
</script>
