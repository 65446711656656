<template>
  <div class="mr-3 active-bookings-info" v-if="workorderBookingsInFlight.length > 0">
    <div class="p-0 columns">
      <div class="p-0 column">
        <b-tooltip
          class="mt-4 is-pulled-right"
          label="Click to see the details of workorder bookings in flight!"
          position="is-left"
        >
          <b-button
            class="flicker"
            rounded
            size="is-small"
            type="is-primary"
            icon-left="briefcase"
            :disabled="isActive"
            :label="numberOfActiveBookingsString"
            @click="isActive = true"
            ref="notificationButton"
          ></b-button>
        </b-tooltip>
      </div>
    </div>
    <div class="p-0 columns is-relative">
      <div class="p-0 column">
        <b-message
          class="is-dark"
          auto-close
          :duration="30000"
          title="Workorder Bookings In Flight"
          has-icon
          type="is-dark"
          v-model="isActive"
          aria-close-label="Close"
        >
          {{ formattedText }}
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'ActiveBookingsInfo',
  data() {
    return {
      isActive: false,
      duration: 10000,
    };
  },
  computed: {
    ...mapGetters('pageActions', ['workorderBookingsInFlight']),
    numberOfActiveBookingsString() {
      return this.workorderBookingsInFlight.length.toString();
    },
    formattedText() {
      const locale = 'en-us';
      const options = {
        weekday: 'short', // long, short, narrow
        month: 'short', // numeric, 2-digit, long, short, narrow
        day: 'numeric', // numeric, 2-digit
        hour: 'numeric', // numeric, 2-digit
        minute: 'numeric', // numeric, 2-digit
      };
      return this.workorderBookingsInFlight
        .map(
          it =>
            `${it.editedBy} / ${it.startDate.toLocaleString(locale, options)} - ${it.endDate.toLocaleString(
              locale,
              options
            )} / ${it.assignedTechs?.length || 0} techs`
        )
        .join('\n');
    },
  },
  watch: {
    numberOfActiveBookingsString: {
      handler() {
        this.$refs.notificationButton?.$el?.classList?.remove('flicker');
        debounce(() => this.$refs.notificationButton?.$el?.classList?.add('flicker'), 1)();
      },
    },
  },
};
</script>

<style lang="scss">
@keyframes flicker {
  from {
    background-color: dodgerblue;
  }
  to {
    background-color: inherit;
  }
}

.active-bookings-info {
  .flicker {
    animation: flicker 600ms 5 ease-out;
  }

  .message {
    white-space: pre-wrap;
    position: absolute;
    max-height: 300px;
    width: 650px;
    overflow: auto;
    right: 0;
    top: -30px;
    box-shadow: 2px 2px 3px 2px rgb(0.12, 0.12, 0.12, 0.12);

    .message-header {
      position: sticky !important;
      top: 0 !important;
    }
  }
}
</style>
