<template>
  <div id="schedule-edit-page">
    <div class="loading" v-if="$asyncComputed.schedule.updating">
      <div class="header">Schedule</div>
      <div class="is-size-4">Loading</div>
      <b-progress></b-progress>
    </div>
    <div class="not-loading" v-else>
      <div class="header">Schedule</div>
      <form @submit.prevent="() => save()">
        <div class="columns">
          <div class="column warning-message">
            <b-icon icon="exclamation-triangle" type="is-warning"></b-icon>
            <span>Schedule is repeatable</span>
          </div>
          <div class="column" v-if="!isSingleWeekSchedule">
            <b-tooltip label="Select most recent billing date in the past to start repeating the schedule">
              <b-field label="Repeats From">
                <b-datepicker
                  placeholder="Select Date..."
                  v-model="newSchedule.repeatFromDate"
                  icon="calendar-day"
                  :min-date="lastMonth"
                  :max-date="new Date()"
                  required
                  editable
                ></b-datepicker>
              </b-field>
            </b-tooltip>
          </div>
          <div class="column"></div>
          <div class="column"></div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <b-field>
              <b-switch v-model="newSchedule.status" true-value="active" false-value="inactive">Active</b-switch>
            </b-field>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-half">
            <b-field label="Name">
              <b-input v-model="newSchedule.name" expanded required></b-input>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Code (Alias)">
              <b-input v-model="newSchedule.code" expanded></b-input>
            </b-field>
          </div>
        </div>
        <schedule-week-edit-page
          class="mb-2"
          v-for="(week, idx) in newSchedule.weeks"
          :key="idx"
          :existing-week="week.days"
          :index="idx"
          :allow-delete="!canAddMoreWeeks"
          @update="weekUpdated"
          @delete="weekDeleted"
        ></schedule-week-edit-page>
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <div class="add-week-button" :class="{ disabled: !canAddMoreWeeks }" @click="addWeek">
              <b-icon class="week-icon" icon="plus-square" size="is-large"></b-icon>
              <span class="add-week-text">Add Week {{ newSchedule.weeks.length + 1 }}</span>
            </div>
          </div>
          <div class="column"></div>
        </div>
        <div class="columns">
          <div class="column action-buttons">
            <b-button class="mr-2" type="is-danger" @click="goBack">Cancel</b-button>
            <button class="button is-success" type="submit">{{ isNew ? 'Create' : 'Update' }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import SchedulesService from '@/service/schedules.service';

import visibilityMixin from '@/mixins/visibility';
import ScheduleWeekEditPage from '@/pages/ScheduleWeekEditPage';

const MAX_WEEKS = 2;

export default {
  name: 'ScheduleEditPage',
  components: {
    ScheduleWeekEditPage,
  },
  mixins: [visibilityMixin],
  props: {
    scheduleId: {
      type: String,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      newSchedule: { ...SchedulesService.DEFAULT_SCHEDULE },
      saving: false,
    };
  },
  asyncComputed: {
    schedule: {
      get() {
        return this.isNew
          ? { ...SchedulesService.DEFAULT_SCHEDULE }
          : SchedulesService.getById(this.scheduleId).then(it => {
              this.newSchedule = {
                ...this.newSchedule,
                ...it,
                weeks: it.weeks?.map(week => ({
                  days: week.days.map(day => ({
                    ...SchedulesService.DEFAULT_SCHEDULE_DEFINITION,
                    ...this.fillUpTimeData(day),
                  })),
                })),
              };
              return it;
            });
      },
      default: { ...SchedulesService.DEFAULT_SCHEDULE },
    },
  },
  computed: {
    lastMonth() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date;
    },
    canAddMoreWeeks() {
      return this.newSchedule.weeks.length < MAX_WEEKS;
    },
    isSingleWeekSchedule() {
      return this.newSchedule.weeks.length < 2;
    },
    days() {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    },
    pages() {
      return this.$router.options.routes.map(it => ({
        name: it.name.toLowerCase(),
        path: it.path,
      }));
    },
    router() {
      return this.$router;
    },
    isNew() {
      return this.scheduleId === 'new';
    },
  },
  watch: {
    isSingleWeekSchedule: {
      handler(value) {
        this.newSchedule.repeatFromDate = value ? null : this.newSchedule.repeatFromDate;
      },
    },
  },
  methods: {
    ...mapActions('auth', ['refreshEmployeeData']),
    addWeek() {
      if (this.canAddMoreWeeks) {
        this.newSchedule.weeks.push({ ...SchedulesService.DEFAULT_WEEK });
      }
    },
    fillUpTimeData(schedule) {
      return {
        ...schedule,
        timeSlots: schedule.timeSlots?.map(it => {
          let startTime = null;
          let endTime = null;

          if (it?.startHour !== null) {
            startTime = new Date();
            startTime.setHours(it.startHour, it.startMinutes || 0);
          }

          if (it?.endHour !== null) {
            endTime = new Date();
            endTime.setHours(it.endHour, it.endMinutes || 0);
          }
          return {
            startTime,
            endTime,
            timeString: this.getTimeString({ startTime, endTime }),
          };
        }),
      };
    },
    getTimeString(slot) {
      if (!slot?.startTime || !slot?.endTime) {
        return null;
      }

      return `${slot.startTime.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute: '2-digit',
      })} - ${slot.endTime.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    },
    weekUpdated(index, week) {
      this.newSchedule.weeks[index].days = week;
    },
    weekDeleted(index) {
      this.newSchedule.weeks.splice(index, 1);
      this.newSchedule.weeks = [...this.newSchedule.weeks];
    },
    goBack(schedule) {
      this.$emit('close', schedule);
    },
    async save() {
      try {
        const data = {
          ...this.newSchedule,
          weeks: this.newSchedule.weeks.map(week => ({
            days: week.days.map(it => {
              const validSlots = it.timeSlots.filter(slot => !!slot.timeString);
              const slots = validSlots.length > 0 ? validSlots : [{ startTime: null, endTime: null }];
              return {
                timeSlots: slots
                  .filter(slot => !!slot.timeString)
                  .map(slot => ({
                    startHour: slot.startTime?.getHours() ?? null,
                    startMinutes: slot.startTime?.getMinutes() ?? null,
                    endHour: slot.endTime?.getHours() ?? null,
                    endMinutes: slot.endTime?.getMinutes() ?? null,
                  })),
                breakDurationInMinutes: it.breakDurationInMinutes,
              };
            }),
          })),
          updateDate: new Date(),
        };
        const schedule = this.isNew
          ? await SchedulesService.create(data)
          : await SchedulesService.update(this.newSchedule.id, data);
        this.$buefy.toast.open({
          message: 'Successfully saved Schedule',
          type: 'is-success',
        });
        this.refreshEmployeeData();
        this.goBack(schedule);
      } catch (e) {
        this.$buefy.notification.open({
          message: `There was an error saving Schedule, ${e.message}`,
          type: 'is-danger',
          hasIcon: true,
          duration: 10000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
#schedule-edit-page {
  .warning-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    font-style: italic;
    font-size: 25px;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .add-week-button {
    height: 200px;
    width: 400px;
    border: darkgray dashed 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .week-icon {
      color: darkgray;
    }
    .add-week-text {
      margin-top: 5px;
      font-size: 20px;
      color: darkgray;
    }
    &:hover {
      cursor: pointer;
      border: gray solid 2px;
      .week-icon,
      .add-week-text {
        color: gray;
      }
    }
    &.disabled,
    &.disabled:hover {
      cursor: not-allowed;
      border: lightgray dashed 2px;
      .week-icon {
        color: lightgray;
      }
      .add-week-text {
        color: lightgray;
      }
    }
  }
}
</style>
