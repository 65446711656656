<template>
  <div id="login">
    <div class="card-overlay"></div>
    <div class="content-container">
      <form class="login" @submit="doLoginGoogle">
        <div class="image-container">
          <b-image
            src="https://flowrightphi.com/wp-content/uploads/2021/03/flow-right-site-logo-no-r-500.png"
          ></b-image>
        </div>
        <b-notification class="sign-in-error" v-model="hasError" type="is-danger" has-icon :indefinite="true">
          {{ errorMessage }}
        </b-notification>
        <div class="main-sign-in" v-if="!customLogin">
          <div class="my-3 main-login">
            <button class="button is-white is-outlined is-medium is-fullwidth" icon-left="sign-in-alt">Login</button>
          </div>
        </div>
        <div class="main-sign-in" v-else>
          <div class="my-3 main-login">
            <b-field label="Email">
              <b-input v-model="username"></b-input>
            </b-field>
            <b-field label="Password" type="password">
              <b-input v-model="password" type="password"></b-input>
            </b-field>
            <button class="button">Login Custom</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'Login',
  data: function () {
    return {
      username: '',
      password: '',
      hasError: false,
      errorMessage: null,
    };
  },
  computed: {
    customLogin() {
      return !isEmpty(this.$route.query.customlogin);
    },
  },
  mounted() {
    // sticky header on vue main app gets in the way
    const el = document.body;
    el.classList.remove('has-navbar-fixed-top');
  },
  beforeDestroy() {
    document.body.classList.add('has-navbar-fixed-top');
  },
  methods: {
    ...mapActions('auth', ['login', 'loginGoogle']),
    async doLogin(e) {
      e.preventDefault();
      try {
        await this.login({
          email: this.username,
          password: this.password,
        });
      } catch (e) {
        this.handleError(e);
      }
    },
    handleError(e) {
      this.hasError = true;
      if (e.code === 'auth/user-not-found') {
        // we dont want people to know if there is or is not a user
        this.errorMessage = 'The password is invalid or the user does not have a password.';
        return;
      }
      this.errorMessage = e.message;
    },
    async doLoginGoogle(e) {
      e.preventDefault();
      if (this.customLogin) {
        await this.doLogin(e);
        return;
      }
      try {
        await this.loginGoogle();
      } catch (e) {
        this.handleError(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  height: 100vh;
  background-image: url('~@/assets/background.jpg');
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;

  .card-overlay {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    backdrop-filter: blur(3px);
  }

  .content-container {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .image-container {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
  }

  .is-white {
    border-color: #fafafa;
    color: #fafafa;

    &:focus,
    &:hover {
      background-color: transparent !important;
    }
  }

  .secondary {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .main-sign-in,
  .alternative-sign-in {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main-login {
    width: 100%;
  }

  .alternative-sign-in-inputs {
    min-width: 400px;
  }

  .login {
    .sign-in-error {
      max-width: 510px;
    }

    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    width: auto;
    height: auto;
    max-height: 600px;
  }
}
</style>
