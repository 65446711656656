import { chunk, flatten, omit, pick } from 'lodash';
import { ConversionService } from '@newmoon-org/shared';

import searchService from '@/service/algolia';
import { db, fieldPath } from '@/service/firebase';

import store from '@/store';

const { mapDates, mapDoc } = ConversionService;

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_DATE_FIELDS = ['createdAt', 'updatedAt'];

export { init, getChunkedResults };

async function getChunkedResults(dbRef, ids) {
  if (!ids) return [];
  const chunks = chunk(ids, 10);
  const batches = await Promise.all(chunks.map(it => getBatches(it)));
  return flatten(batches);

  function getBatches(ids) {
    return dbRef
      .where(fieldPath.documentId(), 'in', [...ids])
      .get()
      .then(sn => sn.docs.map(d => ({ ...d.data(), id: d.id })));
  }
}

function init({ collectionPath, algoliaIndex, dateFields, schema, filterDeleted }: initValues) {
  const dbRef = db.collection(collectionPath);
  const index = algoliaIndex ? searchService.get(algoliaIndex) : null;
  const normalizedDateFields = [...DEFAULT_DATE_FIELDS, ...(dateFields ?? [])];

  return {
    fieldPath,
    dbRef,
    list,
    getAll,
    getAllActive,
    getById,
    create,
    preCreate,
    update,
    remove,
    mapDocWithDates,
    mapDataDates,
    listByIds,
    getChunkedDBResults,
    softDelete,
    dateFields: normalizedDateFields,
    collectionPath,
  };

  function mapDocWithDates(doc, toUi = true) {
    const result = mapDoc(doc);
    return mapDataDates(result, toUi);
  }

  function mapDataDates(data, toUi = true) {
    return mapDates(data, normalizedDateFields, toUi);
  }

  async function getAll() {
    return dbRef
      .get()
      .then(r => r.docs.map(it => mapDocWithDates(it)).filter(it => (filterDeleted ? !it.deleted : true)));
  }

  async function getAllActive(status = 'active') {
    return dbRef
      .where('status', '==', status)
      .get()
      .then(r => r.docs.map(it => mapDocWithDates(it)).filter(it => (filterDeleted ? !it.deleted : true)));
  }

  async function getById(id) {
    return dbRef
      .doc(id)
      .get()
      .then(r => (r.exists && (filterDeleted ? !r.data()?.deleted : true) ? mapDocWithDates(r) : {}));
  }

  async function create(record) {
    const ref = dbRef.doc();
    const toSave = preCreate(record);
    await ref.set(toSave);
    return {
      id: ref.id,
      ...toSave,
    };
  }

  function preCreate(record) {
    const normalized = mapDates(omit(record, 'id'), normalizedDateFields, false);
    normalized.createdAt = new Date();
    const user = store.getters['auth/user'].employee;
    normalized.createdBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);
    let toSave = normalized;
    if (schema) toSave = new schema(normalized).toFirebase();
    return toSave;
  }

  async function update(id, record, merge = true) {
    if (!id) {
      console.warn('Missing id to update a record');
      return;
    }

    const ref = dbRef.doc(id);
    const normalized = mapDates(omit(record, 'id', 'createdAt'), normalizedDateFields, false);
    normalized.updatedAt = new Date();
    const user = store.getters['auth/user'].employee;
    normalized.updatedBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);
    let toSave = normalized;
    if (schema) toSave = new schema(normalized).toFirebase();

    await ref.set(toSave, { merge });
    return {
      id,
      ...toSave,
    };
  }

  async function softDelete(id) {
    await dbRef.doc(id).set({ deleted: true }, { merge: true });
  }

  async function remove(id) {
    if (!id) {
      console.warn('Missing id to delete as record. Skipping...');
      return;
    }

    await dbRef.doc(id).delete();
    return id;
  }

  async function listByIds(ids) {
    return await getChunkedDBResults(ids);
  }

  async function list({ pageSize = 40, page = 0, search = '', searchFilter }) {
    if (!algoliaIndex) throw new Error(`Must use algoliaIndex for ${collectionPath}`);
    const actualPageSize = Number(pageSize ?? DEFAULT_PAGE_SIZE);
    const actualPage = Number(page ?? 0);
    return listAlgolia();

    async function listAlgolia() {
      // null based search to get the total
      const algoliaConfig = {
        attributesToRetrieve: undefined,
        attributesToHighlight: undefined,
        hitsPerPage: actualPageSize,
        page: actualPage,
        filters: searchFilter,
      };

      try {
        const r = await index?.search(search || '', algoliaConfig);
        const data = await getChunkedDBResults(r?.hits.map(h => h.objectID));
        const meta = { ...omit(r, 'hits'), total: r?.nbHits };
        return { ...meta, data };
      } catch (e) {
        const error = e as Error;
        throw new Error(error.message);
      }
    }
  }

  async function getChunkedDBResults(ids) {
    if (!ids) return [];
    const chunks = chunk(ids, 10);
    const batches = await Promise.all(chunks.map(it => getBatches(it)));
    return flatten(batches);

    function getBatches(ids) {
      return dbRef
        .where(fieldPath.documentId(), 'in', [...ids])
        .get()
        .then(sn => sn.docs.map(it => mapDocWithDates(it)).filter(it => (filterDeleted ? !it.deleted : true)));
    }
  }
}

interface initValues {
  collectionPath: string;
  dateFields?: string[];
  algoliaIndex?: string;
  schema?;
  filterDeleted?: boolean;
}
