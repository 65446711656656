import PageActionsService from '@/service/page-actions.service';

export default {
  methods: {
    upsertPageAction(actionId, action, merge = false) {
      return PageActionsService.update(actionId, action, merge);
    },
    removePageAction(actionId) {
      return PageActionsService.remove(actionId);
    },
  },
};
