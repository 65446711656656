<template>
  <div id="customers">
    <div class="header-container">
      <div class="header">Projects</div>
      <b-tooltip label="Create a new project" position="is-left">
        <b-button class="is-primary" icon-right="plus" @click="createProject"></b-button>
      </b-tooltip>
    </div>
    <div class="table-content">
      <search-list index-name="projects" :facets="facets" :facet-toggles="toggleFacets">
        <template #results="{ items }">
          <table class="table is-striped">
            <thead>
              <th>Name</th>
              <th>Project Code</th>
              <th>Company</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.objectID">
                <td>
                  <a @click="editProject(item)">
                    <ais-highlight attribute="name" :hit="item"></ais-highlight>
                  </a>
                </td>
                <td>
                  <ais-highlight attribute="code" :hit="item"></ais-highlight>
                </td>
                <td>
                  <ais-highlight attribute="company" :hit="item"></ais-highlight>
                </td>
                <td>
                  <span>{{ formatDate(item.startDate_timestamp) }}</span>
                </td>
                <td>
                  <span>{{ formatDate(item.endDate_timestamp) }}</span>
                </td>
                <td>
                  <b-button
                    class="button is-small"
                    @click="editProject(item)"
                    icon-right="edit"
                    style="margin-right: 5px"
                  ></b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </search-list>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SearchList from '@/components/list/searchList.vue';

export default {
  name: 'ProjectListPage',
  components: { SearchList },
  data() {
    return {
      isLoading: true,
      facets: [
        { facet: 'status', displayName: 'Status' },
        { facet: 'foreman.email', displayName: 'Foreman' },
        { facet: 'company', displayName: 'Company' },
      ],
      toggleFacets: [
        {
          facet: 'deleted',
          displayName: 'Deleted',
          on: true,
          off: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    loggedInUser() {
      return this.user?.employee ?? {};
    },
  },
  methods: {
    async createProject() {
      await this.$router.push({
        name: 'project-create',
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleString('en-US', {
        timeZone: 'America/Denver',
        dateStyle: 'short',
        timeStyle: 'short',
      });
    },
    async editProject(project) {
      await this.$router.push({
        name: 'project-edit',
        params: {
          projectId: project.objectID,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
