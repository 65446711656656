<template>
  <div id="data-history-inspector-page">
    <div class="title is-3">Data History</div>
    <template v-if="$asyncComputed.history.updating">
      <div>Loading</div>
      <b-progress></b-progress>
    </template>
    <div class="grid grid-cols-2 pb-6 gap-x-8 gap-y-10">
      <div class="sm:col-span-1">
        <b-field label="Data Collection">
          <b-select v-model="collection" expanded>
            <option v-for="value in collections" :value="value" :key="value">
              {{ value }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="sm:col-span-1">
        <b-field label="Instance ID">
          <input class="input" type="text" placeholder="Data Instance Id" v-model="instanceId" />
        </b-field>
      </div>
    </div>
    <div class="text-center pb-3" v-if="history.length">
      <b-button class="mr-2" icon-left="less-than" :disabled="!hasPrevious" @click="index -= 1" />
      <b-button icon-left="greater-than" :disabled="!hasNext" @click="index += 1" />
    </div>
    <vue-json-compare
      v-if="history.length"
      :old-data="oldValue"
      :new-data="newValue"
      class="border border-gray-900/10"
    ></vue-json-compare>
    <div class="text-center pt-3" v-if="history.length">
      <b-button class="mr-2" icon-left="less-than" :disabled="!hasPrevious" @click="index -= 1" />
      <b-button icon-left="greater-than" :disabled="!hasNext" @click="index += 1" />
    </div>
  </div>
</template>

<script>
import vueJsonCompare from 'vue-json-compare';

import { SUPPORTED_COLLECTION, getHistory } from '@/service/dataHistory.service';

export default {
  name: 'DataHistoryInspectorPage',
  components: {
    vueJsonCompare,
  },
  data() {
    return {
      instanceId: null,
      index: 0,
      collection: SUPPORTED_COLLECTION.WORKORDERS,
    };
  },
  computed: {
    collections() {
      return SUPPORTED_COLLECTION;
    },
    oldValue() {
      return this.index <= this.history.length - 1 ? this.history[this.index].data : {};
    },
    newValue() {
      const nextIndex = this.index + 1 <= this.history.length - 1 ? this.index + 1 : this.index;
      return nextIndex <= this.history.length - 1 ? this.history[nextIndex].data : {};
    },
    hasNext() {
      return this.index + 2 <= this.history.length - 1;
    },
    hasPrevious() {
      return this.index - 1 >= 0;
    },
  },
  watch: {
    collection: {
      handler() {
        this.instanceId = null;
      },
    },
  },
  asyncComputed: {
    history: {
      get() {
        this.index = 0;
        return this.collection && this.instanceId ? getHistory(this.collection, this.instanceId) : [];
      },
      default: [],
    },
  },
  mounted() {
    console.log('hi');
  },
};
</script>
