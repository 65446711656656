import { ProductArea } from '@newmoon-org/types';

import { db } from '@/service/firebase';

export async function create(productArea: ProductArea): Promise<ProductArea> {
  const ref = db.collection('productAreas').doc();
  await ref.set({
    ...productArea,
    createdAt: new Date(),
  });
  return {
    ...productArea,
    id: ref.id,
  };
}

export async function update(productArea: ProductArea): Promise<ProductArea> {
  const ref = db.collection('productAreas').doc(productArea.id);

  const { id, ...rest } = productArea;
  const updateDb = { ...rest, updatedAt: new Date() };

  await ref.set(updateDb, { merge: true });
  return {
    ...updateDb,
    id,
  };
}

export async function remove({ id }: ProductArea): Promise<void> {
  await db.collection('productAreas').doc(id).delete();
}

export async function getForCatalog(catalogTypeId: string): Promise<ProductArea[]> {
  const snapshot = await db
    .collection('productAreas')
    .where('catalogType.id', '==', catalogTypeId)
    .orderBy('createdAt')
    .get();

  return snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      updatedAt: data?.updatedAt?.toDate(),
      createdAt: data?.createdAt?.toDate(),
    } as ProductArea;
  });
}
