import { partial, isEmpty, isNull } from 'lodash';

export default {
  user: state => state.user,
  permissions: state => state.permissions,
  isLoggedIn: state => !isEmpty(state.user?.firebaseUser),
  isTech: () => partial(isRole, 'Service Tech'),
  isAdmin: () => partial(isRole, 'Admin'),
  hasPermission: state => {
    return key => {
      if (!state.permissions) return false;
      const permission = state.permissions.find(p => p.key === key);
      return !!permission;
    };
  },
  canModifyAsManager: (state, getters) => {
    return companyCode => {
      if (getters.hasPermission('team-management:admin')) {
        return true;
      }

      const canManageTeams = !!state.user?.employee?.manager || getters.hasPermission('team-management:write');
      return canManageTeams && !!state.user?.employee?.companies?.find(it => it.code === companyCode);
    };
  },
};

function isRole(roleName, state) {
  const role = state.user?.employee ?? null;
  if (isNull(role)) return false;
  return role.name === roleName;
}
