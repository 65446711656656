<template>
  <div id="dispatch-workflows-empty-state">
    <div class="content">Please Select a Work Order to View</div>
  </div>
</template>

<script>
export default {
  name: 'DispatchWorkflowsEmptyState',
};
</script>

<style scoped lang="scss">
#dispatch-workflows-empty-state {
  min-width: 600px;
  background-color: #efefef;
  border-radius: 2px;
  margin-right: 1em;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
