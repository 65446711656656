import { mapActions } from 'vuex';
import { DateService as dateServiceShared } from '@newmoon-org/shared';
import { flatten, identity, isEqual, uniqBy, uniqWith, values } from 'lodash';

import PageActionsService from '@/service/page-actions.service';

import pageActions from '@/mixins/pageActions';

export default {
  data() {
    return {
      conflictsStartUnsub: null,
      conflictsEndUnsub: null,
      pollerConflictsStart: [],
      pollerConflictsEnd: [],
      actionTypes: {
        WORKORDER: 'workorder',
        COSTCODE: 'costcode',
      },
    };
  },
  computed: {
    pollerConflicts() {
      return uniqBy([...this.pollerConflictsStart, ...this.pollerConflictsEnd], 'id');
    },
    conflictingBookingsAssignedTechs() {
      return uniqWith(
        flatten(this.pollerConflicts.map(it => [...(it.assignedTechs ?? []), it.foreman])).filter(identity),
        isEqual
      );
    },
  },
  watch: {
    pollerConflicts: {
      deep: true,
      handler(value) {
        this.updateWorkorderBookingsInFlight(value);
      },
    },
  },
  mounted() {
    this.updateWorkorderBookingsInFlight([]);
  },
  destroyed() {
    this.updateWorkorderBookingsInFlight([]);
  },
  methods: {
    ...pageActions.methods,
    ...mapActions('pageActions', ['updateWorkorderBookingsInFlight']),
    buildBasicActions() {
      return {
        actionType: this.actionTypes.WORKORDER,
        updateDate: new Date(),
      };
    },
    handleConflictingWorkorderBookingsLoad(
      startDate,
      endDate,
      excludeActionId,
      actionTypes = values(this.actionTypes)
    ) {
      if (this.conflictsStartUnsub) {
        this.conflictsStartUnsub();
        this.pollerConflictsStart = [];
      }

      if (this.conflictsEndUnsub) {
        this.conflictsEndUnsub();
        this.pollerConflictsEnd = [];
      }

      if (!startDate || !endDate || !excludeActionId) {
        return;
      }

      const startOfTheDay = dateServiceShared.getStartOfDay(startDate);
      const startOfNextDay = dateServiceShared.getStartOfTomorrow(endDate);
      const filterFunction = it => {
        const freshDate = new Date(new Date().getTime() - 70 * 1000).getTime();
        const dataDate = it.updateDate?.getTime();
        return (
          dataDate >= freshDate &&
          !!it.id &&
          excludeActionId !== it.id &&
          dateServiceShared.hasTimeConflict(it.startDate, it.endDate, startDate, endDate) &&
          [...(it.assignedTechs ?? []), it.foreman].filter(identity).length > 0
        );
      };

      this.conflictsStartUnsub = PageActionsService.dbRef
        .where('actionType', 'in', actionTypes)
        .where('startDate', '>=', startOfTheDay)
        .where('startDate', '<=', startOfNextDay)
        .onSnapshot(docs => {
          this.pollerConflictsStart = docs.docs
            .map(it => PageActionsService.mapDocWithDates(it))
            .filter(filterFunction);
        });

      this.conflictsEndUnsub = PageActionsService.dbRef
        .where('actionType', 'in', actionTypes)
        .where('endDate', '>=', startOfTheDay)
        .where('endDate', '<=', startOfNextDay)
        .onSnapshot(docs => {
          this.pollerConflictsEnd = docs.docs.map(it => PageActionsService.mapDocWithDates(it)).filter(filterFunction);
        });
    },
  },
};
