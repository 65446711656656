<template>
  <div class="search-list">
    <div class="filter-button">
      <b-button class="is-primary" @click="filterPanelOpen = !filterPanelOpen">
        {{ filterPanelOpen ? 'Apply' : 'Filters' }}
      </b-button>
    </div>
    <ais-instant-search
      :search-client="searchClient"
      :index-name="indexName"
      :search-function="globalSearchFunction"
      :routing="router"
    >
      <refresh :is-active="true" :index-name="indexName"></refresh>
      <ais-configure :filters="defaultFilters"></ais-configure>
      <div class="search-box">
        <div class="is-flex-direction-row is-flex grow-1">
          <ais-search-box class="is-flex-grow-1" :show-loading-indicator="false">
            <template #default="{ refine }">
              <b-field label="Search">
                <input
                  class="input"
                  type="search"
                  v-model="searchQuery"
                  @input="debounceSearch(refine, $event.currentTarget.value)"
                />
              </b-field>
            </template>
          </ais-search-box>
          <ais-sort-by v-if="hasSortBy" :items="sortBy"></ais-sort-by>
          <div class="ml-2 actions is-flex-grow-0" v-if="isReportable">
            <b-tooltip label="Generate a CSV Report with the current filters set." position="is-left">
              <b-button @click="generateReport" :loading="isGeneratingReport">
                <b-icon icon="download"></b-icon>
              </b-button>
            </b-tooltip>
            <div class="ml-2" v-if="reportIsDownloadable && !isGeneratingReport">
              <a class="button" download :href="reportHref">Download</a>
            </div>
          </div>
        </div>
        <div class="stats">
          <ais-stats></ais-stats>
        </div>
      </div>
      <ais-current-refinements class="current-refinement" v-if="hasFacets && showRefinements">
        <template #default="{ items, createURL }">
          <div class="refinement-container">
            <div class="refinement" v-for="(item, index) in getAllItems(items)" :key="`${index}-refinement`">
              <div class="refinement-label">{{ item.label }}{{ getRefinementLabel(item.refinement.label) }}</div>
              <a class="remove" :href="createURL(item.refinement)" @click.prevent="item.refine(item.refinement)">
                <b-icon icon="times" size="is-small"></b-icon>
              </a>
            </div>
          </div>
        </template>
      </ais-current-refinements>
      <div class="search-content">
        <div class="facets-mobile" v-if="hasFacets">
          <panel :model="filterPanelOpen" fullwidth>
            <template #content>
              <div class="filter-panel-container">
                <div class="facet" v-if="numericMenuAttributes.length > 0">
                  <div v-for="(menu, index) in numericMenuAttributes" :key="`${index}-numericAttr`">
                    <div class="facet-name">{{ menu.displayName }}</div>
                    <ais-numeric-menu :attribute="menu.attribute" :items="menu.items"></ais-numeric-menu>
                  </div>
                </div>
                <div class="facet" v-for="(def, index) in theFacetToggles" :key="`${index}-facet-toggle`">
                  <ais-toggle-refinement
                    :attribute="def.facet"
                    :label="def.displayName"
                    :class-names="{
                      'ais-ToggleRefinement-checkbox': 'checkbox',
                      'ais-ToggleRefinement-count': 'newmoon-badge-count',
                    }"
                    :on="def.on"
                    :off="def.off"
                  ></ais-toggle-refinement>
                </div>
                <div class="facet" v-for="(def, index) in facets" :key="`${index}-facet`">
                  <div class="facet-name">{{ def.displayName }}</div>
                  <ais-refinement-list
                    :attribute="def.facet"
                    :limit="5"
                    :class-names="{
                      'ais-RefinementList-count': 'newmoon-badge',
                      'ais-RefinementList-showMore': 'button is-text is-small my-2',
                    }"
                    show-more
                  ></ais-refinement-list>
                </div>
              </div>
            </template>
            <template #bottom>
              <div class="filter-button">
                <b-button class="is-primary" @click="filterPanelOpen = !filterPanelOpen">
                  {{ filterPanelOpen ? 'Apply' : 'Filters' }}
                </b-button>
              </div>
            </template>
          </panel>
        </div>
        <div class="facets" v-if="hasFacets">
          <div class="facet" v-for="(def, index) in theFacetToggles" :key="`${index}-facet-2`">
            <ais-toggle-refinement
              :attribute="def.facet"
              :label="def.displayName"
              :class-names="{
                'ais-ToggleRefinement-checkbox': 'checkbox',
                'ais-ToggleRefinement-count': 'newmoon-badge-count',
              }"
              :on="def.on"
              :off="def.off"
            >
              <template #default="{ items, refine, sendEvent, createUrl }">
                <slot name="toggle" :props="{ items, refine, sendEvent, createUrl }"></slot>
              </template>
            </ais-toggle-refinement>
          </div>
          <div class="facet" v-for="(menu, index) in numericMenuAttributes" :key="`${index}-numberic-2`">
            <ais-numeric-menu :attribute="menu.attribute" :items="menu.items" v-if="numericMenuAttributes.length > 0">
              <template #default="{ items, refine, sendEvent, createURL }">
                <slot name="custom-date" :props="{ items, refine, sendEvent, createURL }"></slot>
              </template>
            </ais-numeric-menu>
          </div>
          <div class="facet" v-for="(def, index) in facets" :key="`${index}-facet-list`">
            <div class="facet-name">{{ def.displayName }}</div>
            <ais-refinement-list
              :attribute="def.facet"
              :limit="5"
              :class-names="{
                'ais-RefinementList-count': 'newmoon-badge',
                'ais-RefinementList-showMore': 'button is-text is-small my-2',
              }"
              show-more
            ></ais-refinement-list>
          </div>
        </div>
        <ais-hits class="hits">
          <template #default="{ items }">
            <slot name="results" :items="transform(items)">
              <div class="hits-container">
                <div class="empty" v-if="!items.length">Nothing Found</div>
                <div class="hit" v-for="item in transform(items)" :key="item.objectID">
                  <div class="attributes" v-for="a in attributes" :key="a.key">
                    <div class="attribute">
                      <div class="attribute-label">{{ a.displayName }}</div>
                      <ais-highlight :attribute="a.key" :hit="item" v-if="a.hightlight"></ais-highlight>
                      <div class="attribute-value" v-else>{{ getItem(item, a.key) }}</div>
                    </div>
                  </div>
                  <div class="actions">
                    <div class="action" v-for="(a, index) in theActions" :key="`${index}-action`">
                      <b-tooltip v-if="a.tooltip && a.if(item)" :label="a.tooltip" :position="a.tooltipPosition">
                        <b-button
                          size="is-small"
                          :icon-left="getActionIcon(a, item)"
                          @click="a.click(item)"
                          :class="getActionClasses(a, item)"
                        ></b-button>
                      </b-tooltip>
                      <b-button
                        v-else-if="a.if(item)"
                        size="is-small"
                        :icon-left="getActionIcon(a, item)"
                        @click="a.click(item)"
                        :class="getActionClasses(a, item)"
                      ></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </template>
        </ais-hits>
      </div>
      <div class="pagination">
        <ais-pagination></ais-pagination>
      </div>
    </ais-instant-search>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { history } from 'instantsearch.js/es/lib/routers';
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings';
import { debounce, get, keyBy, pick, reduce } from 'lodash';

import { getSearchClient } from '@/service/algolia';
import TaskService from '@/service/task.service';
import { getChunkedResults } from '@/service/generic.service';
import { db } from '@/service/firebase';

import Refresh from '@/components/list/refresh.vue';
import Panel from '@/components/panel/Panel.vue';

export default {
  name: 'SearchList',
  components: {
    Refresh,
    Panel,
  },
  props: {
    showRefinements: {
      type: Boolean,
      default: true,
    },
    indexName: {
      type: String,
      required: true,
    },
    collectionName: {
      type: String,
      required: false,
      default: null,
    },
    facets: {
      type: Array,
      default: () => [],
    },
    facetToggles: {
      type: Array,
      default: () => [],
    },
    searchFunction: {
      type: Function,
      default: null,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    numericMenuAttributes: {
      type: Array,
      default: () => [],
    },
    debounceDelay: {
      type: Number,
      default: 200,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    transform: {
      type: Function,
      default: items => {
        return items;
      },
    },
    isReportable: {
      type: Boolean,
      default: false,
    },
    currentProductList: {
      type: Array,
      default: () => [],
    },
    defaultFilters: {
      type: String,
      default: '',
    },
    sortBy: {
      type: Array,
      default: null,
    },
    hydrate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      router: {
        router: history(),
        stateMapping: singleIndexMapping(this.indexName),
      },
      isGeneratingReport: false,
      searchClient: this.getSearch(this.hydrate),
      filterPanelOpen: false,
      searchQuery: '',
      debounceSearch: debounce(this.refineSearch, this.debounceDelay),
      searchState: {},
      reportIsDownloadable: false,
      reportHref: null,
    };
  },
  computed: {
    hasSortBy() {
      return !!this.sortBy;
    },
    reportCollectionName() {
      return this.collectionName ?? this.indexName;
    },
    facetDefsByFacetName() {
      return [...this.facets, ...this.facetToggles, ...this.numericMenuAttributes].reduce((acc, facetDef) => {
        if (facetDef.attribute) {
          return { ...acc, [facetDef.attribute]: facetDef };
        }
        return { ...acc, [facetDef.facet]: facetDef };
      }, {});
    },
    theActions() {
      return this.actions.map(a => {
        return {
          if: () => true,
          click: () => undefined,
          classes: [],
          ...a,
        };
      });
    },
    hasFacets() {
      return this.facetToggles.length !== 0 || this.facets.length !== 0;
    },
    theFacetToggles() {
      return this.facetToggles.map(t => ({
        on: true,
        ...t,
      }));
    },
  },
  mounted() {
    if (this.$router.currentRoute?.query?.query) this.searchQuery = this.$router.currentRoute.query.query;
  },
  methods: {
    getSearch() {
      const algoliaClient = getSearchClient();
      if (this.hydrate) {
        return {
          ...algoliaClient,
          search: async results => {
            return hydrateSearch(results, this.indexName);
          },
        };
      }
      return algoliaClient;

      async function hydrateSearch(algoliaRequests, indexName) {
        const response = await algoliaClient.search(algoliaRequests);
        const ids = getResults(response);
        const dbEntitiesById = await getDbEntitiesById(indexName, ids);
        response.results = response.results.map(result => {
          result.hits = result.hits.map(hit => {
            return {
              ...hit,
              ...dbEntitiesById[hit.objectID],
            };
          });
          return result;
        });

        return response;
      }

      async function getDbEntitiesById(indexName, ids) {
        const dbRef = db.collection(indexName);
        const dbEntities = await getChunkedResults(dbRef, ids);
        return keyBy(dbEntities, 'id');
      }

      function getResults(response) {
        const { results } = response;
        const ids = results.map(result => result.hits.map(hit => hit.objectID)).flat();
        return ids;
      }
    },
    getRefinementLabel(label) {
      return `: ${label}`;
    },
    globalSearchFunction(helper) {
      this.searchState = {
        ...pick(helper.state, ['disjunctiveFacetsRefinements', 'query', 'facetsRefinements', 'numericRefinements']),
      };
      if (this.searchFunction) {
        return this.searchFunction(helper);
      }
      const page = helper.getPage();
      helper.setPage(page).search();
    },
    async generateReport() {
      const { doc, getDownloadUrl } = await TaskService.generateFilterableReport(
        this.reportCollectionName,
        this.getFilters()
      );
      this.isGeneratingReport = true;
      const unsubscribe = doc.onSnapshot(async doc => {
        this.report = doc.data();

        if (this.report.status === 'new') return;

        if (this.report.status === 'error') {
          this.$buefy.notification.open({
            message: this.report.error,
            type: 'is-danger',
            indefinite: true,
            closable: true,
          });
        } else if (this.report.status === 'complete') {
          this.reportHref = await getDownloadUrl();
          this.reportIsDownloadable = true;
        }
        this.isGeneratingReport = false;
        unsubscribe();
      });
    },
    getFilters() {
      let dates = [];
      if (Object.keys(this.searchState?.numericRefinements).length > 0) {
        dates = reduce(
          this.searchState.numericRefinements,
          (dateRefinements, def, field) => {
            return [
              ...dateRefinements,
              {
                startDateTime: get(def, '>=.0', null),
                endDateTime: get(def, '<=.0', null),
                field,
              },
            ];
          },
          []
        );
      }

      return {
        facets: {
          facetsRefinements: this.searchState?.facetsRefinements,
          disjunctiveFacetsRefinements: this.searchState?.disjunctiveFacetsRefinements,
        },
        query: this.searchQuery,
        dates,
      };
    },
    refineSearch(refine, value) {
      this.searchQuery = value;
      refine(value);
    },
    getActionIcon(action, item) {
      if (this.currentProductList.find(cp => cp.productId === item.objectID)) {
        return 'check';
      }

      return action.icon;
    },
    getActionClasses(action, item) {
      if (this.currentProductList.find(cp => cp.productId === item.objectID)) {
        return ['is-success'];
      }

      return action.classes;
    },
    /* eslint-disable */
    getAllItems(items) {
      const getLabel = (label, attribute) => {
        const { values } = this.numericMenuAttributes.find(nma => nma.attribute === attribute);
        if (values) {
          const [operator, labelVal] = label.split(' ');
          const friendlyLabel = values[labelVal];
          if (friendlyLabel) {
            return `${operator} ${friendlyLabel}`;
          }
          return `${operator} ${dayjs(+labelVal).format('M/D/YY')}`;
        }

        return label;
      };

      return items.reduce((acc, item) => {
        acc.push(
          ...item.refinements.map(r => ({
            ...item,
            label: this.facetDefsByFacetName[item.label]?.displayName ?? item.label ?? 'Unknown',
            refinement: {
              ...r,
              label: this.numericMenuAttributes.find(nma => nma.attribute === r.attribute)
                ? getLabel(r.label, r.attribute)
                : r.label,
            },
          }))
        );
        return acc;
      }, []);
    },
    getItem(item, key) {
      return get(item, key, '');
    },
  },
};
</script>

<style lang="scss">
.ais-Highlight-highlighted {
  background-color: rgba(84, 104, 255, 0.3) !important;
}

.newmoon-badge {
  border-radius: 99999px;
  border: 1px solid rgb(90, 94, 154);
  padding: 0 5px;
  font-size: 12px;
  background: rgb(245, 245, 250);
}

.newmoon-badge-count {
  display: none !important;
}

.filter-button {
  display: none;
}

.search-list {
  .ais-ToggleRefinement {
    //margin: 10px 0;
  }

  .search,
  .actions {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: right;

    .action {
      margin-right: 5px;
    }
  }

  .empty {
    color: #4a4a4a;
    opacity: 0.5;
  }

  .stats {
    display: flex;
    flex: auto;
    justify-content: right;

    .ais-Stats-text {
      font-size: 14px;
    }
  }

  .hits {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    flex-grow: 1;

    .hits-container {
      display: flex;
      flex: auto;
      flex-direction: column;

      .empty {
        font-size: 24px;
        font-weight: bold;
      }

      .hit {
        display: flex;
        flex-direction: column;
        background: #fff;
        padding: 20px 10px 20px 30px;
        border-radius: 3px;
        margin: 10px 0;

        box-shadow: rgba(35, 38, 59, 0.05) 0px 0px 0px 1px, rgba(35, 38, 59, 0.15) 0px 1px 3px 0px,
          0 0 0 1px rgba(35, 38, 59, 0.05), 0 1px 3px 0 rgba(35, 38, 59, 0.15);

        .attributes {
          .attribute {
            display: flex;
            flex-direction: row;
            margin: 3px 0;
          }

          .attribute-label {
            font-weight: 600;
            margin-right: 1em;
            display: flex;
            justify-self: right;
            min-width: 150px;
          }
        }
      }
    }
  }

  .current-refinement {
    .refinement-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      padding: 10px 0 20px 0;
      align-items: center;
      flex-wrap: wrap;

      .refinement {
        font-size: 14px;
        height: 20px;
        background: rgb(242, 243, 255);
        outline: 1px solid rgb(202, 207, 255);
        color: rgb(43, 60, 187);
        border-radius: 3px;
        margin: 5px 8px 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        .refinement-label {
          padding: 0 5px;
        }

        .remove {
          border-left: 1px solid rgb(202, 207, 255);
          display: flex;
          padding: 0 3px;
          align-items: center;
          font-size: 10px;
          font-weight: 100;
          color: rgb(43, 60, 187);
          height: 100%;

          &:hover {
            background: rgba(43, 60, 187, 0.4);
          }
        }
      }
    }
  }

  .ais-InstantSearch {
    width: 100%;
  }

  .ais-SearchBox-submit,
  ais-SearchBox-reset {
    display: none;
  }

  display: flex;

  .search-form {
    display: flex;
    flex-grow: 1;
  }

  .search-content {
    display: flex;
    flex-direction: row;
    position: relative;

    .facets {
      display: flex;
      flex-direction: column;
      padding-right: 5px;
      min-width: 250px;
      max-width: 250px;

      .facet-name {
        margin: 10px 0;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .facets-mobile {
      display: none;
    }
  }
}

.pagination {
  width: 100%;

  .ais-Pagination {
    width: 100%;

    .ais-Pagination-list {
      margin-top: 24px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      li {
        margin: 8px;
        display: block;

        a {
          padding: 8px;
          background-color: white;
          height: 40px;
          width: 40px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px #ddd solid;

          &:hover {
            color: white;
            background-color: #004e7c;
          }
        }

        &.ais-Pagination-item--disabled {
          color: #66666633;
        }

        &.ais-Pagination-item--selected {
          pointer-events: none;
          cursor: not-allowed;

          a {
            padding: 8px;
            background-color: white;
            height: 40px;
            width: 40px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: #004e7c;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px), screen and (max-device-width: 580px) {
  .filter-panel-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;

    .facet-name {
      margin: 10px 0;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .filter-button {
    position: fixed;
    bottom: 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 48;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: rgba(0.92, 0.92, 0.92, 0.32);

    button {
      border-radius: 45px;
      width: 40%;
      box-shadow: 2px 2px 4px 2px rgba(0.12, 0.12, 0.12, 0.32);
    }
  }

  .search-list {
    .search-content {
      .facets {
        display: none;
      }

      .facets-mobile {
        display: block;
      }
    }
  }

  @media screen and (max-width: 399px), screen and (max-device-width: 399px) {
    .search-list {
      .search-content {
        width: 100%;

        .hits {
          box-sizing: border-box;
          position: relative;

          .hits-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .hit {
            max-width: 90vw;
            min-width: 90vw;
            padding: 4px 8px;
            font-size: 0.9rem;
            box-sizing: border-box;
          }
        }
      }

      .pagination {
        padding-right: 24px;
      }
    }
  }
}
</style>
