<template>
  <div class="work-order-totals">
    <div class="total" v-if="!isLoading">
      <div class="my-1 is-size-4 total-title">Project Totals</div>
      <div class="total-items">
        <div class="total-line-item">
          <div class="total-label">Non Taxable</div>
          <div class="total-value">{{ (total.nonTaxable / 100) | money }}</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Taxable</div>
          <div class="total-value">{{ (total.taxable / 100) | money }}</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Discount</div>
          <div class="total-value">({{ (total.discount / 100) | money }})</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Payments</div>
          <div class="total-value">({{ (total.payments / 100) | money }})</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Tax</div>
          <div class="total-value">{{ (total.tax / 100) | money }}</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Total</div>
          <div class="total-value">{{ (total.total / 100) | money }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WorkOrderService as workOrderShared } from '@newmoon-org/shared';

export default {
  name: 'ProjectTotals',
  props: {
    workOrders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      total: null,
      isLoading: true,
    };
  },
  watch: {
    workOrders: {
      async handler(val) {
        this.isLoading = true;
        this.total = workOrderShared.getProjectCost(val);
        this.isLoading = false;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.total {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .is-emergency {
    font-size: 13px;
    color: red;
  }

  .total-items {
    display: flex;
    flex-direction: column;

    .total-line-item {
      display: flex;
      flex-direction: row;

      .total-label {
        min-width: 200px;
        font-size: 14px;
      }

      .total-value {
        flex-grow: 1;
        margin-left: 1em;
      }
    }
  }
}
</style>
