export default {
  props: {
    commentLabel: {
      type: String,
      default: 'Reason',
    },
    buttonSize: {
      type: String,
      default: null,
    },
    buttonType: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: 'Confirm',
    },
    iconRight: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    modalHeader: {
      type: String,
      default: 'Confirm!',
    },
    minRequiredCharacters: {
      type: Number,
      default: 1,
    },
  },
};
