<template>
  <confirm-message-button
    id="confirm-comment-button"
    :comment-label="commentLabel"
    :button-size="buttonSize"
    :button-type="buttonType"
    :button-label="buttonLabel"
    :icon-right="iconRight"
    :icon-left="iconLeft"
    :is-disabled="isDisabled"
    :is-loading="savingComment || isLoading"
    :modal-header="modalHeader"
    :min-required-characters="minRequiredCharacters"
    @commit-message="saveComment"
  ></confirm-message-button>
</template>

<script>
import CommentsService from '@/service/comments.service';

import ConfirmMessageButton from '@/components/ConfirmMessageButton';

import confirmMessageButton from '@/mixins/confirmMessageButton';

export default {
  name: 'ConfirmCommentButton',
  components: {
    ConfirmMessageButton,
  },
  mixins: [confirmMessageButton],
  props: {
    associationId: {
      type: String,
      required: true,
    },
    commentType: {
      type: String,
      required: true,
    },
    postConfirmAction: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      comment: null,
      savingComment: false,
    };
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
  },
  methods: {
    saveComment(comment) {
      this.savingComment = true;
      CommentsService.create({
        value: comment,
        type: this.commentType,
        associationId: this.associationId,
      })
        .then(() => {
          this.postConfirmAction();
          this.$emit('onCommentSaved');
        })
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to save comment, ${e.message}`,
            type: 'is-danger',
          })
        )
        .finally(() => {
          this.savingComment = false;
        });
    },
  },
};
</script>
