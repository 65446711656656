import { Comment, WorkOrder } from '@newmoon-org/types';

import WorkordersService from '@/service/workorders.service';
import commentsService from '@/service/comments.service';

type WorkOrderStoreState = {
  comments: Comment[];
  workAssignment: WorkOrder | null;
};

const initialState = (): WorkOrderStoreState => ({
  comments: [],
  workAssignment: null,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setComments(state: WorkOrderStoreState, comments: Comment[]) {
      state.comments = comments;
    },
    setWorkOrder(state: WorkOrderStoreState, workOrder: WorkOrder) {
      state.workAssignment = workOrder;
    },
  },
  actions: {
    async fetchWorkAssignment({ commit }, { workAssignmentId }) {
      const [workOrder, comments] = await Promise.all([
        WorkordersService.getById(workAssignmentId),
        commentsService.getCommentByAssociation(workAssignmentId),
      ]);

      commit('setComments', comments);
      commit('setWorkOrder', workOrder);
    },
    addComment({ commit, state }, { comment }) {
      commit('setComments', [...state.comments, comment]);
    },
  },
  getters: {
    comments(state: WorkOrderStoreState) {
      return state.comments;
    },
  },
};
