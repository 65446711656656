<template>
  <div id="dfr-aggregate">
    <b-tabs vertical type="is-boxed">
      <b-tab-item label="Employee">
        <dfr-employee-aggregate></dfr-employee-aggregate>
      </b-tab-item>
      <b-tab-item label="Company">
        <dfr-company-aggregate></dfr-company-aggregate>
      </b-tab-item>
      <b-tab-item label="Historical">
        <dfr-historical-reports></dfr-historical-reports>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import DfrEmployeeAggregate from '@/components/dfr/DfrEmployeeAggregate.vue';
import DfrCompanyAggregate from '@/components/dfr/DfrCompanyAggregate.vue';
import DfrHistoricalReports from '@/components/dfr/DfrHistoricalReports.vue';

export default {
  name: 'DfrAggregate',
  components: {
    DfrCompanyAggregate,
    DfrEmployeeAggregate,
    DfrHistoricalReports,
  },
};
</script>

<style scoped lang="scss">
#dfr-aggregate {
  max-width: 1200px;
}
</style>
