import { init } from '@/service/generic.service';

const DEFAULT_SCHEDULE_DEFINITION = {
  timeSlots: [
    {
      startHour: null,
      startMinutes: null,
      endHour: null,
      endMinutes: null,
    },
  ],
  breakDurationInMinutes: 0,
};

const DEFAULT_WEEK = {
  days: new Array(7).fill({
    ...DEFAULT_SCHEDULE_DEFINITION,
    timeSlots: [...DEFAULT_SCHEDULE_DEFINITION.timeSlots],
  }),
};

const DEFAULT_SCHEDULE = {
  id: 'new',
  repeatFromDate: null,
  name: null,
  code: null,
  status: 'active',
  weeks: [{ ...DEFAULT_WEEK }],
};

const methods = init({
  collectionPath: 'scheduleDefinitions',
  dateFields: ['repeatFromDate'],
});

export default {
  DEFAULT_SCHEDULE,
  DEFAULT_SCHEDULE_DEFINITION,
  DEFAULT_WEEK,
  dbRef: methods.dbRef,
  list: methods.list,
  listByIds: methods.listByIds,
  get: methods.getAll,
  getById: methods.getById,
  create: methods.create,
  update: methods.update,
  remove: methods.remove,
  createDate: new Date(),
  updateDate: new Date(),
};
