import { ref } from 'vue';
import {
  Realtime,
  type RealtimeChannel,
  type RealtimeClient,
  type TokenParams,
  type ErrorInfo,
  type TokenDetails,
} from 'ably';

import { getApi } from './Api';

const instance = ref<RealtimeClient>();

export type ListenerChannel = RealtimeChannel;

export default {
  init,
  join,
};

function init() {
  instance.value = new Realtime({
    authCallback,
  });
}

function join(channelName: string): ListenerChannel {
  if (!instance.value) {
    throw new Error('Socket instance not initialized.');
  }
  return instance.value.channels.get(channelName);
}

async function authCallback(
  _tokenParams: TokenParams,
  callback: (error: ErrorInfo | string | null, tokenReq: TokenDetails | null) => void
) {
  const api = getApi();
  try {
    const { data: token } = await api.post('/auth/verify-websocket');
    callback(null, token);
  } catch (e) {
    const error = e as Error;
    callback(error.message, null);
  }
}
