<template>
  <div id="catalog-products">
    <div class="header-container">
      <div class="header">Catalog Products</div>
      <b-tooltip label="Create a new Catalog Product" position="is-left" v-if="hasPermission('catalog-product:write')">
        <b-button class="is-primary" icon-right="plus" @click="newCatalogProduct"></b-button>
      </b-tooltip>
    </div>
    <search-list
      index-name="catalog"
      :facets="facets"
      :actions="actions"
      :attributes="attributes"
      :transform="transform"
      :is-reportable="true"
    ></search-list>
  </div>
</template>

<script>
import { map, get } from 'lodash';
import { mapGetters } from 'vuex';

import SearchList from '@/components/list/searchList.vue';

export default {
  name: 'CatalogProductsPage',
  components: { SearchList },
  data() {
    return {
      transform(items) {
        const moneyTransforms = [
          'priceToCustomerCost.markupTaxable',
          'priceToCustomerCost.markupNonTaxable',
          'priceToCustomerCost.total',
        ];
        return map(items, item => ({
          ...item,
          ...moneyTransforms.reduce(
            (acc, key) => ({
              ...acc,
              [key]: this.$options.filters.money(parseInt(get(item, key, 0), 10) / 100),
            }),
            {}
          ),
        }));
      },
      facets: [
        { facet: 'catalogTypeName', displayName: 'Catalog' },
        { facet: 'productArea.label', displayName: 'Area' },
        { facet: 'productCategory', displayName: 'Category' },
        { facet: 'specification', displayName: 'Specification' },
      ],
      attributes: [
        {
          displayName: 'Name',
          key: 'name',
          hightlight: true,
        },
        {
          displayName: 'Status',
          key: 'status',
          hightlight: true,
        },
        {
          displayName: 'Catalog',
          key: 'catalogTypeName',
          hightlight: true,
        },
        {
          displayName: 'Area',
          key: 'productArea.label',
          hightlight: true,
        },
        {
          displayName: 'Category',
          key: 'productCategory',
          hightlight: true,
        },
        {
          displayName: 'Taxable',
          key: 'priceToCustomerCost.markupTaxable',
          hightlight: false,
        },
        {
          displayName: 'Non Taxable',
          key: 'priceToCustomerCost.markupNonTaxable',
          hightlight: false,
        },
        {
          displayName: 'Price To Customer',
          key: 'priceToCustomerCost.total',
          hightlight: false,
        },
      ],
      actions: [
        {
          icon: 'info',
          click: async item => {
            window.open(item.trainingLink, '_blank');
          },
          classes: [],
        },
        {
          icon: 'copy',
          click: async item => {
            await this.$router.push({
              name: 'catalog-product-edit',
              params: {
                catalogProductId: 'copy',
              },
              query: {
                catalogProductId: item.objectID,
              },
            });
          },
          if: () => this.hasPermission('catalog-product:write'),
          classes: [],
        },
        {
          icon: 'edit',
          click: async item => {
            await this.$router.push({
              name: 'catalog-product-edit',
              params: {
                catalogProductId: item.objectID,
              },
            });
          },
          classes: ['is-primary'],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'hasPermission']),
    loggedInUserRole() {
      return this.user?.employee?.role?.name;
    },
    userCanEdit() {
      return 'Service Tech' !== this.loggedInUserRole;
    },
  },
  methods: {
    getEditButtonText(name) {
      return this.userCanEdit ? `Edit Catalog Product ${name}` : `View Product ${name}`;
    },
    newCatalogProduct() {
      this.$router.push({
        name: 'catalog-product-edit',
        params: {
          catalogProductId: 'new',
        },
      });
    },
  },
};
</script>
