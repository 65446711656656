<template>
  <div class="dfr-approval-page">
    <template v-if="saving">
      <div>Saving...</div>
      <b-progress></b-progress>
    </template>
    <template v-else-if="$asyncComputed.dfr.updating">
      <div>Loading...</div>
      <b-progress></b-progress>
    </template>
    <template v-else-if="!dfr.id">
      <div class="columns">
        <div class="column">
          <b-message type="is-danger">
            Failed to load the DFR with id {{ dfrId }}. It might have been removed from the database...
          </b-message>
        </div>
      </div>
      <div class="columns">
        <div class="column is-pulled-left">
          <b-button label="Back" @click="goBack"></b-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="header">
        {{ inFinalStatus ? 'Summary' : isSelfUpdate ? 'Report Update' : 'Report Approval' }}
        <b-tag class="ml-3" :type="statusColors[dfr.status]" size="is-medium">
          {{ normalizeStatus(dfr.status) | titleCase }}
        </b-tag>
      </div>
      <div class="content">
        <div class="row-content">
          <div>
            <div class="row">
              <div class="title is-5">Report Info</div>
            </div>
            <div v-if="conflictingDfrs.length > 0" class="row text-red-800">
              <label>Conflicts</label>
              <ul class="comma-list">
                <li :key="conflict.id" v-for="conflict in conflictingDfrs">
                  <router-link :to="{ name: 'dfr-approval', params: { dfrId: conflict.id } }">
                    <a>
                      {{ conflict.workOrder.workOrderNumber }}
                    </a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="row">
              <label>Type</label>
              <p>{{ dfr.type }}</p>
            </div>
            <div class="row">
              <label>Date</label>
              <p>{{ formatDate(dfr.startDate) }}</p>
            </div>
            <div class="row">
              <label>Time</label>
              <p>{{ formatTime(dfr.startDate, dfr.endDate) }} [{{ numberOfHours(dfr) }} hour(s)]</p>
            </div>
            <div class="row">
              <label>Reported by</label>
              <p>{{ getReportedByLabel() }}</p>
            </div>
            <div class="row">
              <label>Created At</label>
              <p>{{ formatDateWithTime(dfr.createdAt) }}</p>
            </div>
            <div class="mt-4 row">
              <div class="title is-5">Work Info</div>
            </div>
            <div class="row">
              <label>Company</label>
              <p>{{ getCompanyLabel() }}</p>
            </div>
            <div class="row">
              <label>Work Order #</label>
              <p>{{ dfr.workOrder.workOrderNumber }}</p>
              <b-tooltip class="ml-3" v-if="dfr.workOrder.isPrevailingWage" label="Prevailing Wage">
                <b-icon icon="money-bill-wave" size="is-medium" type="is-success"></b-icon>
              </b-tooltip>
            </div>
            <div class="row">
              <label>Project</label>
              <p>{{ dfr.workOrder.techProjectId || dfr.workOrder.projectCode || 'N/A' }}</p>
            </div>
            <div class="row">
              <label>Employee</label>
              <p>{{ getEmployeeLabel() }}</p>
            </div>
            <div class="row" v-if="dfr.type === 'Project'">
              <label>Cost code</label>
              <p>{{ dfr.costCode.name }}</p>
            </div>
            <template v-if="inFinalStatus">
              <div class="mt-4 row">
                <div class="title is-5">Approval Info</div>
              </div>
              <div class="row">
                <label>Final Status</label>
                <p>{{ normalizeStatus(dfr.status) | titleCase }}</p>
              </div>
              <div class="row">
                <label>Finalized By</label>
                <p>{{ getFinalizedByLabel() }}</p>
              </div>
              <div class="row">
                <label>Finalized At</label>
                <p>{{ formatDateWithTime(dfr.finalizedBy.date) }}</p>
              </div>
            </template>
            <template v-else-if="canUpdate">
              <div class="mt-4 row">
                <div class="title is-5">Change Info</div>
              </div>
              <div class="row">
                <b-field label="Date Worked">
                  <b-datepicker
                    v-model="selectedStartDate"
                    placeholder="Click to select..."
                    icon="calendar-day"
                    v-if="canDoAnything"
                    @input="startDateChanged"
                  ></b-datepicker>
                </b-field>
              </div>
              <div class="row">
                <b-field label="Start Time">
                  <b-timepicker
                    v-model="selectedStartTime"
                    placeholder="Click to select..."
                    icon="clock"
                    :max-time="selectedEndTime"
                    @input="startTimeChanged"
                    :enable-seconds="false"
                    :increment-minutes="15"
                    locale="en-US"
                  ></b-timepicker>
                </b-field>
                <b-field label="End Time">
                  <b-timepicker
                    v-model="selectedEndTime"
                    placeholder="Click to select..."
                    icon="clock"
                    @input="endTimeChanged"
                    :min-time="selectedStartTime"
                    :enable-seconds="false"
                    :increment-minutes="15"
                    locale="en-US"
                  ></b-timepicker>
                </b-field>
              </div>
            </template>
            <dfr-report-view class="mt-4" :dfr="dfr"></dfr-report-view>
            <div class="row" v-if="!canModify">
              <b-message type="is-warning" has-icon>You cannot make any updates to this DFR</b-message>
            </div>
            <div class="row">
              <template v-if="!isHr && !dfr.hasHrApproval && !inFinalStatus && dfr.status !== needRevision">
                <b-message type="is-warning">HR must Approve the DFR first!</b-message>
              </template>
            </div>
            <div class="row">
              <div class="buttons">
                <b-button @click="goBack">Cancel</b-button>
                <template v-if="isApproved && !isExported && hasPermission('dfr:admin')">
                  <confirm-comment-button
                    class="mr-2"
                    :association-id="dfrId"
                    :comment-type="commentType"
                    modal-header="Confirm Disapproval!"
                    comment-label="Reason for disapproval"
                    button-label="Disapprove"
                    button-type="is-danger"
                    icon-left="times"
                    :post-confirm-action="() => doUpdateStatus(statusNew)"
                  ></confirm-comment-button>
                </template>
                <template v-if="!inFinalStatus && canModify">
                  <b-button v-if="canUpdate" icon-left="pencil-alt" :disabled="!readyForUpdate" @click="update">
                    Update
                  </b-button>
                  <template v-if="dfr.status !== needRevision">
                    <template v-if="dfr.hasHrApproval">
                      <confirm-comment-button
                        class="mr-2"
                        v-if="canFinalize"
                        :association-id="dfrId"
                        :comment-type="commentType"
                        modal-header="Confirm Revision!"
                        comment-label="Reason for revision"
                        button-label="Needs Revision"
                        button-type="is-warning"
                        icon-left="exclamation-triangle"
                        :post-confirm-action="() => doUpdateStatus(needRevision)"
                      ></confirm-comment-button>
                      <confirm-comment-button
                        class="mr-2"
                        v-if="canFinalize"
                        :association-id="dfrId"
                        :comment-type="commentType"
                        modal-header="Confirm Denial!"
                        comment-label="Reason for denial"
                        button-label="Deny"
                        button-type="is-danger"
                        icon-left="times"
                        :post-confirm-action="() => doUpdateStatus(denied)"
                      ></confirm-comment-button>
                      <b-button
                        v-if="canFinalize"
                        icon-left="check"
                        type="is-success"
                        @click="doUpdateStatus(approved)"
                      >
                        Approve
                      </b-button>
                    </template>
                    <template v-else-if="isHr">
                      <confirm-comment-button
                        class="mr-2"
                        :association-id="dfrId"
                        :comment-type="commentType"
                        modal-header="Confirm Denial!"
                        comment-label="Reason for denial"
                        button-label="HR Deny"
                        button-type="is-danger"
                        icon-left="times"
                        :post-confirm-action="() => setHrApproval(false)"
                      ></confirm-comment-button>
                      <b-button icon-left="check" type="is-success" @click="setHrApproval(true)">HR Approve</b-button>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <comments-view
            :key="dfrId"
            :association-id="dfrId"
            :comment-type="commentType"
            :displayable-types="displayableTypes"
            :view-only="inFinalStatus"
          ></comments-view>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { first, pick } from 'lodash';
import { mapGetters } from 'vuex';
import { DateService, CommentType } from '@newmoon-org/shared';

import DfrService from '@/service/dfr.service';

import DfrReportView from '@/components/dfr/DfrReportView.vue';
import ConfirmCommentButton from '@/components/ConfirmCommentButton.vue';

import CommentsView from '@/pages/CommentsView.vue';

const { getNumberOfHoursBetweenDates } = DateService;

export default {
  name: 'DFRApprovalPage',
  components: {
    DfrReportView,
    CommentsView,
    ConfirmCommentButton,
  },
  data() {
    return {
      saving: false,
      selectedStartTime: null,
      selectedEndTime: null,
      selectedStartDate: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'canModifyAsManager', 'hasPermission']),
    isHr() {
      return this.loggedInUser.workflowFunction?.name?.toLowerCase() === 'hr';
    },
    commentType() {
      return CommentType.DFR;
    },
    isExported() {
      return !!this.dfr?.report?.generated;
    },
    displayableTypes() {
      return [CommentType.DFR];
    },
    dfrId() {
      return this.$route.params.dfrId;
    },
    loggedInUser() {
      return this.user?.employee ?? null;
    },
    loggedInUserId() {
      return this.loggedInUser?.id ?? null;
    },
    employee() {
      return this.pickEmployee(this.loggedInUser);
    },
    isSelfUpdate() {
      return this.loggedInUserId === this.dfr.employee?.id;
    },
    isUpdateByCreator() {
      return this.loggedInUserId === this.dfr.reportedBy?.id;
    },
    startDateIsSame() {
      return this.selectedStartTime?.getTime() === this.dfr.startDate?.getTime();
    },
    endDateIsSame() {
      return this.selectedEndTime?.getTime() === this.dfr.endDate?.getTime();
    },
    canDoAnything() {
      return ['hr', 'developer', 'global administrator', 'administrator'].includes(
        this.loggedInUser.workflowFunction?.name?.toLowerCase()
      );
    },
    readyForUpdate() {
      return !this.startDateIsSame || !this.endDateIsSame;
    },
    isManagerForCompany() {
      return this.canModifyAsManager(this.dfr?.company?.code);
    },
    canFinalize() {
      return this.isManagerForCompany || this.canDoAnything;
    },
    canUpdate() {
      return this.isSelfUpdate || this.isUpdateByCreator || this.canDoAnything;
    },
    canModify() {
      return this.canUpdate || this.canFinalize;
    },
    needRevision() {
      return DfrService.STATUS.NEEDS_REVISION;
    },
    denied() {
      return DfrService.STATUS.DENIED;
    },
    approved() {
      return DfrService.STATUS.APPROVED;
    },
    statusNew() {
      return DfrService.STATUS.NEW;
    },
    inFinalStatus() {
      return DfrService.FINAL_STATUSES.includes(this.dfr.status);
    },
    isApproved() {
      return DfrService.STATUS.APPROVED === this.dfr.status;
    },
    statusColors() {
      return {
        [DfrService.STATUS.NEW]: '',
        [DfrService.STATUS.NEEDS_REVISION]: 'is-warning',
        [DfrService.STATUS.DENIED]: 'is-danger',
        [DfrService.STATUS.APPROVED]: 'is-success',
      };
    },
  },
  asyncComputed: {
    conflicts: {
      get() {
        return this.dfrId ? DfrService.getConflicts([this.dfrId]).then(r => first(r)?.inConflictWith ?? []) : [];
      },
      default: [],
    },
    conflictingDfrs: {
      async get() {
        return DfrService.listByIds(this.conflicts);
      },
      default: [],
    },
    dfr: {
      get() {
        return this.dfrId
          ? DfrService.get(this.dfrId).then(r => {
              this.selectedStartTime = new Date(r.startDate);
              this.selectedEndTime = new Date(r.endDate);
              this.selectedStartDate = new Date(r.startDate);
              return { ...r, finalizedBy: r.finalizedBy ?? {} };
            })
          : {
              company: {},
              finalizedBy: {},
              reportedBy: {},
              employee: {},
              workOrder: {},
            };
      },
      default: {
        company: {},
        finalizedBy: {},
        reportedBy: {},
        employee: {},
        workOrder: {},
      },
    },
  },
  methods: {
    getCompanyLabel() {
      return `${this.dfr?.company?.company || 'Unknown'}(${this.dfr?.company?.code || 'Unknown code'})`;
    },
    getEmployeeLabel() {
      return `${[this.dfr?.employee?.lastName || 'Unknown', this.dfr?.employee?.firstName || 'Unknown'].join(' ')} (${
        this.dfr?.employee?.code || 'Unknown code'
      })`;
    },
    getFinalizedByLabel() {
      return `${[this.dfr?.finalizedBy?.lastName || 'Unknown', this.dfr?.finalizedBy?.firstName || 'Unknown'].join(
        ' '
      )}(${this.dfr?.finalizedBy?.code || 'Unknown code'})`;
    },
    getReportedByLabel() {
      return this.isSelfUpdate
        ? 'Self'
        : `${[this.dfr?.reportedBy?.lastName || 'Unknown', this.dfr?.reportedBy?.firstName || 'Unknown'].join(' ')}(${
            this.dfr?.reportedBy?.code || 'Unknown code'
          })`;
    },
    numberOfHours(dfr) {
      return getNumberOfHoursBetweenDates(dfr.startDate, dfr.endDate);
    },
    normalizeStatus(status) {
      return status?.replace('_', ' ');
    },
    pickEmployee(e) {
      return pick(e, [
        'id',
        'firstName',
        'lastName',
        'email',
        'code',
        'manager',
        'isForeman',
        'workflowFunction',
        'payrollSystem',
      ]);
    },
    formatDateWithTime(date) {
      return date ? dayjs(date).format('MMM D YYYY [at] hh:mma') : 'N/A';
    },
    formatDate(date) {
      return date ? dayjs(date).format('MMM D YYYY') : 'N/A';
    },
    formatTime(start, end) {
      const format = 'hh:mma';
      return `${dayjs(start).format(format)} - ${dayjs(end).format(format)}`;
    },
    startDateChanged(date) {
      const oldStartTime = new Date(this.selectedStartTime);
      this.selectedStartTime = new Date(date);
      this.selectedStartTime?.setHours(oldStartTime?.getHours() ?? 7, oldStartTime?.getMinutes() ?? 0, 0, 0);

      const oldEndTime = new Date(this.selectedEndTime);
      this.selectedEndTime = new Date(date);
      this.selectedEndTime?.setHours(oldEndTime?.getHours() ?? 7, oldEndTime?.getMinutes() ?? 0, 0, 0);
    },
    startTimeChanged(value) {
      this.selectedStartTime = new Date(this.selectedStartDate);
      this.selectedStartTime?.setHours(value?.getHours() ?? 7, value?.getMinutes() ?? 0, 0, 0);
    },
    endTimeChanged(value) {
      this.selectedEndTime = new Date(this.selectedEndTime);
      this.selectedEndTime?.setHours(value?.getHours() ?? 7, value?.getMinutes() ?? 0, 0, 0);
    },
    doUpdateStatus(status) {
      this.updateObj({
        status,
        finalizedBy: DfrService.FINAL_STATUSES.includes(status) ? { ...this.employee, date: new Date() } : null,
        hasHrApproval: [this.statusNew, this.needRevision].includes(status) ? false : this.dfr.hasHrApproval,
        hrFinalizedBy: [this.statusNew, this.needRevision].includes(status) ? null : this.dfr.hrFinalizedBy,
      });
    },
    setHrApproval(hasHrApproval = true) {
      const date = new Date();
      this.updateObj({
        hasHrApproval,
        hrFinalizedBy: { ...this.employee, date },
        status: hasHrApproval ? this.dfr.status : this.denied,
        finalizedBy: hasHrApproval ? this.dfr.finalizedBy : { ...this.employee, date },
      });
    },
    update() {
      this.updateObj({
        startDate: this.selectedStartTime,
        endDate: this.selectedEndTime,
        status: DfrService.STATUS.NEW,
        hasHrApproval: false,
        hrFinalizedBy: null,
      });
    },
    updateObj(obj) {
      this.saving = true;
      DfrService.update(this.dfrId, obj)
        .then(() => this.goBack())
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to update dfr, ${e.message}`,
            type: 'is-danger',
          })
        )
        .finally(() => {
          this.saving = false;
        });
    },
    async goBack() {
      await this.$router.push({ name: 'dfr' });
    },
  },
};
</script>

<style lang="scss">
.dfr-approval-page {
  display: flex;
  flex-direction: column;

  .comma-list {
    display: inline;
    list-style: none;
    margin: 0;

    li {
      display: inline;
    }

    li + li:before {
      content: ', ';
    }
  }

  .header {
    display: flex;
    align-items: baseline;
    font-size: 2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .row-content {
      display: flex;
      flex-direction: row;

      & > div {
        flex: 2;

        &:first-of-type {
          margin-right: 8px;
        }
      }

      @media screen and (max-width: 825px) {
        flex-direction: column;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      margin-right: 64px;
      font-size: 1.2rem;
      padding: 8px;
      flex-wrap: wrap;

      @media screen and (max-width: 1100px) {
        flex-direction: column;
      }

      label {
        display: inline;
        font-weight: normal;
        margin-right: 8px;
        min-width: 175px;
        color: #676767;

        &::after {
          content: ':';
        }
      }

      .field {
        margin-right: 8px;

        label {
          display: block;
          min-width: 1px;
        }
      }

      p {
        font-weight: 500;
        max-width: 300px;
        color: #454545;
      }

      ul {
        max-width: 300px;
      }
    }

    .divider {
      height: 1px;
      background-color: #33333333;
    }
  }
}
</style>
