<template>
  <div class="year-picker">
    <section>
      <b-field :label="label">
        <b-select placeholder="Select" v-model="year">
          <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
        </b-select>
      </b-field>
    </section>
  </div>
</template>

<script>
export default {
  name: 'YearPicker',
  props: {
    label: {
      type: String,
      default: 'Year',
    },
    selectedValue: {
      type: [String, Number],
      default: '',
    },
    numYears: {
      type: Number,
      default: 5,
    },
    minYear: {
      type: Number,
      default: () => new Date().getFullYear(),
    },
  },
  data() {
    return {
      year: this.selectedValue,
      years: this.getYears(),
    };
  },
  watch: {
    year(val) {
      this.$emit('change', val);
    },
    minYear: {
      handler(val) {
        if (this.year < val) {
          this.year = val;
        }
        this.years = this.getYears();
      },
    },
  },
  methods: {
    getYears() {
      const years = [];
      for (let i = 0; i <= this.numYears; i++) {
        years.push(this.minYear + i);
      }
      return years;
    },
  },
};
</script>

<style scoped lang="scss">
.year-picker {
}
</style>
