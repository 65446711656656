import CustomerBookingPage from '@/pages/CustomerBookingPage';
import ManageWorkOrderPage from '@/pages/ManageWorkOrderPage';
import WorkOrdersPage from '@/pages/WorkOrdersListPage';
import OnCallWorkOrdersListPage from '@/pages/OnCallWorkOrdersListPage';

export default [
  {
    component: CustomerBookingPage,
    meta: {
      requiresAuth: true,
      label: 'Customers Page',
      featureLabel: 'Work Order Booking/Edit Page',
    },
    name: 'customer-booking',
    path: '/customers/booking/:customerId/:workOrderId',
  },
  {
    component: WorkOrdersPage,
    meta: {
      requiresAuth: true,
      label: 'Work Orders Page',
      featureLabel: 'Work Order List Page',
    },
    name: 'work-orders',
    path: '/work-orders',
  },
  {
    component: OnCallWorkOrdersListPage,
    meta: {
      requiresAuth: true,
      label: 'On Call Work Orders Page',
      featureLabel: 'On Call Work Order List Page',
      permissions: ['on-call-work-order:view'],
    },
    name: 'on-call-work-orders',
    path: '/on-call-work-orders',
  },
  {
    component: ManageWorkOrderPage,
    meta: {
      requiresAuth: true,
      label: 'Work Orders Management Page',
      featureLabel: 'Work Order Management Page',
    },
    name: 'manage-work-order',
    path: '/work-orders/:workOrderId',
  },
];
