<template>
  <div class="table-view">
    <b-table
      :data="hits"
      :striped="true"
      paginated
      backend-pagination
      :total="results.nbHits"
      :per-page="results.hitsPerPage"
      :current-page="page"
      @page-change="onPageChange"
    >
      <b-table-column v-for="h in attributes" :key="h.key" :field="h.key" :label="h.displayName" v-slot="props">
        <a
          v-html="props.row[h.key].value"
          v-if="isFunction(props.row[h.key].config.click)"
          @click="() => props.row[h.key].config.click(props.row[h.key].hit)"
        ></a>
        <span v-html="props.row[h.key].value" v-else></span>
      </b-table-column>
      <b-table-column key="actions" label="Actions" v-slot="props" width="150">
        <span class="mr-1" v-for="(action, i) in actions" :key="`${i}-action`">
          <b-button
            class="is-small"
            @click="() => action.click(props.row[attributes[0].key].hit)"
            :class="action.classes"
            v-if="action.if(props.row[attributes[0].key].hit)"
          >
            <b-icon :icon="action.icon" v-if="action.icon"></b-icon>
            <span v-if="action.text">{{ action.text }}</span>
          </b-button>
        </span>
      </b-table-column>
      <template #empty>
        <div class="no-results has-text-centered">No Results</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { get, isFunction } from 'lodash';

export default {
  name: 'AlgoliaListTable',
  props: {
    attributes: {
      type: Array,
      default: () => [],
    },
    results: {
      type: Object,
      default: () => ({}),
    },
    actions: {
      type: Array,
      default: () => [],
    },
    hydrate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFunction,
    };
  },
  computed: {
    headers() {
      return this.attributes.map(a => a.displayName);
    },
    hits() {
      return this.results?.hits?.map(this.getRow);
    },
    page() {
      return this.results?.page + 1;
    },
  },
  methods: {
    onPageChange(page) {
      this.$emit('on-page-change', { page: page - 1 });
    },
    getRow(hit) {
      return this.attributes.reduce((map, a) => {
        if (a.highlight && !this.hydrate) {
          const result = get(hit._highlightResult, a.key, {});
          return {
            ...map,
            [a.key]: {
              config: { ...a },
              hit,
              value: result.value ? result.value : get(hit, a.key, ''),
            },
          };
        }
        return {
          ...map,
          [a.key]: {
            config: { ...a },
            hit,
            value: get(hit, a.key, ''),
          },
        };
      }, {});
    },
  },
};
</script>

<style lang="scss">
.table-view {
  .no-results {
    font-size: 13px;
  }

  tbody {
    td {
      em {
        background-color: rgba(84, 104, 255, 0.3);
        font-style: normal;
      }
    }
  }
}
</style>
