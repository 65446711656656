import { isEmpty, keyBy } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['user', 'permissions']),
    excludedPageViewsAsNames() {
      return this.loggedInUser.employee?.role?.noViewPages?.map(it => it.name.toLowerCase()) || [];
    },
    loggedInUser() {
      return this.user;
    },
    loggedInUserRole() {
      return this.loggedInUser.employee?.role?.name || null;
    },
    loggedInUserWorkflowFunction() {
      return this.loggedInUser.employee?.workflowFunction?.name || null;
    },
    loggedInUserAccessLevel() {
      return this.loggedInUser.employee?.role?.name || null;
    },
    loggedInUserIsAdmin() {
      return this.loggedInUserRole?.toLowerCase() === 'admin';
    },
    loggedInUserIsTech() {
      return this.loggedInUserAccessLevel?.toLowerCase() === 'service tech';
    },
    currentPageName() {
      return this.$router.currentRoute.name?.toLowerCase();
    },
    pagesMap() {
      return keyBy(
        this.$router.options.routes.map(it => ({
          name: it.name.toLowerCase(),
          path: it.path,
          meta: it.meta,
        })),
        'name'
      );
    },
  },
  methods: {
    canViewPage(pageName) {
      const legacyCanAccessPage = () => {
        const name = pageName?.toLowerCase();
        return (
          isEmpty(this.excludedPageViewsAsNames) ||
          !this.excludedPageViewsAsNames.includes(name) ||
          !this.pagesMap[name]?.meta?.requiresAuth
        );
      };

      return legacyCanAccessPage();
    },
  },
};
