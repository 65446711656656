import chunk from 'lodash/chunk';
import { Comment } from '@newmoon-org/types';

import { init } from '@/service/generic.service';

const DATE_FIELDS = ['createdAt'];

const methods = init({
  collectionPath: 'comments',
  dateFields: DATE_FIELDS,
});

export default {
  dbRef: methods.dbRef,
  remove: methods.remove,
  create: methods.create,
  getCommentsFromIds,
  DATE_FIELDS,
  getCommentByAssociation,
};

async function getCommentByAssociation(associationId): Promise<Comment[]> {
  const sn = await methods.dbRef.where('associationId', '==', associationId).get();
  return sn.docs.map(d => ({ ...d.data(), id: d.id } as Comment));
}

async function getCommentsFromIds(ids: string[]): Promise<Comment[]> {
  const chunks = chunk(ids, 10);
  const batches = await Promise.all(chunks.map(it => getBatches(it)));
  return batches.flat();

  async function getBatches(ids: string[]): Promise<Comment[]> {
    const sn = await methods.dbRef.where('associationId', 'in', ids).get();
    return sn.docs.map(d => ({
      ...methods.mapDataDates(d.data()),
      id: d.id,
    })) as unknown as Comment[];
  }
}
