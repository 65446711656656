import DispatchWorkflowWorkOrderView from '@/pages/DispatchWorkflowWorkOrderView';
import DispatchWorkflowsPage from '@/pages/DispatchWorkflowsPage';
import DispatchWorkflowsEmptyState from '@/pages/DispatchWorkflowsEmptyState';

export default [
  {
    component: DispatchWorkflowsPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Dispatch Workflows List Page',
    },
    name: 'dispatch-workflows',
    path: '/dispatchworkflows',
    children: [
      {
        path: ':workOrderId',
        name: 'dispatchWorkflowWorkOrderView',
        component: DispatchWorkflowWorkOrderView,
      },
      {
        path: '',
        name: 'dispatchWorkflowsEmptyState',
        component: DispatchWorkflowsEmptyState,
      },
    ],
  },
];
