<template>
  <div class="month-picker">
    <section>
      <b-field :label="label">
        <b-select placeholder="Select" v-model="month">
          <option v-for="m in months" :key="m.key" :value="m.key">{{ m.text }}</option>
        </b-select>
      </b-field>
    </section>
  </div>
</template>

<script>
const months = [
  { key: 0, text: 'Jan' },
  { key: 1, text: 'Feb' },
  { key: 2, text: 'Mar' },
  { key: 3, text: 'Apr' },
  { key: 4, text: 'May' },
  { key: 5, text: 'Jun' },
  { key: 6, text: 'Jul' },
  { key: 7, text: 'Aug' },
  { key: 8, text: 'Sep' },
  { key: 9, text: 'Oct' },
  { key: 10, text: 'Nov' },
  { key: 11, text: 'Dec' },
];

export default {
  name: 'MonthPicker',
  props: {
    label: {
      type: String,
      default: 'Month',
    },
    selectedValue: {
      type: [String, Number],
      default: '',
    },
    minMonth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      month: this.selectedValue,
      months: this.getMonths(),
    };
  },
  watch: {
    month(val) {
      this.$emit('change', val);
    },
    minMonth() {
      this.months = this.getMonths();
    },
  },
  methods: {
    getMonths() {
      return months.filter(m => m.key >= this.minMonth);
    },
  },
};
</script>
