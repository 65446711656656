<template>
  <div class="dfr-historical-reports">
    <div class="mb-5 is-size-4">Dfr Historical Reports</div>
    <table class="table is-bordered is-fullwidth">
      <thead>
        <tr>
          <th>Created</th>
          <th>Filters</th>
          <th width="50">Status</th>
          <th width="50">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, i) in reports" :key="i">
          <td>{{ getFormattedDateTime(report.createdAt) }}</td>
          <td>
            <strong>Prevailing Wage:</strong>
            <span class="ml-1">{{ report.filters.isPrevailingWage }}</span>
            <br />
            <strong>Payroll System:</strong>
            <span class="ml-1">{{ report.filters.payrollSystem }}</span>
            <br />
            <template v-if="report.filters.startDate">
              <strong>Start:</strong>
              <span class="ml-1">{{ getFormattedDate(report.filters.startDate) }}</span>
              <br />
            </template>
            <template v-if="report.filters.endDate">
              <strong>End:</strong>
              <span class="ml-1">{{ getFormattedDate(report.filters.endDate) }}</span>
            </template>
          </td>
          <td>
            <b-tooltip
              multiline
              :label="report.error"
              :triggers="report.error && report.error.length > 0 ? ['hover'] : []"
            >
              <b-tag :type="getStatusColor(report.status)">{{ report.status }}</b-tag>
            </b-tooltip>
          </td>
          <td>
            <b-button :disabled="!report.destination" size="is-small" @click="downloadReport(report)">
              Download
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TaskService from '@/service/task.service';

export default {
  name: 'DfrHistoricalReports',
  data() {
    return {
      reports: [],
    };
  },
  computed: {
    completedReports() {
      return this.reports.filter(r => r.status === 'complete');
    },
  },
  async mounted() {
    this.reports = await TaskService.getByType('csvDfrReport', 25);
  },
  methods: {
    getStatusColor(status) {
      if (status === 'error') {
        return 'is-danger';
      } else if (status === 'new') {
        return 'is-info';
      } else if (status === 'complete') {
        return 'is-success';
      }
    },
    getFormattedDate(firebaseTimestamp) {
      return firebaseTimestamp.toDate().toLocaleDateString();
    },
    getFormattedDateTime(firebaseTimestamp) {
      return firebaseTimestamp.toDate().toLocaleString();
    },
    async downloadReport(report) {
      const href = await TaskService.generateHref(report)();
      window.open(href, '_blank');
    },
  },
};
</script>
