<template>
  <div id="application-dropdown">
    <b-navbar-dropdown position="is-bottom-left" :label="displayName">
      <b-navbar-item value="logout" @click="logOutAndCleanUp">Logout</b-navbar-item>
    </b-navbar-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ApplicationDropdown',
  computed: {
    ...mapGetters('auth', ['user', 'isLoggedIn']),

    displayName() {
      return (
        `${this.user?.employee?.firstName ?? ''} ${this.user?.employee?.lastName ?? ''}`.trim() ||
        this.user?.firebaseUser?.email ||
        ''
      );
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('centralScheduler', ['loadEvents']),
    logOutAndCleanUp() {
      this.loadEvents({});
      this.logout();
    },
  },
};
</script>

<style scoped></style>
