<template>
  <div id="employee-agg">
    <div class="mb-5 is-size-4">Dfr Employee Report</div>
    <div class="is-flex is-flex-direction-row">
      <div class="is-flex-grow-1">
        <b-field label="Date Range">
          <b-datepicker
            v-model="dates"
            :date-formatter="formatDateRange"
            :disabled="fetchingAggregation"
            :show-week-number="false"
            placeholder="Choose range"
            :icon-right="dates ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="dates = []"
            trap-focus
            range
          ></b-datepicker>
        </b-field>
      </div>
    </div>
    <b-progress class="my-5" v-if="fetchingAggregation"></b-progress>
    <div v-if="aggregateData">
      <dfr-report-table :aggregate-data="aggregateData"></dfr-report-table>
    </div>
  </div>
</template>

<script>
import { capitalize, debounce, get } from 'lodash';
import dayjs from 'dayjs';

import EmployeesService from '@/service/employees.service';
import DfrService from '@/service/dfr.service';

import DfrReportTable from '@/components/dfr/DfrReportTable.vue';

export default {
  name: 'DfrEmployeeAggregate',
  components: { DfrReportTable },
  data() {
    return {
      employeeIds: [],
      searchQuery: null,
      employee: null,
      fetchingEmployees: false,
      fetchingAggregation: false,
      fetchEmployee: debounce(this.fetchEmployeeData, 200),
      isAggregatingEmployee: false,
      dates: [],
      aggregateData: null,
      filteredAggregationData: null,
      capitalize,
    };
  },
  computed: {
    canAggregateEmployee() {
      return this.employeeIds && this.dates.length > 1;
    },
  },
  watch: {
    dates() {
      this.getAggData();
    },
    searchQuery() {
      if (!this.aggregateData) return;
      if (!this.searchQuery) this.filteredAggregationData = this.aggregateData;

      this.filteredAggregationData = this.aggregateData.filter(dfr => {
        const searchField = `${dfr?.employee?.firstName} ${dfr?.employee?.lastName}`;
        return searchField.search(new RegExp(this.searchQuery, 'i')) !== -1;
      });
    },
  },
  mounted() {
    this.fetchEmployeeData(null);
  },
  methods: {
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    getStatus(data, status) {
      return get(data.dfrByStatus, status, { duration: 0 });
    },
    async getAggData() {
      if (!this.canAggregateEmployee) return;
      this.fetchingAggregation = true;
      this.aggregateData = await DfrService.getEmployeesAggregate({
        employeeIds: this.employeeIds,
        startDate: this.dates[0],
        endDate: this.dates[1],
      }).finally(() => (this.fetchingAggregation = false));

      this.filteredAggregationData = this.aggregateData;
    },
    async fetchEmployeeData() {
      this.fetchingEmployees = true;
      this.employeeIds = await EmployeesService.getAll()
        .then(r => r.map(e => e.id))
        .finally(() => (this.fetchingEmployees = false));
    },
  },
};
</script>

<style scoped></style>
