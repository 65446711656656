import { init } from '@/service/generic.service';

const methods = init({
  collectionPath: 'pageActions',
  dateFields: ['startDate', 'endDate', 'updateDate'],
});

export default {
  dbRef: methods.dbRef,
  getAll: methods.getAll,
  getById: methods.getById,
  create: methods.create,
  update: methods.update,
  remove: methods.remove,
  mapDocWithDates: methods.mapDocWithDates,
};
