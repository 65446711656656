<template>
  <div class="p-0 mr-0" id="confirm-message-button">
    <b-button
      :label="buttonLabel"
      :type="buttonType"
      :size="buttonSize"
      @click="isActive = true"
      :icon-left="iconLeft"
      :icon-right="iconRight"
      :disabled="isDisabled"
      :loading="isLoading"
    ></b-button>
    <b-modal
      v-model="isActive"
      trap-focus
      :full-screen="isMobile"
      aria-role="dialog"
      aria-modal
      :destroy-on-hide="false"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">{{ modalHeader }}</header>
          <section class="modal-card-body">
            <b-field
              :label="commentLabel"
              :message="
                minRequiredCharacters > 1
                  ? `You must enter a minimum of ${minRequiredCharacters} characters to submit.`
                  : ''
              "
            >
              <b-input type="textarea" v-model="comment" :loading="isLoading" :disabled="isLoading"></b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              size="is-small"
              label="Cancel"
              type="is-danger"
              :loading="isLoading"
              @click="isActive = false"
            ></b-button>
            <b-button
              size="is-small"
              label="Submit"
              type="is-success"
              :loading="isLoading"
              @click="commitMessage"
              :disabled="!canSubmit"
            ></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import confirmMessageButton from '@/mixins/confirmMessageButton';

export default {
  name: 'ConfirmMessageButton',
  mixins: [confirmMessageButton],
  data() {
    return {
      isActive: false,
      comment: null,
    };
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
    canSubmit() {
      return this.comment?.length >= this.minRequiredCharacters;
    },
  },
  methods: {
    commitMessage() {
      this.$emit('commit-message', this.comment);
      this.isActive = false;
      this.comment = null;
    },
  },
};
</script>
