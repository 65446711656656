import { ConversionService } from '@newmoon-org/shared';

import { db } from '@/service/firebase';
import employeesService from '@/service/employees.service.js';
import workordersService from '@/service/workorders.service.js';

export const enum SUPPORTED_COLLECTION {
  WORKORDERS = 'workorders',
  EMPLOYEES = 'employees',
}

const SERVICE_MAP = {
  [SUPPORTED_COLLECTION.WORKORDERS]: workordersService,
  [SUPPORTED_COLLECTION.EMPLOYEES]: employeesService,
};

export function getHistory(collection: SUPPORTED_COLLECTION, instanceId: string) {
  const service = SERVICE_MAP[collection];

  if (!service) {
    throw Error(`Failed to identify history service for ${collection}`);
  }

  return db
    .collection(`${service.collectionPath}History`)
    .where('meta.instanceId', '==', instanceId)
    .orderBy('meta.eventTimestamp')
    .get()
    .then(r =>
      r.docs.map(it => {
        const doc = mapDocWithDates(it);
        doc.data = service.mapDocWithDates({ data: () => doc.data, id: doc.data?.id });
        return doc;
      })
    );
}

function mapDocWithDates(doc: any, toUi = true) {
  const result = ConversionService.mapDoc(doc);
  return ConversionService.mapDates(result, ['meta.eventTimestamp'], toUi);
}
