<template>
  <div></div>
</template>
<script>
import { createWidgetMixin } from 'vue-instantsearch';

import { realtimeDb } from '@/service/firebase';

const connectRefresh = (renderFn, unmountFn) => () => ({
  init() {
    renderFn({ refresh: {} }, true);
  },
  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },
  dispose() {
    unmountFn();
  },
});

export default {
  name: 'Refresh',
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    indexName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastUpdated: null,
      ref: null,
      callback: null,
    };
  },
  mounted() {
    if (!this.isActive) return;

    this.ref = realtimeDb.ref(`algolia/indexes/${this.indexName}/lastUpdated`);
    this.callback = this.ref.on('value', v => {
      const updatedAt = v.val();
      if (!this.lastUpdated) {
        this.lastUpdated = updatedAt;
        return;
      }

      if (this.lastUpdated !== updatedAt) {
        try {
          this.state.refresh();
        } catch (e) {
          // sometimes state is null here - the table is refreshing and snapshot will return an immediate value
          console.warn(e);
        }
      }
      this.lastUpdated = updatedAt;
    });
  },
  destroyed() {
    if (!this.isActive) return;
    this.ref.off('value', this.callback);
  },
};
</script>

<style scoped></style>
