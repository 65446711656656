import { AlgoliaDfrEntry } from '@newmoon-org/types';

import { DfrState } from './index';

export default {
  setSelected(state: DfrState, payload: AlgoliaDfrEntry[]) {
    state.selected = [...payload];
  },
  clearSelected(state: DfrState) {
    state.selected = [];
  },
};
