<template>
  <div class="sidebar">
    <div class="sidebar-wrapper">
      <router-link
        class="menu-list-item"
        v-for="item in menuItems"
        :key="item.route"
        :to="{ name: item.route }"
        v-slot="{ href, navigate }"
        :class="[isRouteActive(item.isActive) && 'is-active']"
        custom
      >
        <div class="divider" v-if="item.type === 'divider'"></div>
        <a class="pl-3" v-else :href="href" @click="navigate">
          <b-icon class="mr-2" :icon="item.icon"></b-icon>
          <div class="px-1 py-2 text">{{ item.label }}</div>
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { navigation } from '@/components/navigation';

import visibilityMixin from '@/mixins/visibility';

export default {
  name: 'NewmoonSidebar',
  mixins: [visibilityMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    menuItems() {
      // sometimes we have multiple dividers in a row, this removes them
      return this.navItems.reduce((menuItems, item) => {
        const lastItem = menuItems[menuItems.length - 1];
        if (!lastItem) {
          menuItems.push(item);
          return menuItems;
        } else if (lastItem.type === 'divider' && item.type === 'divider') return menuItems;

        menuItems.push(item);
        return menuItems;
      }, []);
    },
    navItems() {
      return navigation
        .filter(item => this.canViewPage(item.route))
        .filter(item => {
          if (!item.permissions || item.permissions.length === 0) return true;
          return item.permissions.some(permission => this.hasPermission(permission));
        });
    },
  },
  methods: {
    isRouteActive(routeName) {
      return this.$route.path ? this.$route?.path?.search(routeName) === 1 : false;
    },
  },
};
</script>

<style lang="scss">
@import '~bulma/sass/utilities/_all.sass';
.sidebar {
  min-width: 300px;
  padding: 10px;
  position: relative;
  height: 100%;

  .sidebar-wrapper {
    top: 55px;
    bottom: 0;
    background: #f5f5fa;
    position: fixed;
    min-width: 280px;
    z-index: 39;
    overflow-y: scroll;
  }

  .divider {
    height: 1px;
    background-color: #33333333;
    width: 90%;
    margin: 4px auto;
  }
}

.menu-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #444;
  border-radius: 3px;
  &.is-active {
    background-color: rgb(0, 78, 124);
    color: $white;
  }
}

@media (max-width: 1024px) {
  .sidebar {
    display: none;
    width: 100%;
    order: 2;
  }
}
</style>
