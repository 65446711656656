<template>
  <div id="signature-view">
    <div class="my-3">{{ headerText }}</div>
    <signature-pad @signed="signed" @saved="saved" :signature="signature" :disable-signed="shouldBeDisabled">
      <template #above-buttons v-if="verbalAcceptance">
        <b-field class="mt-3">
          <b-checkbox v-model="isVerballyAccepted">Verbal Acceptance</b-checkbox>
        </b-field>
      </template>
    </signature-pad>
  </div>
</template>

<script>
import SignaturePad from '@/components/util/SignaturePad';

export default {
  name: 'SignatureView',
  components: {
    SignaturePad,
  },
  props: {
    headerText: {
      type: String,
      default: 'Please Sign This',
    },
    signature: {
      type: String,
      default: '',
    },
    disableSigned: {
      type: Boolean,
      default: true,
    },
    verbalAcceptance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVerballyAccepted: false,
    };
  },
  computed: {
    shouldBeDisabled() {
      return this.isVerballyAccepted || this.disableSigned;
    },
  },
  methods: {
    signed(data) {
      this.$emit('signed', {
        data,
        isVerballyAccepted: this.isVerballyAccepted,
      });
    },
    saved(data) {
      this.$emit('saved', {
        data,
        isVerballyAccepted: this.isVerballyAccepted,
      });
    },
  },
};
</script>
