<template>
  <div id="role-edit-page">
    <div class="loading" v-if="$asyncComputed.role.updating">
      <div class="header">Role</div>
      <div class="is-size-4">Loading</div>
      <b-progress></b-progress>
    </div>
    <div class="not-loading" v-else>
      <div class="header">Role</div>
      <form @submit.prevent="() => save()">
        <div class="columns">
          <div class="column">
            <b-field>
              <b-switch v-model="newRole.status" true-value="active" false-value="inactive">Active</b-switch>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Name">
              <b-input v-model="newRole.name" expanded required></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-one-quarter">
            <b-dropdown multiple v-model="selectedViews" expanded scrollable>
              <template #trigger>
                <b-button type="is-info" icon-right="caret-down">No View Pages ({{ selectedViews.length }})</b-button>
              </template>
              <b-dropdown-item v-for="page in pages" :value="page.name" :key="page.name" aria-role="listitem">
                <span>{{ page.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column is-three-quarters">
            <b-taglist>
              <b-tag v-for="pg in selectedViews" :key="pg" type="is-info">{{ getPageLabel(pg) }}</b-tag>
            </b-taglist>
          </div>
          <div class="column is-one-quarter">
            <b-dropdown multiple v-model="selectedCreates" expanded scrollable>
              <template #trigger>
                <b-button type="is-success" icon-right="caret-down">
                  No Create Pages ({{ selectedCreates.length }})
                </b-button>
              </template>
              <b-dropdown-item v-for="page in pages" :value="page.name" :key="page.name" aria-role="listitem">
                <span>{{ page.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column is-three-quarters">
            <b-taglist>
              <b-tag v-for="pg in selectedCreates" :key="pg" type="is-success">{{ getPageLabel(pg) }}</b-tag>
            </b-taglist>
          </div>
          <div class="column is-one-quarter">
            <b-dropdown multiple v-model="selectedUpdates" expanded scrollable>
              <template #trigger>
                <b-button type="is-warning" icon-right="caret-down">
                  No Update Pages ({{ selectedUpdates.length }})
                </b-button>
              </template>
              <b-dropdown-item v-for="page in pages" :value="page.name" :key="page.name" aria-role="listitem">
                <span>{{ page.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column is-three-quarters">
            <b-taglist>
              <b-tag v-for="pg in selectedUpdates" :key="pg" type="is-warning">{{ getPageLabel(pg) }}</b-tag>
            </b-taglist>
          </div>
          <div class="column is-one-quarter">
            <b-dropdown multiple v-model="selectedDeletes" expanded scrollable>
              <template #trigger>
                <b-button type="is-danger" icon-right="caret-down">
                  No Delete Pages ({{ selectedDeletes.length }})
                </b-button>
              </template>
              <b-dropdown-item v-for="page in pages" :value="page.name" :key="page.name" aria-role="listitem">
                <span>{{ page.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column is-three-quarters">
            <b-taglist>
              <b-tag v-for="pg in selectedDeletes" :key="pg" type="is-danger">{{ getPageLabel(pg) }}</b-tag>
            </b-taglist>
          </div>
        </div>
        <div class="columns">
          <div class="column action-buttons">
            <b-button class="mr-2" type="is-danger" @click="goBack">Cancel</b-button>
            <button class="button is-success" type="submit">{{ isNew ? 'Create' : 'Update' }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { isEqual, sortBy } from 'lodash';
import { mapActions } from 'vuex';

import RolesService from '@/service/roles.service';

import visibilityMixin from '@/mixins/visibility';

export default {
  name: 'RoleEditPage',
  mixins: [visibilityMixin],
  props: {
    roleId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      newRole: { ...RolesService.DEFAULT_ROLE },
      saving: false,
      selectedViews: [],
      selectedUpdates: [],
      selectedCreates: [],
      selectedDeletes: [],
    };
  },
  asyncComputed: {
    role: {
      get() {
        return this.isNew
          ? RolesService.DEFAULT_ROLE
          : RolesService.getById(this.roleIdInput).then(it => {
              this.newRole = { ...this.newRole, ...it };
              this.selectedViews = this.newRole.noViewPages?.map(it => it.name) || [];
              this.selectedCreates = this.newRole.noCreatePages?.map(it => it.name) || [];
              this.selectedUpdates = this.newRole.noUpdatePages?.map(it => it.name) || [];
              this.selectedDeletes = this.newRole.noDeletePages?.map(it => it.name) || [];
              return it;
            });
      },
      default: RolesService.DEFAULT_ROLE,
    },
  },
  computed: {
    roleIdInput() {
      return this.roleId ?? this.$route.params.roleId;
    },
    pages() {
      return sortBy(
        this.$router.options.routes.map(it => ({
          name: it.name.toLowerCase(),
          path: it.path,
          label: it.meta?.featureLabel ?? it.name.toLowerCase(),
        })),
        it => it.label
      );
    },
    isNew() {
      return this.roleIdInput === 'new';
    },
  },
  watch: {
    selectedViews: {
      handler(value, old) {
        if (isEqual(value, old)) {
          return;
        }
        this.selectedViews = sortBy(this.selectedViews);
      },
    },
    selectedUpdates: {
      handler(value, old) {
        if (isEqual(value, old)) {
          return;
        }
        this.selectedUpdates = sortBy(this.selectedUpdates);
      },
    },
    selectedCreates: {
      handler(value, old) {
        if (isEqual(value, old)) {
          return;
        }
        this.selectedCreates = sortBy(this.selectedCreates);
      },
    },
    selectedDeletes: {
      handler(value, old) {
        if (isEqual(value, old)) {
          return;
        }
        this.selectedDeletes = sortBy(this.selectedDeletes);
      },
    },
  },
  methods: {
    ...mapActions('auth', ['refreshEmployeeData']),
    goBack(role) {
      this.$emit('close', role);
      if (!this.roleId) {
        this.$router.back();
      }
    },
    getPageLabel(pageName) {
      const lowerCasePage = pageName.toLowerCase();
      const page = this.pagesMap[lowerCasePage];
      return page?.meta?.featureLabel ?? lowerCasePage;
    },
    async save() {
      this.newRole.name = this.newRole.name?.trim();
      this.newRole.noViewPages = this.selectedViews.map(it => ({
        name: it.toLowerCase(),
        path: this.pagesMap[it.toLowerCase()]?.path,
      }));
      this.newRole.noCreatePages = this.selectedCreates.map(it => ({
        name: it.toLowerCase(),
        path: this.pagesMap[it.toLowerCase()]?.path,
      }));
      this.newRole.noUpdatePages = this.selectedUpdates.map(it => ({
        name: it.toLowerCase(),
        path: this.pagesMap[it.toLowerCase()]?.path,
      }));
      this.newRole.noDeletePages = this.selectedDeletes.map(it => ({
        name: it.toLowerCase(),
        path: this.pagesMap[it.toLowerCase()]?.path,
      }));
      try {
        const role = await (this.isNew
          ? RolesService.create(this.newRole)
          : RolesService.update(this.newRole.id, this.newRole));
        this.$buefy.toast.open({
          message: 'Successfully saved Role',
          type: 'is-success',
        });
        await this.refreshEmployeeData();
        this.goBack(role);
      } catch (e) {
        this.$buefy.notification.open({
          message: `There was an error saving Role, ${e.message}`,
          type: 'is-danger',
          hasIcon: true,
          duration: 10000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
#role-edit-page {
  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
