import { init } from '@/service/generic.service';

const DATE_FIELDS = ['createdOn', 'startTime', 'endTime'];

const methods = init({
  collectionPath: 'centralScheduler',
  dateFields: DATE_FIELDS,
});

export default {
  dbRef: methods.dbRef,
  mapDocWithDates: methods.mapDocWithDates,
};
