import AdminPanelPage from '@/pages/AdminPanelPage';
import RolesAdminPage from '@/pages/RolesAdminPage';
import RoleEditPage from '@/pages/RoleEditPage.vue';
import DataHistoryInspectorPage from '@/pages/DataHistoryInspectorPage.vue';

export default [
  {
    component: AdminPanelPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Admin Page',
    },
    name: 'admin-panel',
    path: '/admin-panel',
    children: [
      {
        path: 'admin-data-history-inspector',
        name: 'admin-data-history-inspector',
        component: DataHistoryInspectorPage,
      },
      {
        path: 'roles',
        name: 'role-management',
        component: RolesAdminPage,
      },
      {
        path: 'roles/:roleId',
        name: 'role-edit',
        component: RoleEditPage,
      },
    ],
  },
];
