<template>
  <div class="work-assignment-cost-code-edit">
    <div class="contents">
      <div class="columns is-mobile">
        <div class="column">
          <div class="is-size-5">{{ costCodeToEdit.name }} (#{{ costCodeToEdit.costCodeNumber || 'Unknown' }})</div>
          <div class="subtitle is-6">{{ duration }} hour(s) duration</div>
        </div>
        <div class="column is-narrow">
          <b-switch v-model="applyAreaFilter">Apply Product Area Filter</b-switch>
          <b-switch v-model="costCodeToEdit.selected">Active</b-switch>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-message type="is-danger" aria-role="alert" v-if="noDateSelected">
            No Work Assignment date selected, time commitment will not be created.
          </b-message>
        </div>
      </div>
      <div class="columns is-fullwidth is-multiline">
        <div class="column">
          <div class="columns">
            <div class="column is-one-third">
              <b-field label="Area Of The Property">
                <b-input v-model="costCodeToEdit.area" type="text" disabled expanded></b-input>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field label="Category">
                <b-input v-model="costCodeToEdit.category" type="text" disabled expanded></b-input>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field label="Item">
                <b-input v-model="costCodeToEdit.catalogType.name" type="text" disabled expanded></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-fullwidth">
        <div class="column is-half">
          <b-field label="Start At">
            <b-timepicker
              v-model="costCodeToEdit.startAtTime"
              placeholder="Select Time"
              icon="clock"
              editable
              @input="startAtChanged"
              :increment-minutes="5"
              :max-time="costCodeToEdit.endAtTime"
              :disabled="!costCodeToEdit.selected"
              :required="costCodeToEdit.selected"
            ></b-timepicker>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="End At">
            <b-timepicker
              v-model="costCodeToEdit.endAtTime"
              placeholder="Select Time"
              icon="clock"
              editable
              @input="endAtChanged"
              :increment-minutes="5"
              :min-time="costCodeToEdit.startAtTime"
              :disabled="!costCodeToEdit.selected"
              :required="costCodeToEdit.selected"
            ></b-timepicker>
          </b-field>
        </div>
      </div>
      <template>
        <div class="mb-5 is-size-5">Service Team</div>
        <div class="columns">
          <div class="column is-half">
            <b-field label="Foreman">
              <b-dropdown
                v-model="selectedForeman"
                @input="assignForeman"
                aria-role="list"
                :disabled="!costCodeToEdit.selected || loadingSchedulesPoller"
              >
                <template #trigger>
                  <b-button :icon-right="loadingSchedulesPoller ? 'spinner' : 'caret-down'">
                    {{
                      costCodeToEdit.foreman && costCodeToEdit.foreman.id
                        ? `${costCodeToEdit.foreman.lastName} ${costCodeToEdit.foreman.firstName}`
                        : 'Select a foreman'
                    }}
                  </b-button>
                </template>
                <b-dropdown-item
                  v-for="foreman in availableForemen"
                  :value="foreman.id"
                  :key="foreman.id"
                  aria-role="listItem"
                >
                  <span>{{ foreman.lastName }} {{ foreman.firstName }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
          <div class="column is-half" v-if="costCodeToEdit.foreman && costCodeToEdit.foreman.id">
            <b-taglist>
              <b-tag type="is-success" size="is-large">
                {{ costCodeToEdit.foreman.lastName }} {{ costCodeToEdit.foreman.firstName }}
              </b-tag>
            </b-taglist>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <b-field label="Technicians">
              <b-dropdown
                v-model="assignedTechs"
                @input="assignTechs"
                multiple
                aria-role="list"
                :disabled="!costCodeToEdit.selected || loadingSchedulesPoller"
              >
                <template #trigger>
                  <b-button :icon-right="loadingSchedulesPoller ? 'spinner' : 'caret-down'">
                    Technicians ({{ costCodeToEdit.assignedTechs ? costCodeToEdit.assignedTechs.length : 0 }})
                  </b-button>
                </template>
                <b-dropdown-item v-for="tech in availableTechs" :value="tech.id" :key="tech.id" aria-role="listitem">
                  <span>{{ tech.lastName }} {{ tech.firstName }}{{ namePostfixes(tech) }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
          <div class="column is-half" v-if="costCodeToEdit.assignedTechs">
            <b-taglist>
              <b-tag
                v-for="(tech, i) in costCodeToEdit.assignedTechs"
                :key="i"
                :type="getSelectedTechTagType(tech)"
                size="is-medium"
              >
                <b-tooltip v-if="getTechTagLabel(tech)" b-tooltip :label="getTechTagLabel(tech)">
                  <div>{{ tech.lastName }} {{ tech.firstName }}</div>
                </b-tooltip>
                <div v-else>{{ tech.lastName }} {{ tech.firstName }}</div>
              </b-tag>
            </b-taglist>
          </div>
        </div>
      </template>
    </div>
    <div class="main-footer">
      <b-button class="is-danger" @click="$emit('cancelled')">Cancel</b-button>
      <b-button class="is-success" @click="$emit('saved', costCodeToEdit)">Save</b-button>
    </div>
  </div>
</template>

<script>
import { debounce, identity, isEmpty, isEqual, pick, sortedUniq, uniqWith } from 'lodash';
import { DateService as dateServiceShared } from '@newmoon-org/shared';
import dayjs from 'dayjs';

import WorkOrderService from '@/service/workorders.service';

import schedulerMixin from '@/mixins/scheduler';
import workorderPageActionsMixin from '@/mixins/workorderPageActions';
import visibility from '@/mixins/visibility';

export default {
  name: 'WorkAssignmentCostCodeEdit',
  mixins: [schedulerMixin, workorderPageActionsMixin, visibility],
  props: {
    workorderId: {
      type: String,
      default: 'new',
    },
    date: {
      type: Date,
      default: null,
    },
    costCode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      costCodeToEdit: {
        catalogType: {},
      },
      applyAreaFilter: false,
      pageActionsSnapshotId: null,
      selectedForeman: null,
      assignedTechs: [],
      foremen: [],
      pingCancelled: false,
      pingFunction: () => console.log('init'),
      allTechs: [],
    };
  },
  computed: {
    noDateSelected() {
      return !this.date;
    },
    assignedTeam() {
      return sortedUniq([...this.assignedTechs, this.selectedForeman].filter(identity));
    },
    shouldUpdateActions() {
      return !!this.date && !!this.costCodeToEdit.selected && !this.pingCancelled;
    },
    badTimeSelection() {
      return (
        this.costCodeToEdit.startAtTime &&
        this.costCodeToEdit.endAtTime &&
        dayjs(this.costCodeToEdit.startAtTime).isAfter(dayjs(this.costCodeToEdit.endAtTime))
      );
    },
    duration() {
      return WorkOrderService.getApproximateCostCodesDuration(this.costCodeToEdit);
    },
    loggedInFirstName() {
      return this.loggedInUser?.employee?.firstName;
    },
    loggedInLastName() {
      return this.loggedInUser?.employee?.lastName;
    },
    loggedInUserName() {
      return `${this.loggedInLastName} ${this.loggedInFirstName}`;
    },
    employeeScheduleQualifications() {
      return (
        this.employees.reduce(
          (acc, tech) => ({
            ...acc,
            [tech.id]: dateServiceShared.scheduleIsQualified(
              this.scheduleDefinitions[tech.schedule?.id],
              this.costCodeToEdit.startDate,
              this.costCodeToEdit.endDate
            ),
          }),
          {}
        ) ?? {}
      );
    },
    availableTechs() {
      const shouldNotSkip = it =>
        !this.employeesToSkip.includes(it.id) &&
        this.selectedForeman !== it.id &&
        !!it.skillIds?.includes(this.costCodeToEdit.catalogType.id) &&
        !!this.employeeScheduleQualifications[it.id]?.isQualified &&
        (!this.applyAreaFilter || !!it.productAreas?.ids?.includes(this.costCodeToEdit.productArea?.id));

      return this.allTechs.filter(shouldNotSkip);
    },
    availableForemen() {
      const shouldNotSkip = it =>
        !this.employeesToSkip.includes(it.id) &&
        !this.assignedTechs.includes(it.id) &&
        !!this.employeeScheduleQualifications[it.id]?.isQualified &&
        !!it.skillIds?.includes(this.costCodeToEdit.catalogType.id) &&
        (!this.applyAreaFilter || !!it.productAreas?.ids?.includes(this.costCodeToEdit.productArea?.id));
      return this.foremen.filter(shouldNotSkip);
    },
    employeesToSkip() {
      return uniqWith([...this.affectedEmployees, ...this.conflictingBookingsAssignedTechs], isEqual);
    },
    affectedEmployeesSet() {
      return new Set(this.affectedEmployees);
    },
    availableTeamSet() {
      return new Set(this.availableTeam);
    },
    pageConflictsSet() {
      return new Set(this.conflictingBookingsAssignedTechs);
    },
    availableTeam() {
      return sortedUniq(
        [...this.availableForemen.map(it => it.id), ...this.availableTechs.map(it => it.id)].filter(identity)
      );
    },
  },
  watch: {
    duration: {
      immediate: true,
      handler(value, old) {
        if (!isEqual(value, old)) {
          this.refreshListeners();
        }
      },
    },
    'costCodeToEdit.selected': {
      immediate: true,
      handler(value) {
        if (!value) {
          this.costCodeToEdit = {
            ...this.costCodeToEdit,
            foreman: {},
            assignedTechs: [],
            assignedTechsEmails: [],
            startDate: null,
            endDate: null,
            startAtTime: null,
            endAtTime: null,
          };

          this.selectedForeman = null;
          this.assignedTechs = [];
        }
      },
    },
    employees: {
      handler(value, old) {
        if (!isEqual(value, old) && !isEmpty(value)) {
          this.reassignTechsForemen();
        }
      },
    },
    availableTeam: {
      handler(value, old) {
        if (!isEmpty(this.employees) && !isEqual(value, old)) {
          this.confirmAssignments();
        }
      },
    },
    assignedTeam: {
      handler(value, old) {
        if (!isEqual(value, old)) {
          this.updateAction();
        }
      },
    },
    date: {
      handler() {
        this.startAtChanged(this.costCodeToEdit.startAtTime);
        this.endAtChanged(this.costCodeToEdit.endAtTime);
      },
    },
  },
  mounted() {
    this.costCodeToEdit = { ...this.costCode };
    this.selectedForeman = this.costCodeToEdit?.foreman?.id ?? null;
    this.assignedTechs = this.costCodeToEdit?.assignedTechs?.map(it => it.id) || [];
    this.pageActionsSnapshotId = `${this.workorderId}-${this.costCodeToEdit.id}-${this.loggedInUser?.employee?.id}`;
    this.pingFunction = debounce(this.updateAction, 60000);
    this.loadEmployees();
  },
  async destroyed() {
    this.pingFunction.cancel();
    this.pingCancelled = true;
    await this.removePageAction(this.pageActionsSnapshotId);
  },
  methods: {
    getSelectedTechTagType(tech) {
      return this.affectedEmployeesSet.has(tech?.id) || !this.availableTeamSet.has(tech?.id)
        ? 'is-danger'
        : this.pageConflictsSet.has(tech?.id)
        ? 'is-warning'
        : 'is-info';
    },
    getTechTagLabel(tech) {
      return this.affectedEmployeesSet.has(tech?.id)
        ? 'Conflicting booking exists!'
        : !this.availableTeamSet.has(tech?.id)
        ? 'Can no longer find the employee in the qualified team'
        : this.pageConflictsSet.has(tech?.id)
        ? 'Somebody tries to book the employee now!'
        : null;
    },
    reassignTechsForemen() {
      this.foremen = this.employees?.filter(it => it.isForeman)?.map(it => this.normalizeForeman(it)) ?? [];
      this.allTechs = this.employees?.map(it => this.normalizeEmployee(it)) ?? [];
    },
    async updateActionAndRefreshConflicts() {
      if (!this.shouldUpdateActions) {
        await this.removePageAction(this.pageActionsSnapshotId);
        return;
      }

      await this.updateAction();
      this.handleConflictingWorkorderBookingsLoad(
        this.costCodeToEdit.startDate,
        this.costCodeToEdit.endDate,
        this.pageActionsSnapshotId
      );
    },
    async updateAction() {
      if (!this.shouldUpdateActions) {
        return;
      }
      this.pingFunction();
      await this.upsertPageAction(this.pageActionsSnapshotId, this.getPageActionsSnapshot());
    },
    getPageActionsSnapshot() {
      return {
        ...this.buildBasicActions(),
        actionType: this.actionTypes.COSTCODE,
        costCodeId: this.costCodeToEdit.id || null,
        workorderId: this.workorderId || null,
        editedBy: this.loggedInUserName || null,
        editedByUserId: this.loggedInUser?.id || null,
        foreman: this.selectedForeman ?? null,
        assignedTechs: this.assignedTechs?.filter(it => it) || [],
        startDate: this.costCodeToEdit.startDate || null,
        endDate: this.costCodeToEdit.endDate || null,
      };
    },
    namePostfixes(tech) {
      const postfixes = [tech?.manager ? 'Mgr' : null, tech?.isForeman ? 'Fman' : null].filter(it => it);
      return postfixes.length > 0 ? ` (${postfixes.join(', ')})` : '';
    },
    assignTechs() {
      this.costCodeToEdit.assignedTechs = this.availableTechs.filter(it => this.assignedTechs.includes(it.id));
      this.costCodeToEdit.assignedTechsEmails = this.costCodeToEdit.assignedTechs?.map(it => it.email) || [];
      this.assignedTechs = this.costCodeToEdit.assignedTechs?.map(it => it.id) || [];
    },
    assignForeman(foreman) {
      if (this.costCodeToEdit.foreman?.id === foreman) {
        this.costCodeToEdit.foreman = {};
        this.selectedForeman = null;
      } else {
        this.costCodeToEdit.foreman = this.availableForemen.find(it => foreman === it.id) ?? {};
        this.selectedForeman = this.costCodeToEdit.foreman?.id;
      }
    },
    confirmAssignments() {
      const assignedForeman = this.availableForemen.find(it => it.id === this.costCodeToEdit.foreman?.id) || {};
      const techIds = this.costCodeToEdit.assignedTechs?.map(it => it.id) || [];
      const assignedTechs = this.availableTechs?.filter(it => techIds.includes(it.id)) || [];

      if (!!this.costCodeToEdit.foreman?.id && !assignedForeman.id) {
        this.$buefy.notification.open({
          message:
            'Foreman that was supposed to be assigned to the WO were already assigned somewhere else. Please reassign!',
          type: 'is-warning',
          closable: true,
          indefinite: true,
        });
      }

      if (this.costCodeToEdit.assignedTechs?.length !== assignedTechs.length) {
        this.$buefy.notification.open({
          message:
            'Some technicians that were supposed to be assigned to the WO were already assigned somewhere else. Please reassign!',
          type: 'is-warning',
          closable: true,
          indefinite: true,
        });
      }
    },
    normalizeEmployee(employee) {
      return {
        ...pick(
          employee,
          'id',
          'manager',
          'isForeman',
          'firstName',
          'lastName',
          'email',
          'schedule',
          'skillIds',
          'productAreas',
          'profileImgUrl'
        ),
        manager: String(employee.manager) === 'true',
        isForeman: employee.isForeman,
      };
    },
    normalizeForeman(foreman) {
      return pick(
        foreman,
        'id',
        'manager',
        'isForeman',
        'firstName',
        'lastName',
        'email',
        'scheduleType',
        'primaryManagerEmail',
        'role',
        'skillIds',
        'productAreas',
        'profileImgUrl'
      );
    },
    startAtChanged(value) {
      if (this.badTimeSelection) {
        this.costCodeToEdit.endAtTime = null;
        this.costCodeToEdit.endDate = null;
      }

      this.costCodeToEdit.startDate = WorkOrderService.joinDateWithTime(this.date, value);
    },
    endAtChanged(value) {
      if (this.badTimeSelection) {
        this.costCodeToEdit.startAtTime = null;
        this.costCodeToEdit.startDate = null;
      }

      this.costCodeToEdit.endDate = WorkOrderService.joinDateWithTime(this.date, value);
    },
    refreshListeners() {
      if (this.costCodeToEdit.selected) {
        this.handleDynamicProsLoads(
          this.costCodeToEdit.startDate,
          this.costCodeToEdit.endDate,
          this.workorderId,
          this.costCodeToEdit.id
        );
        this.updateActionAndRefreshConflicts();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.work-assignment-cost-code-edit {
  box-shadow: 0 10px 10px 0 lightgray;

  .contents {
    display: inherit;
    padding: 10px;
  }

  .main-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px;
    column-gap: 10px;
  }
}
</style>
