const HELP_LINK = 'https://docs.google.com/document/d/1DlsRi02yl_HN8nDB6cRQ7pXFEVd2o7EMiL0R9cApDrg/edit';

export default [
  {
    meta: {
      featureLabel: 'Help Page',
    },
    name: 'help',
    path: '/help',
    beforeEnter() {
      window.open(HELP_LINK, '_blank');
    },
  },
];
