<template>
  <div id="manage-work-order-page">
    <nav class="breadcrumb is-mobile">
      <ul>
        <li>
          <router-link class="p-0 m-0" to="/work-orders"><a>Work Orders</a></router-link>
        </li>
        <li class="is-active">
          <router-link class="p-0 m-0" to="/work-orders/${workOrderId}">
            <a>WO {{ workOrder.workOrderNumber }} - {{ workOrder.techProjectId }}</a>
          </router-link>
        </li>
      </ul>
    </nav>
    <b-message class="is-info" v-if="workOrder.importedAt" title="Imported" size="is-small">
      This Work Order is historical, cost data was not retrievable.
    </b-message>
    <b-message class="is-success" v-if="workOrder.state === workOrderStates.FINISHED" title="Completed" size="is-small">
      This Work Order is Complete.
    </b-message>
    <b-message
      v-if="itemsSignatureRequired && itemsSignatureExists && isInEditableStatus"
      title="Detected Changes"
      size="is-small"
      type="is-info"
    >
      Based on some changes in price, quantity, line items, a new signature is required
    </b-message>
    <div class="columns header">
      <div class="column">
        <div class="is-size-3">
          WO {{ workOrder.workOrderNumber }} -
          {{ workOrder.techProjectId }}
        </div>
      </div>
      <div class="column is-narrow">
        <b-button
          v-if="workOrder.parentWorkOrderId"
          tag="router-link"
          :to="{ name: 'manage-work-order', params: { workOrderId: workOrder.parentWorkOrderId } }"
        >
          Go to Parent WO
        </b-button>
      </div>
      <b-modal
        v-model="viewReasonForDelayModal"
        trap-focus
        :full-screen="isMobile"
        aria-role="dialog"
        aria-modal
        destroy-on-hide
      >
        <template #default>
          <div class="modal-card">
            <header class="modal-card-head">Confirm Delay</header>
            <section class="modal-card-body">
              <div class="columns">
                <div class="column">
                  <b-field label="Reason For Delay">
                    <b-select v-model="workOrder.reasonForDelay" expanded required>
                      <option v-for="(delayVal, delayKey) in workOrderDelays" :value="delayKey" :key="delayKey">
                        {{ delayVal }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button @click="cancelDelayModal">Cancel</b-button>
              <b-button
                @click="wait"
                type="is-success"
                :loading="isCreatingDelayedWO"
                :disabled="!workOrder.reasonForDelay"
              >
                Confirm
              </b-button>
            </footer>
          </div>
        </template>
      </b-modal>
    </div>
    <b-modal
      v-model="isItemsSignatureViewActive"
      trap-focus
      :full-screen="isMobile"
      aria-role="dialog"
      aria-modal
      :destroy-on-hide="true"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">Signature</header>
          <section class="modal-card-body">
            <work-order-totals :work-order="workOrder"></work-order-totals>
            <signature-view
              :header-text="signatureHeader"
              :signature="existingItemsSignature"
              @saved="getSignature"
              :disable-signed="false"
              :verbal-acceptance="true"
            ></signature-view>
          </section>
          <footer class="modal-card-foot">
            <b-button
              size="is-small"
              label="Close"
              type="is-danger"
              @click="isItemsSignatureViewActive = false"
            ></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="isCompletionSignatureViewActive"
      trap-focus
      :full-screen="isMobile"
      aria-role="dialog"
      aria-modal
      :destroy-on-hide="false"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">Signature</header>
          <section class="modal-card-body">
            <signature-view
              :header-text="completionSignatureHeader"
              :signature="existingCompletionSignature"
              @saved="getCompletionSignature"
              :disable-signed="false"
              :verbal-acceptance="true"
            ></signature-view>
          </section>
          <footer class="modal-card-foot">
            <b-button
              size="is-small"
              label="Close"
              type="is-danger"
              @click="isCompletionSignatureViewActive = false"
            ></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
    <template v-if="$asyncComputed.workOrder.updating">
      <div>Loading</div>
      <b-progress></b-progress>
    </template>
    <template v-else>
      <div class="navigation">
        <b-button
          class="is-small is-info outline"
          @click="editStep(false)"
          :disabled="currentStep === 0"
          icon-right="chevron-left"
        ></b-button>
        <b-steps
          class="steps"
          type="is-black"
          v-model="currentStep"
          :animate-initially="true"
          mobile-mode="compact"
          :has-navigation="false"
        >
          <b-step-item label="Info" icon="user" clickable></b-step-item>
          <b-step-item
            label="Work"
            icon="shopping-cart"
            :type="itemsSignature.hash && !itemsSignatureRequired ? 'is-success' : 'is-black'"
            clickable
          ></b-step-item>
          <b-step-item label="Pay" icon="dollar-sign" type="is-black" :clickable="canSeePayment"></b-step-item>
        </b-steps>
        <b-button
          class="is-small is-info is-outline"
          @click="editStep(true)"
          :disabled="!canGoRight || currentStep === 2"
          icon-right="chevron-right"
        ></b-button>
      </div>
      <div>
        <div class="work-order-info" v-if="currentStep === 0">
          <b-skeleton size="is-large" :active="$asyncComputed.customer.updating"></b-skeleton>
          <div class="columns is-mobile">
            <div class="column">
              <b-field>
                <b-switch v-model="workOrder.isPrevailingWage" disabled>Prevailing Wage</b-switch>
              </b-field>
            </div>
          </div>
          <div class="customer-info">
            <div class="columns" v-if="!$asyncComputed.customer.updating">
              <div class="column">
                <div class="mb-2 is-size-3">{{ customer.name }}</div>
                <div class="customer-field">
                  <span class="field-name">Contact</span>
                  <span class="field-value">{{ contact.name }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Phone</span>
                  <span class="field-value">{{ contact.phone }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Email</span>
                  <span class="field-value">{{ contact.email || 'No Email' }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Address</span>
                  <span class="field-value">{{ address }}</span>
                </div>
              </div>
            </div>
            <div class="columns" v-if="!$asyncComputed.workOrder.updating">
              <div class="column">
                <div class="mb-2 is-size-3">Authorization Contact</div>
                <div class="customer-field">
                  <span class="field-name">Name</span>
                  <span class="field-value">{{ workOrder.authorizationContact.name }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Phone</span>
                  <span class="field-value">
                    <a class="underline caret-blue-500" :href="`tel:${workOrder.authorizationContact.phone}`">
                      {{ workOrder.authorizationContact.phone }}
                    </a>
                  </span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Email</span>
                  <span class="field-value">{{ workOrder.authorizationContact.email || 'No Email' }}</span>
                </div>
                <div class="customer-field">
                  <span class="field-name">Billing Address</span>
                  <span class="field-value">{{ workOrder.authorizationContact.address }}</span>
                </div>
              </div>
              <b-skeleton size="is-large" :active="$asyncComputed.customer.updating"></b-skeleton>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="is-flex is-flex-direction-row">
                <div class="label">Job Site</div>
                <a
                  class="ml-2 field underline caret-blue-500"
                  :href="`https://maps.google.com/maps?q=${workOrder.jobSite.address}`"
                  target="_blank"
                >
                  {{ workOrder.jobSite.address }}
                </a>
              </div>
              <div class="is-flex is-flex-direction-row">
                <div class="label">Date</div>
                <div class="ml-2 field">{{ workOrder.date | formatDate }}</div>
              </div>
              <div class="is-flex is-flex-direction-row">
                <div class="label">Method Of Payment</div>
                <div class="ml-2 field">{{ workOrder.methodOfPayment }}</div>
              </div>
            </div>
          </div>
          <div class="my-4 is-size-3">Type of Problem</div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field label="Work Order Type" expanded>
                <b-select v-model="workOrder.catalogTypeId" expanded disabled>
                  <option v-for="t in workOrderTypes" :value="t.id" :key="t.id">{{ t.name }}</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field label="Company" expanded>
                <b-select v-model="workOrder.company" expanded disabled>
                  <option v-for="c in companyOptions" :value="c.code" :key="c.code">
                    {{ c.code }} - {{ c.company }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field label="Appointment Reason" expanded>
                <b-select v-model="workOrder.appointmentReason" expanded disabled>
                  <option v-for="a in appointmentReasons" :value="a.value" :key="a.value">{{ a.name }}</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field>
                <b-switch v-model="workOrder.isEmergency" disabled>Emergency</b-switch>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field>
                <b-switch v-model="workOrder.isHealthAndSafety" disabled>Health &amp; Safety</b-switch>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field label="Job Info" disabled>
                <b-input type="textarea" v-model="workOrder.jobInfo" disabled></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <b-field label="Notes to Tech" disabled>
                <b-input type="textarea" v-model="workOrder.techNotes" disabled></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <work-order-products
                :work-order="workOrder"
                :show-product-price="false"
                :is-addable="false"
                :show-totals="false"
                :is-view-only="true"
              ></work-order-products>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <work-order-payments :work-order="workOrder" :can-edit="false"></work-order-payments>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <work-order-payment-history :work-order-id="workOrder.id"></work-order-payment-history>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <comments-view
                :association-id="workOrderId"
                :displayable-types="displayedCommentTypes"
                :comment-type="techCommentType"
              ></comments-view>
            </div>
          </div>
          <div class="my-4 is-size-3">Technicians</div>
          <div class="columns is-multiline is-mobile" v-if="loggedInAsDispatch && workOrder.state !== 'finished'">
            <div class="column is-half" v-if="workOrder.waitlisted">
              <b-field label="Work Date">
                <b-datetimepicker
                  v-model="workOrder.date"
                  icon="calendar"
                  placeholder="Click to select..."
                  :timepicker="{ incrementMinutes: 5 }"
                ></b-datetimepicker>
              </b-field>
            </div>
            <div class="column is-half" v-if="workOrder.waitlisted"></div>
            <div class="column is-half">
              <b-field label="Assigned Primary Technician" expanded>
                <b-select v-model="primaryTech" expanded>
                  <option v-for="tech in primaryTechs" :value="tech" :key="tech.id">
                    {{ tech.lastName }} {{ tech.firstName }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Assigned Secondary Technician" expanded>
                <b-select v-model="secondaryTech" expanded>
                  <option v-for="tech in secondaryTechs" :value="tech" :key="`${tech.id}-secondary`">
                    {{ tech.lastName }} {{ tech.firstName }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns is-multiline is-mobile" v-else>
            <div class="column is-fullwidth" v-for="tech in assignedTechs" :key="`${tech.id}-assigned`">
              <b-field :label="`${tech.isPrimary ? 'Primary ' : 'Assistant '}Technician`" expanded>
                <b-input type="text" :value="`${tech.lastName} ${tech.firstName}`" disabled></b-input>
              </b-field>
            </div>
          </div>
          <div class="my-4 is-size-3">Administration</div>
          <div class="is-flex is-flex-direction-row">
            <div class="created">Created By:</div>
            <div class="ml-1">{{ createdBy.firstName }} {{ createdBy.lastName }}</div>
          </div>
          <div class="is-flex is-flex-direction-row">
            <div class="created">Created At:</div>
            <div class="ml-1">{{ workOrder.createdAt | formatDate }}</div>
          </div>
          <div class="is-flex is-flex-direction-row">
            <div class="created">Referred By:</div>
            <div class="ml-1">
              {{ workOrder.referredBy && workOrder.referredBy.length > 0 ? workOrder.referredBy.join(', ') : 'None' }}
            </div>
          </div>
          <workorder-site-history
            :customer-id="customer.id"
            :job-site-id="workOrder.jobSite.id"
          ></workorder-site-history>
          <div class="buttons">
            <b-button class="is-fullsized" @click="editStep(true)">Next</b-button>
          </div>
        </div>

        <div v-if="currentStep === 1">
          <work-order-products
            :work-order="workOrder"
            @productUpdate="updateProducts"
            :is-addable="canEditLineItems"
            :is-editable="canEditLineItems"
            :show-product-price="true"
            :catalog="actualWorkOrderType?.name"
          ></work-order-products>
          <work-order-maintenance-product
            :work-order="workOrder"
            @onMaintenanceRequired="updateMaintenanceRequired"
            @onMaintenanceFrequency="updateMaintenanceFrequency"
            @onProductChange="updateMaintenanceProducts"
          ></work-order-maintenance-product>
          <div class="buttons">
            <template v-if="canGoRight">
              <b-button @click="editStep(false)">Previous</b-button>
              <b-button @click="editStep(true)">Next</b-button>
            </template>
            <b-button class="is-danger" @click="decline" v-if="isInStartState">Decline</b-button>
            <b-button class="is-warning" v-if="isInEditableStatus" @click="viewReasonForDelayModal = true">
              Delay
            </b-button>
            <b-button
              class="is-success"
              v-if="isInEditableStatus && itemsSignatureRequired"
              @click="isItemsSignatureViewActive = true"
            >
              {{ itemsSignatureExists ? 'Accept New Terms' : 'Accept Terms' }}
            </b-button>
            <b-button
              class="is-success"
              v-if="workOrder.state === workOrderStates.IN_PROGRESS && !itemsSignatureRequired"
              @click="isCompletionSignatureViewActive = true"
            >
              Accept Completion
            </b-button>
          </div>
        </div>

        <div class="final-payment" v-if="currentStep === 2">
          <work-order-products :work-order="workOrder" :is-editable="false" :is-addable="false"></work-order-products>
          <div v-if="!submittedDetails">
            <div class="mb-3" v-if="workOrder.status === workOrderStatuses.DECLINED">
              <div class="my-3 is-size-3">Decline</div>
              <b-field label="Reason For Decline">
                <textarea class="textarea" v-model="workOrder.reasonForDecline"></textarea>
              </b-field>
            </div>
            <div class="block owes">
              <div class="my-3" v-if="workOrder.status === workOrderStatuses.DECLINED">
                Customer Owes {{ (totals.assessmentFee / 100) | money }}
              </div>
            </div>

            <div class="block payment-options">
              <div class="title is-4">Payment</div>
              <b-field label="Method of Payment">
                <b-select v-model="workOrder.methodOfPayment" expanded disabled>
                  <option
                    v-for="paymentMethod in paymentMethods"
                    :value="paymentMethod.value"
                    :key="paymentMethod.value"
                  >
                    {{ paymentMethod.label }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Customer Payment">
                <b-select v-model="workOrder.actualMethodOfPayment" expanded :disabled="!canEdit">
                  <option
                    v-for="paymentMethod in paymentMethods"
                    :value="paymentMethod.value"
                    :key="`${paymentMethod.value}-actual`"
                  >
                    {{ paymentMethod.label }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Customer Payment Amount">
                <b-input v-model="workOrder.customerPaymentAmount" expanded :disabled="!canEdit"></b-input>
              </b-field>
              <b-field label="Comment" message="You must enter a minimum of 4 characters to submit.">
                <textarea class="textarea" v-model="workOrder.customerPaymentComment" :disabled="!canEdit"></textarea>
              </b-field>
            </div>
            <div class="block buttons payment-waiting">
              <b-button
                class="button is-success"
                @click="submitDetails"
                :disabled="!canSubmitPayment"
                v-if="!submittedDetails"
              >
                Submit
              </b-button>
            </div>
          </div>

          <div v-else>
            <b-message class="is-success">Successfully captured payment</b-message>
            <b-field label="Customer Payment Amount">
              <b-input v-model="workOrder.customerPaymentAmount" expanded disabled></b-input>
            </b-field>
          </div>

          <div class="block buttons payment-actions" v-if="submittedDetails">
            <b-button v-if="canGoRight" @click="editStep(false)">Previous</b-button>
            <b-button
              class="is-info"
              v-if="workOrder.status === workOrderStatuses.WAITING"
              @click="transitionState(workOrderStates.IN_PROGRESS)"
              :disabled="!canEdit"
            >
              Work
            </b-button>
            <b-button
              class="is-danger"
              v-if="workOrder.status === workOrderStatuses.WAITING"
              @click="decline"
              :disabled="!canEdit"
            >
              Decline
            </b-button>
            <confirm-comment-button
              button-label="Done"
              button-type="is-success"
              :is-disabled="!canEdit"
              :association-id="workOrderId"
              :comment-type="techCommentType"
              comment-label="Comment"
              :min-required-characters="4"
              modal-header="Closing Comment"
              @onCommentSaved="transitionToFinished"
            ></confirm-comment-button>
          </div>
        </div>
        <work-order-photos :work-order-id="workOrderId"></work-order-photos>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { get, identity, isEmpty, isEqual, keyBy, pick, uniqBy, uniqWith } from 'lodash';
import { mapGetters } from 'vuex';
import { CommentType } from '@newmoon-org/shared';
import { ArSubmissionStatus } from '@newmoon-org/types';

import CatalogService from '@/service/catalog.service';
import CustomerService from '@/service/customer.service';
import EmployeesService from '@/service/employees.service';
import WorkOrderService from '@/service/workorders.service';

import SignatureView from '@/components/SignatureView.vue';
import WorkOrderPayments from '@/components/workorders/WorkOrderPayments.vue';
import WorkOrderPaymentHistory from '@/components/workorders/WorkorderPaymentHistory.vue';
import WorkOrderPhotos from '@/components/workorders/WorkOrderPhotos.vue';
import WorkOrderProducts from '@/components/workorders/WorkOrderProducts.vue';
import WorkorderSiteHistory from '@/components/workorders/WorkorderSiteHistory.vue';
import WorkOrderTotals from '@/components/workorders/WorkOrderTotals.vue';
import WorkOrderMaintenanceProduct from '@/components/workorders/WorkOrderMaintenanceProduct.vue';
import ConfirmCommentButton from '@/components/ConfirmCommentButton.vue';

import schedulerMixin from '@/mixins/scheduler';
import CommentsView from '@/pages/CommentsView.vue';

export default {
  name: 'ManageWorkOrderPage',
  components: {
    WorkOrderPhotos,
    WorkOrderPayments,
    CommentsView,
    WorkOrderTotals,
    SignatureView,
    WorkOrderProducts,
    WorkorderSiteHistory,
    WorkOrderPaymentHistory,
    WorkOrderMaintenanceProduct,
    ConfirmCommentButton,
  },
  mixins: [schedulerMixin],
  data() {
    return {
      isCreatingDelayedWO: false,
      maintenanceFrequencies: [1, 2, 3, 6, 12, 18, 24],
      pendingOptimisticAdds: [],
      allTechs: [],
      primaryTech: {},
      secondaryTech: {},
      isEditLineItemModalActive: false,
      viewReasonForDelayModal: false,
      isCompletionSignatureViewActive: false,
      selectedLineItem: null,
      submittedDetails: false,
      workOrderDelays: { ...WorkOrderService.REASONS_FOR_DELAY },
      workOrderStates: { ...WorkOrderService.WORK_ORDER_STATES },
      workOrderStatuses: { ...WorkOrderService.WORK_ORDER_STATUS },
      currentStep: 0,
      isItemsSignatureViewActive: false,
      paymentMethods: WorkOrderService.WORK_ORDER_PAYMENT_METHODS,
      propertyRelations: [
        { value: 'owner', label: 'Owner' },
        { value: 'landlord', label: 'Landlord' },
        { value: 'propertyManager', label: 'Property Manager' },
      ],
      appointmentTypes: [
        { value: 'pending-parts', label: 'Unassigned - Pending Parts' },
        { value: 'waitlist', label: 'Unassigned - Wait list' },
        { value: 'assigned', label: 'Assigned' },
      ],
      lineItems: [],
      customerIsAuthorized: true,
      itemsSignature: {
        hash: null,
        signature: null,
        isVerballyAccepted: null,
        date: null,
      },
      completionSignature: {
        hash: null,
        signature: null,
        isVerballyAccepted: null,
        date: null,
      },
      jobPhotos: [],
      confirmDeletePhotosActive: false,
      previewImagesActive: false,
      1: 0,
      displayedCommentTypes: [
        CommentType.DFR,
        CommentType.ACCOUNTING_WORKFLOW,
        CommentType.DISPATCH_WORKFLOW,
        CommentType.TECH,
      ],
      techCommentType: CommentType.TECH,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('features', ['isFeatureEnabled']),
    actualWorkOrderType() {
      return this.workOrderTypesMap[this.workOrder.catalogTypeId] ?? {};
    },
    isInStartState() {
      return [this.workOrderStates.START, this.workOrderStates.NLA].includes(this.workOrder.state);
    },
    workOrderTypesMap() {
      return keyBy(this.workOrderTypes, 'id');
    },
    assignedTechs() {
      return [this.primaryTech, this.secondaryTech].filter(it => it?.techId || it?.id);
    },
    availableTechs() {
      const shouldNotSkip = it => !this.employeesToSkip?.includes(it.id);
      return this.allTechs?.filter(shouldNotSkip);
    },
    employeesToSkip() {
      return uniqWith([...(this.affectedEmployees ?? []), ...(this.conflictingBookingsAssignedTechs ?? [])], isEqual);
    },
    secondaryTechs() {
      return this.availableTechs?.filter(it => it.id !== this.primaryTech?.id && it.id !== this.primaryTech?.techId);
    },
    primaryTechs() {
      return this.availableTechs?.filter(
        it => it.id !== this.secondaryTech?.id && it.id !== this.secondaryTech?.techId
      );
    },
    loggedInUser() {
      return this.user;
    },
    loggedInAsTech() {
      return this.loggedInUser?.employee?.role?.name === 'Service Tech';
    },
    loggedInAsDispatch() {
      return this.loggedInUser?.employee?.role?.name === 'Dispatch';
    },
    canGoRight() {
      return this.canSeePayment || this.currentStep === 0;
    },
    canSeePayment() {
      return [this.workOrderStates.FINISHED, this.workOrderStates.PAYMENT].includes(this.workOrder.state);
    },
    signatureHeader() {
      return 'I agree to the terms and conditions applied.';
    },
    completionSignatureHeader() {
      return 'I agree to the fact that the job was completed.';
    },
    existingItemsSignature() {
      return this.itemsSignatureRequired ? null : this.itemsSignature?.signature;
    },
    existingCompletionSignature() {
      return this.completionSignature?.signature;
    },
    totals() {
      return WorkOrderService.getWorkOrderCost(this.workOrder);
    },
    selectedPhotos() {
      return this.jobPhotos.filter(p => p.isSelected);
    },
    lineItemsHash() {
      const lineItemsList = this.lineItems
        ?.map(it => `${it.product.id}-${it.quantity}-${it.isOptional ?? false}`)
        .sort();
      return this.getHash(lineItemsList?.join(','));
    },
    itemsSignatureRequired() {
      return this.itemsSignature.hash !== this.lineItemsHash || !this.itemsSignatureExists;
    },
    itemsSignatureExists() {
      return !!this.itemsSignature?.signature || !!this.itemsSignature?.isVerballyAccepted;
    },
    workOrderId() {
      return this.$route.params.workOrderId;
    },
    contact() {
      if (this.contacts?.length) {
        return this.contacts[0];
      }
      return {};
    },
    contacts() {
      return this.customer?.contacts;
    },
    address() {
      return this.customer?.billing?.address ?? '';
    },
    searchQuery() {
      return this.$route.query.searchQuery;
    },
    isMobile() {
      return this.$isMobile();
    },
    canEdit() {
      return ![this.workOrderStates.FINISHED].includes(this.workOrder.state);
    },
    isInEditableStatus() {
      return ![this.workOrderStatuses.DECLINED, this.workOrderStatuses.DELAYED].includes(this.workOrder.status);
    },
    canEditLineItems() {
      return ![this.workOrderStates.FINISHED, this.workOrderStates.PAYMENT].includes(this.workOrder.state);
    },
    canSubmitPayment() {
      return true;
      // return this.workOrder.customerPaymentComment?.length >= 4;
    },
  },
  watch: {
    techsWereAssigned: {
      handler(val) {
        if (this.workOrder.status) return;
        this.workOrder.status = val ? this.workOrderStatuses.ASSIGNED : this.workOrderStatuses.NEW;
        this.setStatusHistory();
      },
    },
    itemsSignatureRequired: {
      handler(value) {
        if (this.$asyncComputed.workOrder.updating) {
          return;
        }

        if (value) {
          this.transitionState(this.workOrderStates.NLA);
        } else {
          this.transitionState(this.workOrderStates.IN_PROGRESS);
        }
      },
    },
    loggedInUser: {
      handler() {
        if (!this.loggedInAsTech) {
          this.$buefy.dialog.confirm({
            title: 'Service Tech Workflow',
            message: `You are entering the Service Tech workflow area, are you sure you would like to continue?`,
            cancelText: 'No',
            confirmText: 'Yes',
            type: 'is-success',
            onCancel: () => {
              this.$router.go(-1);
            },
          });
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getAvailableTeam();
  },
  asyncComputed: {
    createdBy() {
      if (!this.workOrder?.createdBy)
        return Promise.resolve({
          firstName: 'Unknown',
          lastName: 'Unknown',
        });
      return EmployeesService.get(get(this.workOrder.createdBy, 'id', this.workOrder.createdBy));
    },
    workOrderTypes() {
      return CatalogService.getTypes();
    },
    appointmentReasons() {
      return WorkOrderService.getReasons();
    },
    companyOptions: {
      async get() {
        return WorkOrderService.woCompanies();
      },
      default: [],
    },
    workOrder: {
      async get() {
        return this.workOrderId
          ? WorkOrderService.getById(this.workOrderId).then(r => {
              if (!r.id) {
                this.$router.go(-1);
                return;
              }
              // if a user tries to access the manage work order page
              // for a project work order redirect them out.
              if (r?.project?.id) {
                this.$router.push({
                  name: 'project-work-order-edit',
                  params: {
                    projectId: r.project.id,
                    workOrderId: this.workOrderId,
                  },
                });
              }
              this.lineItems = r.lineItems;
              this.itemsSignature = r.itemsSignature || {
                hash: null,
                signature: null,
                isVerballyAccepted: null,
              };
              this.completionSignature = r.completionSignature || {
                hash: null,
                signature: null,
                isVerballyAccepted: null,
              };
              this.submittedDetails = r.waitingStatePaymentDetailsSubmitted || false;

              this.primaryTech = r.assignedTechs?.find(it => it.isPrimary && it.id) || {};
              this.secondaryTech = r.assignedTechs?.find(it => !it.isPrimary && it.id) || {};
              const result = {
                ...r,
                isMaintenanceRequired: r.isMaintenanceRequired ?? true,
                requiredMaintenceProduct: r.requiredMaintenceProduct ?? null,
                maintenanceFrequency: r.maintenanceFrequency ?? null,
                state: r.state ?? this.workOrderStates.NLA,
                actualMethodOfPayment: null,
                authorizationContact: r.authorizationContact || {},
                status: r.status ?? '',
                jobSite: r.jobSite || get(this.customer?.jobSites, '[0]', {}),
              };

              this.calculateStep(result.state);
              this.handleDynamicProsLoads(result.startDate, result.endDate, result.id);
              return result;
            })
          : Promise.resolve({
              jobSite: {},
              isMaintenanceRequired: true,
              maintenanceFrequency: null,
              authorizationContact: {},
              itemsSignatureAudit: [],
              date: null,
            }).catch(e => console.error(e));
      },
      default: {
        jobSite: {},
        isMaintenanceRequired: true,
        maintenanceFrequency: null,
        authorizationContact: {},
        date: null,
        itemsSignatureAudit: [],
      },
    },
    customer: {
      async get() {
        const customer = await (this.workOrder?.customer?.id
          ? await CustomerService.get(this.workOrder?.customer?.id)
          : {});
        if (!isEmpty(this.workOrder)) {
          this.workOrder.jobSite = this.workOrder?.jobSite || get(customer.jobSites, '[0]', {});
          if (!get(this.workOrder?.jobSite, 'id', null)) {
            this.workOrder.jobSite = customer.jobSites?.find(it => it.address === this.workOrder.jobSite) || {};
          }
        }
        return customer;
      },
      default: { id: null, ezCareDate: new Date(), ezCareStatus: 'None' },
    },
    serviceZones: {
      async get() {
        const serviceZones = await CatalogService.getBySpecifications('service-zone');
        this.workOrder.serviceZone = serviceZones.find(it => it.id === this.workOrder.serviceZone?.id) || { id: null };
        return serviceZones;
      },
      default: [],
    },
    isValid() {
      return (!this.itemsSignatureRequired || this.itemsSignature?.isVerballyAccepted) && this.workOrder.id;
    },
  },
  methods: {
    transitionToFinished() {
      this.transitionState(this.workOrderStates.FINISHED);
    },
    async updateMaintenanceFrequency({ maintenanceFrequency }) {
      this.workOrder.maintenanceFrequency = maintenanceFrequency;
      await this.update();
    },
    async updateMaintenanceRequired({ isMaintenanceRequired }) {
      this.workOrder.isMaintenanceRequired = isMaintenanceRequired;
      await this.update();
    },
    async updateMaintenanceProducts({ products }) {
      this.workOrder.maintenanceRequiredProducts = [...products];
      await this.update();
    },
    setStatusHistory() {
      this.workOrder.statusTransitionHistory = [
        ...(this.workOrder.statusTransitionHistory ?? []),
        {
          user: {
            id: this.loggedInUser?.employee?.id,
            name: [this.loggedInUser?.employee?.lastName, this.loggedInUser?.employee?.firstName]
              .filter(identity)
              .join(' '),
          },
          value: this.workOrder.status,
          date: new Date(),
        },
      ];
    },
    setStateHistory() {
      this.workOrder.stateTransitionHistory = [
        ...(this.workOrder.stateTransitionHistory ?? []),
        {
          user: {
            id: this.loggedInUser?.employee?.id,
            name: [this.loggedInUser?.employee?.lastName, this.loggedInUser?.employee?.firstName]
              .filter(identity)
              .join(' '),
          },
          value: this.workOrder.state,
          date: new Date(),
        },
      ];
    },
    cancelDelayModal() {
      this.viewReasonForDelayModal = false;
      this.workOrder.reasonForDelay = null;
    },
    getAvailableTeam() {
      if (this.employeesUnsub) {
        this.employeesUnsub();
      }

      this.employeesUnsub = EmployeesService.dbRef
        .where('workflowFunction.name', '==', 'Service Tech')
        .where('status', '==', 'active')
        .orderBy('lastName')
        .orderBy('firstName')
        .onSnapshot(
          docs => {
            this.allTechs = docs.docs
              .map(it => ({
                id: it.id,
                ...pick(it.data(), 'firstName', 'lastName', 'email'),
              }))
              .map(this.normalizeEmployee);
          },
          e => {
            this.$buefy.notification.open({
              message: e.message,
              type: 'is-danger',
            });
          }
        );
    },
    dateChanged(value) {
      this.workOrder.startDate = value;
      this.workOrder.endDate = new Date(value.getTime() + Number(this.workOrder.duration) * 60 * 60 * 1000);
      this.handleDynamicProsLoads(this.workOrder.startDate, this.workOrder.endDate, this.workOrderId);
    },
    normalizeEmployee(employee) {
      return pick(employee, 'id', 'firstName', 'lastName', 'email');
    },
    itemsSignatureChanged(signature) {
      this.itemsSignature.hash = this.lineItemsHash;
      this.itemsSignature.signature = signature.data;
      this.itemsSignature.isVerballyAccepted = signature.isVerballyAccepted;
      this.itemsSignature.date = new Date();
      this.itemsSignature = { ...this.itemsSignature };
    },
    getSignature(signature) {
      this.itemsSignatureChanged(signature);
      this.isItemsSignatureViewActive = false;
      if (this.itemsSignature.signature || this.itemsSignature.isVerballyAccepted) {
        this.transitionState(this.workOrderStates.IN_PROGRESS);
      }
    },
    completionSignatureChanged(signature) {
      this.completionSignature.hash = null;
      this.completionSignature.signature = signature.data;
      this.completionSignature.isVerballyAccepted = signature.isVerballyAccepted;
      this.completionSignature.date = new Date();
      this.completionSignature = { ...this.completionSignature };
    },
    getCompletionSignature(signature) {
      this.completionSignatureChanged(signature);
      this.isCompletionSignatureViewActive = false;
      if (this.completionSignature.signature || this.completionSignature.isVerballyAccepted) {
        this.workOrder.status = '';
        this.submittedDetails = false;
        this.workOrder.actualMethodOfPayment = null;
        this.workOrder.customerPaymentAmount = null;
        this.workOrder.customerPaymentComment = null;
        this.transitionState(this.workOrderStates.PAYMENT);
      }
    },
    transitionState(state = null) {
      console.log(`transitionState ${state}`);
      let setStep = true;
      const { state: beforeState, status } = this.workOrder;

      if (state) {
        setStep = !(
          this.workOrder.state === this.workOrderStates.IN_PROGRESS &&
          [this.workOrderStates.START, this.workOrderStates.NLA].includes(state)
        );
        this.workOrder.state = state;
        this.setStateHistory();
      } else {
        if (this.isInStartState) {
          this.workOrder.state = this.workOrderStates.IN_PROGRESS;
          this.setStateHistory();
        } else if (this.workOrder.state === this.workOrderStates.IN_PROGRESS) {
          this.workOrder.state = this.workOrderStates.PAYMENT;
          this.setStateHistory();
        } else if (this.workOrder.state === this.workOrderStates.PAYMENT) {
          this.workOrder.state = this.workOrderStates.FINISHED;
          this.setStateHistory();
        }
      }

      if (this.workOrder.state === this.workOrderStates.FINISHED) {
        this.workOrder.finishedAt = new Date();

        // add in the event to AR Submission here
        this.workOrder.accountingTag = {
          arSubmissions: [
            {
              createdAt: new Date(),
              createdBy: this.user.employee.id,
              employeeCode: this.user.employee.code,
              employeeName: `${this.user.employee.lastName} ${this.user.employee.firstName}`,
              status: ArSubmissionStatus.WorkOrderClosed,
            },
          ],
        };
      }

      const isStartToInProgress =
        [this.workOrderStates.START, this.workOrderStates.NLA].includes(beforeState) &&
        state === this.workOrderStates.IN_PROGRESS;

      const isStartToDelay = this.workOrderStates.START === beforeState && status === this.workOrderStatuses.DELAYED;

      const isBeforeScheduledWorkTime = dayjs().isBefore(dayjs(this.workOrder.date));

      if ((isStartToDelay || isStartToInProgress) && isBeforeScheduledWorkTime) {
        // not sure why we are using two dates here on the BE
        this.workOrder.date = new Date();
        this.workOrder.startDate = new Date();
        this.workOrder.endDate = dayjs().add(this.workOrder.duration, 'hours').toDate();
        this.workOrder.isStartedAheadOfTime = true;
      }

      if (setStep) {
        this.calculateStep(this.workOrder.state);
      }

      this.update();
    },
    async wait() {
      try {
        this.isCreatingDelayedWO = true;
        const [delayedWo, transferredWo] = await WorkOrderService.delayWorkOrder(this.workOrder);
        this.workOrder = delayedWo;
        this.viewReasonForDelayModal = false;
        this.isCreatingDelayedWO = false;

        this.$buefy.notification.open({
          message: `Moved the current WO into the new one with the number #${transferredWo.workOrderNumber} for a future work.`,
          type: 'is-warning',
          indefinite: true,
        });
        this.setStatusHistory();
        this.transitionState(this.workOrderStates.PAYMENT);
      } catch (e) {
        this.$buefy.notification.open({
          message: e.message,
          type: 'is-danger',
        });
      } finally {
        this.viewReasonForDelayModal = false;
        this.isCreatingDelayedWO = false;
      }
    },
    calculateStep(currentState) {
      if ([this.workOrderStates.START, this.workOrderStates.NLA].includes(currentState)) {
        this.currentStep = 0;
      } else if ([this.workOrderStates.IN_PROGRESS].includes(currentState)) {
        this.currentStep = 1;
      } else if ([this.workOrderStates.PAYMENT].includes(currentState)) {
        this.currentStep = 2;
      } else if ([this.workOrderStates.FINISHED].includes(currentState)) {
        this.currentStep = 0;
      }
    },
    getHash(str) {
      let hash = 0;
      for (let i = 0; i < str?.length; i++) {
        let character = str.charCodeAt(i);
        hash = (hash << 5) - hash + character;
        hash = hash & hash;
      }
      return hash;
    },
    submitDetails() {
      console.log('submitting details');
      this.submittedDetails = true;
      this.workOrder.waitingStatePaymentDetailsSubmitted = true;
      this.workOrder.payments = this.workOrder.payments ?? [];
      this.workOrder.payments.push(this.gatherPayment());
      return this.update();
    },
    gatherPayment() {
      const paymentObj = {
        // always put price into pennies
        methodOfPayment: this.workOrder.actualMethodOfPayment,
        customerPaymentAmount: this.workOrder.customerPaymentAmount * 100,
        customerPaymentComment: this.workOrder.customerPaymentComment,
        createdAt: new Date(),
      };

      return paymentObj;
    },
    update() {
      this.saving = true;
      this.workOrder.assignedTechs = [this.primaryTech, this.secondaryTech].filter(it => it?.techId || it?.id);
      this.workOrder.assignedTechsEmails = this.workOrder.assignedTechs?.map(it => it.email) || [];
      this.workOrder.lineItems = this.lineItems;
      this.workOrder.itemsSignature = this.itemsSignature;
      this.workOrder.itemsSignatureAudit = uniqBy(
        [...(this.workOrder.itemsSignatureAudit ?? []), { ...this.itemsSignature }].filter(it => !isEmpty(it)),
        'hash'
      );
      this.workOrder.completionSignature = this.completionSignature;
      return WorkOrderService.update(this.workOrder).finally(() => {
        this.saving = false;
      });
    },
    async decline() {
      const { result } = await this.$buefy.dialog.confirm({
        message: `Are you sure you want to decline?`,
        closeOnConfirm: true,
      });
      if (!result) return;

      this.workOrder.status = this.workOrderStatuses.DECLINED;
      this.setStatusHistory();
      this.transitionState(this.workOrderStates.PAYMENT);
    },
    updateProducts(products) {
      this.lineItems = [...products];
      this.update();
    },
    async cancel() {
      await this.$router.push({
        name: 'work-orders',
        query: { search: this.searchQuery },
      });
    },
    editLineItem(index, lineItem) {
      this.selectedLineItem = { ...lineItem, index };
      this.isEditLineItemModalActive = true;
    },
    editStep(forward = true) {
      window.scrollTo(0, 0);
      if (forward) {
        this.currentStep++;
        return;
      }
      this.currentStep--;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~bulma/sass/utilities/_all';

#manage-work-order-page {
  .created {
    font-weight: bold;
  }

  .customer-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1em 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1em 0;
  }

  .line-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .line-items {
    border: #ddd solid 1px;
    border-radius: 5px;

    .is-odd {
      background-color: #eff1fa;
    }

    .line-item-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .line-item-actions {
        flex-direction: row;
        display: flex;
      }
    }
  }

  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;

    .steps {
      flex-grow: 1;
    }
  }

  .navigation-buttons {
    display: flex;
    flex-direction: row;

    & > * {
      min-width: 100px;

      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }

  @include touch {
    .buttons {
      button {
        width: 100%;
        margin-right: 0;
      }

      flex-direction: column !important;
    }

    .customer-info {
      flex-direction: column !important;
    }
  }

  .field-name {
    font-weight: bold;
    margin-right: 1em;
  }

  .customer {
    .card {
      min-width: 400px;
      max-width: 600px;
    }
  }
}
</style>
