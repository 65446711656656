<template>
  <div id="admin-panel">
    <div class="header-container">
      <div class="header">Admin Panel</div>
    </div>
    <b-tabs v-model="activeTab">
      <b-tab-item v-if="loggedInUserIsAdmin" label="Access Levels" icon="lock">
        <roles-admin-page></roles-admin-page>
      </b-tab-item>
      <b-tab-item label="Schedules" icon="calendar-check">
        <schedules-admin-page></schedules-admin-page>
      </b-tab-item>
      <b-tab-item label="Companies" icon="building">
        <div class="table-content">
          <b-tooltip label="Add a new Company" position="is-right">
            <b-button class="is-primary" icon-right="plus" @click="newCompany()"></b-button>
          </b-tooltip>
        </div>
        <div class="table">
          <b-table :data="companies" :loading="$asyncComputed.companies.updating" :striped="true">
            <template #empty>
              <div class="has-text-centered">No Companies Found</div>
            </template>
            <b-table-column label="Code" field="code" v-slot="props">{{ props.row.code }}</b-table-column>
            <b-table-column label="Name" field="company" v-slot="props">{{ props.row.company }}</b-table-column>
            <b-table-column label="Social Media Link" field="socialLink" v-slot="props">
              {{ props.row.socialLink }}
            </b-table-column>
            <b-table-column label="Phone Number" field="phoneNumber" v-slot="props">
              {{ props.row.phoneNumber }}
            </b-table-column>
            <b-table-column label="Email Address" field="emailAddress" v-slot="props">
              {{ props.row.emailAddress }}
            </b-table-column>
            <b-table-column label="Physical Address" field="address" v-slot="props">
              {{ props.row.address }}
            </b-table-column>
            <b-table-column label="WO Status" field="woStatus" v-slot="props">
              <b-icon
                :type="props.row.woStatus ? `is-success` : `is-danger`"
                :icon="props.row.woStatus ? `check` : `times`"
                size="is-small"
              ></b-icon>
            </b-table-column>
            <b-table-column label="Project Status" field="projectStatus" v-slot="props">
              <b-icon
                :type="props.row.projectStatus ? `is-success` : `is-danger`"
                :icon="props.row.projectStatus ? `check` : `times`"
                size="is-small"
              ></b-icon>
            </b-table-column>
            <b-table-column label="Employee Status" field="employeeStatus" v-slot="props">
              <b-icon
                :type="props.row.employeeStatus ? `is-success` : `is-danger`"
                :icon="props.row.employeeStatus ? `check` : `times`"
                size="is-small"
              ></b-icon>
            </b-table-column>
            <b-table-column label="Actions" field="actions" v-slot="props" width="150">
              <div class="buttons">
                <b-tooltip label="Edit" position="is-left">
                  <b-button
                    class="mr-1 is-small is-primary"
                    icon-right="edit"
                    @click="editCompany(props.row)"
                  ></b-button>
                </b-tooltip>
              </div>
            </b-table-column>
          </b-table>
        </div>
        <b-modal
          v-model="isEditModalActive"
          trap-focus
          :full-screen="isMobile"
          aria-role="dialog"
          aria-modal
          :destroy-on-hide="false"
        >
          <template #default>
            <div class="modal-card">
              <header class="modal-card-head">
                {{ isNewCompany ? `New Company` : `Edit Company "${selectedCompany.code}"` }}
              </header>
              <section class="modal-card-body">
                <div class="columns">
                  <div class="column is-narrow">
                    <b-field>
                      <b-tooltip
                        label="If the company is not active you are not able to find it as a selection in a Work Order."
                        position="is-right"
                        multilined
                      >
                        <b-switch v-model="selectedCompany.woStatus">WO Active</b-switch>
                      </b-tooltip>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-field>
                      <b-tooltip
                        label="If the company is not active you are not able to find it as a selection in a Project."
                        position="is-right"
                        multilined
                      >
                        <b-switch v-model="selectedCompany.projectStatus">Project Active</b-switch>
                      </b-tooltip>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-field>
                      <b-tooltip
                        label="If the company is not active you are not able to find it as a selection in Employee primary company."
                        position="is-right"
                        multilined
                      >
                        <b-switch v-model="selectedCompany.employeeStatus">Employee Active</b-switch>
                      </b-tooltip>
                    </b-field>
                  </div>
                </div>
                <b-field label="Company Number">
                  <b-input v-model="selectedCompany.code"></b-input>
                </b-field>
                <b-field label="Name">
                  <b-input v-model="selectedCompany.company"></b-input>
                </b-field>
                <b-field label="Social Media Link">
                  <b-input v-model="selectedCompany.socialLink"></b-input>
                </b-field>
                <b-field label="Phone Number">
                  <b-input v-model="selectedCompany.phoneNumber"></b-input>
                </b-field>
                <b-field label="Email Address">
                  <b-input v-model="selectedCompany.emailAddress"></b-input>
                </b-field>
                <b-field label="Physical Address">
                  <b-input v-model="selectedCompany.address"></b-input>
                </b-field>
              </section>
              <footer class="modal-card-foot">
                <div class="is-pulled-right">
                  <b-button size="is-small" label="Close" type="is-danger" @click="closeCompany"></b-button>
                  <b-button
                    size="is-small"
                    :label="'Save'"
                    type="is-success"
                    @click="upsertCompany(selectedCompany)"
                  ></b-button>
                </div>
              </footer>
            </div>
          </template>
        </b-modal>
      </b-tab-item>
      <b-tab-item label="Email Templates" icon="envelope-open-text">
        <div class="table">
          <b-table :data="emails" :loading="$asyncComputed.emails.updating" :striped="true">
            <template #empty>
              <div class="has-text-centered">No Email Templates Found</div>
            </template>
            <b-table-column label="Type" field="name" v-slot="props">{{ props.row.name }}</b-table-column>
            <b-table-column label="Email Subject" field="emailSubject" v-slot="props">
              {{ props.row.emailSubject }}
            </b-table-column>
            <b-table-column label="Email Content" field="emailContent" v-slot="props">
              {{ props.row.emailContent }}
            </b-table-column>
            <b-table-column label="Actions" field="actions" v-slot="props" width="150">
              <div class="buttons">
                <b-tooltip label="Edit" position="is-left">
                  <b-button class="mr-1 is-small is-primary" icon-right="edit" @click="editEmail(props.row)"></b-button>
                </b-tooltip>
              </div>
            </b-table-column>
          </b-table>
        </div>
        <b-modal
          v-model="isEditModalActive"
          trap-focus
          :full-screen="isMobile"
          aria-role="dialog"
          aria-modal
          :destroy-on-hide="false"
        >
          <template #default>
            <div class="modal-card">
              <header class="modal-card-head">
                {{ `Edit "${selectedEmail.name}" Email Info` }}
              </header>
              <section class="modal-card-body">
                <b-field label="Email Subject">
                  <b-input v-model="selectedEmail.emailSubject"></b-input>
                </b-field>
                <b-field label="Email Content">
                  <textarea class="textarea" v-model="selectedEmail.emailContent"></textarea>
                </b-field>
              </section>
              <footer class="modal-card-foot">
                <div class="is-pulled-right">
                  <b-button size="is-small" label="Close" type="is-danger" @click="closeEmail"></b-button>
                  <b-button
                    size="is-small"
                    :label="'Save'"
                    type="is-success"
                    @click="upsertEmail(selectedEmail)"
                  ></b-button>
                </div>
              </footer>
            </div>
          </template>
        </b-modal>
      </b-tab-item>
      <b-tab-item label="Appointment Reasons" icon="question">
        <div class="table-content">
          <b-tooltip label="Add a new Appointment Reason" position="is-right">
            <b-button class="is-primary" icon-right="plus" @click="newReason()"></b-button>
          </b-tooltip>
        </div>
        <div class="table">
          <b-table :data="reasons" :loading="$asyncComputed.reasons.updating" :striped="true">
            <template #empty>
              <div class="has-text-centered">No Appointment Reasons Found</div>
            </template>
            <b-table-column label="Name" field="name" v-slot="props">{{ props.row.name }}</b-table-column>
            <b-table-column label="Value" field="value" v-slot="props">{{ props.row.value }}</b-table-column>
            <b-table-column label="Status" field="status" v-slot="props">{{ props.row.status }}</b-table-column>
            <b-table-column label="Actions" field="actions" v-slot="props" width="150">
              <div class="buttons">
                <b-tooltip label="Edit" position="is-left">
                  <b-button
                    class="mr-1 is-small is-primary"
                    icon-right="edit"
                    @click="editReason(props.row)"
                  ></b-button>
                </b-tooltip>
              </div>
            </b-table-column>
          </b-table>
        </div>
        <b-modal
          v-model="isEditModalActive"
          trap-focus
          :full-screen="isMobile"
          aria-role="dialog"
          aria-modal
          :destroy-on-hide="false"
        >
          <template #default>
            <div class="modal-card">
              <header class="modal-card-head">
                {{ isNewReason ? `New Appointment Reason` : `Edit "${selectedReason.name}"` }}
              </header>
              <section class="modal-card-body">
                <b-field>
                  <b-tooltip
                    label="If the Appointment Reason is not active you are not able to find it as a selection in a work order."
                    position="is-right"
                    multilined
                  >
                    <b-switch v-model="selectedReason.status" true-value="Active" false-value="Inactive">
                      Active
                    </b-switch>
                  </b-tooltip>
                </b-field>
                <b-field label="Name">
                  <b-input v-model="selectedReason.name"></b-input>
                </b-field>
                <b-field label="Value">
                  <b-input v-model="selectedReason.value"></b-input>
                </b-field>
              </section>
              <footer class="modal-card-foot">
                <div class="is-pulled-right">
                  <b-button size="is-small" label="Close" type="is-danger" @click="closeReason"></b-button>
                  <b-button
                    size="is-small"
                    :label="'Save'"
                    type="is-success"
                    @click="upsertReason(selectedReason)"
                  ></b-button>
                </div>
              </footer>
            </div>
          </template>
        </b-modal>
      </b-tab-item>
      <b-tab-item label="Time Off Reasons" icon="plane">
        <to-reasons-admin-page></to-reasons-admin-page>
      </b-tab-item>
      <b-tab-item label="Holidays" icon="calendar">
        <holidays-admin-page></holidays-admin-page>
      </b-tab-item>
      <b-tab-item v-if="loggedInUserIsAdmin" label="Data History Inspector" icon="search">
        <data-history-inspector-page></data-history-inspector-page>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { omit } from 'lodash';

import { db } from '@/service/firebase';

import SchedulesAdminPage from '@/pages/SchedulesAdminPage.vue';
import ToReasonsAdminPage from '@/pages/TOReasonsAdminPage.vue';
import HolidaysAdminPage from '@/pages/HolidaysAdminPage.vue';
import DataHistoryInspectorPage from '@/pages/DataHistoryInspectorPage.vue';
import visibilityMixin from '@/mixins/visibility';
import RolesAdminPage from '@/pages/RolesAdminPage.vue';

export default {
  name: 'AdminPanelPage',
  components: {
    RolesAdminPage,
    SchedulesAdminPage,
    ToReasonsAdminPage,
    HolidaysAdminPage,
    DataHistoryInspectorPage,
  },
  mixins: [visibilityMixin],
  data() {
    return {
      activeTab: 0,
      isLoading: true,
      isEditModalActive: false,
      isDeleting: false,
      selectedEmail: {},
      selectedReason: {
        status: 'Active',
      },
      selectedCompany: {
        woStatus: true,
        projectStatus: true,
        employeeStatus: true,
      },
      selectedTOReason: {
        status: 'Active',
      },
    };
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
    isNewReason() {
      return !this.selectedReason.id;
    },
    isNewCompany() {
      return !this.selectedCompany.id;
    },
    isNewTOReason() {
      return !this.selectedTOReason.id;
    },
  },
  asyncComputed: {
    emails: {
      async get() {
        return await db
          .collection('emails')
          .orderBy('name')
          .get()
          .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      },
      default: [],
    },
    reasons: {
      async get() {
        return await db
          .collection('appointmentReasons')
          .orderBy('name')
          .get()
          .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      },
      default: [],
    },
    companies: {
      async get() {
        return await db
          .collection('companies')
          .orderBy('code')
          .get()
          .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      },
      default: [],
    },
    toReasons: {
      async get() {
        return await db
          .collection('toOfficialReasons')
          .orderBy('name')
          .get()
          .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      },
      default: [],
    },
  },
  methods: {
    editEmail(c) {
      this.selectedEmail = { ...c };
      this.isEditModalActive = true;
    },
    closeEmail() {
      this.isEditModalActive = false;
      this.selectedEmail = {};
    },
    async upsertEmail(c) {
      this.isSaving = true;
      try {
        await db
          .collection('emails')
          .doc(c.id)
          .set({ ...omit(c, 'id') }, { merge: true });
      } catch (e) {
        console.log(e);
      }
      await this.$asyncComputed.emails.update();
      this.isSaving = false;
      this.closeEmail();
    },
    newReason() {
      this.selectedReason = {};
      this.isEditModalActive = true;
    },
    editReason(c) {
      this.selectedReason = { ...c };
      this.isEditModalActive = true;
    },
    closeReason() {
      this.isEditModalActive = false;
      this.selectedReason = {};
    },
    async upsertReason(c) {
      this.isSaving = true;
      try {
        await db
          .collection('appointmentReasons')
          .doc(c.id)
          .set({ ...omit(c, 'id') }, { merge: true });
      } catch (e) {
        console.log(e);
      }
      await this.$asyncComputed.reasons.update();
      this.isSaving = false;
      this.closeReason();
    },
    newCompany() {
      this.selectedCompany = {};
      this.isEditModalActive = true;
    },
    editCompany(c) {
      this.selectedCompany = {
        ...c,
        woStatus: !!c.woStatus,
        projectStatus: !!c.projectStatus,
        employeeStatus: !!c.employeeStatus,
      };
      this.isEditModalActive = true;
    },
    closeCompany() {
      this.isEditModalActive = false;
      this.selectedCompany = {};
    },
    async upsertCompany(c) {
      this.isSaving = true;
      try {
        await db
          .collection('companies')
          .doc(c.id)
          .set({ ...omit(c, 'id') }, { merge: true });
      } catch (e) {
        console.log(e);
      }
      await this.$asyncComputed.companies.update();
      this.isSaving = false;
      this.closeCompany();
    },
    newTOReason() {
      this.selectedTOReason = {};
      this.isEditModalActive = true;
    },
    editTOReason(c) {
      this.selectedTOReason = { ...c };
      this.isEditModalActive = true;
    },
    closeTOReason() {
      this.isEditModalActive = false;
      this.selectedTOReason = {};
    },
    async upsertTOReason(c) {
      this.isSaving = true;
      try {
        await db
          .collection('toOfficialReasons')
          .doc(c.id)
          .set({ ...omit(c, 'id') }, { merge: true });
      } catch (e) {
        console.log(e);
      }
      await this.$asyncComputed.toReasons.update();
      this.isSaving = false;
      this.closeTOReason();
    },
  },
};
</script>

<style scoped lang="scss"></style>
