<template>
  <div id="no-permissions-page">
    <div class="text-center text-red-700">
      <b-icon icon="low-vision" size="is-large"></b-icon>
    </div>
    <div class="text-center text-xl mt-5">
      Please reach out to administrator to set up your employee record and to get access!
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoPermissionsPage',
};
</script>
<style lang="scss">
#no-permissions-page {
  width: 100%;
  height: 100vh;
}
</style>
