import axios from 'axios';
import firebase from 'firebase/compat/app';

const baseURL = process.env.VUE_APP_BASE_URL;
const instance = axios.create({
  baseURL,
  timeout: 60000,
});

export { call };

async function call(config) {
  const token = await firebase.auth().currentUser?.getIdToken();
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  return instance(config);
}
