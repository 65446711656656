<template>
  <div id="work-order-view">
    <template v-if="loadingWo || $asyncComputed.assignedEmployees.updating || $asyncComputed.createdBy.updating">
      <div>Loading</div>
      <b-progress></b-progress>
    </template>
    <template v-else>
      <div class="is-flex is-flex-direction-row is-align-items-baseline">
        <div class="mb-4 is-size-3">
          <router-link :to="{ name: 'work-order-details', params: { workOrderId } }" target="_blank">
            <span>{{ identifierView }}</span>
          </router-link>
        </div>
        <b-icon class="ml-1" icon="external-link-alt"></b-icon>
      </div>
      <template v-if="showHealth">
        <div class="group-title">Status</div>
        <div class="field-group">
          <div class="field-title">Status</div>
          <div class="field-data">{{ workOrder.status || 'not set' | titleCase }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">State</div>
          <div class="field-data">{{ workOrder.state || 'not set' | titleCase }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Prevailing Wage</div>
          <div class="field-data">
            {{ workOrder.isPrevailingWage ? 'yes' : 'no' | titleCase }}
          </div>
        </div>
        <div class="field-group" v-if="workOrder.reasonForDecline">
          <div class="field-title">Decline Reason</div>
          <div class="field-data">{{ workOrder.reasonForDecline }}</div>
        </div>
        <div class="field-group" v-if="workOrder.reasonForDelay">
          <div class="field-title">Delay Reason</div>
          <div class="field-data">{{ workOrder.reasonForDelay }}</div>
        </div>
      </template>
      <template v-if="showAdministration">
        <div class="group-title">Administration</div>
        <div class="field-group">
          <div class="field-title">Created At</div>
          <div class="field-data">{{ createdAt }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Created By</div>
          <div class="field-data">{{ createdBy }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Company</div>
          <div class="field-data">{{ companyName }}</div>
        </div>
      </template>
      <template v-if="showCustomer">
        <div class="group-title">Customer Info</div>
        <div class="field-group">
          <div class="field-title">Customer</div>
          <div class="field-data">{{ customerName }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Billing Address</div>
          <div class="field-data">{{ customerAddress }}</div>
        </div>
      </template>
      <template v-if="showGeneralInfo">
        <div class="group-title">General Info</div>
        <div class="field-group">
          <div class="field-title">Original Project</div>
          <div class="field-data">
            {{ workOrder.originalTechProjectId ? workOrder.originalTechProjectId : 'Project was never altered' }}
          </div>
        </div>
        <div class="field-group">
          <div class="field-title">Job Site</div>
          <div class="field-data">{{ jobSite }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Service Zone</div>
          <div class="field-data">{{ serviceZone.name ?? 'Unknown' }}</div>
        </div>
        <div class="field-group" v-if="customerAuthorizationContact">
          <div class="field-title">Contact</div>
          <div class="field-data">{{ customerAuthorizationContact }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Tax Rate</div>
          <div class="field-data">{{ workOrder.taxRate ?? 'Unknown' }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Tax Jurisdiction</div>
          <div class="field-data">{{ workOrder.taxJurisdictionCode ?? 'Unknown' }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Problem Type</div>
          <div class="field-data">
            {{ workOrder.catalog.name || 'unknown' | titleCase }}
          </div>
        </div>
        <div class="field-group">
          <div class="field-title">Service Reason</div>
          <div class="field-data">
            {{ workOrder.appointmentReason || 'unknown' | titleCase }}
          </div>
        </div>
        <div class="field-group">
          <div class="field-title">Maintenance</div>
          <div class="field-data">
            {{ workOrder.isMaintanenceRequired ? 'yes' : 'no' | titleCase }}
          </div>
        </div>
        <div class="field-group">
          <div class="field-title">Frequency</div>
          <div class="field-data">{{ workOrder.maintenanceFrequency || 'N/A' }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Work Hours</div>
          <div class="field-data">{{ workHours }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Technicians</div>
          <div class="field-data">{{ assignedTechsString }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Job Info</div>
          <div class="field-data">{{ workOrder.jobInfo }}</div>
        </div>
        <div class="field-group">
          <div class="field-title">Note to Tech</div>
          <div class="field-data">{{ workOrder.techNotes }}</div>
        </div>
      </template>
      <template v-if="childWorkOrder.id || parentWorkOrder.id">
        <div class="group-title">Dependents</div>
        <div class="field-group" v-if="parentWorkOrder.id">
          <div class="field-title">Parent workorder</div>
          <div class="field-data">
            <router-link
              :to="{ name: 'work-order-details', params: { workOrderId: parentWorkOrder.id } }"
              target="_blank"
            >
              <span>#{{ parentWorkOrder.workOrderNumber }}</span>
            </router-link>
          </div>
          <b-icon class="ml-1" icon="external-link-alt"></b-icon>
        </div>
        <div class="field-group" v-if="childWorkOrder.id">
          <div class="field-title">Child workorder</div>
          <div class="field-data">
            <router-link
              :to="{ name: 'work-order-details', params: { workOrderId: childWorkOrder.id } }"
              target="_blank"
            >
              <span>#{{ childWorkOrder.workOrderNumber }}</span>
            </router-link>
          </div>
          <b-icon class="ml-1" icon="external-link-alt"></b-icon>
        </div>
      </template>
      <template v-if="showProducts">
        <div class="group-title">Products</div>
        <work-order-products
          class="mb-5"
          :work-order="workOrder"
          :show-header="false"
          :is-addable="false"
          :is-editable="false"
          :show-product-price="true"
          :show-totals="false"
        ></work-order-products>
        <work-order-maintenance-product :work-order="workOrder" :is-read-only="true"></work-order-maintenance-product>
      </template>
      <template v-if="showSignatures">
        <work-order-signatures :work-order="workOrder"></work-order-signatures>
      </template>
      <template v-if="showFinancials">
        <div class="group-title">Financials</div>
        <div class="payments-and-totals">
          <div class="totals">
            <template v-if="showPayments">
              <div class="title is-6">Payments</div>
              <div class="totals-content">
                <div class="total-line-item" v-for="payment in workOrder.payments" :key="payment.createdAt.seconds">
                  <div v-if="payment?.customerPaymentAmount > 0">
                    <label>{{ payment.methodOfPayment?.toUpperCase() }}</label>
                    <p>{{ (payment.customerPaymentAmount / 100) | money }}</p>
                    <div class="comment">{{ payment.customerPaymentComment }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-if="showTotals">
        <work-order-totals :work-order="workOrder" :show-header="false"></work-order-totals>
      </template>
      <work-order-photos :work-order-id="workOrderId"></work-order-photos>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { first, flatten, get, identity, isEmpty, keyBy, last, pick, sortBy, uniqBy } from 'lodash';
import { WorkOrderService as workOrderShared } from '@newmoon-org/shared';
import { mapGetters } from 'vuex';

import CatalogService from '@/service/catalog.service';
import CompanyService from '@/service/company.service';
import CustomerService from '@/service/customer.service';
import EmployeesService from '@/service/employees.service';
import WorkordersService from '@/service/workorders.service';

import WorkOrderPhotos from '@/components/workorders/WorkOrderPhotos.vue';
import WorkOrderProducts from '@/components/workorders/WorkOrderProducts.vue';
import WorkOrderSignatures from '@/components/workorders/WorkOrderSignatures.vue';
import WorkOrderTotals from '@/components/workorders/WorkOrderTotals.vue';

import WorkOrderMaintenanceProduct from './WorkOrderMaintenanceProduct.vue';

export default {
  name: 'WorkOrderView',
  components: {
    WorkOrderPhotos,
    WorkOrderSignatures,
    WorkOrderTotals,
    WorkOrderProducts,
    WorkOrderMaintenanceProduct,
  },
  props: {
    workOrderId: {
      type: String,
      required: true,
    },
    showHealth: {
      type: Boolean,
      default: true,
    },
    showAdministration: {
      type: Boolean,
      default: true,
    },
    showCustomer: {
      type: Boolean,
      default: true,
    },
    showGeneralInfo: {
      type: Boolean,
      default: true,
    },
    showProducts: {
      type: Boolean,
      default: true,
    },
    showSignatures: {
      type: Boolean,
      default: true,
    },
    showFinancials: {
      type: Boolean,
      default: true,
    },
    showPayments: {
      type: Boolean,
      default: true,
    },
    showTotals: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingWo: false,
      parentWorkOrder: {},
      childWorkOrder: {},
      workOrder: {
        company: {},
        customer: {},
        payments: [],
        jobSite: {},
        lineItems: [],
        catalog: {},
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    assignedTechs() {
      return uniqBy(
        [
          ...flatten(this.workOrder.costCodes?.map(it => [...(it.assignedTechs ?? []), it.foreman])).filter(
            it => !isEmpty(it)
          ),
          ...(this.workOrder.assignedTechs ?? []),
        ],
        'id'
      );
    },
    assignedTechsString() {
      const employeesMap = keyBy(this.assignedEmployees, 'id');
      const techs = this.assignedTechs.map(it => {
        const attrs = [
          employeesMap[it.id]?.code ?? null,
          it.email ?? null,
          it.isPrimary ? 'primary' : null,
          employeesMap[it.id]?.isForeman ? 'fmn' : null,
          employeesMap[it.id]?.manager ? 'mgr' : null,
        ].filter(identity);
        return `${it.lastName} ${it.firstName} (${attrs.join('/')})`;
      });
      return techs.join(', ') || 'No assigned techs';
    },
    lineItems() {
      return this.workOrder?.lineItems ?? [];
    },
    createdAt() {
      return this.convertToDate(this?.workOrder?.createdAt);
    },
    workHours() {
      const startDate = dayjs(
        first(sortBy(this.workOrder?.costCodes, 'startDate'))?.startDate ?? this.workOrder?.startDate
      );
      const endDate = dayjs(last(sortBy(this.workOrder?.costCodes, 'endDate'))?.endDate ?? this.workOrder?.endDate);
      return `${startDate?.format('MM/DD/YY h:mm A') ?? 'Unknown'} - ${
        endDate?.format('MM/DD/YY h:mm A') ?? 'Unknown'
      }`;
    },
    identifierView() {
      const pr = `PR# ${this.workOrder?.project?.code ?? this.workOrder?.techProjectId ?? 'Unknown'}/`;
      const wo = `WO# ${this.workOrder?.workOrderNumber ?? 'Unknown'}`;
      return `${pr}${wo}`;
    },
    startDate() {
      return this.convertToDate(this.workOrder?.startDate);
    },
    endDate() {
      return this.convertToDate(this.workOrder?.endDate);
    },
    companyName() {
      return this.workOrder?.company?.company ?? 'Unknown';
    },
    customerName() {
      return this?.workOrder?.customer?.displayAsName ?? 'Unknown';
    },
    customerAddress() {
      return this?.workOrder?.customer?.billing?.address ?? 'Unknown';
    },
    customerAuthorizationContact() {
      const c = this?.workOrder?.authorizationContact;
      const list = [
        `${c?.name}${c?.propertyRelation ? ` (${c?.propertyRelation})` : ''}`,
        c?.address,
        c?.email,
        c?.phone,
      ].filter(identity);
      return c?.name ? list.join(', ') : null;
    },
    jobSite() {
      return this?.workOrder?.jobSite?.address ?? 'Unknown';
    },
    total() {
      return workOrderShared.getWorkOrderCost({ ...this.workOrder });
    },
  },
  asyncComputed: {
    createdBy: {
      get() {
        return this.workOrder?.createdBy
          ? EmployeesService.get(get(this.workOrder.createdBy, 'id', this.workOrder.createdBy)).then(
              it => `${it.firstName} ${it.lastName}${it.code ? ` (${it.code})` : ''}`
            )
          : 'Unknown';
      },
      default: {
        firstName: 'Unknown',
        lastName: 'Unknown',
      },
    },
    assignedEmployees: {
      get() {
        return this.assignedTechs.length > 0
          ? EmployeesService.getByIds(this.assignedTechs.map(it => it.id)).then(r =>
              r.map(it => ({
                ...pick(it, ['email', 'firstName', 'lastName', 'code', 'id']),
                isForeman: String(it.isForeman) === 'true',
                manager: String(it.manager) === 'true',
              }))
            )
          : [];
      },
      default: [],
    },
    serviceZone: {
      async get() {
        return this.workOrder?.serviceZone?.id ? await CatalogService.getById(this.workOrder.serviceZone.id) : {};
      },
    },
  },
  mounted() {
    this.loadAndListenForWo();
  },
  methods: {
    loadAndListenForWo() {
      this.loadingWo = true;
      WorkordersService.dbRef.doc(this.workOrderId).onSnapshot(async r => {
        const workOrder = WorkordersService.mapWorkOrderFromResponse(r) ?? {};
        this.parentWorkOrder = workOrder.parentWorkOrderId
          ? pick(await WorkordersService.getById(workOrder.parentWorkOrderId), ['id', 'workOrderNumber'])
          : {};
        this.childWorkOrder =
          (await WorkordersService.dbRef
            .where('parentWorkOrderId', '==', workOrder.id)
            .get()
            .then(r =>
              first(
                r.docs.map(it => ({
                  id: it.id,
                  workOrderNumber: it.data().workOrderNumber,
                }))
              )
            )) ?? {};
        const companies = await CompanyService.getAll();
        workOrder.company = companies.find(c => c.code === workOrder.company);
        workOrder.customer = workOrder.customer?.id ? await CustomerService.get(workOrder.customer?.id) : {};
        workOrder.payments = workOrder.payments ?? [];
        workOrder.catalog = workOrder.catalogTypeId
          ? (await CatalogService.getTypeById(workOrder.catalogTypeId).then(it => pick(it, ['id', 'name']))) ?? {}
          : {};
        this.workOrder = workOrder;
        this.loadingWo = false;
      });
    },
    convertToDate(date) {
      return (
        date?.toLocaleString('en-US', {
          timeZone: 'America/Denver',
          dateStyle: 'short',
          timeStyle: 'short',
        }) ?? ''
      );
    },
  },
};
</script>

<style scoped lang="scss">
#work-order-view {
  .group-title {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .field-group {
    display: flex;
    flex-direction: row;
    margin: 0.75rem 0 0.75rem 0;
  }

  .field-title {
    font-weight: 600;
    font-size: 1rem;
    margin-right: 0.75rem;
    min-width: 150px;
  }

  .field-data {
    font-size: 1rem;
  }

  .table {
    margin-bottom: 32px;

    .no-data {
      padding: 0 16px 16px 32px;
    }

    .data-list {
      width: 100%;

      thead {
        border-bottom: 1px solid lightgray;

        tr {
          border-bottom: 1px solid lightgray;

          th {
            font-size: 14px;
            font-weight: 300;
            padding: 8px;
          }
        }
      }

      .detail-product {
        border: none;
        border-bottom: 1px solid lightgray;
        background: inherit;
      }

      .detail-data {
        border-bottom: 1px solid lightgray;

        td {
          padding: 8px;
        }
      }
    }
  }

  .payments-and-totals {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .payments {
    flex: 1;
  }

  .totals {
    flex: 1;

    .totals-content {
      display: flex;
      flex-direction: column;
      width: 250px;

      .total-line-item {
        display: flex;
        padding: 2px;
        border-bottom: 1px solid #333333;
        font-size: 1.1rem;
        flex-wrap: wrap;

        label {
          flex-grow: 1;
          flex-basis: 50%;
          min-width: 115px;

          &::after {
            content: ':';
          }
        }

        p {
          flex-grow: 1;
          flex-basis: 50%;
          font-weight: bold;
          text-align: right;
          min-width: 115px;
        }

        .comment {
          flex-grow: 1;
          flex-basis: 100%;
        }

        &.grand-total {
          border-top: 1px solid #333333;

          label {
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
