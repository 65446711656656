import CatalogProductsPage from '@/pages/CatalogProductsPage';
import CatalogProductEditPage from '@/pages/CatalogProductEditPage';
import CatalogItemsPage from '@/pages/CatalogItemsPage';
import CatalogItemEditPage from '@/pages/CatalogItemEditPage';
import CatalogsPage from '@/pages/CatalogsPage';
import CatalogEditPage from '@/pages/CatalogEditPage';

export default [
  {
    component: CatalogProductsPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Catalog Product List Page',
    },
    name: 'catalog-product',
    path: '/catalog-product',
  },
  {
    component: CatalogProductEditPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Catalog Product Edit Page',
    },
    name: 'catalog-product-edit',
    path: '/catalog-product/:catalogProductId',
  },
  {
    component: CatalogItemsPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Catalog Item List Page',
    },
    name: 'catalog-item',
    path: '/catalog-item',
  },
  {
    component: CatalogItemEditPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Catalog Item Edit Page',
    },
    name: 'catalog-item-edit',
    path: '/catalog-item/:catalogItemId',
  },
  {
    component: CatalogsPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Catalog List Page',
    },
    name: 'catalog',
    path: '/catalog',
  },
  {
    component: CatalogEditPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Catalog Edit Page',
    },
    name: 'catalog-edit',
    path: '/catalog/:catalogId',
  },
];
