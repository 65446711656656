<template>
  <div id="customers">
    <div class="header-container">
      <div class="header">Customers</div>
      <b-tooltip label="Create a new customer" position="is-left">
        <b-button class="is-primary" icon-right="plus" @click="newCustomer"></b-button>
      </b-tooltip>
    </div>
    <div class="table-content">
      <algolia-list :config="config"></algolia-list>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AlgoliaList from '@/components/list/AlgoliaList.vue';

export default {
  name: 'Customers',
  components: { AlgoliaList },
  data() {
    return {
      config: {
        indexName: 'customers',
        hydrate: true,
        attributes: [
          {
            displayName: 'Name',
            key: 'name',
            highlight: true,
            click: c => {
              this.goToCustomerEdit(c);
            },
          },
          {
            displayName: 'Contact',
            key: 'contacts[0].name',
          },
          {
            displayName: 'Phone',
            key: 'contacts[0].phone',
          },
          {
            displayName: 'Address',
            key: 'billing.address',
            highlight: true,
          },
        ],
        facets: {
          multiValue: [
            { facet: 'billing.zip', displayName: 'Zip Code' },
            { facet: 'billing.city', displayName: 'City' },
            { facet: 'accountingStatus', displayName: 'Status' },
          ],
          toggle: [
            {
              facet: 'deleted',
              displayName: 'Deleted',
              default: true,
              defaultValue: false,
              display: true,
            },
          ],
        },
        actionColumnWidth: 150,
        actions: [
          {
            text: 'Book WO',
            click: async item => {
              await this.goToBooking(item);
            },
            if: customer => {
              return !customer.deleted;
            },
            classes: ['is-primary'],
          },
          {
            icon: 'edit',
            if: () => true,
            click: async item => {
              await this.goToCustomerEdit(item);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  methods: {
    searchFunction(helper) {
      const page = helper.getPage();
      if (!helper.state.facets.includes('deleted')) helper.state.facets.push('deleted');
      helper.addFacetExclusion('deleted', true);

      helper.setPage(page).search();
    },
    async newCustomer() {
      await this.goToCustomerEdit({ id: 'new' });
    },
    async goToCustomerEdit(customer) {
      await this.$router.push({
        name: 'customer-edit',
        params: {
          customerId: customer.id ?? customer.objectID,
        },
      });
    },
    async goToBooking(customer) {
      await this.$router.push({
        name: 'customer-booking',
        params: {
          customerId: customer.id ?? customer.objectID,
          workOrderId: 'new',
        },
      });
    },
  },
};
</script>

<style lang="scss">
#customers {
  max-width: 1600px;
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.skeletons {
  margin: 30px 0;
}

.space {
  margin-right: 10px;
}
</style>
