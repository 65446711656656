<template>
  <div id="catalog-items">
    <div class="header-container">
      <div class="header">Catalog Items</div>
      <div class="actions">
        <b-tooltip label="Create a new Catalog Item" position="is-left">
          <b-button class="is-primary" icon-right="plus" @click="newCatalogItem"></b-button>
        </b-tooltip>
      </div>
    </div>
    <search-list index-name="catalogItems" :facets="facets" :is-reportable="true">
      <template #results="{ items }">
        <table class="table is-striped">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.objectID">
              <td>
                <a @click="goToCatalogItemEditPage(item)">
                  <ais-highlight attribute="name" :hit="item"></ais-highlight>
                </a>
              </td>
              <td>
                <ais-highlight attribute="type" :hit="item"></ais-highlight>
              </td>
              <td>
                <b-button class="button is-small" @click="goToCatalogItemEditPage(item)" icon-right="edit"></b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </search-list>
  </div>
</template>

<script>
import SearchList from '@/components/list/searchList.vue';

export default {
  name: 'CatalogItemsPage',
  components: { SearchList },
  data() {
    return {
      isLoading: false,
      facets: [{ facet: 'type', displayName: 'Type' }],
    };
  },
  methods: {
    newCatalogItem() {
      this.$router.push({
        name: 'catalog-item-edit',
        params: { catalogItemId: 'new' },
      });
    },
    goToCatalogItemEditPage(catalogItem) {
      this.$router.push({
        name: 'catalog-item-edit',
        params: { catalogItemId: catalogItem.objectID },
      });
    },
  },
};
</script>

<style scoped lang="scss">
#catalog-items {
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .actions {
      display: flex;
      flex-direction: row;
      *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  .is-well {
    background-color: #efefef;
    border: #ddd 1px solid;
    border-radius: 5px;
  }
}
</style>
