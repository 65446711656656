import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';
import { omit } from 'lodash';

import { init } from '@/service/generic.service';
import { db } from '@/service/firebase';
import WorkOrderService from '@/service/workorders.service';
import CommonListsService from '@/service/common-lists.service';

export const PROSPECTIVE_TYPES_COMMON_LIST_ID = 'prospectiveCustomerTypes';

export const EZ_CARE_TYPES = [
  {
    value: null,
    label: 'None',
  },
  {
    value: 'basic',
    label: 'Basic(2)',
  },
  {
    value: 'standard',
    label: 'Standard(4)',
  },
  {
    value: 'complete',
    label: 'Complete(6)',
  },
];

const methods = init({
  collectionPath: 'customers',
  orderByFields: ['displayAsName'],
  algoliaIndex: 'customers',
  dateFields: ['ezCareDate', 'updatedAt', 'createdAt'],
});

const defaultCustomer = () => ({
  ezCareStatus: 'none',
  accountingStatus: 'none',
  discountStatus: 'none',
  billing: {},
  contacts: [],
  jobSites: [],
  deleted: false,
});

async function save(customer, mergeCustomer = {}) {
  const method = customer.id ? 'update' : 'set';

  const customerRef = db.collection('customers').doc(customer.id);
  const customerGettingMergedRef = db.collection('customers').doc(mergeCustomer.id);

  return db.runTransaction(async transaction => {
    // if we are merging
    // - update customer on all Service WO's
    // - soft delete the customer
    if (mergeCustomer.id) {
      const workOrders = await WorkOrderService.getByCustomerId(mergeCustomer.id);
      workOrders.forEach(wo => {
        const workOrderRef = db.collection('workorders').doc(wo.id);
        transaction.update(workOrderRef, {
          customer: { id: customer.id, displayAsName: customer.name },
        });
      });

      transaction.update(customerGettingMergedRef, { deleted: true });
    }

    // save customer, and merged jobsites & contacts if applicable
    const meta = { updatedAt: new Date() };
    // if we are creating a new customer, set the createdAt date
    if (method === 'set') meta.createdAt = new Date();

    transaction[method](customerRef, {
      ...omit(customer, 'id'),
      ...meta,
    });

    return customerRef;
  });
}

async function get(id) {
  const customer = await methods.getById(id);
  customer.jobSites = customer.jobSites?.map(it => conversionServiceShared.mapDates(it, ['ezCareStartDate']));
  return customer;
}

async function list(params) {
  return methods.list(params);
}

async function getProspectiveTypes() {
  return (await CommonListsService.getById(PROSPECTIVE_TYPES_COMMON_LIST_ID))?.list ?? [];
}

export default {
  list,
  get,
  update: methods.update,
  create: methods.create,
  softDelete: methods.softDelete,
  discountStatusOptions: [
    { label: 'None', value: 'none' },
    {
      label: 'Military',
      value: 'military',
    },
    { label: 'Senior Citizen', value: 'seniorCitizen' },
  ],
  accountStatusOptions: [
    { label: 'None', value: 'none' },
    { label: 'Hold', value: 'hold' },
    { label: 'Bill Out', value: 'billOut' },
    { label: 'Pay on Site', value: 'payOnSite' },
    { label: 'CC Vault', value: 'ccVault' },
    { label: 'Synchrony/Financing', value: 'synchronyFinancing' },
    { label: 'Credit on Acct', value: 'creditOnAccount' },
    { label: 'Prospect', value: 'Prospect' },
  ],
  ezCareStatusOptions: [
    { label: 'None', value: 'none' },
    { label: 'Good', value: 'good' },
    {
      label: 'Better',
      value: 'better',
    },
    { label: 'Best', value: 'best' },
  ],
  defaultCustomer,
  dbRef: methods.dbRef,
  dateFields: methods.dateFields,
  save: save,
  getProspectiveTypes,
};
