import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/performance';
import 'firebase/compat/analytics';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};
firebase.initializeApp(firebaseConfig);

// utils
firebase.firestore().settings({ ignoreUndefinedProperties: true });
const db = firebase.firestore();
const realtimeDb = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
const fieldPath = firebase.firestore.FieldPath;

const performance = firebase.performance();
const analytics = firebase.analytics();

if (process.env.VUE_APP_USE_EMULATOR === 'yes') {
  db.useEmulator('localhost', 4001);
}

// if(process.env.VUE_APP_APP_CHECK_PUBLIC_KEY !== false) {
//   const appCheck = firebase.appCheck();
//   appCheck.activate(process.env.VUE_APP_APP_CHECK_PUBLIC_KEY, true);
// }

async function queryForList(collectionName) {
  return db
    .collection(collectionName)
    .get()
    .then(response => {
      const array = [];
      response.forEach(doc => {
        array.push({ id: doc.id, ...doc.data() });
      });
      return array;
    });
}

async function createDocument(collectionName, document) {
  return db
    .collection(collectionName)
    .add(document)
    .then(response => response.id);
}

async function updateDocument(collectionName, documentId, document) {
  return db
    .collection(collectionName)
    .doc(documentId)
    .set(document)
    .then(() => documentId);
}

// export utils/refs
export {
  db,
  auth,
  storage,
  fieldPath,
  performance,
  analytics,
  realtimeDb,
  queryForList,
  createDocument,
  updateDocument,
};
