import { init } from '@/service/generic.service';

const methods = init({
  collectionPath: 'companies',
  algoliaIndex: 'companies',
});

export default {
  dbRef: methods.dbRef,
  get: methods.getById,
  list: methods.list,
  getAll: methods.getAll,
  getAllActive: methods.getAllActive,
  update: methods.update,
};
