import { AlgoliaDfrEntry } from '@newmoon-org/types';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export type DfrState = {
  selected: AlgoliaDfrEntry[];
};
const initialState = (): DfrState => ({
  selected: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
