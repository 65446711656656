import ProjectListPage from '@/pages/ProjectListPage';
import ProjectEditPage from '@/pages/ProjectEditPage';
import ProjectWorkOrderEdit from '@/pages/ProjectWorkOrderEdit';
import ProjectCreatePage from '@/pages/ProjectCreatePage.vue';

export default [
  {
    component: ProjectListPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Project List Page',
    },
    name: 'projects',
    path: '/projects',
  },
  {
    component: ProjectEditPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Project Edit Page',
    },
    name: 'project-edit',
    path: '/projects/:projectId',
  },
  {
    component: ProjectCreatePage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Project Create Page',
    },
    name: 'project-create',
    path: '/project-create',
  },
  {
    component: ProjectWorkOrderEdit,
    meta: {
      requiresAuth: true,
      featureLabel: 'Work Assignment Management Page',
    },
    name: 'project-work-order-edit',
    path: '/projects/:projectId/work-order/:workOrderId',
  },
];
