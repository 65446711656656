<template>
  <div class="flowright-autocomplete">
    <b-field label="Address Search">
      <input class="input" type="text" ref="autocomplete" :disabled="deleted" />
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'AddressAutoComplete',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    deleted: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      query: null,
      autocomplete: null,
    };
  },
  mounted() {
    const options = {
      componentRestrictions: { country: 'us' },
    };
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, options);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (!place) return;

      let address1 = '';
      let postcode = '';
      let state = '';
      let country = '';
      let locality = '';
      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality':
            locality = component.long_name;
            break;
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          case 'country':
            country = component.long_name;
            break;
        }
      }

      this.$emit('input', {
        address1,
        postcode,
        state,
        country,
        locality,
      });
    });
  },
  methods: {
    handler(e) {
      console.log(e);
    },
  },
};
</script>
