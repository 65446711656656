import LoginPage from '@/pages/LoginPage';
import NoPermissionsPage from '@/pages/NoPermissionsPage';

export default [
  {
    component: LoginPage,
    meta: {
      requiresAuth: false,
      featureLabel: 'Login Page',
    },
    name: 'login',
    path: '/login',
  },
  {
    component: NoPermissionsPage,
    meta: {
      requiresAuth: false,
      featureLabel: 'Missing Access',
    },
    name: 'missingAccess',
    path: '/missing-access',
  },
];
