<template>
  <div id="dfr-report-table">
    <div v-if="aggregateData"></div>
    <b-field class="my-4" label="Search">
      <b-input type="text" v-model="searchQuery" placeholder="Employee Name"></b-input>
    </b-field>
    <div class="is-flex is-flex-direction-column">
      <table class="table is-striped">
        <thead>
          <th>Employee</th>
          <th>New</th>
          <th>Manager Approval</th>
          <th>Approved</th>
          <th>Total</th>
        </thead>
        <tbody>
          <tr v-for="(dfrAgg, idx) in filteredAggregationData" :key="idx">
            <td>{{ getName(dfrAgg) }}</td>
            <td>{{ getStatus(dfrAgg, 'new-hr').duration.toFixed(2) }}</td>
            <td>{{ getStatus(dfrAgg, 'new-mgr').duration.toFixed(2) }}</td>
            <td>{{ getStatus(dfrAgg, 'approved').duration.toFixed(2) }}</td>
            <td>{{ dfrAgg.total.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'DfrReportTable',
  props: {
    aggregateData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: null,
    };
  },
  computed: {
    filteredAggregationData() {
      if (!this.aggregateData) return;
      if (!this.searchQuery) return this.aggregateData;

      return this.aggregateData.filter(dfr => {
        const searchField = `${dfr?.employee?.firstName} ${dfr?.employee?.lastName}`;
        return searchField.search(new RegExp(this.searchQuery, 'i')) !== -1;
      });
    },
  },
  methods: {
    getStatus(data, status) {
      return get(data.dfrByStatus, status, { duration: 0 });
    },
    getName(data) {
      return `${data?.employee?.firstName} ${data?.employee?.lastName}`;
    },
  },
};
</script>

<style scoped></style>
