<template>
  <div class="work-order-totals">
    <div class="total">
      <div class="my-1 is-size-4 total-title" v-if="showHeader">Totals</div>
      <div class="my-1 is-emergency" v-if="isEmergency">Pricing affects the emergency status of the call.</div>
      <div class="total-items">
        <div class="total-line-item" v-if="!workOrder.isTaxOverride">
          <div class="total-label">Non Taxable</div>
          <div class="total-value">{{ (total.nonTaxable / 100) | money }}</div>
        </div>
        <div class="total-line-item" v-if="!workOrder.isTaxOverride">
          <div class="total-label">Taxable</div>
          <div class="total-value">{{ (total.taxable / 100) | money }}</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Discount</div>
          <div class="total-value">({{ (total.discount / 100) | money }})</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Payments</div>
          <div class="total-value">({{ (total.payments / 100) | money }})</div>
        </div>
        <div class="total-line-item" v-if="!workOrder.isTaxOverride">
          <div class="total-label">Tax ({{ workOrder.taxRate * 100 }}%)</div>
          <div class="total-value">{{ (total.tax / 100) | money }}</div>
        </div>
        <div class="total-line-item">
          <div class="total-label">Total</div>
          <div class="total-value">{{ (total.total / 100) | money }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WorkOrderService as workOrderShared } from '@newmoon-org/shared';

export default {
  name: 'WorkOrderTotals',
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    total() {
      return workOrderShared.getWorkOrderCost({ ...this.workOrder });
    },
    isEmergency() {
      return this.workOrder.isEmergency ?? false;
    },
  },
};
</script>

<style scoped lang="scss">
.total {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .is-emergency {
    font-size: 13px;
    color: red;
  }

  .total-items {
    display: flex;
    flex-direction: column;

    .total-line-item {
      display: flex;
      flex-direction: row;
      margin: 2px 0;

      .total-label {
        min-width: 200px;
        font-size: 16px;
      }

      .total-value {
        flex-grow: 1;
        margin-left: 1em;
      }
    }
  }
}
</style>
