import { first, get, groupBy, chain, identity } from 'lodash';

import { init } from '@/service/generic.service';
import { db } from '@/service/firebase';

const CATALOG_ITEM_TYPES = [
  {
    value: 'fpv',
    label: 'FPVM',
  },
  {
    value: 'permit',
    label: 'Permit',
  },
  {
    value: 'fixture',
    label: 'Fixture',
  },
  {
    value: 'appliance',
    label: 'Appliance',
  },
  {
    value: 'equipment',
    label: 'Equipment',
  },
  {
    value: 'merchandise',
    label: 'Merchandise',
  },
  {
    value: 'labor',
    label: 'Labor',
  },
];

const QUALITY = [
  {
    value: 'good',
    label: 'Good',
  },
  {
    value: 'better',
    label: 'Better',
  },
  {
    value: 'best',
    label: 'Best',
  },
];

const methods = init({
  collectionPath: 'catalogItems',
  algoliaIndex: 'catalogItems',
});

export default {
  list: methods.list,
  getById,
  create: methods.create,
  update: methods.update,
  getItemsForProduct,
  assignSupplier,
  unassignSupplier,
  editAssignedSupplier,
  CATALOG_ITEM_TYPES,
  QUALITY,
};

async function editAssignedSupplier(catalogItemId, supplierItemId, newSupplier) {
  const catalogItem = await getById(catalogItemId);
  const suppliers = catalogItem.suppliers || [];

  const index = suppliers.findIndex(s => s.id === supplierItemId);
  if (index === -1) throw new Error('Supplier not found');

  suppliers[index] = { ...suppliers[index], ...newSupplier, pricedAt: new Date() };
  return methods.update(catalogItemId, { suppliers }, true);
}

async function assignSupplier(catalogItemId, newSupplier) {
  const catalogItem = await getById(catalogItemId);
  const suppliers = catalogItem.suppliers || [];

  if (suppliers.find(s => s.id === newSupplier.id))
    throw new Error('Supplier already exists, please select another supplier.');

  suppliers.push({ ...newSupplier, pricedAt: new Date() });
  return methods.update(catalogItemId, { suppliers }, true);
}

async function unassignSupplier(catalogItemId, supplierId) {
  const catalogItem = await getById(catalogItemId);
  const suppliers = catalogItem.suppliers || [];

  const index = suppliers.findIndex(s => s.id === supplierId);
  if (index === -1) throw new Error('Supplier not found');

  suppliers.splice(index, 1);
  return methods.update(catalogItemId, { suppliers }, true);
}

async function getById(id) {
  const doc = await db.collection('catalogItems').doc(id).get();
  const data = { ...doc.data(), id: doc.id };

  // todo use chunked db calls
  if (data.supplierItems) {
    data.supplierItems = await Promise.all(
      chain(data.supplierItems)
        .map('supplierItemId')
        .filter(identity)
        .map(id =>
          db
            .collection('supplierItems')
            .doc(id)
            .get()
            .then(item => ({ ...item.data(), supplierItemId: id }))
        )
        .value()
    );
  }
  return data;
}

async function getItemsForProduct(product) {
  if (!product.items) return [];
  const items = product.items.map(i => i.itemId).filter(i => i);

  const itemsById = groupBy(await methods.getChunkedDBResults(items), 'id');
  return product.items.map(i => ({ ...i, ...first(get(itemsById, i.itemId)) }));
}
