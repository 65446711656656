import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';
import { v4 as uuidv4 } from 'uuid';

import CustomerService from '@/service/customer.service';

export default {
  data() {
    return {
      customer: {},
      loadingCustomer: false,
      customerUnsub: null,
    };
  },
  methods: {
    observeCustomer(id) {
      if (!id) {
        return;
      }

      this.loadingCustomer = true;
      if (this.customerUnsub) {
        this.customerUnsub();
        this.customer = {};
      }

      this.customerUnsub = CustomerService.dbRef.doc(id).onSnapshot(async doc => {
        if (doc.exists) {
          this.customer = conversionServiceShared.mapDates(
            conversionServiceShared.mapDoc(doc),
            CustomerService.dateFields
          );
          this.customer.jobSites = this.customer.jobSites?.map(it =>
            conversionServiceShared.mapDates(it, ['ezCareStartDate'])
          );
          this.customer.ezCareDate = this.customer.ezCareDate || new Date();
          const hasMissingJobSites = !!this.customer.jobSites?.find(it => !it.id);
          this.customer.jobSites =
            this.customer.jobSites?.map(it => ({
              ...it,
              id: it.id || uuidv4(),
            })) || [];
          if (hasMissingJobSites) {
            await CustomerService.update(this.customer.id, this.customer);
          }
        } else {
          this.$buefy.notification.open({
            message: `Failed to find the customer(${id})`,
            type: 'is-danger',
          });
        }

        this.loadingCustomer = false;
      });
    },
  },
};
