<template>
  <div class="type-picker">
    <b-field label="Time Periods">
      <b-select expanded placeholder="Select" v-model="selectedType">
        <option v-for="option in frequencyOptions" :key="option.key" :value="option.key">{{ option.text }}</option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'TypePicker',
  props: {
    selectedValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedType: this.selectedValue,
      frequencyOptions: [
        {
          key: 'day-time',
          text: 'Day / Time',
        },
        {
          key: 'month-year',
          text: 'Month / Year',
        },
      ],
    };
  },
  watch: {
    selectedType(newVal) {
      this.$emit('change', newVal);
    },
  },
};
</script>
