import { AlgoliaDfrEntry } from '@newmoon-org/types';

export default {
  addSelected({ commit, state }, payload: AlgoliaDfrEntry) {
    commit('setSelected', [...state.selected, payload]);
  },
  clearSelected({ commit }) {
    commit('clearSelected');
  },
  removeSelected({ commit, state }, payload: AlgoliaDfrEntry) {
    commit(
      'setSelected',
      state.selected.filter(entry => entry.objectID !== payload.objectID)
    );
  },
};
