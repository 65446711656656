import { pick } from 'lodash';

import { init } from '@/service/generic.service';

import store from '@/store';

const methods = init({ collectionPath: 'catalogTypes' });

export default {
  dbRef: methods.dbRef,
  get: methods.getById,
  getAll: methods.getAll,
  getAllActive: methods.getAllActive,
  update: methods.update,
  create,
};

function create(catalog) {
  catalog.createdAt = new Date();
  const user = store.getters['auth/user'].employee;
  catalog.createdBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);
  return methods.create({
    ...catalog,
    value: catalog.name.replaceAll(/\s/g, ''),
  });
}
