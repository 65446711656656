import DFREditPage from '@/pages/DFREditPage';
import DFRList from '@/pages/DFRPage';
import DFRApprovalPage from '@/pages/DFRApprovalPage';

export default [
  {
    component: DFRApprovalPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'DFR Management/Edit Page',
    },
    name: 'dfr-approval',
    path: '/dfr/:dfrId',
  },
  {
    component: DFREditPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'DFR Creation Page',
    },
    name: 'dfr-report',
    path: '/dfrReport',
  },
  {
    component: DFRList,
    meta: {
      requiresAuth: true,
      featureLabel: 'DFR List Page',
    },
    name: 'dfr',
    path: '/dfr',
  },
];
