export default [
  {
    meta: {
      featureLabel: 'Reports Page',
    },
    name: 'reporting',
    path: '/reporting',
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_NUXT_BASE_URL}/reporting/work-orders`;
    },
  },
];
