<template>
  <div id="catalog-items-edit">
    <nav class="breadcrumb">
      <ul>
        <li>
          <router-link class="p-0 m-0" to="/catalog-item"><a>Catalog Items</a></router-link>
        </li>
        <li class="is-active">
          <router-link class="p-0 m-0" to="/catalog-item">
            <a>{{ title | truncate(200) }}</a>
          </router-link>
        </li>
      </ul>
    </nav>
    <b-modal
      v-model="isAddNewActive"
      trap-focus
      :full-screen="isMobile"
      aria-role="dialog"
      aria-modal
      :destroy-on-hide="false"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">Add new {{ addNewType }}</header>
          <section class="modal-card-body">
            <b-field label="Value">
              <b-input v-model="addNewValue" expanded required></b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button size="is-small" label="Close" type="is-danger" @click="closeModal()"></b-button>
            <b-button
              size="is-small"
              label="Add"
              type="is-success"
              @click="closeModal(true)"
              :disabled="!isAddNewValueValid"
            ></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
    <div v-if="loading">
      <div>Loading</div>
      <b-progress></b-progress>
    </div>
    <form class="block" v-else @submit.prevent="save">
      <div class="header">{{ title }}</div>
      <div class="columns">
        <div class="column">
          <b-field label="Name">
            <b-input v-model="item.name"></b-input>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-field label="Type">
            <b-select v-model="item.type">
              <option v-for="type in types" :value="type.value" :key="type.value">{{ type.label }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-narrow" v-if="!isPermit">
          <b-field label="Quality">
            <b-select v-model="item.quality">
              <option v-for="q in qualities" :value="q.value" :key="q.value">{{ q.label }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns" v-if="!isPermit">
        <div class="column">
          <b-field label="Description">
            <textarea class="textarea" v-model="item.description"></textarea>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-narrow" v-if="!isPermit">
          <b-field>
            <b-switch v-model="item.isTaxable">Taxable</b-switch>
          </b-field>
        </div>
        <div class="column" v-if="!isPermit">
          <b-field label="Margin">
            <b-input icon="percentage" v-model="item.marginPercentage"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field :label="isPermit ? 'Cost' : 'Cost Per Unit'">
            <b-input icon="dollar-sign" v-model="item.cost"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Cost To Customer">
            <b-input icon="dollar-sign" v-model="cost.markupCost" disabled></b-input>
          </b-field>
        </div>
      </div>
      <div v-if="isFpv">
        <div class="my-4 is-size-3">FPVM</div>
        <div class="columns">
          <div class="column" v-for="field in currentTypeFields" :key="field.key">
            <b-field grouped>
              <b-field :label="field.label" expanded>
                <b-select v-model="item[field.fieldKey]" :loading="$asyncComputed.detail.updating" expanded>
                  <option v-for="d in detail[field.key]" :value="d" :key="d">{{ d }}</option>
                </b-select>
                <b-button class="is-success" label="Add New" @click="openModal(field.key)"></b-button>
              </b-field>
            </b-field>
          </div>
        </div>
      </div>
      <div v-if="isAppliance">
        <div class="my-4 is-size-3">Appliance</div>
        <div class="columns">
          <div class="column" v-for="(field, i) in currentTypeFields" :key="i">
            <b-field grouped>
              <b-field :label="field.label" expanded>
                <b-select v-model="item[field.fieldKey]" :loading="$asyncComputed.detail.updating" expanded>
                  <option v-for="d in detail[field.key]" :value="d" :key="d">{{ d }}</option>
                </b-select>
                <b-button class="is-success" label="Add New" @click="openModal(field.key)"></b-button>
              </b-field>
            </b-field>
          </div>
        </div>
      </div>
      <div v-if="isFixture">
        <div class="my-4 is-size-3">Fixture</div>
        <div class="columns">
          <div class="column" v-for="field in currentTypeFields" :key="field.key">
            <b-field grouped>
              <b-field :label="field.label" expanded>
                <b-select v-model="item[field.fieldKey]" :loading="$asyncComputed.detail.updating" expanded>
                  <option v-for="d in detail[field.key]" :value="d" :key="d">{{ d }}</option>
                </b-select>
                <b-button class="is-success" label="Add New" @click="openModal(field.key)"></b-button>
              </b-field>
            </b-field>
          </div>
        </div>
      </div>
      <div v-if="!isPermit">
        <div class="supplier-header">
          <div class="my-4 is-size-3">Suppliers</div>
          <div>
            <b-button class="is-small is-info" icon-right="plus" @click="isAddSupplierModalActive = true"></b-button>
          </div>
        </div>
        <div class="columns is-vcentered" v-if="!hasSupplierItems">
          <div class="column">
            <b-message type="is-danger">
              <span class="mr-3">There is no suppliers associated with this item. Please assign a Supplier.</span>
              <b-button class="is-small" icon-left="plus" @click="isAddSupplierModalActive = true">
                Assign Supplier
              </b-button>
            </b-message>
          </div>
        </div>
        <div v-else>
          <div class="grouping">
            <div
              class="p-3 m-0 columns is-gapless is-vcentered"
              v-for="(s, index) in item.suppliers"
              :key="s.id"
              :class="{ 'is-even': index % 2 === 0, 'is-odd': index % 2 !== 0 }"
            >
              <div class="column is-9">
                <div class="columns">
                  <div class="column is-narrow">
                    <span class="field-name text-lg">{{ s.name }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-narrow">
                    <span class="field-name min-w-2">Sku</span>
                    <span>{{ s.sku }}</span>
                  </div>
                  <div class="column is-narrow">
                    <span class="field-name">Cost</span>
                    <span>${{ s.cost }}</span>
                  </div>

                  <div class="column is-narrow">
                    <span class="field-name">Priced At</span>
                    <span>{{ s.pricedAt.toDate().toLocaleDateString() }}</span>
                  </div>
                </div>
              </div>
              <div class="column is-3">
                <div class="mr-1 is-pulled-right">
                  <div class="buttons">
                    <b-button
                      class="is-small is-danger"
                      icon-left="trash"
                      :loading="unassigningSupplier"
                      @click="unassignSupplier(s.id)"
                    ></b-button>
                    <b-button class="is-small is-info" icon-left="edit" @click="onEditSupplier(s)"></b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 buttons is-pulled-right">
        <b-button class="is-danger" @click="goBack">Cancel</b-button>
        <button class="button is-success" type="submit">Save</button>
      </div>
    </form>
    <b-modal v-model="isEditSupplierModalActive" has-modal-card trap-focus>
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">{{ editSupplier.name }}</header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-fullwidth">
                <b-field label="Description">
                  <b-input type="textarea" v-model="editSupplier.description" label="Description" rows="3" required />
                </b-field>
                <b-field label="Sku">
                  <b-input v-model="editSupplier.sku" label="Sku" required />
                </b-field>
                <b-field label="Cost" required>
                  <b-input type="number" icon="dollar-sign" v-model="editSupplier.cost" label="Cost" />
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button
              size="is-small"
              label="Confirm"
              type="is-primary"
              :loading="editingSupplier"
              @click="editAssignedSupplier"
              :disabled="isEditSupplierSubmitDisabled"
            ></b-button>
            <b-button
              size="is-small"
              label="Cancel"
              type="is-danger"
              @click="isEditSupplierModalActive = false"
            ></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="isAddSupplierModalActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">Assign Supplier</header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-fullwidth">
                <b-field label="Search">
                  <b-autocomplete
                    v-model="suppliersQuery"
                    :data="suppliers"
                    :loading="isLoadingSuppliers"
                    icon="search"
                    open-on-focus
                    clearable
                    append-to-body
                    field="name"
                    @select="setSelectedSupplier"
                  >
                    <template #empty>No results found at all</template>
                  </b-autocomplete>
                </b-field>
                <b-field label="Description">
                  <b-input type="textarea" v-model="supplierMeta.description" label="Description" rows="3" required />
                </b-field>
                <b-field label="Sku">
                  <b-input v-model="supplierMeta.sku" label="Sku" required />
                </b-field>
                <b-field label="Cost" required>
                  <b-input type="number" icon="dollar-sign" v-model="supplierMeta.cost" label="Cost" />
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button
              size="is-small"
              label="Add"
              type="is-primary"
              @click="assignSupplier"
              :loading="assigningSupplier"
              :disabled="!isSelectedSupplierSet"
            ></b-button>
            <b-button
              size="is-small"
              label="Cancel"
              type="is-danger"
              @click="isAddSupplierModalActive = false"
            ></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { CatalogService as catalogShared } from '@newmoon-org/shared';
import _, { partial } from 'lodash';

import CatalogItemService from '@/service/catalog-items.service';
import ItemDetailService from '@/service/catalogItemDetails.service';
import { listSuppliers } from '@/service/suppliers.service';

import visibilityMixin from '@/mixins/visibility';

export default {
  name: 'CatalogItemEditPage',
  mixins: [visibilityMixin],
  data() {
    return {
      types: CatalogItemService.CATALOG_ITEM_TYPES,
      qualities: CatalogItemService.QUALITY,
      isAddSupplierModalActive: false,
      suppliersQuery: '',
      suppliers: [],
      selectedSupplier: null,
      searchSuppliersDebounced: _.debounce(this.searchSuppliers, 300),
      isLoadingSuppliers: false,
      isAddNewActive: false,
      addNewType: '',
      addNewValue: '',
      supplierMeta: {},
      assigningSupplier: false,
      unassigningSupplier: false,
      isEditSupplierModalActive: false,
      editSupplier: {},
      editingSupplier: false,
    };
  },
  computed: {
    isEditSupplierSubmitDisabled() {
      return false;
    },
    isMobile() {
      return this.$isMobile();
    },
    isAddNewValueValid() {
      return !!this.addNewValue;
    },
    cost() {
      return catalogShared.getCatalogItemPrice(this.item);
    },
    id() {
      return this.$route.params.catalogItemId;
    },
    isNew() {
      return this.id === 'new';
    },
    title() {
      return this.isNew ? 'Create New Item' : `${this.item.name}`;
    },
    loading() {
      return this.$asyncComputed.item.updating;
    },
    hasSupplierItems() {
      return !_.isEmpty(this.item.suppliers);
    },
    isFpv() {
      return this.item.type === 'fpv';
    },
    isAppliance() {
      return this.item.type === 'appliance';
    },
    isFixture() {
      return this.item.type === 'fixture';
    },
    isPermit() {
      return this.item.type === 'permit';
    },
    isSelectedSupplierSet() {
      return (
        !_.isEmpty(this.selectedSupplier) &&
        !_.isEmpty(this.supplierMeta.sku) &&
        !_.isEmpty(this.supplierMeta.cost) &&
        !_.isEmpty(this.supplierMeta.description)
      );
    },
    currentTypeFields() {
      return ItemDetailService.TYPE_DEFINITIONS[this.item.type]?.fields ?? [];
    },
  },
  watch: {
    suppliersQuery: {
      immediate: true,
      async handler() {
        if (_.isEmpty(this.suppliersQuery)) return;
        await this.searchSuppliersDebounced();
      },
    },
    'item.type': {
      handler(value) {
        if (value) {
          this.$asyncComputed.detail.update();
        }
      },
    },
  },
  asyncComputed: {
    item: {
      get() {
        if (this.isNew) {
          return Promise.resolve({
            suppliers: [],
            marginPercentage: 150,
            isTaxable: true,
          });
        }
        return CatalogItemService.getById(this.id).then(item => ({
          suppliers: [],
          ...item,
          cost: (item.cost / 100).toFixed(2),
        }));
      },
      default: {
        suppliers: [],
      },
    },
    detail: {
      async get() {
        const defaultValue = this.item.type
          ? ItemDetailService.TYPE_DEFINITIONS[this.item.type]?.defaultValue ?? {}
          : {};
        return this.item.type
          ? ItemDetailService.getById(this.item.type).then(r => ({
              ...defaultValue,
              ...r,
            }))
          : Promise.resolve(defaultValue);
      },
      default: {},
    },
  },
  mounted() {
    this.searchSuppliers();
  },
  methods: {
    async searchSuppliers() {
      this.isLoadingSuppliers = true;
      try {
        const suppliers = await listSuppliers({ page: 0, size: 1000 });
        if (!this.suppliersQuery) {
          this.suppliers = suppliers;
          return;
        }
        this.suppliers = suppliers.filter(s => s.name.toLowerCase().indexOf(this.suppliersQuery.toLowerCase()) !== -1);
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        });
      } finally {
        this.isLoadingSuppliers = false;
      }
    },
    setSelectedSupplier(supplier) {
      this.selectedSupplier = supplier;
    },
    onEditSupplier(supplier) {
      this.editSupplier = { ...supplier };
      this.isEditSupplierModalActive = true;
    },
    async assignSupplier() {
      try {
        this.assigningSupplier = true;
        await CatalogItemService.assignSupplier(this.id, {
          id: this.selectedSupplier.id,
          name: this.selectedSupplier.name,
          ...this.supplierMeta,
        });
        this.$asyncComputed.item.update();
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        });
      } finally {
        this.assigningSupplier = false;
        this.selectedSupplier = null;
        this.isAddSupplierModalActive = false;
      }
    },
    async unassignSupplier(supplierId) {
      try {
        this.unassigningSupplier = true;
        await CatalogItemService.unassignSupplier(this.id, supplierId);
        this.$asyncComputed.item.update();
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        });
      } finally {
        this.unassigningSupplier = false;
      }
    },
    async editAssignedSupplier() {
      try {
        this.editingSupplier = true;
        await CatalogItemService.editAssignedSupplier(this.id, this.editSupplier.id, this.editSupplier);
        this.$asyncComputed.item.update();
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        });
      } finally {
        this.editingSupplier = false;
        this.isEditSupplierModalActive = false;
      }
    },
    async save(e) {
      e.preventDefault();
      const item = {
        ...this.item,
        cost: this.item.cost * 100,
      };

      const fn = this.isNew ? CatalogItemService.create : partial(CatalogItemService.update, this.id);
      try {
        await fn(item);
        this.$buefy.toast.open({
          message: 'Successfully saved Item',
          type: 'is-success',
        });
        await this.goBack();
      } catch (e) {
        this.$buefy.notification.open({
          message: e.message,
          type: 'is-danger',
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async closeModal(isAdding = false) {
      this.isAddNewActive = false;
      if (isAdding) {
        this.detail[this.addNewType] = Array.from(new Set([...this.detail[this.addNewType], this.addNewValue])).sort();
        await ItemDetailService.update(this.detail.id, this.detail).then(() => this.$asyncComputed.detail.update());
      }
      this.addNewValue = null;
      this.addNewType = '';
    },
    openModal(type) {
      this.isAddNewActive = true;
      this.addNewType = type;
    },
  },
};
</script>

<style scoped lang="scss">
#catalog-items-edit {
  .field-name {
    font-weight: bold;
    margin-right: 1em;
  }

  .grouping {
    border: #ddd solid 1px;
    border-radius: 5px;
  }

  .is-odd {
    background-color: #eff1fa;
  }

  .supplier-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5em;
    align-items: center;
  }
}
</style>
