<template>
  <b-modal
    class="select-customer-modal"
    v-model="isActive"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-modal
    @close="onClose"
  >
    <template #default>
      <form class="modal-card" @submit.prevent="submit">
        <header class="modal-card-head">{{ title }}</header>
        <section class="modal-card-body">
          <slot name="before-content"></slot>
          <ais-instant-search index-name="customers" :search-client="searchClient">
            <ais-configure :facet-filters.camel="['deleted:false']"></ais-configure>
            <ais-search-box>
              <template #default="{ currentRefinement, refine }">
                <b-field>
                  <!-- eslint-disable vue/valid-v-model  -->
                  <input
                    class="input"
                    type="search"
                    placeholder="Search Customers"
                    v-model="currentRefinement"
                    @input="refine($event.currentTarget.value)"
                  />
                </b-field>
              </template>
            </ais-search-box>
            <ais-hits class="mt-2">
              <template #default="{ items }">
                <div class="items">
                  <div class="item" v-for="item in items" :key="item.objectID">
                    <slot name="item">
                      <b-radio
                        v-model="selectedCompany"
                        name="selectedCompany"
                        :native-value="item"
                        :disabled="disabledItem(item)"
                      >
                        {{ item.name }} {{ disabledItem(item) ? '(Unable to select this customer)' : '' }}
                      </b-radio>
                    </slot>
                  </div>
                </div>
              </template>
            </ais-hits>
          </ais-instant-search>
        </section>
        <footer class="modal-card-foot">
          <b-button type="is-primary" native-type="submit" :disabled="!canSubmit">{{ submitButtonText }}</b-button>
        </footer>
      </form>
    </template>
  </b-modal>
</template>

<script>
import { getSearchClient } from '@/service/algolia';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Select Customer',
    },
    submitButtonText: {
      type: String,
      default: 'Select & Review',
    },
    disabledItem: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      isActive: false,
      searchClient: getSearchClient(),
      selectedCompany: {},
    };
  },
  computed: {
    canSubmit() {
      return !!this.selectedCompany.objectID;
    },
  },
  watch: {
    active() {
      this.isActive = this.active;
    },
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    submit() {
      this.$emit('on-select', this.selectedCompany);
    },
  },
};
</script>
