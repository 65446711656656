import { render, staticRenderFns } from "./DetailsProductRow.vue?vue&type=template&id=6fbd6198&scoped=true"
import script from "./DetailsProductRow.vue?vue&type=script&lang=js"
export * from "./DetailsProductRow.vue?vue&type=script&lang=js"
import style0 from "./DetailsProductRow.vue?vue&type=style&index=0&id=6fbd6198&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbd6198",
  null
  
)

export default component.exports