<template>
  <div id="signature-pad">
    <template v-if="!disableSigned">
      <div>
        <b-button class="my-1 is-pulled-right is-small" @click="undoSignature">Clear</b-button>
      </div>
      <vue-signature-pad
        height="300px"
        ref="signaturePad"
        :custom-style="{ border: 'black 1px solid' }"
        :options="{ onEnd }"
      ></vue-signature-pad>
    </template>
    <slot name="above-buttons"></slot>
    <div class="the-buttons" v-if="!signature || !disableSigned">
      <b-button class="mt-1 is-fullwidth" type="is-success" @click="saveSignature">Save</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignaturePad',
  props: {
    signature: {
      type: String,
      default: '',
    },
    disableSigned: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    signature: {
      immediate: true,
      handler() {
        this.handlePadView();
      },
    },
    disableSigned: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$refs.signaturePad.lockSignaturePad();
          this.undoSignature();
        }
      },
    },
  },
  methods: {
    handlePadView() {
      if (!this.$refs.signaturePad) {
        return;
      }

      if (this.signature) {
        this.$refs.signaturePad.fromDataURL(this.signature);
      } else {
        this.$refs.signaturePad.undoSignature();
        this.$refs.signaturePad.openSignaturePad();
      }
    },
    saveSignature() {
      const { data } = this.$refs.signaturePad?.saveSignature() ?? {
        data: null,
      };
      this.$emit('saved', data);
    },
    onEnd() {
      const { data } = this.$refs.signaturePad?.saveSignature() ?? {
        data: null,
      };
      this.$emit('signed', data);
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
      this.$emit('signed', null);
    },
  },
};
</script>

<style lang="scss">
#signature-pad {
  display: flex;
  flex-direction: column;
  .the-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;
  }
}
</style>
