<template>
  <div id="work-orders-page">
    <div class="header-container">
      <div class="header">Work Orders</div>
    </div>
    <search-list
      index-name="workorders"
      :facets="facets"
      :search-function="searchFunction"
      :facet-toggles="facetToggles"
      :transform="transform"
      :is-reportable="true"
      :numeric-menu-attributes="numericMenuAttributes"
    >
      <template #custom-date="{ props }">
        <div class="date-header">Work Date</div>
        <ul class="ais-NumericMenu-list">
          <li
            class="ais-NumericMenu-item"
            v-for="(item, index) in props.items"
            :key="item.label"
            :class="{ 'ais-NumericMenu-item--selected': index === 0 }"
          >
            <label class="ais-NumericMenu-label">
              <input
                class="ais-NumericMenu-radio"
                type="radio"
                name="NumericMenu"
                :checked="index === 0"
                @change="refineCustom(props.refine, item)"
              />
              <span class="ais-NumericMenu-labelText">{{ item.label }}</span>
            </label>
            <template v-if="item.label === 'Date Range'">
              <b-datepicker
                v-model="dates"
                :date-formatter="formatDateRange"
                :disabled="customDatesDisabled"
                :show-week-number="false"
                placeholder="Choose range"
                :icon-right="dates ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDates"
                trap-focus
                range
              ></b-datepicker>
            </template>
          </li>
        </ul>
      </template>
      <template #results="{ items }">
        <work-order-list-card v-for="item in items" :key="item.objectID" :work-order="item"></work-order-list-card>
      </template>
    </search-list>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { isNumber, isString } from 'lodash';
import { mapGetters } from 'vuex';

import { getDateItems, getDateValues } from '@/service/dateFilter.service';

import SearchList from '@/components/list/searchList.vue';
import WorkOrderListCard from '@/components/workorders/WorkOrderListCard.vue';

const defaultNumSearch = '%7B%7D';

export default {
  name: 'WorkOrdersListPage',
  components: {
    SearchList,
    WorkOrderListCard,
  },
  data() {
    const rangeStart = Math.ceil(dayjs().startOf('day').toDate().valueOf());
    const rangeEnd = Math.ceil(dayjs().add(1, 'day').endOf('day').valueOf());
    return {
      facets: [
        { facet: 'appointmentReason', displayName: 'Appointment Reason' },
        { facet: 'company', displayName: 'Company' },
        { facet: 'techProjectId', displayName: 'Tech Project' },
        { facet: 'serviceZone.name', displayName: 'Service Zone' },
        { facet: 'status', displayName: 'Dispatch Status' },
        { facet: 'state', displayName: 'State' },
        { facet: 'type', displayName: 'Type' },
        { facet: 'isImported', displayName: 'Imported' },
      ],
      facetToggles: [
        { facet: 'waitlisted', displayName: 'Waitlisted' },
        { facet: 'isMaintenanceRequired', displayName: 'Maintenance Required' },
        { facet: 'isProject', displayName: 'Work Order Assignments' },
        { facet: 'isProject', displayName: 'Service Work Orders', on: false },
        { facet: 'isOnCall', displayName: 'On Call Work Order' },
      ],
      transform(items) {
        return items.map(i => ({
          ...i,
          date: convertToDate(i.date_timestamp),
          techNames: i.assignedTechs ? i.assignedTechs.map(t => `${t?.firstName} ${t?.lastName}`).join(', ') : '',
        }));

        function convertToDate(date) {
          const formatDate = date?._seconds
            ? new Date(date._seconds * 1000)
            : isNumber(date) || isString(date)
            ? new Date(date)
            : null;
          return formatDate
            ? formatDate.toLocaleString('en-US', {
                timeZone: 'America/Denver',
                dateStyle: 'short',
                timeStyle: 'short',
              })
            : 'Unknown';
        }
      },
      dates: [],
      startDateRange: rangeStart,
      endDateRange: rangeEnd,
      customDateRefine: null,
      customDatesDisabled: true,
      numericMenuAttributes: [
        {
          displayName: 'Work Date',
          attribute: 'date_timestamp',
          values: getDateValues(),
          items: [
            { label: 'No date filter' },
            ...getDateItems(new Date()),
            {
              label: 'Date Range',
              start: this.startDateRange,
              end: this.endDateRange,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    searchQuery() {
      return this.$route.query.search;
    },
    loggedInUser() {
      return this.user;
    },
    loggedInAsTech() {
      return this.loggedInUser?.employee?.role?.name === 'Service Tech';
    },
  },
  watch: {
    dates(newVal) {
      if (newVal.length) {
        const [start, end] = newVal;
        const startString = Math.floor(dayjs(start).startOf('day').toDate().valueOf());
        const endString = Math.ceil(dayjs(end).endOf('day').toDate().valueOf());

        if (this.customDateRefine) {
          //%7B%22start%22:1660390089471,%22end%22:1662982089471%7D - format of algolia numeric filter
          const formatted = `%7B%22start%22:${startString},%22end%22:${endString}%7D`;
          this.customDateRefine(formatted);
        }
      }
    },
  },
  methods: {
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    clearDates() {
      this.dates = [];
    },
    refineCustom(refine, item) {
      this.customDateRefine = refine;
      const isDateRange = item.label === 'Date Range';
      if (!isDateRange) {
        this.customDatesDisabled = true;
        this.clearDates();
        return refine(item.value);
      }

      this.customDatesDisabled = !isDateRange;
      refine(defaultNumSearch);
    },
    searchFunction(helper) {
      const page = helper.getPage();
      if (this.loggedInAsTech) {
        if (!helper.state.facets.includes('assignedTechsEmails')) helper.state.facets.push('assignedTechsEmails');
        helper.addFacetRefinement('assignedTechsEmails', this.loggedInUser.employee.email);
      }
      if (!helper.state.facets.includes('isImported')) {
        helper.state.facets.push('isImported');
        helper.addFacetRefinement('isImported', false);
      }
      helper.setPage(page).search();
    },
  },
};
</script>

<style scoped>
#work-orders-page {
  max-width: 1600px;
}
</style>
