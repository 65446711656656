<template>
  <div class="toggle-facet" v-if="display">
    <b-field>
      <b-switch v-model="value" @input="onFacetChange">{{ facet.displayName }}</b-switch>
    </b-field>
  </div>
</template>

<script>
import { isBoolean } from 'lodash';

export default {
  name: 'AlgoliaToggleFacet',
  props: {
    facet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: this.facet.default ? this.facet.defaultValue : false,
    };
  },
  computed: {
    display() {
      return isBoolean(this.facet.display) ? this.facet.display : true;
    },
  },
  methods: {
    onFacetChange() {
      this.$emit('filter-change', {
        key: this.facet.facet,
        value: this.value,
        type: 'toggle',
      });
    },
  },
};
</script>
