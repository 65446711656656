import { partial } from 'lodash';

const useLogger = process.env.VUE_APP_LOGGER;
const log = {
  info: partial(logMessage, 'info', ''),
  error: partial(logMessage, 'error', ''),
  warn: partial(logMessage, 'warn', ''),
  debug: partial(logMessage, 'debug', ''),
};

export { log, getLogger };

function getLogger(loggerName = '') {
  return {
    info: partial(logMessage, 'info', loggerName),
    error: partial(logMessage, 'error', loggerName),
    warn: partial(logMessage, 'warn', loggerName),
    debug: partial(logMessage, 'debug', loggerName),
  };
}

function logMessage(key, name, message) {
  if (!useLogger) return;
  console[key](name, message);
}
