<template>
  <div id="dfr-entries">
    <p class="text-sm my-1" v-if="hasPermission('team-management:write')">
      For Batch Updates you may only select DFRs of like type. For example, you may not select a DFR that's waiting for
      HR input and a DFR that is waiting on Manager Input.
    </p>
    <div class="mb-4 is-flex is-flex-direction-row is-justify-content-end">
      <b-tooltip class="mr-2" label="Payroll Export" position="is-left">
        <dfr-report v-if="canSeeAll" classes=""></dfr-report>
      </b-tooltip>
      <dfr-batch-update v-if="hasPermission('team-management:write')"></dfr-batch-update>
      <b-tooltip label="Create reports" position="is-left">
        <b-button class="is-primary" icon-right="plus" @click="goToReport"></b-button>
      </b-tooltip>
    </div>
    <search-list
      index-name="dfr"
      :facets="facets"
      :facet-toggles="facetToggles"
      :numeric-menu-attributes="numericMenuAttributes"
      :search-function="refineSearch"
      :is-reportable="true"
      :show-refinements="true"
    >
      <template #results="{ items }">
        <table class="table is-striped">
          <thead>
            <th>Selected</th>
            <th>Employee</th>
            <th>WO #</th>
            <th>Reported</th>
            <th>HR</th>
            <th>Status</th>
            <th>Conflicts</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <dfr-row v-for="item in items" :item="item" :key="item.objectID"></dfr-row>
          </tbody>
        </table>
      </template>
      <template #custom-date="{ props }">
        <div class="date-header">Start Date</div>
        <ul class="ais-NumericMenu-list">
          <li
            class="ais-NumericMenu-item"
            v-for="(item, index) in props.items"
            :key="item.label"
            :class="{ 'ais-NumericMenu-item--selected': index === 0 }"
          >
            <label class="ais-NumericMenu-label">
              <input
                class="ais-NumericMenu-radio"
                type="radio"
                name="NumericMenu"
                :checked="index === 0"
                @change="refineCustom(props.refine, item)"
              />
              <span class="ais-NumericMenu-labelText">{{ item.label }}</span>
            </label>
            <template v-if="item.label === 'Date Range'">
              <b-datepicker
                v-model="dates"
                :date-formatter="formatDateRange"
                :disabled="customDatesDisabled"
                :show-week-number="false"
                placeholder="Choose range"
                :icon-right="dates ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDates"
                trap-focus
                range
              ></b-datepicker>
            </template>
          </li>
        </ul>
      </template>
    </search-list>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { identity } from 'lodash';
import { mapGetters } from 'vuex';

import { getDateItems, getDateValues } from '@/service/dateFilter.service';

import dfrReport from '@/components/dfr/DFRReport.vue';
import dfrRow from '@/components/dfr/DFRRow.vue';
import SearchList from '@/components/list/searchList.vue';
import DfrBatchUpdate from '@/components/dfr/DfrBatchUpdate.vue';

const defaultNumSearch = '%7B%7D';
export default {
  name: 'DfrEntriesList',
  components: {
    DfrBatchUpdate,
    SearchList,
    dfrRow,
    dfrReport,
  },
  data() {
    const today = new Date();
    const rangeStart = Math.ceil(today.setDate(today.getDate() + 1));
    const rangeEnd = Math.ceil(today.setDate(today.getDate() + 2));
    return {
      modificationDates: [],
      dates: [],
      startDateRange: rangeStart,
      endDateRange: rangeEnd,
      customDateRefine: null,
      customDatesDisabled: true,
      numericMenuAttributes: [
        {
          displayName: 'Start Date',
          attribute: 'startDate_timestamp',
          values: getDateValues(),
          items: [
            { label: 'No date filter' },
            ...getDateItems(new Date()),
            {
              label: 'Date Range',
              start: this.startDateRange,
              end: this.endDateRange,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('auth', ['hasPermission']),
    loggedInUser() {
      return this.user?.employee ?? {};
    },
    loggedInUserId() {
      return this.user?.employee?.id ?? null;
    },
    canSeeAll() {
      return ['hr', 'developer', 'global administrator', 'administrator'].includes(
        this.loggedInUser.workflowFunction?.name?.toLowerCase()
      );
    },
    facets() {
      return [
        { facet: 'status', displayName: 'Status' },
        this.canSeeAll
          ? {
              facet: 'employee.workflowFunction.name',
              displayName: 'Workflow function',
            }
          : null,
        { facet: 'company.company', displayName: 'Company' },
        { facet: 'employee.code', displayName: 'Employee' },
        { facet: 'hasHrApproval', displayName: 'Approved By HR' },
      ].filter(identity);
    },
    facetToggles() {
      return [
        this.canSeeAll
          ? {
              facet: 'employee.isForeman',
              displayName: 'Foreman',
            }
          : null,
        this.canSeeAll
          ? {
              facet: 'employee.manager',
              displayName: 'Manager',
            }
          : null,
      ].filter(identity);
    },
  },
  watch: {
    dates(newVal) {
      if (newVal.length) {
        const [start, end] = newVal;
        const startString = Math.floor(start);
        const endString = Math.ceil(end);
        if (this.customDateRefine) {
          //%7B%22start%22:1660390089471,%22end%22:1662982089471%7D - format of algolia numeric filter
          const formatted = `%7B%22start%22:${startString},%22end%22:${endString}%7D`;
          this.customDateRefine(formatted);
        }
      }
    },
  },
  mounted() {
    // the state will be held through the store, so we need to clear it on mount
    this.$store.dispatch('dfr/clearSelected');
  },
  methods: {
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    clearDates() {
      this.dates = [];
    },
    refineCustom(refine, item) {
      this.customDateRefine = refine;
      const isDateRange = item.label === 'Date Range';
      if (!isDateRange) {
        this.customDatesDisabled = true;
        this.clearDates();
        return refine(item.value);
      }

      this.customDatesDisabled = !isDateRange;
      refine(defaultNumSearch);
    },
    refineSearch(helper) {
      const page = helper.getPage();

      if (this.canSeeAll) {
        helper.setPage(page).search();
        return;
      }

      if (this.loggedInUser.manager) {
        this.searchForManager(helper);
        return;
      }

      this.searchForEmployee(helper);
    },
    searchForEmployee(helper) {
      const page = helper.getPage();

      if (!helper.state.facets.includes('ownershipIds')) {
        helper.state.facets.push('ownershipIds');
      }

      helper.addFacetRefinement('ownershipIds', this.loggedInUserId).setPage(page).search();
    },
    searchForManager(helper) {
      const page = helper.getPage();

      if (!helper.state.disjunctiveFacets.includes('company.code')) {
        helper.state.disjunctiveFacets.push('company.code');
      }

      this.loggedInUser.companies?.forEach(it => {
        helper.addDisjunctiveFacetRefinement('company.code', it.code).setPage(page).search();
      });
    },
    async goToReport() {
      await this.$router.push({ name: 'dfr-report' });
    },
  },
};
</script>

<style scoped></style>
