<template>
  <b-navbar :shadow="true" :fixed-top="true">
    <template #brand>
      <div class="image-container">
        <b-image
          src="https://flowrightphi.com/wp-content/uploads/2021/03/flow-right-site-logo-no-r-500-220x37.png"
        ></b-image>
      </div>
    </template>
    <template #end>
      <div class="block menu-nav md:hidden">
        <b-navbar-item v-for="(n, index) in navItems" @click="goTo(n)" :active="isActive(n)" :key="index">
          {{ n.label }}
        </b-navbar-item>
      </div>
      <active-bookings-info></active-bookings-info>
      <application-dropdown></application-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';

import { navigation } from '@/components/navigation';
import ApplicationDropdown from '@/components/ApplicationDropdown';
import ActiveBookingsInfo from '@/components/ActiveBookingsInfo';

import visibilityMixin from '@/mixins/visibility';

export default {
  name: 'NewmoonNavbar',
  components: {
    ApplicationDropdown,
    ActiveBookingsInfo,
  },
  mixins: [visibilityMixin],
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    navItems() {
      return navigation
        .filter(item => this.canViewPage(item.route))
        .filter(item => {
          if (!item.permissions || item.permissions.length === 0) return true;
          return item.permissions.some(permission => this.hasPermission(permission));
        });
    },
  },
  methods: {
    goTo({ route }) {
      if (this.$route?.name === route) {
        return;
      }
      this.$router.push({ name: route });
    },
    isActive({ isActive }) {
      return this.$route.name ? this.$route?.name?.indexOf(isActive) !== -1 : false;
    },
  },
};
</script>
<style lang="scss">
.image-container {
  display: flex;
  align-items: center;
}
.nav-container {
  display: flex;
  flex-direction: column;
  max-width: 2000px;
}
</style>
