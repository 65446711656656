import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const initialState = () => {
  return {
    features: {},
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
