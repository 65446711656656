import { head } from 'lodash';
import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';

import { init } from '@/service/generic.service';

const methods = init({
  collectionPath: 'employees',
  algoliaIndex: 'employees',
  dateFields: ['workStartDate', 'workEndDate'],
});

const DEFAULT_EMPLOYEE = {
  id: null,
  role: { id: null, name: null },
  workflowFunction: { id: null, name: null },
  companies: [],
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  payrollSystem: null,
  scheduleType: null,
  managerId: null,
  manager: false,
  isForeman: false,
  skills: [],
  skillIds: [],
  primarySkill: null,
  primarySkillId: null,
  workStartDate: null,
  workEndDate: null,
  status: 'active',
  code: null,
  profileImgPath: '',
  profileImgUrl: null,
};
const PAYROLL_SYSTEMS = [
  { displayName: 'Admin', value: 'admin' },
  { displayName: 'Admin-Salaried', value: 'admin_salaried' },
  { displayName: 'Field', value: 'field' },
  { displayName: 'Manager', value: 'manager' },
];

export default {
  PAYROLL_SYSTEMS,
  DEFAULT_EMPLOYEE,
  list: methods.list,
  get: methods.getById,
  getByIds: methods.getChunkedDBResults,
  getAll: methods.getAll,
  getAllActive: methods.getAllActive,
  create: methods.create,
  update,
  dateFields: methods.dateFields,
  getByEmail,
  getManagers,
  getEmployees,
  dbRef: methods.dbRef,
  getNumberOfEmployeesWithSimilarCode,
  mapDocWithDates: methods.mapDocWithDates,
  collectionPath: methods.collectionPath,
};

async function update(id, data) {
  const toUpdate = { ...data, role: { id: data.role.id, name: data.role.name } };
  console.log('toUpdate', toUpdate);
  return methods.update(id, toUpdate, false);
}

async function getByEmail(email) {
  try {
    const value = await methods.dbRef
      .where('email', '==', email)
      .limit(1)
      .get()
      .then(docs => head(docs.docs.map(conversionServiceShared.mapDoc)));

    return value;
  } catch (e) {
    console.log(e);
  }
}

async function getManagers() {
  return methods.dbRef
    .where('manager', '==', true)
    .where('status', '==', 'active')
    .orderBy('lastName')
    .orderBy('firstName')
    .get()
    .then(sn => sn.docs.map(d => ({ ...d.data(), id: d.id })));
}

async function getEmployees() {
  return methods.dbRef
    .where('status', '==', 'active')
    .orderBy('lastName')
    .orderBy('firstName')
    .get()
    .then(sn => sn.docs.map(d => ({ ...d.data(), id: d.id })));
}

async function getNumberOfEmployeesWithSimilarCode(employeeId, employeeCode) {
  if (!employeeCode) {
    return 0;
  }

  let ref = methods.dbRef.where('code', '==', employeeCode);

  if (employeeId) {
    ref = ref.where(methods.fieldPath.documentId(), '!=', employeeId);
  }

  return ref.get().then(r => r.size);
}
