import { CatalogService as catalogShared } from '@newmoon-org/shared';
import { omit, pick } from 'lodash';

import { db } from '@/service/firebase';
import CatalogItemService from '@/service/catalog-items.service';
import { init } from '@/service/generic.service';

import store from '@/store';

const methods = init({
  collectionPath: 'catalog',
  algoliaIndex: 'catalog',
});

export default {
  getTypes,
  getTypeById,
  getWOTypes,
  list: methods.list,
  dbRef: methods.dbRef,
  listByIds: methods.listByIds,
  getById,
  getBySpecifications,
  create,
  update,
  updateCatalogType,
  catalogShared,
  getCostCodes,
  getChunkedDBResults: methods.getChunkedDBResults,
};

async function create(product) {
  const catalogProduct = { ...product };
  const user = store.getters['auth/user'].employee;
  catalogProduct.createdAt = new Date();
  catalogProduct.createdBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);
  catalogProduct.itemIds = catalogProduct.items.map(i => i.itemId);
  const ref = db.collection('catalog').doc();
  await ref.set(catalogProduct);
}

async function update(product) {
  if (product.specification === 'discount') {
    if (product.markdownCost > 0 && product.markdownPercentage > 0) {
      throw new Error('You can only set a markdown cost or a markdown percentage, not both');
    }
  }

  const catalogProduct = { ...product };
  const user = store.getters['auth/user'].employee;
  catalogProduct.updatedAt = new Date();
  catalogProduct.updatedBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);
  catalogProduct.itemIds = catalogProduct.items.map(i => i.itemId);
  await db.collection('catalog').doc(catalogProduct.id).set(catalogProduct);
}

async function getById(id) {
  const doc = await db.collection('catalog').doc(id).get();
  const product = { ...doc.data(), id: doc.id };
  product.items = await CatalogItemService.getItemsForProduct(product);
  return product;
}

async function getCostCodes(company) {
  return company
    ? db
        .collection('catalog')
        .where('specification', '==', 'cost-code')
        .where('status', '==', 'Active')
        .where('companies', 'array-contains', company)
        .get()
        .then(r => r.docs.map(methods.mapDocWithDates))
    : [];
}

export async function getTypes() {
  return db
    .collection('catalogTypes')
    .get()
    .then(sn => sn.docs.map(d => ({ ...d.data(), id: d.id })));
}

async function getTypeById(id) {
  return db
    .collection('catalogTypes')
    .doc(id)
    .get()
    .then(sn => ({ ...sn.data(), id: sn.id }));
}

async function getWOTypes() {
  return db
    .collection('catalogTypes')
    .where('woStatus', '==', true)
    .get()
    .then(sn => sn.docs.map(d => ({ ...d.data(), id: d.id })));
}

async function updateCatalogType(typeId, type) {
  return db
    .collection('catalogTypes')
    .doc(typeId)
    .set({ ...omit(type, ['id']) }, { merge: true });
}

async function getBySpecifications(specification) {
  const sn = await db.collection('catalog').where('specification', '==', specification.toLowerCase()).get();
  return sn.docs.map(d => ({ ...d.data(), id: d.id }));
}
