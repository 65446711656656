<template>
  <div id="dispatch-workflows-page">
    <div class="header-container">
      <div class="header">Dispatch Workflows</div>
    </div>
    <div class="page-content">
      <div class="table-content">
        <search-list
          index-name="workorders"
          :attributes="attributes"
          :actions="actions"
          :facets="facets"
          :facet-toggles="facetToggles"
          :transform="transform"
          :search-function="searchFunction"
          :is-reportable="true"
          :numeric-menu-attributes="numericMenuAttributes"
        >
          <template #results="{ items }">
            <table class="table is-striped">
              <thead>
                <th>WO Number</th>
                <th>Project</th>
                <th>Customer</th>
                <th>Company</th>
                <th>Pro Contractor</th>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.objectID">
                  <td>
                    <a @click="renderWorkOrder(item)">
                      <ais-highlight attribute="workOrderNumber" :hit="item"></ais-highlight>
                    </a>
                  </td>
                  <td>{{ item.techProjectId || item?.project?.code || 'Unknown' }}</td>
                  <td>
                    <ais-highlight attribute="customer.displayAsName" :hit="item"></ais-highlight>
                  </td>
                  <td>
                    <ais-highlight attribute="company" :hit="item"></ais-highlight>
                  </td>
                  <td>
                    <b-icon
                      :icon="item.isSubmitted ? 'check' : 'times'"
                      :type="item.isSubmitted ? 'is-success' : 'is-danger'"
                    ></b-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template #custom-date="{ props }">
            <div>
              <div class="date-header">Date Filter</div>
              <ul class="ais-NumericMenu-list">
                <li
                  class="ais-NumericMenu-item"
                  v-for="(item, index) in props.items"
                  :key="item.label"
                  :class="{ 'ais-NumericMenu-item--selected': index === 0 }"
                >
                  <label class="ais-NumericMenu-label">
                    <input
                      class="ais-NumericMenu-radio"
                      type="radio"
                      name="NumericMenu"
                      :checked="index === 0"
                      @change="refineCustom(props.refine, item)"
                    />
                    <span class="ais-NumericMenu-labelText">{{ item.label }}</span>
                  </label>
                  <template v-if="item.label === 'Date Range'">
                    <b-datepicker
                      v-model="dates"
                      :date-formatter="formatDateRange"
                      :disabled="customDatesDisabled"
                      :show-week-number="false"
                      placeholder="Choose range"
                      :icon-right="dates ? 'close-circle' : ''"
                      icon-right-clickable
                      @icon-right-click="clearDates"
                      trap-focus
                      range
                    ></b-datepicker>
                  </template>
                </li>
              </ul>
            </div>
          </template>
        </search-list>
      </div>
      <div class="work-order-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { getDateItems, getDateValues } from '@/service/dateFilter.service';
import WorkordersService from '@/service/workorders.service';

import SearchList from '@/components/list/searchList.vue';

const defaultNumSearch = '%7B%7D';

export default {
  name: 'DispatchWorkflowsPage',
  components: {
    SearchList,
  },
  data() {
    const today = new Date();
    const rangeStart = Math.ceil(today.setDate(today.getDate() + 1));
    const rangeEnd = Math.ceil(today.setDate(today.getDate() + 2));
    return {
      facets: [
        { facet: 'appointmentReason', displayName: 'Appointment Reason' },
        { facet: 'company', displayName: 'Company' },
        { facet: 'techProjectId', displayName: 'Tech Project' },
        { facet: 'serviceZone.name', displayName: 'Service Zone' },
        { facet: 'status', displayName: 'Dispatch Status' },
        { facet: 'state', displayName: 'State' },
        { facet: 'type', displayName: 'Type' },
        { facet: 'isProject', displayName: 'Project' },
        { facet: 'isImported', displayName: 'Imported' },
      ],
      facetToggles: [{ facet: 'isMaintenanceRequired', displayName: 'Maintenance Required' }],
      attributes: [],
      actions: [],
      dates: [],
      startDateRange: rangeStart,
      endDateRange: rangeEnd,
      customDateRefine: null,
      customDatesDisabled: true,
      numericMenuAttributes: [
        {
          displayName: 'Date',
          attribute: 'date_timestamp',
          values: getDateValues(),
          items: [
            { label: 'No date filter' },
            ...getDateItems(new Date()),
            {
              label: 'Date Range',
              start: this.startDateRange,
              end: this.endDateRange,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$router.push({ name: 'dispatchWorkflowsEmptyState' });
  },
  methods: {
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    clearDates() {
      this.dates = [];
    },
    refineCustom(refine, item) {
      this.customDateRefine = refine;
      const isDateRange = item.label === 'Date Range';
      if (!isDateRange) {
        this.customDatesDisabled = true;
        this.clearDates();
        return refine(item.value);
      }

      this.customDatesDisabled = !isDateRange;
      refine(defaultNumSearch);
    },
    searchFunction(helper) {
      const page = helper.getPage();
      if (!helper.state.facets.includes('isProject')) {
        helper.state.facets.push('isProject');
        helper.addFacetRefinement('isProject', false);
      }
      if (!helper.state.facets.includes('isImported')) {
        helper.state.facets.push('isImported');
        helper.addFacetRefinement('isImported', false);
      }
      helper.setPage(page).search();
    },
    transform(items) {
      return items.map(i => {
        return {
          ...i,
          isSubmitted: this.isSubmitted(i),
        };
      });
    },
    isSubmitted(wo) {
      return wo?.dispatchTag?.proContractor?.status === WorkordersService.PRO_CONTRACTOR_STATUSES.SUBMITTED_TO_PC.value;
    },
    renderWorkOrder(item) {
      this.$router.push({
        name: 'dispatchWorkflowWorkOrderView',
        params: {
          workOrderId: item.objectID,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
#dispatch-workflows-page {
  .page-content {
    display: flex;
    flex-direction: row;
    .table-content {
      padding-right: 2em;
      flex-grow: 1;
      min-width: 800px;
    }
  }
}
</style>
