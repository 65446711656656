import { omit } from 'lodash';

import { db } from '@/service/firebase';

export default {
  list,
  upsert,
  remove,
};

async function list() {
  return await db
    .collection('holidays')
    .orderBy('date', 'desc')
    .get()
    .then(qs =>
      qs.docs.map(doc => {
        const data = doc.data();

        return {
          id: doc.id,
          ...data,
          date: data.date.toDate(),
        };
      })
    );
}

async function upsert(data) {
  return await db
    .collection('holidays')
    .doc(data.id)
    .set({ ...omit(data, 'id') }, { merge: true });
}

async function remove(id) {
  return await db.collection('holidays').doc(id).delete();
}
