import dayjs from 'dayjs';

export function getTodayRange(today: Date): Date[] {
  const start = dayjs(today).startOf('day');
  const end = dayjs(today).endOf('day');
  return [start.toDate(), end.toDate()];
}

export function getYesterdayDateRange(today: Date): Date[] {
  const yesterday = dayjs(today).add(-1, 'day');
  const start = yesterday.startOf('day');
  const end = yesterday.endOf('day');

  return [start.toDate(), end.toDate()];
}

export function getThisWeekDateRange(today: Date): Date[] {
  const start = dayjs(today).startOf('week');
  const end = dayjs(today).endOf('week');
  return [start.toDate(), end.toDate()];
}

export function getLastWeekDateRange(today: Date): Date[] {
  const lastWeek = dayjs(today).add(-1, 'week');
  const start = lastWeek.startOf('week');
  const end = lastWeek.endOf('week');
  return [start.toDate(), end.toDate()];
}

export function getThisMonthDateRange(today: Date): Date[] {
  const start = dayjs(today).startOf('month');
  const end = dayjs(today).endOf('month');
  return [start.toDate(), end.toDate()];
}

export function getDateItems(today: Date): NumericItem[] {
  return [
    {
      label: 'Today',
      dateFn: getTodayRange,
    },
    {
      label: 'Yesterday',
      dateFn: getYesterdayDateRange,
    },
    {
      label: 'This Week',
      dateFn: getThisWeekDateRange,
    },
    {
      label: 'Last Week',
      dateFn: getLastWeekDateRange,
    },
    {
      label: 'This Month',
      dateFn: getThisMonthDateRange,
    },
  ].map(c => {
    const [start, end] = c.dateFn(today);
    return { label: c.label, start: start.getTime(), end: end.getTime() };
  });
}

export function getDateValues(): Record<string, string> {
  return {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    thisMonth: 'This Month',
  };
}

interface NumericItem {
  label: string;
  start: number;
  end: number;
}
