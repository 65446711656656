import date from './date';
import simpleDate from './simpleDate';
import money from './money';
import capitalize from './capitalize';
import titleCase from './titleCase';
import truncate from './truncate';

export default {
  date,
  simpleDate,
  money,
  capitalize,
  titleCase,
  truncate,
};
