<template>
  <div id="mlh-report-page">
    <div class="columns">
      <div class="column">
        <b-field label="Start Date">
          <b-datepicker
            :disabled="loading"
            v-model="startDate"
            icon="calendar-plus"
            placeholder="Click to select..."
            :timepicker="{ incrementMinutes: 5 }"
            :max-date="endDate"
          ></b-datepicker>
        </b-field>
      </div>
      <div class="column">
        <b-field label="End Date">
          <b-datepicker
            :disabled="loading"
            v-model="endDate"
            icon="calendar-times"
            placeholder="Click to select..."
            :min-date="startDate"
          ></b-datepicker>
        </b-field>
      </div>
    </div>
    <b-table
      :data="tableData"
      :columns="columns"
      :per-page="20"
      paginated
      striped
      :height="height"
      sticky-header
      :loading="loading"
    ></b-table>
  </div>
</template>

<script>
import { flatten, groupBy, identity, isEmpty, keyBy, reduce, sortBy, get } from 'lodash';
import { ConversionService as conversionServiceShared, DateService } from '@newmoon-org/shared';
import dayjs from 'dayjs';

import WorkOrderService from '@/service/workorders.service';
import EmployeesService from '@/service/employees.service';
import CatalogService from '@/service/catalog.service';
import SchedulesService from '@/service/schedules.service';

import scheduler from '@/mixins/scheduler';

const { getNumberOfHoursBetweenDates, getStartOfDay, getEndOfDay, getNumberOfWorkHoursBetweenDates } = DateService;

export default {
  name: 'MLHReportPage',
  mixins: [scheduler],
  asyncComputed: {
    catalogs: {
      get() {
        return CatalogService.getTypes().then(r => sortBy(r, 'name'));
      },
      default: [],
    },
  },
  data() {
    return {
      loadingEmployees: false,
      loadingWorkorders: false,
      workordersListener: null,
      employeesListener: null,
      startDate: getStartOfDay(new Date()),
      endDate: getEndOfDay(new Date()),
      employees: [],
      workorders: [],
      columns: [
        {
          field: 'name',
          label: 'Catalog',
        },
        {
          field: 'possible',
          label: 'Possible MLH (hrs)',
          centered: true,
        },
        {
          field: 'timeOffs',
          label: 'Called Off MLH (hrs)',
          centered: true,
        },
        {
          field: 'transfer',
          label: 'Skill Set Transfer (hrs)',
          centered: true,
        },
        {
          field: 'committed',
          label: 'Committed MLH (hrs)',
          centered: true,
        },
        {
          field: 'overtime',
          label: 'Overtime MLH (hrs)',
          centered: true,
        },
        {
          field: 'available',
          label: 'Available MLH (hrs)',
          centered: true,
        },
        {
          field: 'sales',
          label: 'Sales Goal (hrs)',
          centered: true,
        },
      ],
    };
  },
  computed: {
    height() {
      const height = window.innerHeight;
      return height * 0.8;
    },
    loading() {
      return this.loadingEmployees || this.loadingWorkorders;
    },
    startOfTheDayStartDate() {
      return getStartOfDay(this.startDate);
    },
    endOfTheDayEndDate() {
      return getEndOfDay(this.endDate);
    },
    tableData() {
      return (
        this.catalogs?.map(it => {
          const skill = it.id;
          return {
            name: it.name,
            possible: Number((this.skillToPossibleHours[skill] ?? 0).toFixed(1)),
            timeOffs: Number(((this.skillToTimeOffHours[skill] ?? 0) * -1).toFixed(1)),
            transfer: Number((this.skillToTransferHours[skill] ?? 0).toFixed(1)),
            committed: Number((this.skillToCommittedHours[skill] ?? 0).toFixed(1)),
            overtime: Number((this.skillToOvertimeHours[skill] ?? 0).toFixed(1)),
            available: Number((this.skillToAvailableHours[skill] ?? 0).toFixed(1)),
            sales: Number(((this.skillToSalesGoalHours[skill] ?? 0) * -1).toFixed(1)),
          };
        }) ?? []
      );
    },
    timeoffsByEmployeePrimarySkill() {
      return groupBy(
        sortBy(
          this.affectedSchedules.filter(it => it.timeOffId && this.employeesMap[it.employeeId]?.id),
          'startTime'
        ),
        it => this.employeesMap[it.employeeId]?.primarySkillId ?? 'unknown'
      );
    },
    employeesMap() {
      return keyBy(this.employees, 'id');
    },
    skillToPossibleHours() {
      return reduce(
        this.employees,
        (acc, employee) => {
          return employee.primarySkillId
            ? {
                ...acc,
                [employee.primarySkillId]: employee.workDayDuration + (acc[employee.primarySkillId] ?? 0),
              }
            : acc;
        },
        {}
      );
    },
    skillToTransferHours() {
      /**
       * If the workorder has cost codes(cost codes might have different catalog types)
       * then we walk through all the cost codes and add techs' hours accordingly,
       * else we use wo commitment data(duration, catalogType)
       */
      return reduce(
        this.workorders,
        (acc, wo) => {
          const costCodeTechs = flatten(
            wo.costCodes
              ?.filter(it => it.selected && it.startDate)
              ?.map(it =>
                it.assignedTechs?.map(t => ({
                  ...t,
                  ccDuration: getNumberOfHoursBetweenDates(it.startDate, it.endDate),
                  ccTypeId: it.catalogType?.id,
                }))
              )
          ).filter(identity);
          const techIsValid = tech =>
            !!this.employeesMap[tech.id]?.primarySkillId &&
            this.employeesMap[tech.id].primarySkillId !== (tech.ccTypeId ?? wo.catalogType?.id);
          const validTechs = (costCodeTechs || wo.assignedTechs || []).filter(techIsValid);
          const decreases = reduce(
            validTechs,
            (accInner, tech) => ({
              ...accInner,
              [this.employeesMap[tech.id].primarySkillId]:
                (accInner[wo.id] ?? 0) - (tech.ccDuration ?? wo.duration ?? 0),
            }),
            acc
          );
          return {
            ...decreases,
            [wo.id]: wo.duration * (validTechs?.length ?? 0) + (decreases[wo.id] ?? 0),
          };
        },
        {}
      );
    },
    skillToCommittedHours() {
      /**
       * If the workorder has cost codes(cost codes might have different catalog types)
       * then we walk through all the cost codes and add techs' hours accordingly,
       * else we use wo commitment data(duration, catalogType)
       */
      return reduce(
        this.workorders,
        (acc, wo) => {
          const woTotal = (wo.duration ?? 0) * (wo.assignedTechs?.length ?? 0);
          const key = get(wo, 'catalogType.id', get(wo, 'catalogTypeId'));
          return !isEmpty(wo.costCodes)
            ? wo.costCodes
                .filter(cc => cc.selected && cc.startDate)
                .reduce((accInternal, cc) => {
                  const duration = getNumberOfHoursBetweenDates(cc.startDate, cc.endDate);
                  const techs = [...(cc.assignedTechs ?? []), cc.foreman].filter(identity);
                  const total = duration * techs.length;
                  return {
                    ...accInternal,
                    [cc.catalogType?.id]: total + (accInternal[cc.catalogType?.id] ?? 0),
                  };
                }, acc)
            : {
                ...acc,
                [key]: woTotal + (acc[wo.catalogType?.id] ?? 0),
              };
        },
        {}
      );
    },
    skillToAvailableHours() {
      return reduce(
        this.catalogs,
        (acc, catalog) => {
          return {
            ...acc,
            [catalog.id]:
              this.getHoursForSkill(catalog.id, 'possible') +
              this.getHoursForSkill(catalog.id, 'timeoff') +
              this.getHoursForSkill(catalog.id, 'transfer') -
              this.getHoursForSkill(catalog.id, 'committed'),
          };
        },
        {}
      );
    },
    skillToSalesGoalHours() {
      return reduce(
        this.catalogs,
        (acc, catalog) => {
          return {
            ...acc,
            [catalog.id]:
              this.getHoursForSkill(catalog.id, 'committed') -
              this.getHoursForSkill(catalog.id, 'possible') -
              this.getHoursForSkill(catalog.id, 'timeoff'),
          };
        },
        {}
      );
    },
    skillToTimeOffHours() {
      return reduce(
        this.employees,
        (acc, employee) => {
          const primarySkill = employee.primarySkillId ?? 'unknown';
          const count = reduce(
            this.timeoffsByEmployeePrimarySkill[primarySkill],
            (accInner, timeOff) => {
              const key = employee.primarySkillId ?? 'unknown';

              if (employee.id !== timeOff.employeeId) {
                return accInner;
              }

              const timeOffStartTime = dayjs(timeOff.startTime);
              const timeOffEndTime = dayjs(timeOff.endTime);
              const timeOffStart = timeOffStartTime.isBefore(dayjs(this.startOfTheDayStartDate))
                ? this.startOfTheDayStartDate
                : timeOff.startTime;
              const timeOffEnd = timeOffEndTime.isAfter(dayjs(this.endOfTheDayEndDate))
                ? this.endOfTheDayEndDate
                : timeOff.endTime;
              const duration = getNumberOfWorkHoursBetweenDates(employee.schedule, timeOffStart, timeOffEnd);
              return {
                ...accInner,
                [key]: (accInner[key] ?? 0) + duration,
              };
            },
            {}
          );
          return employee.primarySkillId
            ? {
                ...acc,
                [primarySkill]: (count[primarySkill] ?? 0) + (acc[primarySkill] ?? 0),
              }
            : acc;
        },
        {}
      );
    },
    skillToOvertimeHours() {
      return reduce(
        this.catalogs,
        (acc, catalog) => {
          const overtime =
            this.getHoursForSkill(catalog.id, 'committed') +
            this.getHoursForSkill(catalog.id, 'available') -
            this.getHoursForSkill(catalog.id, 'possible') -
            this.getHoursForSkill(catalog.id, 'timeoff') -
            this.getHoursForSkill(catalog.id, 'transfer');
          return {
            ...acc,
            [catalog.id]: overtime < 0 ? Math.abs(overtime) : 0,
          };
        },
        {}
      );
    },
  },
  watch: {
    startDate: {
      immediate: true,
      handler() {
        this.defineListeners();
      },
    },
    endDate: {
      handler() {
        this.defineListeners();
      },
    },
  },
  methods: {
    defineListeners() {
      this.defineEmployeesListener();
      this.defineWorkordersListener();
      this.handleDynamicProsLoads(this.startOfTheDayStartDate, this.endOfTheDayEndDate);
    },
    defineEmployeesListener() {
      this.loadingEmployees = true;
      if (this.employeesListener) {
        this.employeesListener();
        this.employees = [];
      }

      this.employeesListener = EmployeesService.dbRef
        .where('workflowFunction.name', '==', 'Service Tech')
        .where('status', '==', 'active')
        .onSnapshot(async docs => {
          const employees = docs.docs.map(conversionServiceShared.mapDoc);
          const schedules = await SchedulesService.listByIds(
            employees.map(it => it.schedule?.id).filter(it => it)
          ).then(schedules => keyBy(schedules, 'id'));
          this.employees = employees
            .map(it => {
              if (!it.schedule?.id || String(it.manager) === 'true') {
                return null;
              }

              const workDayInfo = this.getWorkDayAndWorkDayDuration(
                schedules[it.schedule.id],
                this.startOfTheDayStartDate,
                this.endOfTheDayEndDate
              );
              return {
                ...it,
                ...workDayInfo,
                schedule: schedules[it.schedule.id],
              };
            })
            .filter(it => it);
          this.loadingEmployees = false;
        });
    },
    defineWorkordersListener() {
      this.loadingWorkorders = true;
      if (this.workordersListener) {
        this.workordersListener();
        this.workorders = [];
      }

      this.workordersListener = WorkOrderService.dbRef
        .where('date', '>=', this.startOfTheDayStartDate)
        .where('date', '<', this.endOfTheDayEndDate)
        .where('deleted', '==', false)
        .onSnapshot(docs => {
          this.workorders = WorkOrderService.mapWorkOrdersFromResponse(docs).filter(
            it => it.assignedTechs?.length > 0 || it.costCodes?.find(cc => cc.selected && cc.assignedTechs?.length > 0)
          );
          this.loadingWorkorders = false;
        });
    },
    getHoursForSkill(skillId, type) {
      switch (type?.toLowerCase()) {
        case 'possible':
          return Number((this.skillToPossibleHours[skillId] ?? 0).toFixed(1));
        case 'transfer':
          return Number((this.skillToTransferHours[skillId] ?? 0).toFixed(1));
        case 'committed':
          return Number((this.skillToCommittedHours[skillId] ?? 0).toFixed(1));
        case 'overtime':
          return Number((this.skillToOvertimeHours[skillId] ?? 0).toFixed(1));
        case 'available':
          return Number((this.skillToAvailableHours[skillId] ?? 0).toFixed(1));
        case 'sales':
          return Number(((this.skillToSalesGoalHours[skillId] ?? 0) * -1).toFixed(1));
        case 'timeoff':
          return Number(((this.skillToTimeOffHours[skillId] ?? 0) * -1).toFixed(1));
        default:
          return 0;
      }
    },
    getWorkDayAndWorkDayDuration(schedule, startDate, endDate) {
      return {
        workDayDuration: getNumberOfWorkHoursBetweenDates(schedule, startDate, endDate),
      };
    },
  },
};
</script>
