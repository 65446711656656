import { chain, chunk, first, flatten, groupBy, values } from 'lodash';
import { DfrEmployee, DfrEntry, DfrStatus, ConversionService, DateService } from '@newmoon-org/shared';
import dayjs from 'dayjs';

import { db, realtimeDb } from '@/service/firebase';
import { getChunkedResults, init } from '@/service/generic.service';

const methods = init({
  collectionPath: 'dfr',
  algoliaIndex: 'dfr',
  dateFields: ['startDate', 'endDate', 'finalizedBy.date'],
});

const conflictsRef = db.collection('dfrConflicts');

function newInstance() {
  return {
    type: null,
    employee: null,
    company: null,
    costCode: null,
    workOrder: null,
    startDate: null,
    endDate: null,
    reportedBy: null,
    finalizedBy: null,
    ownershipIds: null,
    status: STATUS.NEW,
    hasHrApproval: false,
    hrFinalizedBy: null,
    report: {
      generated: false,
    },
  };
}

const DFR_AGG_STATUS_IGNORE = [DfrStatus.denied, DfrStatus.needsRevision];

const STATUS = {
  NEW: 'new',
  NEEDS_REVISION: 'needs_revision',
  DENIED: 'denied',
  APPROVED: 'approved',
};

const FINAL_STATUSES = [STATUS.DENIED, STATUS.APPROVED];

async function getCompanyAggregate({ companyId, startDate, endDate }: DfrQuery): Promise<CompanyDfrAgg> {
  if (!companyId) throw new Error('Need Company Id for Aggregation');
  const dbRef = methods.dbRef;
  const data = await dbRef
    .where('company.id', '==', companyId)
    .where('startDate', '>=', startDate)
    .where('startDate', '<=', endDate)
    .get();

  const dfrsForCompany: DfrEntry[] = data.docs.map(d => ({
    ...ConversionService.mapDates(d.data(), ['startDate', 'endDate'], true),
    id: d.id,
  }));
  const employeeDfrAggs = getEmployeeDfrAgg(dfrsForCompany);
  return {
    employeeDfrAggs,
    total: employeeDfrAggs.reduce((total, dfr) => dfr.total + total, 0),
  };
}

async function getEmployeesAggregate({ employeeIds, startDate, endDate }: DfrQuery): Promise<EmployeeDfrAgg[]> {
  if (!employeeIds) throw new Error('No Employee Ids');

  // at max, we can only return 10, so we will need to make multiple queries if the page size if larger than 10
  const chunks: Array<string[]> = chunk(employeeIds, 10);

  const mappedData = await Promise.all(chunks.map(getAgg));
  return flatten(mappedData);

  async function getAgg(ids): Promise<EmployeeDfrAgg[]> {
    const dbRef = methods.dbRef;
    const data = await dbRef
      .where('employee.id', 'in', [...ids])
      .where('startDate', '>=', startDate)
      .where('startDate', '<=', endDate)
      .get();

    const docs: DfrEntry[] = data.docs.map(d => ({
      ...ConversionService.mapDates(d.data(), ['startDate', 'endDate'], true),
      id: d.id,
    }));

    return getEmployeeDfrAgg(docs);
  }
}

function getEmployeeDfrAgg(docs: DfrEntry[]): EmployeeDfrAgg[] {
  const docsByEmployee: Array<DfrEntry[]> = values(groupBy(docs, 'employee.id'));
  return docsByEmployee.map(aggregateEmployee).filter(notEmpty);

  function notEmpty<T>(value: T | null): value is T {
    return value !== null;
  }

  function aggregateEmployee(employeeDfrs: DfrEntry[]): EmployeeDfrAgg | null {
    if (!employeeDfrs) return null;

    const employee = first(employeeDfrs)?.employee;
    if (!employee) return null;
    const dfrByStatus = aggregateDfr(employeeDfrs);
    const total = Number(values(dfrByStatus).reduce((total, dfrData) => total + dfrData.duration, 0));

    return {
      dfrByStatus,
      employee,
      total,
    };
  }
}

function aggregateDfr(data: DfrEntry[]): Record<DfrStatus, DfrAggData> {
  return chain(data)
    .filter(dfr => !DFR_AGG_STATUS_IGNORE.includes(dfr.status))
    .groupBy(dfr => {
      if (dfr.status === DfrStatus.new) {
        return dfr.hasHrApproval ? 'new-mgr' : 'new-hr';
      } else {
        return dfr.status;
      }
    })
    .reduce((acc: Record<DfrStatus, DfrAggData>, statusGroup: DfrEntry[], status: string) => {
      return {
        ...acc,
        [status]: aggregateStatusGroup(statusGroup),
      };
    }, {} as Record<DfrStatus, DfrAggData>)
    .value();

  function aggregateStatusGroup(dfrs): DfrAggData {
    return dfrs.reduce(
      (acc, dfr) => {
        const duration = DateService.getNumberOfHoursBetweenDates(dfr.startDate, dfr.endDate, 2);
        return {
          ...acc,
          duration: Number(acc.duration + duration),
          status: dfr.status,
        };
      },
      {
        duration: 0,
        dfrs,
      }
    );
  }
}

function explodeIfCrossesDayBoundry(record: DfrEntry): DfrEntry[] {
  const start = dayjs(record.startDate);
  const end = dayjs(record.endDate);

  // if they occur on the same day we do nothing
  if (start.isSame(end, 'day')) {
    return [record];
  }

  let relatedIds: any[] = [];
  const records = getDaysBetween(start, end).map(d => {
    let startDate;
    let endDate;
    if (d.isSame(start, 'day')) {
      startDate = start;
      endDate = start.hour(23).minute(59);
    } else if (d.isSame(end, 'day')) {
      startDate = end.hour(0).minute(0);
      endDate = end;
    } else {
      startDate = d.hour(0).minute(0);
      endDate = d.hour(23).minute(59);
    }

    // generate a unique id for exploded records to keep track of relations
    const id = methods.dbRef.doc().id;
    relatedIds = [...relatedIds, id];

    return {
      ...record,
      id,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    };
  });

  // set a property on all expoded records so we can tie back later
  return records.map(r => ({ ...r, relatedIds }));
}

function getDaysBetween(start, end) {
  const range: any[] = [];
  let current = start;
  while (!current.isSame(end, 'day')) {
    range.push(current);
    current = current.add(1, 'days');
  }
  return [...range, end];
}

function createMultipleInTransaction(dfrs: DfrEntry[]) {
  return db.runTransaction(async transaction => {
    dfrs.map(dfr => {
      const record = methods.preCreate(dfr);
      transaction.set(db.collection('dfr').doc(dfr.id), record);
    });
  });
}

function getConflicts(ids: Array<string>) {
  return getChunkedResults(conflictsRef, ids);
}

function waitForAlgoliaEvent(): Promise<unknown> {
  // this will wait for an update to the algolia indexes, or timeout after 10s
  // this happens in the generic sync component
  let updated = null;
  return Promise.race([
    new Promise(resolve => {
      const ref = realtimeDb.ref('algolia/indexes/dfr/lastUpdated');

      function cb(v) {
        if (!updated) {
          updated = v.val();
        } else {
          resolve(true);
          ref.off('value', cb);
        }
      }

      ref.on('value', cb);
    }),
    new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 10000);
    }),
  ]);
}

export default {
  list: methods.list,
  get: methods.getById,
  create: methods.create,
  update: methods.update,
  dbRef: methods.dbRef,
  mapDocWithDates: methods.mapDocWithDates,
  mapDataDates: methods.mapDataDates,
  listByIds: methods.listByIds,
  STATUS,
  FINAL_STATUSES,
  newInstance,
  getCompanyAggregate,
  getEmployeesAggregate,
  explodeIfCrossesDayBoundry,
  createMultipleInTransaction,
  getConflicts,
  waitForAlgoliaEvent,
};

interface DfrQuery {
  employeeIds?: string[];
  startDate: Date;
  endDate: Date;
  companyId?: string;
}

interface DfrAggData {
  duration: number;
  status: DfrStatus;
}

interface EmployeeDfrAgg {
  dfrByStatus: Record<DfrStatus, DfrAggData>;
  employee: DfrEmployee;
  total: number;
}

interface CompanyDfrAgg {
  total: number;
  employeeDfrAggs: EmployeeDfrAgg[];
}
