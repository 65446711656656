import { init } from '@/service/generic.service';

const DATE_FIELDS = ['startDate', 'endDate', 'createdAt', 'updatedAt'];

const PENDING = { key: 'pending', value: 'Pending' };
const IN_PROGRESS = { key: 'inProgress', value: 'In Progress' };
const COMPLETE = { key: 'complete', value: 'Complete' };
const INVOICED = { key: 'invoiced', value: 'Invoiced' };
const READY_TO_INVOICE = { key: 'readyToInvoice', value: 'Ready To Invoice' };
const CA_ASSIGNED = {
  key: 'CAAssignsToAccounting',
  value: 'CA Assigned to Accounting',
};
const CANCELLED = { key: 'cancelled', value: 'Cancelled' };

const PROJECT_STATUS = [IN_PROGRESS, COMPLETE, PENDING, READY_TO_INVOICE, INVOICED, CA_ASSIGNED, CANCELLED];

const methods = init({
  collectionPath: 'projects',
  dateFields: DATE_FIELDS,
});

export default {
  list: methods.getAll,
  update,
  create,
  getById,
  remove: methods.remove,
  PROJECT_STATUS,
  DEFAULT_STATUS: IN_PROGRESS,
  dbRef: methods.dbRef,
};

async function getById(id) {
  return await methods.getById(id);
}

function update(project) {
  if (!project.id) {
    throw new Error('no id');
  }

  // projects should not have workOrders, use workOrder.projectId
  if (project.workOrders) project.workOrders = null;

  if (project.customer) project.customer = pickCustomer(project.customer);
  return methods.update(project.id, project);
}

function pickCustomer(customer) {
  return {
    id: customer.id,
    name: customer.name,
    displayAsName: customer.displayAsName,
    contacts: customer.contacts,
  };
}

async function create(project) {
  if (project.customer) project.customer = pickCustomer(project.customer);

  if (project.workOrders) delete project.workOrders;

  if (!project.costCodes) project.costCodes = [];
  return methods.create(project);
}
