export default [
  {
    meta: {
      requiresAuth: true,
      featureLabel: 'Employee List Page',
    },
    name: 'employees',
    path: '/employees',
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_NUXT_BASE_URL}/employee`;
    },
  },
  {
    meta: {
      requiresAuth: true,
      featureLabel: 'Employee Edit Page',
    },
    name: 'employee-edit',
    path: '/employees/:employeeId',
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_NUXT_BASE_URL}/employee`;
    },
  },
];
