import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';

import { db } from '@/service/firebase';

export default {
  getWorkOrdersBySite,
};

async function getWorkOrdersBySite(customerId, siteId) {
  const workorders = await db
    .collection('workorders')
    .where('jobSite.id', '==', siteId)
    .where('customer.id', '==', customerId)
    .get();
  return workorders.docs.map(conversionServiceShared.mapDoc);
}
