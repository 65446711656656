<template>
  <div id="schedule-week-edit-page">
    <div class="p-0 m-0 columns is-fullwidth">
      <div class="column has-text-left">
        <div class="header is-size-2">Week {{ index + 1 }}</div>
      </div>
      <div class="mt-3 column has-text-right" v-if="allowDelete">
        <b-tooltip label="Remove">
          <b-button icon-right="times" type="is-danger" @click="$emit('delete', index)"></b-button>
        </b-tooltip>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-half" v-for="(day, idx) in days" :key="`${idx}-slot`">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <span>{{ day }}</span>
              <span class="ml-6 has-text-danger-dark" v-if="!newTimes[idx].timeSlots || !hasValidSlots(newTimes[idx])">
                Inactive
              </span>
              <span class="ml-6 has-text-info-dark" v-else>{{ getTimeStrings(newTimes[idx]) }}</span>
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="columns" v-for="(slot, slotIdx) in newTimes[idx].timeSlots" :key="slotIdx">
                <div class="column is-5">
                  <b-field label="Start At">
                    <b-timepicker
                      placeholder="Select Time"
                      icon="clock"
                      editable
                      hour-format="12"
                      :increment-minutes="5"
                      :min-time="addMinutesToOther(idx, slotIdx - 1, 'endTime')"
                      :max-time="addMinutes(slot.endTime, -5)"
                      locale="en-us"
                      @input="date => selectedTime(idx, slotIdx, date, true)"
                      :value="slot.startTime"
                      :required="slot.endTime !== null"
                    ></b-timepicker>
                  </b-field>
                </div>
                <div class="column is-5">
                  <b-field label="Finish At">
                    <b-timepicker
                      placeholder="Select Time"
                      icon="clock"
                      editable
                      hour-format="12"
                      :increment-minutes="5"
                      :min-time="addMinutes(slot.startTime, 5)"
                      :max-time="addMinutesToOther(idx, slotIdx + 1, 'startTime')"
                      locale="en-us"
                      @input="date => selectedTime(idx, slotIdx, date)"
                      :value="slot.endTime"
                      :required="slot.startTime !== null"
                    ></b-timepicker>
                  </b-field>
                </div>
                <div class="column is-2 is-pulled-right has-text-centered">
                  <b-button
                    class="mt-1 circle-button"
                    type="is-info"
                    icon-left="minus"
                    @click="removeTimeSlot(idx, slotIdx)"
                    :disabled="(!newTimes[idx].timeSlots || newTimes[idx].timeSlots.length) === 1"
                  ></b-button>
                </div>
              </div>
              <div class="columns">
                <div class="column is-fullwidth">
                  <b-button
                    class="tall-button is-fullwidth is-flex is-flex-direction-column"
                    icon-left="plus"
                    @click="addTimeSlot(idx)"
                  >
                    Add a time slot
                  </b-button>
                </div>
              </div>
              <div class="columns">
                <div class="column is-fullwidth">
                  <b-field label="Break Duration In Minutes">
                    <b-input
                      :value="newTimes[idx].breakDurationInMinutes"
                      @input="value => changedBreakDuration(idx, value)"
                      step="1"
                      min="0"
                      type="number"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';

export default {
  name: 'ScheduleWeekEditPage',
  props: {
    existingWeek: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTimes: this.getDefaultSchedules(),
    };
  },
  computed: {
    days() {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    },
    pages() {
      return this.$router.options.routes.map(it => ({
        name: it.name.toLowerCase(),
        path: it.path,
      }));
    },
    router() {
      return this.$router;
    },
    isNew() {
      return this.scheduleId === 'new';
    },
  },
  watch: {
    existingWeek: {
      deep: true,
      immediate: true,
      handler(value) {
        const hasValues =
          value?.find(it => it.timeSlots?.find(slot => slot.startHour !== null || slot.endHour !== null)) ?? null;
        this.newTimes = isEmpty(value) || hasValues === null ? this.newTimes : value;
        if (hasValues === null) {
          this.$emit('update', this.index, this.newTimes);
        }
      },
    },
  },
  methods: {
    getTimeStrings(schedule) {
      return schedule.timeSlots
        ?.filter(it => it.timeString)
        .map(it => it.timeString)
        .join(', ');
    },
    getDefaultSchedules() {
      const startTime = new Date();
      startTime.setHours(8, 0);
      const endTime = new Date();
      endTime.setHours(17, 0);
      let schedule = new Array(7);
      schedule = schedule
        .fill(
          {
            timeSlots: [
              {
                startTime: null,
                endTime: null,
                timeString: null,
              },
            ],
            breakDurationInMinutes: 0,
          },
          0,
          1
        )
        .map(it => ({
          ...it,
          timeSlots: [...it.timeSlots],
        }));
      schedule = schedule
        .fill(
          {
            timeSlots: [
              {
                startTime: null,
                endTime: null,
                timeString: null,
              },
            ],
            breakDurationInMinutes: 0,
          },
          6,
          7
        )
        .map(it => ({
          ...it,
          timeSlots: [...it.timeSlots],
        }));
      schedule = schedule
        .fill(
          {
            timeSlots: [
              {
                startTime,
                endTime,
                timeString: this.getTimeString({ startTime, endTime }),
              },
            ],
            breakDurationInMinutes: 0,
          },
          1,
          6
        )
        .map(it => ({
          ...it,
          timeSlots: [...it.timeSlots],
        }));
      return schedule;
    },
    getTimeString(slot) {
      if (!slot?.startTime || !slot?.endTime) {
        return null;
      }

      return `${slot.startTime.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute: '2-digit',
      })} - ${slot.endTime.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    },
    addMinutesToOther(index, slotIndex, key) {
      return this.addMinutes(get(this.newTimes[index].timeSlots[slotIndex], key));
    },
    addMinutes(date, minutes = 0) {
      if (!date) {
        return null;
      }
      const newDate = new Date(date);
      newDate.setHours(date.getHours(), date.getMinutes() + minutes);
      return newDate;
    },
    selectedTime(dayIndex, timeSlotIndex, newDateTime, isStartField) {
      // get previous values
      const previousTimeSlot = this.newTimes[dayIndex].timeSlots[timeSlotIndex];

      // determine new values
      const startTime = isStartField ? newDateTime : previousTimeSlot.startTime;
      const endTime = isStartField ? previousTimeSlot.endTime : newDateTime;
      const timeString = this.getTimeString({ startTime, endTime });

      // update timeslot
      this.newTimes[dayIndex].timeSlots[timeSlotIndex] = { startTime, endTime, timeString };
      this.pushUpdate();
    },
    addTimeSlot(index) {
      this.newTimes[index].timeSlots = this.newTimes[index].timeSlots ?? [];
      this.newTimes[index].timeSlots.push({
        startTime: null,
        endTime: null,
        timeString: null,
      });
      this.pushUpdate();
    },
    removeTimeSlot(index, slotIndex) {
      this.newTimes[index].timeSlots.splice(slotIndex, 1);
      this.pushUpdate();
    },
    changedBreakDuration(index, value) {
      this.newTimes[index].breakDurationInMinutes = value ? Number(value) : 0;
      this.pushUpdate();
    },
    pushUpdate() {
      this.newTimes = [...this.newTimes];
      this.$emit('update', this.index, this.newTimes);
    },
    hasValidSlots(schedule) {
      return !!schedule.timeSlots?.find(it => !!it.timeString);
    },
  },
};
</script>
<style lang="scss">
.tall-button {
  border: 1px dashed lightgray;
  min-height: 70px;
}
.circle-button {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  shape-outside: circle();
}
</style>
