<template>
  <div id="dispatch-workflow-work-order-view">
    <template v-if="loadingWo">
      <div>Loading</div>
      <b-progress></b-progress>
    </template>
    <template v-else>
      <work-order-view :work-order-id="workOrderId" :show-payments="false"></work-order-view>
      <div class="submission">
        <div class="my-4 is-size-3">Pro Contractor</div>
        <div class="needs-to-submit" v-if="!hasSubmittedProContractor">
          <div class="columns">
            <div class="column is-10">
              <b-field label="Status">
                <b-select v-model="selectedProContractorStatus" expanded :required="canSaveProContractorStatus">
                  <option v-for="status in proContractorStatuses" :key="status.value" :value="status.value">
                    {{ status.label }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-button class="is-primary" @click="updateStatus" :disabled="!canSaveProContractorStatus">Save</b-button>
            </div>
          </div>
        </div>
        <b-message class="is-success" v-else>
          This Work Order has been submitted to Pro Contractor on
          {{ convertToDate(workOrder.dispatchTag.proContractor.createdAt) }} by
          {{ workOrder.dispatchTag.proContractor.employeeCode }}
        </b-message>
      </div>
      <work-order-payments :work-order="workOrder" @newPayment="newPayment"></work-order-payments>
    </template>
    <comments-view
      :key="workOrderId"
      :association-id="workOrderId"
      :comment-type="commentType"
      :displayable-types="displayableTypes"
    ></comments-view>
  </div>
</template>

<script>
import { values } from 'lodash';
import { mapGetters } from 'vuex';
import { CommentType } from '@newmoon-org/shared';

import CustomerService from '@/service/customer.service';
import WorkordersService from '@/service/workorders.service';

import WorkOrderPayments from '@/components/workorders/WorkOrderPayments.vue';
import WorkOrderView from '@/components/workorders/WorkOrderView.vue';

import CommentsView from '@/pages/CommentsView.vue';

export default {
  name: 'DispatchWorkflowWorkOrderView',
  components: {
    WorkOrderPayments,
    CommentsView,
    WorkOrderView,
  },
  data() {
    return {
      listener: null,
      workOrder: {},
      loadingWo: false,
      selectedProContractorStatus: null,
      newComment: null,
      savingComment: null,
      displayableTypes: [CommentType.DISPATCH_WORKFLOW, CommentType.ACCOUNTING_WORKFLOW, CommentType.TECH],
      commentType: CommentType.DISPATCH_WORKFLOW,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    proContractorStatuses() {
      return values(WorkordersService.PRO_CONTRACTOR_STATUSES);
    },
    canSaveProContractorStatus() {
      return (
        !this.selectedProContractorStatus ||
        this.selectedProContractorStatus !== this.workOrder?.dispatchTag?.proContractor?.status
      );
    },
    workOrderId() {
      return this.$route.params.workOrderId;
    },
    hasSubmittedProContractor() {
      return (
        this?.workOrder?.dispatchTag?.proContractor?.status ===
        WorkordersService.PRO_CONTRACTOR_STATUSES.SUBMITTED_TO_PC.value
      );
    },
    createdAt() {
      return this.convertToDate(this?.workOrder?.createdAt) ?? 'Unknown Date';
    },
  },
  watch: {
    workOrderId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadAndListenForWo();
        }
      },
    },
  },
  methods: {
    loadAndListenForWo() {
      this.loadingWo = true;

      if (this.listener) {
        this.listener();
      }

      this.listener = WorkordersService.dbRef.doc(this.workOrderId).onSnapshot(async r => {
        const workOrder = WorkordersService.mapWorkOrderFromResponse(r) ?? {};
        this.selectedProContractorStatus = workOrder.dispatchTag?.proContractor?.status ?? null;
        r.customer = await CustomerService.get(workOrder.customer.id);
        this.workOrder = workOrder;
        this.loadingWo = false;
      });
    },
    convertToDate(date) {
      return date
        ? date.toLocaleString('en-US', {
            timeZone: 'America/Denver',
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : 'Unknown';
    },
    async updateStatus() {
      const proContractor = {
        createdAt: new Date(),
        createdBy: this.user.employee.id,
        employeeCode: this.user.employee.code,
        status: this.selectedProContractorStatus,
      };
      await WorkordersService.mergeUpdate(this.workOrderId, {
        dispatchTag: {
          proContractor,
        },
      });
      this.$buefy.notification.open({
        message: 'Successfully Recorded Pro Contractor Submission',
        type: 'is-success',
      });
    },
    newPayment({ payments }) {
      this.workOrder.payments = payments;
    },
  },
};
</script>
