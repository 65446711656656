import * as Sentry from '@sentry/vue';
const sentry = process.env.VUE_APP_SENTRY_DSN;

export function useSentry(Vue, router): void {
  if (!sentry) return;
  Sentry.init({
    Vue,
    dsn: sentry,
    ignoreErrors: ['NavigationDuplicated', 'Redirected when going'],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'mlhim.com', /^\//],
      }),
    ],
    tracesSampleRate: 0.3,
  });
}

export function setSentryUser(state) {
  if (!sentry) return;
  if (!state.user) {
    Sentry.setUser(null);
    return;
  }

  const {
    firebaseUser: { email },
  } = state.user;
  if (!email) return;
  Sentry.setUser({ email });
}
