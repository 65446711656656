<template>
  <div class="time-commitment-page">
    <gantt-chart></gantt-chart>
  </div>
</template>

<script>
import GanttChart from '@/components/timeCommitment/ganttchart/GanttChart.vue';

export default {
  name: 'TimeCommitmentPage',
  components: {
    GanttChart,
  },
  data() {
    return {};
  },
  mounted() {
    document.scrollingElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.querySelector('html').classList.add('no-scroll');
    document.querySelector('.view').classList.add('no-scroll');
  },
  beforeDestroy() {
    document.querySelector('html').classList.remove('no-scroll');
    document.querySelector('.view').classList.remove('no-scroll');
  },
};
</script>

<style scoped lang="scss">
.time-commitment-page {
  position: relative;
  width: 100%;
}
</style>
