import dayjs from 'dayjs';
import { filter, identity, omit, pick, reduce, set, sortBy, sumBy } from 'lodash';
import {
  ConversionService as conversionServiceShared,
  DateService as dateServiceShared,
  WorkOrderService as workOrderShared,
  WorkOrderSchema as WorkOrder,
} from '@newmoon-org/shared';
import { WORK_ORDER_STATES, WORK_ORDER_STATUS } from '@newmoon-org/types';

import { db, queryForList } from '@/service/firebase';
import { init } from '@/service/generic.service';

import store from '@/store';

const { getNumberOfHoursBetweenDates } = dateServiceShared;

const woTypes = {
  plumbing: 'Plumbing',
  rescon: 'ResCon',
  hvac: 'HVAC',
  electric: 'Electric',
};

export const WORK_ORDER_PAYMENT_METHODS = [
  { value: 'cash', label: 'Cash' },
  { value: 'card', label: 'Card' },
  { value: 'check', label: 'Check' },
  { value: 'billOut', label: 'Bill Out (Commercial Only)' },
  { value: 'ccInVault', label: 'CC in Vault' },
];

const REASONS_FOR_DELAY = {
  customer: 'Customer',
  crew: 'Crew',
  parts: 'Parts',
};
const COST_CODE_DATE_FIELDS = ['startDate', 'endDate', 'startAtTime', 'endAtTime'];
const AR_SUBMISSIONS_DATE_FIELDS = ['createdAt'];
const DATE_FIELDS = [
  'date',
  'dateAssigned',
  'dateDue',
  'startDate',
  'endDate',
  'finishedAt',
  'datePickedUp',
  'noteDate',
  'timeStamp',
  'dateEnRoute',
  'dateReOpened',
  'workTime',
  'invoiceLastActionDate',
  'createdAt',
  'updatedAt',
  'dispatchTag.proContractor.createdAt',
  'accountingTag.arSubmission.createdAt',
  'completionSignature.date',
  'itemsSignature.date',
];

const PRO_CONTRACTOR_STATUSES = {
  PENDING_CUSTOMER_INFO: {
    value: 'pending_customer_info',
    label: 'Pending additional customer info',
  },
  PENDING_INTERNAL_INFO: {
    value: 'pending_internal_info',
    label: 'Pending additional internal info',
  },
  SUBMITTED_TO_PC: { value: 'submitted_to_pc', label: 'Submitted to "PC"' },
};

const COST_CODE_FIELDS = [
  ...COST_CODE_DATE_FIELDS,
  'id',
  'workorderId',
  'costCodeNumber',
  'name',
  'area',
  'category',
  'catalogType',
  'productArea',
  'selected',
  'selectedInProject',
  'duration',
  'foreman',
  'assignedTechs',
  'assignedTechsEmails',
];

const methods = init({
  collectionPath: 'workorders',
  algoliaIndex: 'workorders',
  defaultOrderBy: 'workOrderNumber',
  filterDeleted: true,
  dateFields: DATE_FIELDS,
});

export default {
  list: methods.list,
  mapDocWithDates: methods.mapDocWithDates,
  mapDataDates: methods.mapDataDates,
  dbRef: methods.dbRef,
  dateFields: methods.dateFields,
  costCodeDateFields: COST_CODE_DATE_FIELDS,
  update,
  mergeUpdate: (id, record) => methods.update(id, record, true),
  create,
  getById,
  getAvailableTechs,
  getReasons,
  woCompanies,
  projectCompanies,
  employeeCompanies,
  getWorkOrderCost,
  mapWorkOrdersFromResponse,
  mapWorkOrderFromResponse,
  buildTechProjectId,
  getApproximateCostCodesDuration,
  getChunkedDBResults: getChunkedDBResults,
  buildProjectWorkOrderLabel,
  createCopyForProject,
  computeWorkAssignmentMLH,
  woTypes,
  WORK_ORDER_STATES,
  REASONS_FOR_DELAY,
  WORK_ORDER_STATUS,
  PRO_CONTRACTOR_STATUSES,
  WORK_ORDER_PAYMENT_METHODS,
  COST_CODE_FIELDS,
  copy,
  softDelete: methods.softDelete,
  getWorkOrdersByProject,
  getWorkOrdersByJobSite,
  delayWorkOrder,
  getByCustomerId,
  collectionPath: methods.collectionPath,
  getPaymentHistory,
  joinDateWithTime,
};

function buildTechProjectId(employee, date = new Date()) {
  const safeEmployee = {
    firstName: employee?.firstName ?? '',
    lastName: employee?.lastName ?? '',
    code: employee?.code ?? '',
  };
  const firstPartPostfix = filter(safeEmployee?.code?.slice(-2), it => !isNaN(it)).join('');
  const firstPart = `${safeEmployee?.firstName[0]?.toUpperCase() ?? ''}${
    safeEmployee?.lastName[0]?.toUpperCase() ?? ''
  }${firstPartPostfix}`;
  const secondPart = `Q${dateServiceShared.getQuarterNumber(date)}`;
  const thirdPart = String(date.getFullYear()).slice(-2);
  return `${firstPart}${secondPart}${thirdPart}`;
}

export async function delayWorkOrder(workOrder) {
  const update = {
    status: WORK_ORDER_STATUS.DELAYED,
    originalTechProjectId: workOrder.techProjectIdOverride ?? workOrder.techProjectId,
    techProjectId: getDelayedWoProject(),
    techProjectIdOverride: null,
    workOrderNumber: `${workOrder.workOrderNumber}.0`,
  };

  const newWorkOrder = await getTransferredWorkOrder(workOrder);
  return [
    {
      ...workOrder,
      ...update,
    },
    newWorkOrder,
  ];
}

function getDelayedWoProject() {
  const date = new Date();
  const quarter = dateServiceShared.getQuarterNumber(date);
  const year = date.getFullYear();
  return `DWOQ${quarter}${year.toString().slice(-2)}`;
}

function computeWorkAssignmentMLH(wa) {
  const waMapped = mapComplexSubObjectsWithDates(wa);
  return (
    sumBy(
      waMapped?.costCodes?.filter(it => !!it?.selected),
      cc => {
        const duration = getApproximateCostCodesDuration(cc);
        const techs = [...(cc.assignedTechs ?? []), cc.foreman?.id].filter(identity);
        return duration * techs.length;
      }
    ) ?? 0
  );
}

export async function getTransferredWorkOrder(parentWorkOrder) {
  const newWorkOrder = {
    ...copy(parentWorkOrder, [
      'id',
      'assignedTechs',
      'assignedTechsEmails',
      'techsWithOvertime',
      'date',
      'startDate',
      'endDate',
      'createdAt',
      'updatedAt',
      'finishedAt',
      'itemsSignature',
      'itemsSignatureAudit',
      'completionSignature',
      'stateTransitionHistory',
      'statusTransitionHistory',
      'workOrderNumber',
      'techProjectIdOverride',
      'dispatchTag',
    ]),
    status: WORK_ORDER_STATUS.NEW,
    state: WORK_ORDER_STATES.WAITING,
    parentWorkOrderId: parentWorkOrder.id,
    parentWorkOrderNumber: parentWorkOrder.workOrderNumber,
    workOrderNumber: getDelayedWorkOrderNumber(),
    techProjectId: getDelayedWoProject(),
    parentWasDelayed: true,
  };

  return await create(newWorkOrder, { createWorkOrderNumber: false });

  function getDelayedWorkOrderNumber() {
    const { workOrderNumber } = parentWorkOrder;
    const [number, revision] = workOrderNumber.split('.');
    if (revision === undefined) return `${number}.1`;
    return `${number}.${Number(revision) + 1}`;
  }
}

function copy(workOrder, nullifyFields) {
  return {
    ...workOrder,
    ...reduce(nullifyFields, (acc, field) => set(acc, field, null), {}),
  };
}

async function getById(id) {
  if (!id) throw new Error('No Id for WorkOrder');
  const wo = await methods.getById(id);
  return wo?.deleted ? {} : { ...(wo ?? {}), ...mapComplexSubObjectsWithDates(wo) };
}

function mapWorkOrdersFromResponse(response) {
  return response?.docs?.map(it => mapWorkOrderFromResponse(it))?.filter(identity) ?? [];
}

function mapWorkOrderFromResponse(response) {
  const wo = methods.mapDocWithDates(response);
  return wo.deleted ? null : { ...wo, ...mapComplexSubObjectsWithDates(wo) };
}

function getWorkOrderCost(workOrder) {
  return workOrderShared.getWorkOrderCost(workOrder);
}

async function getReasons() {
  return await db
    .collection('appointmentReasons')
    .orderBy('name')
    .where('status', '==', 'Active')
    .get()
    .then(sn => sn.docs.map(it => conversionServiceShared.mapDoc(it)));
}

async function woCompanies() {
  return await db
    .collection('companies')
    .where('woStatus', '==', true)
    .orderBy('code')
    .get()
    .then(sn => sn.docs.map(it => conversionServiceShared.mapDoc(it)));
}

async function projectCompanies() {
  return await db
    .collection('companies')
    .where('projectStatus', '==', true)
    .orderBy('code')
    .get()
    .then(sn => sn.docs.map(it => conversionServiceShared.mapDoc(it)));
}

async function employeeCompanies() {
  return await db
    .collection('companies')
    .where('employeeStatus', '==', true)
    .orderBy('code')
    .get()
    .then(sn => sn.docs.map(it => conversionServiceShared.mapDoc(it)));
}

async function update(workOrder) {
  if (!workOrder.id) throw new Error('no work order id');
  const ref = methods.dbRef.doc(workOrder.id);

  const user = store.getters['auth/user'].employee;
  workOrder.updatedAt = new Date();
  workOrder.updatedBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);

  const dbObject = new WorkOrder({
    ...methods.mapDataDates(omit(workOrder, ['id']), false),
  }).toFirebase();
  await db.runTransaction(async t => {
    if (!dbObject.workOrderNumber) dbObject.workOrderNumber = await createWorkOrderId(t);
    await t.set(ref, dbObject);
  });
  return dbObject;
}

async function create(workOrder, options = { createWorkOrderNumber: true }) {
  const id = await db.runTransaction(async t => {
    const newDoc = db.collection('workorders').doc();
    const user = store.getters['auth/user'].employee;

    if (!options.createWorkOrderNumber && !workOrder.workOrderNumber) {
      throw new Error('No work order number provided when overriding number creation');
    }

    if (options.createWorkOrderNumber) {
      workOrder.workOrderNumber = await createWorkOrderId(t);
    }

    workOrder.costCodes = workOrder.costCodes?.map(it => ({ ...it, workorderId: newDoc.id }));
    workOrder.createdBy = pick(user, ['firstName', 'lastName', 'id', 'workflowFunction', 'code']);
    workOrder.createdAt = new Date();
    workOrder.updatedAt = new Date();

    const body = new WorkOrder({
      ...methods.mapDataDates(workOrder, false),
    }).toFirebase();
    await t.set(newDoc, { ...body });
    return newDoc.id;
  });

  return { ...methods.mapDataDates(workOrder), id };
}

async function createWorkOrderId(transaction) {
  const count = await getCount(transaction);
  const prepend = new Date().getFullYear().toString().slice(-2); // gets 21 for 2021
  return `${prepend}${count.toString().padStart(6, '0')}`;

  async function getCount(t) {
    // always returning whats in the db as the next value and incrementing, read -> increment -> write
    const docRef = db.collection('workorderCounter').doc('counter');
    const doc = await t.get(docRef);
    const currentCount = doc.data().count;
    await t.set(docRef, { count: currentCount + 1 });
    return currentCount;
  }
}

async function getAvailableTechs() {
  return await queryForList('techs');
}

async function createCopyForProject(workOrderId) {
  const wo = await getById(workOrderId);

  const woCopy = copy(wo, [
    'id',
    'assignedTechs',
    'assignedTechsEmails',
    'techsWithOvertime',
    'workOrderNumber',
    'lineItems',
    'date',
    'startDate',
    'endDate',
    'createdAt',
    'updatedAt',
    'itemsSignature',
    'itemsSignatureAudit',
    'completionSignature',
    'stateTransitionHistory',
    'statusTransitionHistory',
    'status',
    'state',
    'finishedAt',
  ]);
  woCopy.costCodes =
    woCopy.costCodes?.map(it => ({ ...pick(it, COST_CODE_FIELDS), startDate: null, endDate: null })) ?? [];
  woCopy.woLabel = buildProjectWorkOrderLabel(woCopy);
  woCopy.state = WORK_ORDER_STATES.PLANNED;
  return create(woCopy);
}

function buildProjectWorkOrderLabel(workOrder) {
  const jobSite = workOrder?.jobSite?.address ? `, ${workOrder?.jobSite?.address}` : '';
  const date = workOrder?.date ? dayjs(workOrder.date).format(', MMM D YYYY') : '';
  const status = workOrder?.status ? `, ${workOrder.status}` : '';
  return `#${workOrder.workOrderNumber}${jobSite}${date}${status}`;
}

function getApproximateCostCodesDuration(costCode) {
  const startTime = costCode.startAtTime ? new Date() : null;
  startTime?.setHours(costCode.startAtTime?.getHours(), costCode.startAtTime?.getMinutes(), 0, 0);
  const endTime = costCode.endAtTime ? new Date() : null;
  endTime?.setHours(costCode.endAtTime?.getHours(), costCode.endAtTime?.getMinutes(), 0, 0);
  return getNumberOfHoursBetweenDates(startTime, endTime);
}

async function getChunkedDBResults(ids) {
  return (
    (await methods.getChunkedDBResults(ids))?.map(it => ({
      ...it,
      ...mapComplexSubObjectsWithDates(it),
    })) ?? []
  );
}

function mapComplexSubObjectsWithDates(wo) {
  return wo && !wo.deleted
    ? {
        accountingTag: {
          ...(wo.accountingTag ?? {}),
          arSubmissions: wo.accountingTag?.arSubmissions?.map(it =>
            conversionServiceShared.mapDates(it, AR_SUBMISSIONS_DATE_FIELDS)
          ),
        },
        itemsSignatureAudit: wo.itemsSignatureAudit?.map(it => conversionServiceShared.mapDates(it, ['date'])),
        statusTransitionHistory: wo.statusTransitionHistory?.map(it => conversionServiceShared.mapDates(it, ['date'])),
        stateTransitionHistory: wo.stateTransitionHistory?.map(it => conversionServiceShared.mapDates(it, ['date'])),
        payments: wo.payments?.map(it => conversionServiceShared.mapDates(it, ['createdAt'])),
        costCodes:
          wo?.costCodes?.map(it =>
            pick(conversionServiceShared.mapDates(it, COST_CODE_DATE_FIELDS), COST_CODE_FIELDS)
          ) ?? null,
      }
    : {};
}

async function getWorkOrdersByJobSite(jobSiteId) {
  const sn = await methods.dbRef.where('jobSite.id', '==', jobSiteId).where('deleted', '==', false).get();
  const data = sn.docs.map(d => ({ id: d.id, ...d.data() }));
  return data;
}

async function getWorkOrdersByProject(projectId) {
  const sn = await methods.dbRef.where('project.id', '==', projectId).where('deleted', '==', false).get();
  return sn.docs.map(d => mapWorkOrderFromResponse(d)).filter(it => it.id);
}

async function getByCustomerId(customerId) {
  const sn = await methods.dbRef.where('customer.id', '==', customerId).where('deleted', '==', false).get();
  return sn.docs.map(it => conversionServiceShared.mapDoc(it));
}

async function getPaymentHistory(woId) {
  if (!woId) {
    return [];
  }

  const result = await methods.dbRef
    .doc(woId)
    .get()
    .then(r => mapWorkOrderFromResponse(r));
  let payments =
    result.payments?.map(it => ({
      ...it,
      workOrderNumber: result.workOrderNumber,
      workOrderId: result.id,
    })) ?? [];
  if (result.parentWorkOrderId && result.parentWorkOrderId !== woId) {
    payments = [...payments, ...(await getPaymentHistory(result.parentWorkOrderId))];
  }

  return sortBy(payments, 'workOrderNumber', 'createdAt');
}

function joinDateWithTime(date, time, defaultHour = 7, defaultMinute = 0) {
  if (!date) {
    return null;
  }

  const fullDate = new Date(date);
  const safeTime = time ? new Date(time) : null;
  fullDate.setHours(safeTime?.getHours() ?? defaultHour, time?.getMinutes() ?? defaultMinute, 0, 0);
  return fullDate;
}
