import { setSentryUser } from '@/sentry';

export default {
  setUser: (state, payload) => {
    state.user = payload;
    setSentryUser(state);
  },
  setPermissions: (state, payload) => {
    if (!payload) {
      state.permissions = [];
      return;
    }
    state.permissions = [...payload];
  },
};
