<template>
  <tr :class="trClass">
    <td>{{ lineItem.product.name }}</td>
    <td class="has-text-right">{{ lineItem.quantity }}</td>
    <td v-if="!ignoreTaxes" class="has-text-right">{{ total.markupNonTaxable | money }}</td>
    <td v-if="!ignoreTaxes" class="has-text-right">{{ total.markupTaxable | money }}</td>
    <td v-if="ignoreTaxes" class="has-text-right">{{ total.total | money }}</td>
  </tr>
</template>

<script>
import { CatalogService as catalogShared } from '@newmoon-org/shared';

import CatalogItemsService from '@/service/catalog-items.service';

export default {
  name: 'DetailsProductRow',
  props: {
    ignoreTaxes: {
      type: Boolean,
      default: false,
    },
    lineItem: {
      type: Object,
      default: () => ({ product: {} }),
    },
    trClass: {
      type: String,
      default: 'detail-product',
    },
  },
  computed: {
    total() {
      const { priceToCustomerCost } = catalogShared.getCatalogProductPrice(
        this.lineItem.product,
        this.lineItem.quantity
      );

      return {
        markupTaxable: priceToCustomerCost?.markupTaxable / 100 ?? 0,
        markupNonTaxable: priceToCustomerCost?.markupNonTaxable / 100 ?? 0,
        total: priceToCustomerCost?.total / 100 ?? 0,
      };
    },
  },

  async mounted() {
    if (!this.lineItem?.product) {
      return;
    }
    this.lineItem.product.items = await CatalogItemsService.getItemsForProduct(this.lineItem.product);
  },
};
</script>

<style scoped lang="scss">
.detail-product {
  background-color: #efefef;
  border: 1px solid #33333333;

  td {
    padding: 8px;
  }
}
</style>
