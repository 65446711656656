<template>
  <div id="to-official-admin-page">
    <div class="columns">
      <div class="column">
        <b-tooltip class="is-pulled-right" label="Add a new Time Off Reason" position="is-left">
          <b-button class="is-primary" icon-right="plus" @click="newTOReason()"></b-button>
        </b-tooltip>
      </div>
    </div>
    <div class="table">
      <b-table :data="toReasons" :loading="$asyncComputed.toReasons.updating" :striped="true">
        <template #empty>
          <div class="has-text-centered">No Time Off Reasons Found</div>
        </template>
        <b-table-column label="Time Off Reason" field="name" v-slot="props">{{ props.row.name }}</b-table-column>
        <b-table-column label="Type" field="type" v-slot="props">{{ props.row.type }}</b-table-column>
        <b-table-column label="Status" field="status" v-slot="props">{{ props.row.status }}</b-table-column>
        <b-table-column label="Actions" field="actions" v-slot="props" width="150">
          <div class="buttons">
            <b-tooltip label="Edit" position="is-left">
              <b-button class="mr-1 is-small is-primary" icon-right="edit" @click="editTOReason(props.row)"></b-button>
            </b-tooltip>
          </div>
        </b-table-column>
      </b-table>
    </div>
    <b-modal
      v-model="isEditModalActive"
      trap-focus
      :full-screen="isMobile"
      aria-role="dialog"
      aria-modal
      :destroy-on-hide="false"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">
            {{ isNewTOReason ? `New Official Time Off Reason` : `Edit "${selectedTOReason.name}"` }}
          </header>
          <section class="modal-card-body">
            <b-field>
              <b-tooltip
                label="If the Official Time Off Reason is not active you are not able to find it as a selection in a work order."
                position="is-right"
                multilined
              >
                <b-switch v-model="selectedTOReason.status" true-value="Active" false-value="Inactive">Active</b-switch>
              </b-tooltip>
            </b-field>
            <b-field label="Name">
              <b-input v-model="selectedTOReason.name"></b-input>
            </b-field>
            <b-field label="Type">
              <b-select v-model="selectedTOReason.type">
                <option>{{ 'official' }}</option>
                <option>{{ 'employee' }}</option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <div class="is-pulled-right">
              <b-button size="is-small" label="Close" type="is-danger" @click="closeTOReason"></b-button>
              <b-button
                size="is-small"
                :label="'Save'"
                type="is-success"
                @click="upsertTOReason(selectedTOReason)"
              ></b-button>
            </div>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { omit } from 'lodash';

import { db } from '@/service/firebase';

export default {
  name: 'TOOfficialAdminPage',
  data() {
    return {
      isLoading: true,
      isEditModalActive: false,
      isDeleting: false,
      selectedTOReason: {
        status: 'Active',
      },
    };
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
    isNewTOReason() {
      return !this.selectedTOReason.id;
    },
  },
  asyncComputed: {
    toReasons: {
      async get() {
        return await db
          .collection('toOfficialReasons')
          .orderBy('type')
          .orderBy('name')
          .get()
          .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      },
      default: [],
    },
  },
  methods: {
    newTOReason() {
      this.selectedTOReason = {};
      this.isEditModalActive = true;
    },
    editTOReason(c) {
      this.selectedTOReason = { ...c };
      this.isEditModalActive = true;
    },
    closeTOReason() {
      this.isEditModalActive = false;
      this.selectedTOReason = {};
    },
    async upsertTOReason(c) {
      this.isSaving = true;
      try {
        await db
          .collection('toOfficialReasons')
          .doc(c.id)
          .set({ ...omit(c, 'id') }, { merge: true });
      } catch (e) {
        console.log(e);
      }
      await this.$asyncComputed.toReasons.update();
      this.isSaving = false;
      this.closeTOReason();
    },
  },
};
</script>
