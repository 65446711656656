import AccountReportingWorkOrder from '@/pages/AccountReportingWorkOrder';
import AccountingReportPage from '@/pages/AccountReportingV2';
import AccountReportingEmptyState from '@/pages/AccountReportingEmptyState';
import WorkOrderDetailsPage from '@/pages/WorkOrderDetails';

export default [
  {
    component: AccountingReportPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Accounting Workflow List Page',
    },
    name: 'accounting-report',
    path: '/accountingreport',
    children: [
      {
        path: ':workOrderId',
        name: 'accountWorkOrderView',
        component: AccountReportingWorkOrder,
      },
      {
        path: '',
        name: 'accountReportEmptyState',
        component: AccountReportingEmptyState,
      },
    ],
  },
  {
    component: WorkOrderDetailsPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Work Order Details Page',
    },
    name: 'work-order-details',
    path: '/workorderdetails/:workOrderId',
  },
];
