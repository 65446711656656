<template>
  <div id="account-reporting-work-order">
    <template v-if="loadingWorkOrder">
      <div>Loading</div>
      <b-progress></b-progress>
    </template>
    <template v-else-if="!workOrder.id">
      <div>Please select work order</div>
    </template>
    <template v-else>
      <work-order-view :work-order-id="workOrderId" :show-payments="false"></work-order-view>
      <work-order-payments :work-order="workOrder" @newPayment="newPayment"></work-order-payments>
      <work-order-ar-submission :work-order="workOrder"></work-order-ar-submission>
      <comments-view
        :key="workOrderId"
        :association-id="workOrderId"
        :comment-type="commentType"
        :displayable-types="displayableTypes"
      ></comments-view>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isDate, isNumber, isString } from 'lodash';
import { CommentType } from '@newmoon-org/shared';
import { ArSubmissionStatus } from '@newmoon-org/types';

import WorkordersService from '@/service/workorders.service';
import CustomerService from '@/service/customer.service';

import WorkOrderView from '@/components/workorders/WorkOrderView.vue';
import WorkOrderArSubmission from '@/components/workorders/WorkOrderARSubmission.vue';
import WorkOrderPayments from '@/components/workorders/WorkOrderPayments.vue';

import CommentsView from '@/pages/CommentsView.vue';

export default {
  name: 'AccountReportingWorkOrder',
  components: {
    WorkOrderPayments,
    WorkOrderArSubmission,
    CommentsView,
    WorkOrderView,
  },
  data() {
    return {
      workOrder: {},
      woWatcher: null,
      loadingWorkOrder: false,
      currentStatus: ArSubmissionStatus.ReadyToInvoice,
      progressionMap: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    commentType() {
      return CommentType.ACCOUNTING_WORKFLOW;
    },
    displayableTypes() {
      return [CommentType.DISPATCH_WORKFLOW, CommentType.ACCOUNTING_WORKFLOW, CommentType.TECH];
    },
    workOrderId() {
      return this.$route.params.workOrderId;
    },
    createdAt() {
      return this?.workOrder?.createdAt ?? 'Unknown Date';
    },
  },
  watch: {
    workOrderId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.watchWorkOrder();
        }
      },
    },
  },
  methods: {
    watchWorkOrder() {
      this.loadingWorkOrder = true;

      if (this.woWatcher) {
        this.woWatcher();
        this.workOrder = {};
      }

      this.woWatcher = WorkordersService.dbRef.doc(this.workOrderId).onSnapshot(async r => {
        this.workOrder = WorkordersService.mapWorkOrderFromResponse(r) ?? {};
        this.workOrder.customer = this.workOrder?.customer?.id
          ? await CustomerService.get(this.workOrder.customer.id)
          : {};
        this.workOrder.payments = this.workOrder.payments ?? [];
        this.loadingWorkOrder = false;
      });
    },
    convertToDate(date) {
      const formatDate = date?.seconds
        ? new Date(date.seconds * 1000)
        : isNumber(date) || isString(date)
        ? new Date(date)
        : isDate(date)
        ? date
        : null;
      return formatDate
        ? formatDate.toLocaleString('en-US', {
            timeZone: 'America/Denver',
            dateStyle: 'short',
            timeStyle: 'short',
          })
        : 'Unknown';
    },
    newPayment({ payments }) {
      this.workOrder.payments = payments;
    },
  },
};
</script>

<style scoped lang="scss">
#account-reporting-work-order {
  min-width: 600px;

  .b-steps {
    flex: 1 0 50%;

    .navigation-button {
      flex: 1 0 100%;
    }
  }

  .date {
    display: flex;
    flex-direction: row;

    .value {
      margin-left: 1em;
    }
  }
}
</style>
