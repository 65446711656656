import { call } from '@/service/functions.service';

const apiEndpoint = `${process.env.VUE_APP_BASE_URL}/taxes`;

function getTaxRate(address) {
  return address
    ? call({
        method: 'get',
        url: `${apiEndpoint}/rate`,
        params: { address },
      }).then(r => r.data)
    : Promise.resolve({});
}

export default {
  getTaxRate,
};
