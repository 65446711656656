import axios, { Axios, type InternalAxiosRequestConfig } from 'axios';

import { auth } from './firebase';

let apiInstance: Axios | null = null;
export function getApi(): Axios {
  if (apiInstance) return apiInstance;
  const baseURL = process.env.VUE_APP_API_ENDPOINT;

  apiInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  apiInstance?.interceptors.request.use(authInterceptor);
  return apiInstance;
}

async function authInterceptor(config: InternalAxiosRequestConfig) {
  const token = await auth.currentUser?.getIdToken();

  config.headers.Authorization = `Bearer ${token}`;

  return config;
}
