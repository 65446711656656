<template>
  <div id="accounting-report-page">
    <div class="header-container">
      <div class="header">Accounting Workflows</div>
    </div>
    <div class="page-content">
      <div class="table-content">
        <search-list
          index-name="workorders"
          :attributes="attributes"
          :numeric-menu-attributes="numericMenuAttributes"
          :actions="actions"
          :facets="facets"
          :facet-toggles="facetToggles"
          :search-function="searchFunction"
          :is-reportable="true"
          :transform="hydrateWorkOrders"
        >
          <template #results="{ items }">
            <table class="table is-striped">
              <thead>
                <th>WO Number</th>
                <th width="55">Project</th>
                <th>Customer</th>
                <th>Company</th>
                <th>Lifecycle</th>
                <th>AR</th>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.objectID">
                  <td>
                    <a @click="renderWorkOrder(item)">
                      <ais-highlight attribute="workOrderNumber" :hit="item"></ais-highlight>
                    </a>
                  </td>
                  <td width="55">{{ item.techProjectId || item?.project?.code || 'Unknown' }}</td>
                  <td>
                    <ais-highlight attribute="customer.displayAsName" :hit="item"></ais-highlight>
                  </td>
                  <td>
                    <ais-highlight attribute="company" :hit="item"></ais-highlight>
                  </td>
                  <td>
                    <span class="mr-1 has-text-weight-bold">Dispatch Status:</span>
                    {{ item.status || 'n/a' | titleCase }}

                    {{ realWorkOrders[item.objectID]?.statusDate }} {{ realWorkOrders[item.objectID]?.statusUser }}
                    <br />
                    <span class="mr-1 has-text-weight-bold">State:</span>
                    {{ item.state || 'n/a' | titleCase }}

                    {{ realWorkOrders[item.objectID]?.stateDate }} {{ realWorkOrders[item.objectID]?.stateUser }}
                  </td>
                  <td>
                    <b-icon class="spinner" v-if="$asyncComputed.realWorkOrders.updating" icon="spinner"></b-icon>
                    <b-icon
                      v-else
                      :icon="realWorkOrders[item.objectID]?.isArSubmitted ? 'check' : 'times'"
                      :type="realWorkOrders[item.objectID]?.isArSubmitted ? 'is-success' : 'is-danger'"
                    ></b-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template #custom-date="{ props }">
            <div class="date-header">Date Worked</div>
            <ul class="ais-NumericMenu-list">
              <li
                class="ais-NumericMenu-item"
                v-for="(item, index) in props.items"
                :key="item.label"
                :class="{ 'ais-NumericMenu-item--selected': index === 0 }"
              >
                <label class="ais-NumericMenu-label">
                  <input
                    class="ais-NumericMenu-radio"
                    type="radio"
                    name="NumericMenu"
                    :checked="index === 0"
                    @change="refineCustom(props.refine, item)"
                  />
                  <span class="ais-NumericMenu-labelText">{{ item.label }}</span>
                </label>
                <template v-if="item.label === 'Date Range'">
                  <b-datepicker
                    v-model="dates"
                    :date-formatter="formatDateRange"
                    :disabled="customDatesDisabled"
                    :show-week-number="false"
                    placeholder="Choose range"
                    :icon-right="dates ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="clearDates"
                    trap-focus
                    range
                  ></b-datepicker>
                </template>
              </li>
            </ul>
          </template>
        </search-list>
      </div>
      <div class="work-order-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual, keyBy, findLast } from 'lodash';
import dayjs from 'dayjs';
import { ArSubmissionStatus } from '@newmoon-org/types';

import { getDateItems, getDateValues } from '@/service/dateFilter.service';
import WorkOrderService from '@/service/workorders.service';

import SearchList from '@/components/list/searchList.vue';

const defaultNumSearch = '%7B%7D';
const AR_SUBMITTED = ArSubmissionStatus.InvoicedInPC;

export default {
  name: 'AccountReportingV2',
  components: {
    SearchList,
  },
  data() {
    const today = new Date();

    const rangeStart = Math.ceil(today.setDate(today.getDate() + 1));
    const rangeEnd = Math.ceil(today.setDate(today.getDate() + 2));
    return {
      currentPageIds: [],
      showProjects: false,
      facetToggles: [{ facet: 'isMaintenanceRequired', displayName: 'Maintenance Required' }],
      facets: [
        { facet: 'appointmentReason', displayName: 'Appointment Reason' },
        { facet: 'company', displayName: 'Company' },
        { facet: 'techProjectId', displayName: 'Tech Project' },
        { facet: 'serviceZone.name', displayName: 'Service Zone' },
        { facet: 'status', displayName: 'Dispatch Status' },
        { facet: 'currentArState', displayName: 'AR State' },
        { facet: 'state', displayName: 'State' },
        { facet: 'type', displayName: 'Type' },
        { facet: 'isProject', displayName: 'Project' },
        { facet: 'isImported', displayName: 'Imported' },
      ],
      attributes: [],
      actions: [],
      dates: [],
      startDateRange: rangeStart,
      endDateRange: rangeEnd,
      customDateRefine: null,
      customDatesDisabled: true,
      numericMenuAttributes: [
        {
          displayName: 'Date',
          attribute: 'date_timestamp',
          values: getDateValues(),
          items: [
            { label: 'No date filter' },
            ...getDateItems(new Date()),
            {
              label: 'Date Range',
              start: this.startDateRange,
              end: this.endDateRange,
            },
          ],
        },
      ],
    };
  },
  asyncComputed: {
    realWorkOrders: {
      async get() {
        return (
          (await WorkOrderService.getChunkedDBResults(this.currentPageIds).then(r =>
            keyBy(
              r.map(it => {
                const state = findLast(it.stateTransitionHistory, that => that.value === it.state);
                const status = findLast(it.statusTransitionHistory, that => that.value === it.status);
                const fmt = 'MM/DD/YY h:mm A';
                return {
                  id: it.id,
                  isArSubmitted: !!it?.accountingTag?.arSubmissions?.find(ar => ar.status === AR_SUBMITTED),
                  stateDate: state?.date ? dayjs(state.date).format(fmt) : '',
                  statusDate: status?.date ? dayjs(status.date).format(fmt) : '',
                  statusUser: status?.user?.name ?? '',
                  stateUser: state?.user?.name ?? '',
                };
              }),
              'id'
            )
          )) ?? {}
        );
      },
      default: {},
    },
  },
  watch: {
    dates(newVal) {
      if (newVal.length) {
        const [start, end] = newVal;
        const startString = Math.floor(start);
        const endString = Math.ceil(end);
        if (this.customDateRefine) {
          //%7B%22start%22:1660390089471,%22end%22:1662982089471%7D - format of algolia numeric filter
          const formatted = `%7B%22start%22:${startString},%22end%22:${endString}%7D`;
          this.customDateRefine(formatted);
        }
      }
    },
  },
  mounted() {
    this.$router.push({
      name: 'accountReportEmptyState',
    });
  },
  methods: {
    hydrateWorkOrders(workOrders) {
      const ids = workOrders?.map(it => it.objectID);
      if (!isEqual(ids, this.currentPageIds)) {
        this.currentPageIds = ids ?? [];
      }
      return workOrders;
    },
    searchFunction(helper) {
      const page = helper.getPage();
      if (!helper.state.facets.includes('isProject')) {
        helper.state.facets.push('isProject');
        helper.addFacetRefinement('isProject', false);
      }
      if (!helper.state.facets.includes('isImported')) {
        helper.state.facets.push('isImported');
        helper.addFacetRefinement('isImported', false);
      }
      helper.setPage(page).search();
    },
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    clearDates() {
      this.dates = [];
    },
    refineCustom(refine, item) {
      this.customDateRefine = refine;
      const isDateRange = item.label === 'Date Range';
      if (!isDateRange) {
        this.customDatesDisabled = true;
        this.clearDates();
        return refine(item.value);
      }

      this.customDatesDisabled = !isDateRange;
      refine(defaultNumSearch);
    },
    renderWorkOrder(item) {
      this.$router.push({
        name: 'accountWorkOrderView',
        params: {
          workOrderId: item.objectID,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
#accounting-report-page {
  .page-content {
    display: flex;
    flex-direction: row;

    .table-content {
      display: flex;
      flex-grow: 1;
      margin-right: 2em;
      min-width: 900px;
      max-width: 900px;
    }

    .work-order-content {
      display: flex;
      flex-grow: 1;
    }
  }

  .ais-NumericMenu-labelText {
    padding-left: 4px;
  }

  .datepicker {
    width: 195px;
  }

  .spinner {
    animation: rotate 600ms infinite;
  }
}
</style>
