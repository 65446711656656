<template>
  <div id="project-workorder-mlh-report">
    <div class="title is-5">MLH Budget Report</div>
    <div class="columns is-variable is-2">
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="hourglass"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Total</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ budget }}</div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="hourglass-half"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Running Total</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ runningTotalPretty }}</div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="hourglass-end"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Remaining</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ remaining }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectService from '@/service/project.service';
import WorkOrderService from '@/service/workorders.service';
import { fieldPath } from '@/service/firebase';

export default {
  name: 'ProjectWorkorderMlhReport',
  props: {
    projectId: {
      type: String,
      required: true,
    },
    workorderId: {
      type: String,
      required: true,
    },
    runningTotal: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      planned: 0,
      committed: 0,
      budget: 0,
    };
  },
  computed: {
    remaining() {
      return (this.budget - this.planned - this.committed - this.runningTotal).toFixed(1);
    },
    runningTotalPretty() {
      return this.runningTotal.toFixed(1);
    },
  },
  async mounted() {
    this.pullProject();
    this.pullWorkordersData();
  },
  methods: {
    pullProject() {
      ProjectService.dbRef.doc(this.projectId).onSnapshot(r => {
        this.budget = Number(r.data()?.mlhBudget ?? 0);
      });
    },
    pullWorkordersData() {
      WorkOrderService.dbRef
        .where('project.id', '==', this.projectId)
        .where('deleted', '==', false)
        .where(fieldPath.documentId(), '!=', this.workorderId)
        .onSnapshot(r => {
          this.planned = 0;
          this.committed = 0;
          r.docs
            .map(d => WorkOrderService.mapWorkOrderFromResponse(d))
            .forEach(it => {
              const total = WorkOrderService.computeWorkAssignmentMLH(it);
              if (it.date) {
                this.committed += total;
              } else {
                this.planned += total;
              }
            });
        });
    },
  },
};
</script>
