import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';
import { flow, pick } from 'lodash';

import { init } from './generic.service';

const methods = init({
  collectionPath: 'scheduleDefinitions',
  dateFields: ['repeatFromDate'],
});

export default {
  getAll: methods.getAll,
  getAllActive: methods.getAllActive,
  get: methods.getById,
  getAllLight,
  update: methods.update,
  dbRef: methods.dbRef,
};

async function getAllLight() {
  return methods.dbRef
    .where('status', '==', 'active')
    .orderBy('name')
    .orderBy('code')
    .get()
    .then(r => r.docs.map(flow(conversionServiceShared.mapDoc, it => pick(it, 'name', 'code', 'id'))));
}
