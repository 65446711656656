<template>
  <div class="newmoon-list-container">
    <div class="mb-0 columns is-mobile">
      <div v-if="config.title" class="column">
        <div class="is-size-4">
          {{ config.title }}
        </div>
      </div>
      <div v-if="config.titleActions" class="column is-narrow">
        <div class="buttons">
          <b-button
            class="is-small"
            v-for="(a, index) in config.titleActions"
            :key="index"
            :icon-right="a.icon"
            :class="a.style"
            @click="() => a.action()"
            :disabled="a.disabled"
          ></b-button>
        </div>
      </div>
    </div>
    <div v-if="config.isSearchable" class="columns">
      <div class="column">
        <div class="search">
          <b-input type="search" v-model="searchQuery" :placeholder="`Search ${config.title}`" icon="search"></b-input>
        </div>
      </div>
    </div>
    <div v-if="data.length">
      <div class="newmoon-list">
        <div
          class="p-2 list-item is-flex-tablet"
          v-for="(d, index) in filteredData"
          :key="index"
          :class="[index % 2 !== 0 ? 'is-odd' : '', ...getAdditionalClasses(d)]"
        >
          <div class="info-container is-flex-grow-1">
            <div class="list-label">
              <span class="field-name" v-if="config.labelLabel">{{ config.labelLabel }}</span>
              <a v-if="config.labelOnClick && getLabel(d) !== 'Unknown Field'" @click="config.labelOnClick(d)">
                {{ getLabel(d) }}
              </a>
              <router-link
                v-else-if="config.labelRouterLink && getLabel(d) !== 'Unknown Field'"
                :to="config.labelRouterLink(d)"
              >
                {{ getLabel(d) }}
              </router-link>
              <span v-else>{{ getLabel(d) }}</span>
            </div>
            <div class="list-info" v-if="config.infoField">
              <span class="field-name" v-if="config.infoLabel">{{ config.infoLabel }}</span>
              <p>{{ getInfo(d) }}</p>
            </div>
            <div v-if="config.infoFields">
              <div class="list-info" v-for="(field, idx) in config.infoFields" :key="idx">
                <span class="field-name" v-if="field.infoLabel">{{ field.infoLabel }}:</span>
                <p>{{ getInfoFields(d, field) }}</p>
              </div>
            </div>

            <slot name="after-label" :item="d"></slot>
          </div>
          <div class="list-actions is-flex-grow-0">
            <div class="buttons">
              <b-button
                class="is-small"
                v-for="(a, idx) in config.listActions"
                :key="idx"
                :icon-right="a.icon"
                :class="a.style"
                @click="() => a.action({ row: d, index })"
              ></b-button>
            </div>
          </div>
        </div>
      </div>
      <b-pagination
        class="py-2"
        v-if="showPagination"
        :total="data.length"
        :per-page="paginationPerPage"
        v-model="currentPage"
      ></b-pagination>
    </div>
    <div v-else>
      <div class="newmoon-list">
        <div class="list-item is-odd">
          <div class="py-3 pl-2 list-label">{{ config.emptyText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export type ListConfig<T> = {
  title: string;
  emptyText: string;
  titleActions: Array<{
    label: string;
    icon: string;
    action: () => void;
    style?: string;
  }>;
  labelField: string;
  listActions?: Array<{
    label: string;
    icon: string;
    action: (args: { row: T; index: number }) => void;
    style?: string;
  }>;
  isSearchable?: boolean;
};
</script>

<script>
import { chunk, get, isArray, isFunction } from 'lodash';

export default {
  name: 'List',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({
        title: '',
        emptyText: 'No items in list',
        labelField: '',
        listActions: [],
        titleActions: [],
        paginationPerPage: 5,
        listItemClassAdder: () => [],
        isSeachable: false,
      }),
    },
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: '',
    };
  },
  computed: {
    showPagination() {
      return this.data.length > this.config.paginationPerPage;
    },
    paginationPerPage() {
      return this.config.paginationPerPage ?? 5;
    },
    filteredData() {
      if (!this.showPagination) return this.data;
      const chunked = chunk(this.data, this.paginationPerPage);
      return chunked[this.currentPage - 1];
    },
  },
  methods: {
    getLabel(row) {
      if (isFunction(this.config.labelField)) {
        return this.config.labelField(row);
      }
      return get(row, this.config.labelField, 'Unknown Field');
    },
    getInfo(row) {
      if (isFunction(this.config.infoField)) {
        return this.config.infoField(row);
      }
      return get(row, this.config.infoField);
    },
    getInfoFields(d, field) {
      if (isFunction(field.infoField)) {
        return field.infoField(d);
      }
      return get(d, field.infoField);
    },
    getAdditionalClasses(d) {
      let classes;
      const { listItemClassAdder } = this.config;
      if (isFunction(listItemClassAdder)) {
        classes = listItemClassAdder(d);
      }
      return isArray(classes) ? classes : [];
    },
  },
};
</script>

<style scoped lang="scss">
.newmoon-list {
  border: #ddd solid 1px;
  border-radius: 5px;
  .list-item {
    &.is-odd {
      background-color: #eff1fa;
    }
    .field-name {
      padding-right: 10px;
      min-width: 150px;
      font-weight: bold;
    }
  }
}
</style>
