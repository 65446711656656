import { isEqual, keyBy, pick, uniq, flatten, uniqBy, uniqWith, filter, isEmpty, reduce, identity } from 'lodash';
import { ConversionService as conversionServiceShared, DateService as dateServiceShared } from '@newmoon-org/shared';

import CentralSchedulerService from '@/service/centralScheduler.service';
import EmployeesService from '@/service/employees.service';
import ScheduleDefinitionsService from '@/service/scheduleDefinitions.service';

export default {
  data() {
    return {
      employeeFunctions: ['Service Tech'],
      employeesUnsub: null,
      startLimitUnsub: null,
      endLimitUnsub: null,
      startPollerSchedules: [],
      endPollerSchedules: [],
      employees: [],
      loadingStartPoller: false,
      loadingEndPoller: false,
      loadingEmployeesPoller: false,
    };
  },
  asyncComputed: {
    scheduleDefinitions: {
      get() {
        return this.loadScheduleDefinitions();
      },
      default: {},
    },
  },
  computed: {
    affectedSchedules() {
      return uniqBy([...this.startPollerSchedules, ...this.endPollerSchedules], 'id');
    },
    affectedEmployees() {
      return uniqWith(
        this.affectedSchedules.map(it => it.employeeId),
        isEqual
      );
    },
    loadingSchedulesPoller() {
      return this.loadingStartPoller || this.loadingEndPoller;
    },
  },
  methods: {
    /*
      Scenario #1
      Workorder start |-------------------| Workorder end
                      ^ xxxxxxxxxxxxxxxxx ^
                      |                   |
                      Other schedules start

      Scenario #2
      Workorder start |---------------------| Workorder end
              ^ xxxxxxxxxxxxxxxxx ^
              |                   |
              Other schedules start

      Scenario #3
      Workorder start |---------------------| Workorder end
                            ^ xxxxxxxxxxxxxxxxx ^
                            |                   |
                            Other schedules start

      Scenario #4
      Workorder start |---------------------| Workorder end
                ^xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx ^
                |                                |
                      Other schedules start
    */
    handleDynamicProsLoads(startDate, endDate, workorderId, costCodeId) {
      if (this.startLimitUnsub) {
        this.startLimitUnsub();
        this.startPollerSchedules = [];
      }

      if (this.endLimitUnsub) {
        this.endLimitUnsub();
        this.endPollerSchedules = [];
      }

      if (!startDate || !endDate) return;

      this.loadingStartPoller = true;
      this.loadingEndPoller = true;
      const startOfTheDay = dateServiceShared.getStartOfDay(startDate);
      const startOfTomorrow = dateServiceShared.getStartOfTomorrow(endDate);
      const isCurrentCostCodeWorkOrder = it => it.workOrderId === workorderId && it.costCodeId === costCodeId;
      const isCurrentWorkOrder = it => it.workOrderId === workorderId;
      const filterFunction = it => {
        let baseClause =
          costCodeId && workorderId ? !isCurrentCostCodeWorkOrder(it) : workorderId ? !isCurrentWorkOrder(it) : true;
        return baseClause && dateServiceShared.hasTimeConflict(it.startTime, it.endTime, startDate, endDate);
      };

      this.startLimitUnsub = CentralSchedulerService.dbRef
        .where('startTime', '>=', startOfTheDay)
        .where('startTime', '<=', startOfTomorrow)
        .onSnapshot(
          docs => {
            this.startPollerSchedules = docs.docs
              .map(it => CentralSchedulerService.mapDocWithDates(it))
              .filter(filterFunction);
            this.loadingStartPoller = false;
          },
          e => {
            this.loadingEndPoller = false;
            this.$buefy.notification.open({
              message: `Failed to pull schedules with start date parameters, ${e.message}`,
              type: 'is-danger',
            });
          }
        );

      this.endLimitUnsub = CentralSchedulerService.dbRef
        .where('endTime', '>=', startOfTheDay)
        .where('endTime', '<=', startOfTomorrow)
        .onSnapshot(
          docs => {
            this.endPollerSchedules = docs.docs
              .map(it => CentralSchedulerService.mapDocWithDates(it))
              .filter(filterFunction);
            this.loadingEndPoller = false;
          },
          e => {
            this.loadingEndPoller = false;
            this.$buefy.notification.open({
              message: `Failed to pull schedules with end date parameters, ${e.message}`,
              type: 'is-danger',
              indefinite: true,
              closable: true,
            });
          }
        );
    },
    async loadScheduleDefinitions() {
      return ScheduleDefinitionsService.getAllActive().then(r =>
        keyBy(
          r.map(it => pick(it, ['id', 'repeatFromDate', 'weeks'])),
          'id'
        )
      );
    },
    async computeCostCodeConflicts(costCodes) {
      const safeCostCodes = filter(
        costCodes,
        it => !!it.startDate && !!it.endDate && (!isEmpty(it.assignedTechs) || !!it.foreman?.id)
      ).map(it => ({
        ...it,
        employeeIds: [...(it.assignedTechs?.map(e => e.id) ?? []), it.foreman?.id].filter(identity),
      }));

      if (safeCostCodes.length === 0) {
        return {};
      }

      const getConflictingEvent = (cc, event) =>
        (cc.workorderId !== event.workOrderId || cc.id !== event.costCodeId) &&
        cc.employeeIds.includes(event.employeeId) &&
        dateServiceShared.hasTimeConflict(event.startTime, event.endTime, cc.startDate, cc.endDate)
          ? event
          : null;

      const [events, employees, scheduleDefinitions] = await Promise.all([
        this.loadEvents(safeCostCodes[0].startDate, safeCostCodes[0].endDate),
        EmployeesService.getByIds(uniq(flatten(safeCostCodes.map(it => it.employeeIds)))),
        this.loadScheduleDefinitions(),
      ]);
      const employeesMap = keyBy(employees, 'id');
      return reduce(
        safeCostCodes,
        (agg, cc) => {
          const eventConflicts = events.map(it => getConflictingEvent(cc, it)).filter(identity);
          const qualifications = this.buildScheduleQualifications(
            scheduleDefinitions,
            cc.employeeIds.map(eId => employeesMap[eId]).filter(identity),
            cc.startDate,
            cc.endDate
          );
          const scheduleConflicts = cc.employeeIds
            .filter(it => !qualifications[it]?.isQualified)
            .map(it => ({ employeeId: it, isScheduleConflict: true }));
          return {
            ...agg,
            [cc.id]: uniqBy(
              [...(agg[cc.id] ?? []), ...eventConflicts, ...scheduleConflicts],
              'employeeId',
              'costCodeId',
              'workOrderId'
            ),
          };
        },
        {}
      );
    },
    buildScheduleQualifications(scheduleDefinitions, techs, startDate, endDate) {
      return (
        techs.reduce(
          (acc, tech) => ({
            ...acc,
            [tech.id]: dateServiceShared.scheduleIsQualified(
              scheduleDefinitions[tech.schedule?.id],
              startDate,
              endDate
            ),
          }),
          {}
        ) ?? {}
      );
    },
    async loadEvents(startDate, endDate) {
      if (!startDate || !endDate) {
        return [];
      }

      const startOfTheDay = dateServiceShared.getStartOfDay(startDate);
      const startOfTomorrow = dateServiceShared.getStartOfTomorrow(endDate);

      const startLimit = await CentralSchedulerService.dbRef
        .where('startTime', '>=', startOfTheDay)
        .where('startTime', '<=', startOfTomorrow)
        .get()
        .then(r => r.docs.map(it => CentralSchedulerService.mapDocWithDates(it)))
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to pull schedules with start date parameters, ${e.message}`,
            type: 'is-danger',
          })
        );

      const endLimit = await CentralSchedulerService.dbRef
        .where('endTime', '>=', startOfTheDay)
        .where('endTime', '<=', startOfTomorrow)
        .get()
        .then(r => r.docs.map(it => CentralSchedulerService.mapDocWithDates(it)))
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to pull schedules with end date parameters, ${e.message}`,
            type: 'is-danger',
            indefinite: true,
            closable: true,
          })
        );

      return uniqBy([...startLimit, ...endLimit], 'id');
    },
    loadEmployees() {
      if (this.employeesUnsub) {
        this.employeesUnsub();
        this.employees = [];
      }

      this.loadingEmployeesPoller = true;
      this.employeesUnsub = EmployeesService.dbRef
        .where('workflowFunction.name', 'in', this.employeeFunctions)
        .where('status', '==', 'active')
        .orderBy('lastName')
        .orderBy('firstName')
        .onSnapshot(
          docs => {
            this.employees = docs.docs.map(conversionServiceShared.mapDoc);
            this.loadingEmployeesPoller = false;
          },
          e => {
            this.loadingEmployeesPoller = false;
            this.$buefy.notification.open({
              message: `Failed to pull available employees, ${e.message}`,
              type: 'is-danger',
              indefinite: true,
              closable: true,
            });
          }
        );
    },
  },
};
