<template>
  <div id="project-comments">
    <div class="mb-1 is-size-4">Work Assignment Comments</div>
    <div v-if="$asyncComputed.comments.updating">
      <b-progress></b-progress>
    </div>
    <div class="is-full has-text-centered" v-else-if="comments.length === 0">
      <div class="subtitle is-5">No comments...</div>
    </div>
    <div class="comments" v-else>
      <div class="column is-full" v-for="(comment, idx) in comments" :key="idx">
        <b-message :title="buildTitle(comment)" :type="idx % 2 === 0 ? 'is-dark' : 'is-black'" :closable="false">
          {{ comment.value }}
        </b-message>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { first, keyBy, keys } from 'lodash';

import CommentsService from '@/service/comments.service';

export default {
  name: 'ProjectComments',
  props: {
    workOrders: {
      type: Array,
      required: true,
    },
  },
  asyncComputed: {
    comments: {
      get() {
        const workOrderById = keyBy(this.workOrders, 'id');
        return this.workOrders.length > 0
          ? CommentsService.getCommentsFromIds(keys(workOrderById)).then(r =>
              r
                .map(c => ({
                  ...c,
                  workOrderNumber: workOrderById[c.associationId].workOrderNumber,
                }))
                .sort((a, b) => {
                  if (a.createdAt && b.createdAt) {
                    return a.createdAt > b.createdAt ? -1 : 1;
                  }
                  return 0;
                })
            )
          : [];
      },
      default: [],
    },
  },
  methods: {
    buildTitle(comment) {
      const workflowFunction = first(comment?.audit)?.userWorkflowFunction
        ? `${first(comment.audit).userWorkflowFunction}, `
        : comment?.createdBy?.workflowFunction?.name
        ? `${comment.createdBy.workflowFunction.name} `
        : '';
      const userName =
        (first(comment?.audit)?.userName ??
          `${comment?.createdBy?.lastName} ${comment?.createdBy?.firstName}`.trim()) ||
        '';
      const userCode = first(comment?.audit)?.userCode ?? comment?.createdBy?.code ?? '';
      const name = `${userName}(${userCode})`;
      return `W0# ${comment.workOrderNumber} - ${workflowFunction} ${name} - ${this.buildCommentDate(comment)}`;
    },
    buildCommentDate(comment) {
      return dayjs(comment.createdAt).format('MMM D YYYY, h:mm a');
    },
  },
};
</script>

<style lang="scss">
.comments {
  display: flex;
  flex-direction: column;
  background-color: #dddddd33;
  padding: 16px 16px 32px;
  max-height: 700px;
  overflow: scroll;
}
</style>
