<template>
  <div class="photo-uploader" :class="{ 'single-file': isSingleUpload }">
    <form class="drop-zone" enctype="mutlipart/form-data" novalidate>
      <b-tooltip v-if="isSingleUpload" label="Drag image here or click to add image" position="is-top">
        <input
          class="input-file"
          type="file"
          multiple
          accept="image/*,application/pdf"
          capture="user"
          :disabled="status === 'saving'"
          @change="
            isSingleUpload ? onUploadSingle($event.target.files) : onUpload($event.target.files);
            fileCount = $event.target.files.length;
          "
        />
      </b-tooltip>
      <input
        class="input-file"
        v-else
        type="file"
        multiple
        accept="image/*,application/pdf"
        :disabled="status === 'saving'"
        @change="
          isSingleUpload ? onUploadSingle($event.target.files) : onUpload($event.target.files);
          fileCount = $event.target.files.length;
        "
      />
      <template v-if="!isSingleUpload">
        <p v-if="status === 'waiting'">Drag files here or click to select files</p>
        <p v-else-if="status === 'saving'">Uploading {{ fileCount }} files...</p>
      </template>
      <template v-else>
        <div class="profile-upload">
          <div class="add-image">
            <b-icon icon="user"></b-icon>
          </div>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import { storage } from '@/service/firebase';

export default {
  name: 'PhotoUploader',
  props: {
    directory: {
      type: String,
      required: true,
    },
    isSingleUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileCount: 0,
      status: 'waiting',
      error: null,
      showDropZone: false,
    };
  },
  methods: {
    async onUploadSingle([file]) {
      try {
        this.status = 'saving';
        const { name } = file;
        if (name) {
          const storageRef = storage.ref(`${this.directory}/${name}`);
          storageRef.put(file).then(data => {
            this.$emit('upload-success', data.ref);
            this.onReset();
          });
        }
      } catch (e) {
        this.error = e;
        this.status = 'error';
        console.error(e);
      }
    },
    async onUpload(files) {
      try {
        this.$emit('instant-add', files);
        this.status = 'saving';
        const fileArray = Array.from(files);
        for (const file of fileArray) {
          const storageRef = storage.ref(`${this.directory}/${file.name}`);
          const data = await storageRef.put(file);
          this.$emit('upload-success', data.ref);
          this.fileCount--;
          if (this.fileCount === 0) {
            this.onReset();
          }
        }
      } catch (e) {
        this.error = e;
        this.status = 'error';
        // do something to notify of error
      }
    },
    onReset() {
      this.status = 'waiting';
      this.uploaded = [];
      this.error = null;
    },
  },
};
</script>

<style scoped lang="scss">
.photo-uploader {
  .drop-zone {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    box-sizing: border-box;
    background-color: whitesmoke;
    min-height: 200px;
    border: 1px solid #33333333;
    position: relative;
    transition: background-color 350ms ease-in-out;
  }

  .input-file {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 18;
  }

  .drop-zone:hover {
    background-color: #66666633;
    color: #333;
  }

  &.single-file {
    .drop-zone {
      width: 60px;
      height: 60px;
      min-height: 60px;
      border-radius: 50%;
      position: relative;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 16;

      .b-tooltip {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        padding-left: 25px;
      }
    }

    .input-file {
      opacity: 0;
      position: absolute;
      height: 48px;
      width: 48px;
    }

    .profile-upload {
      cursor: pointer;
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;

      .add-image {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
