import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const initialState = () => {
  return { workorderBookingsInFlight: [] };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
