import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  hd: 'flowrightphi.com',
  prompt: 'select_account',
});

export default provider;
