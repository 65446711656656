<template>
  <div class="gantt-row-period">
    <div class="timeframe">{{ formattedDate }}</div>
    <div class="period-wrapper day-time" v-if="type === 'day-time'">
      <div
        class="period"
        v-for="(hour, index) in dailyHours"
        :key="hour"
        :class="{ isWeekend: isWeekend(), isCurrentHour: isCurrentHour(index) }"
      >
        {{ hour }}
      </div>
    </div>
    <div class="period-wrapper month-year" v-else-if="type === 'month-year'">
      <div class="period" v-for="d in daysInMonth" :key="d" :class="{ isWeekend: isWeekend(d) }">{{ d }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

export default {
  name: 'GanttRowPeriod',
  props: {
    day: {
      type: Date,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
    month: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: '12hr',
    },
  },
  data() {
    return {
      today: dayjs(),
      todayRef: null,
    };
  },
  computed: {
    dailyHours() {
      if (this.format === '12hr') {
        return [
          '12 am',
          '1 am',
          '2 am',
          '3 am',
          '4 am',
          '5 am',
          '6 am',
          '7 am',
          '8 am',
          '9 am',
          '10 am',
          '11 am',
          '12 pm',
          '1 pm',
          '2 pm',
          '3 pm',
          '4 pm',
          '5 pm',
          '6 pm',
          '7 pm',
          '8 pm',
          '9 pm',
          '10 pm',
          '11 pm',
        ];
      }

      return [
        '0000',
        '0100',
        '0200',
        '0300',
        '0400',
        '0500',
        '0600',
        '0700',
        '0800',
        '0900',
        '1000',
        '1100',
        '1200',
        '1300',
        '1400',
        '1500',
        '1600',
        '1700',
        '1800',
        '1900',
        '2000',
        '2100',
        '2200',
        '2300',
      ];
    },
    daysInMonth() {
      return new Date(this.year, this.month + 1, 0).getDate();
    },
    formattedDate() {
      if (this.type === 'day-time') {
        return dayjs(this.day).format('dddd, MMM. DD, YYYY');
      }

      return dayjs(new Date(this.year, this.month + 1, 0)).format('MMM. YYYY');
    },
  },
  created() {
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);
    this.todayRef = setInterval(() => {
      this.today = dayjs();
    }, 30 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.todayRef);
  },
  methods: {
    isWeekend(day) {
      if (this.type === 'day-time') {
        return [0, 6].includes(new Date(this.day).getDay());
      }

      return [0, 6].includes(new Date(this.year, this.month + 1, day).getDay());
    },
    isCurrentHour(hour) {
      const today = this.today;
      if (today.isSame(dayjs(this.day), 'day')) {
        return today.format('H') === `${hour}`;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.gantt-row-period {
  box-sizing: border-box;
  border-right: 1px solid #33333333;
  width: 100%;

  .timeframe {
    background-color: #f5f5fa;
    color: #333;
    border: 1px solid #efefef;
    border-bottom: none;
    border-right-width: 3px;
    text-align: center;
    font-size: 14px;
    padding: 4px;
  }

  .period-wrapper {
    display: grid;
    grid-auto-flow: column;
    background-color: #f5f5fa;
    color: #333;
    border: 1px solid #efefef;
    text-align: center;
    position: relative;

    &.day-time {
      grid-auto-columns: 35px;
    }

    &.month-year {
      grid-auto-columns: minmax(35px, 1fr);
    }

    & > .period {
      font-size: 10px;

      &.isWeekend {
        background-color: #969696;
        color: #fff;
      }

      &.isCurrentHour {
        background-color: #004e7c;
        color: white;
        transform: scale(1.15);
        font-weight: bold;
      }
    }
  }
}
</style>
