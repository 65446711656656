<template>
  <div class="dfr-line-item">
    <div class="columns is-multiline">
      <div class="column">
        <b-field label="Type">
          <b-select v-model="selectedType" expanded>
            <option v-for="(t, index) in types" :key="index" :value="t">{{ t }}</option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Start Date/Time">
          <b-datetimepicker
            v-model="lineItemLocal.startDate"
            position="is-bottom-left"
            :timepicker="timepickerSettings"
            horizontal-time-picker
          ></b-datetimepicker>
        </b-field>
      </div>
      <div class="column">
        <b-field label="End Date/Time">
          <b-datetimepicker
            v-model="lineItemLocal.endDate"
            position="is-bottom-left"
            :timepicker="timepickerSettings"
            horizontal-time-picker
          ></b-datetimepicker>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field
          label="Work Order"
          :message="{
            'Unable to locate work orders based on the selected dates.':
              canSelectWorkOrder && Object.values(workOrders).length < 1,
          }"
        >
          <b-select
            v-model="lineItemLocal.workOrder"
            :loading="$asyncComputed.workOrders.updating"
            :disabled="!canSelectWorkOrder"
            expanded
          >
            <option v-for="(wo, id) in workOrders" :key="id" :value="wo">{{ wo.workOrderNumber }}</option>
          </b-select>
          <b-tooltip v-if="lineItemLocal.workOrder && lineItemLocal.workOrder.isPrevailingWage" label="Prevailing Wage">
            <b-icon icon="money-bill-wave" size="is-medium" type="is-success"></b-icon>
          </b-tooltip>
        </b-field>
      </div>
      <div class="column" v-if="selectedType === 'Project'">
        <b-field label="Cost Code">
          <b-select
            v-model="lineItemLocal.costCode"
            :loading="$asyncComputed.workOrders.updating"
            :disabled="!lineItemLocal.workOrder"
            expanded
          >
            <option v-for="(c, index) in costCodes" :key="index" :value="c">{{ c.name }}</option>
          </b-select>
        </b-field>
      </div>
      <div class="column" v-if="!isSelfReport">
        <b-field label="Employee">
          <b-select
            v-model="selectedEmployee"
            :loading="$asyncComputed.workOrders.updating"
            :disabled="(selectedType === 'Project' && !lineItemLocal.costCode) || !lineItemLocal.workOrder"
            :required="!unrelatedEmployeeIsSet"
            expanded
          >
            <option v-for="e in employees" :key="e.id" :value="e">{{ e.firstName }} {{ e.lastName }}</option>
          </b-select>
        </b-field>
      </div>
      <div class="column" v-if="!isSelfReport">
        <b-field label="Unrelated Employee">
          <b-autocomplete
            v-model="unrelatedEmployeeName"
            :data="filteredUnrelatedEmployees"
            field="name"
            :loading="$asyncComputed.unrelatedEmployees.updating"
            :open-on-focus="true"
            @select="onSelectedUnrelatedEmployee"
            :disabled="(selectedType === 'Project' && !lineItemLocal.costCode) || !lineItemLocal.workOrder"
            :required="!employeeIsSet"
          ></b-autocomplete>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { identity, isEmpty, keyBy, pick } from 'lodash';
import { DateService } from '@newmoon-org/shared';
import { mapGetters } from 'vuex';

import CompanyService from '@/service/company.service';
import DfrService from '@/service/dfr.service';
import EmployeesService from '@/service/employees.service';
import WorkordersService from '@/service/workorders.service';

const { getStartOfDay, getStartOfTomorrow } = DateService;

export default {
  name: 'DFRLineItem',
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
    isSelfReport: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedEmployee: null,
      unrelatedEmployeeName: null,
      unrelatedEmployee: null,
      lineItemLocal: { ...this.lineItem },
      selectedType: this.lineItem.type ?? 'Work order',
      selectedCompany: this.lineItem.company?.code ?? null,
      types: ['Work order', 'Project'],
      timepickerSettings: {
        incrementMinutes: 15,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('features', ['isFeatureEnabled']),
    lowerDateLimit() {
      return this.loggedInUser.manager || this.canDoAnything ? null : dayjs().subtract(2, 'days').toDate();
    },
    canDoAnything() {
      return ['hr', 'developer', 'global administrator', 'administrator'].includes(
        this.loggedInUser.workflowFunction?.name?.toLowerCase()
      );
    },
    loggedInUser() {
      return this.user?.employee ?? null;
    },
    loggedInUserId() {
      return this.loggedInUser?.id ?? null;
    },
    employee() {
      return this.pickEmployee(this.loggedInUser);
    },
    employees() {
      if (this.isSelfReport) {
        return [];
      }

      const filterEmployees = employees =>
        employees?.filter(it => it && it.id !== this.loggedInUserId)?.map(this.pickEmployee) ?? [];
      if (this.lineItemLocal.costCode) {
        const costCode = this.workOrder?.costCodes?.find(it => it.id === this.lineItemLocal.costCode.id);
        return filterEmployees([...(costCode?.assignedTechs ?? []), costCode?.foreman]);
      } else {
        return filterEmployees(this.workOrder?.assignedTechs);
      }
    },
    workOrder() {
      return this.workOrders[this.lineItemLocal.workOrder?.id] ?? null;
    },
    costCode() {
      return this.workOrder?.costCodes?.find(it => it.id === this.lineItemLocal.costCode?.id) ?? null;
    },
    costCodes() {
      return this.workOrder?.costCodes ?? [];
    },
    employeeIsSet() {
      return !!this.lineItemLocal.employee?.id;
    },
    unrelatedEmployeeIsSet() {
      return !!this.unrelatedEmployee?.id;
    },
    filteredUnrelatedEmployees() {
      return this.unrelatedEmployeeName
        ? this.unrelatedEmployees.filter(it => it.name.toLowerCase().includes(this.unrelatedEmployeeName.toLowerCase()))
        : this.unrelatedEmployees;
    },
    canSelectWorkOrder() {
      return !!(this.lineItemLocal.type && this.lineItemLocal.startDate && this.lineItemLocal.endDate);
    },
  },
  watch: {
    selectedType: {
      handler() {
        this.lineItemLocal = DfrService.newInstance();
      },
    },
    lineItemLocal: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.$asyncComputed.workOrders?.success || !this.$asyncComputed.companies?.success) {
          return;
        }

        this.lineItemLocal.type = this.selectedType;
        this.lineItemLocal.reportedBy = this.employee;
        this.lineItemLocal.employee = this.isSelfReport ? this.employee : this.lineItemLocal.employee;
        this.lineItemLocal.company =
          this.companies.find(it => it.code === (this.selectedCompany ?? this.lineItemLocal.workOrder?.company)) ??
          this.lineItemLocal.company;
        this.$emit('input', this.lineItemLocal);
      },
    },
    selectedEmployee: {
      handler(value) {
        if (!this.isSelfReport && value?.id) {
          this.unrelatedEmployee = null;
          this.unrelatedEmployeeName = null;
        }
        this.setEmployee();
      },
    },
    unrelatedEmployee: {
      handler(value) {
        if (!this.isSelfReport && value?.id) {
          this.selectedEmployee = null;
        }
        this.setEmployee();
      },
    },
  },
  asyncComputed: {
    unrelatedEmployees: {
      get() {
        return !this.isSelfReport
          ? EmployeesService.dbRef
              .where('workflowFunction.name', 'in', ['Foreman', 'Service Tech'])
              .where('status', '==', 'active')
              .orderBy('lastName')
              .orderBy('firstName')
              .get()
              .then(r =>
                r.docs
                  .map(it => EmployeesService.mapDocWithDates(it))
                  .map(it => ({
                    ...it,
                    name: `${it.lastName} ${it.firstName}`,
                  }))
              )
          : [];
      },
      default: [],
    },
    companies: {
      get() {
        return CompanyService.getAll().then(r => r.map(it => pick(it, ['id', 'company', 'code'])));
      },
      default: [],
    },
    workOrders: {
      get() {
        if (!this.canSelectWorkOrder) {
          return [];
        }

        let initialWorkOrdersQuery = this.selectedCompany
          ? WorkordersService.dbRef.where('company', '==', this.selectedCompany)
          : WorkordersService.dbRef;

        if (this.selectedType === 'Project') {
          initialWorkOrdersQuery = initialWorkOrdersQuery.where(
            'date',
            '==',
            getStartOfDay(this.lineItemLocal.startDate)
          );
        } else {
          initialWorkOrdersQuery = initialWorkOrdersQuery
            .where('date', '>=', getStartOfDay(this.lineItemLocal.startDate))
            .where('date', '<=', getStartOfTomorrow(this.lineItemLocal.startDate));
        }

        return initialWorkOrdersQuery
          .get()
          .then(results => {
            const woWithAssignedTech = wo =>
              wo.assignedTechs?.find(it => it.id === this.loggedInUserId || (!this.isSelfReport && this.canDoAnything));
            const pickWo = wo =>
              pick(wo, [
                'id',
                'workOrderNumber',
                'jobInfo',
                'assignedTechs',
                'company',
                'startDate',
                'endDate',
                'isPrevailingWage',
                'project.id',
                'techProjectId',
              ]);
            const pickCc = cc => pick(cc, ['id', 'name', 'foreman', 'assignedTechs', 'startAtTime', 'endAtTime']);
            const costCodesWithAssignedTechs = wo =>
              wo.costCodes?.filter(
                c =>
                  c.foreman?.id === this.loggedInUserId ||
                  c.assignedTechs?.find(t => t.id === this.loggedInUserId) ||
                  (!this.isSelfReport && this.canDoAnything)
              );
            return keyBy(
              WorkordersService.mapWorkOrdersFromResponse(results)
                .filter(it => (this.selectedType === 'Project' && !!it.project?.id) || !it.project?.id)
                .filter(it => {
                  if (this.isFeatureEnabled('dfrWoFinishedActive.active')) {
                    return it.state === WorkordersService.WORK_ORDER_STATES.FINISHED;
                  }
                  return true;
                })
                .map(it => {
                  if (it.project?.id) {
                    const costCodes = costCodesWithAssignedTechs(it).map(pickCc);
                    return costCodes.length > 0 ? { ...pickWo(it), costCodes } : null;
                  }
                  return woWithAssignedTech(it) ? pickWo(it) : null;
                })
                .filter(identity),
              'id'
            );
          })
          .catch(e => {
            this.$buefy.notification.open({
              message: e.message,
              type: 'is-danger',
              indefinite: true,
              closable: true,
            });
            return {};
          });
      },
      default: {},
    },
  },
  mounted() {
    this.lineItemLocal.employee = this.isSelfReport ? this.employee : this.lineItemLocal.employee;
  },
  methods: {
    setEmployee() {
      this.lineItemLocal.isUnrelatedEmployeeItem = !!this.unrelatedEmployee?.id;
      this.lineItemLocal.employee = this.isSelfReport
        ? this.employee
        : isEmpty(this.unrelatedEmployee)
        ? this.selectedEmployee
        : this.unrelatedEmployee;
    },
    onSelectedUnrelatedEmployee(e) {
      this.unrelatedEmployee = this.pickEmployee(this.unrelatedEmployees.find(it => it.id === e?.id));
    },
    selectCompany() {
      if (this.selectedCompany === this.lineItemLocal.company?.code) {
        this.lineItemLocal = DfrService.newInstance();
        this.selectedCompany = null;
      }
    },
    pickEmployee(e) {
      return pick(e, [
        'id',
        'firstName',
        'lastName',
        'email',
        'code',
        'manager',
        'isForeman',
        'workflowFunction',
        'payrollSystem',
      ]);
    },
  },
};
</script>
