import firebase from 'firebase/compat/app';

import { realtimeDb } from '@/service/firebase';

import Reference = firebase.database.Reference;
import DataSnapshot = firebase.database.DataSnapshot;

export function listen(fn: (sn: DataSnapshot) => unknown): Reference | unknown {
  return realtimeDb.ref('settings/lastVersion').on('value', fn);
}
