import TimeCommitmentPage from '@/pages/TimeCommitmentPage';

export default [
  {
    component: TimeCommitmentPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Time Commitments Page',
    },
    name: 'time-commitment',
    path: '/time-commitment',
  },
];
