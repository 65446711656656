<template>
  <div class="dfr-edit-page">
    <div class="title is-3">Report Time</div>
    <div class="box">
      <div class="title is-4">For Yourself</div>
      <dfr-line-item
        class="mt-4"
        :key="`self-${selfLineItems.length}`"
        :line-item="selfLineItem"
        @input="updatedSelfItem"
      ></dfr-line-item>
      <b-button
        class="mt-5 mb-5 is-fullwidth"
        type="is-primary"
        @click="addSelfLineItem"
        :disabled="!selfLineItemIsSet"
      >
        Add Self Report
      </b-button>
      <template v-if="selfLineItems.length > 0">
        <div class="my-4 is-size-5">Self Reports</div>
        <div v-for="(item, index) in selfLineItems" :key="`self-view-${index}`">
          <div class="columns">
            <div class="column is-2">Report {{ index + 1 }}: {{ item.type }}</div>
            <div class="column">Employee: {{ item.employee.lastName }} {{ item.employee.firstName }}</div>
            <div class="column is-2">Reported Time: {{ formatTime(item) }}</div>
            <div class="column is-2">
              {{
                item.costCode
                  ? `Cost Code Id: ${item.costCode.id}`
                  : `Work Order Number: ${item.workOrder.workOrderNumber}`
              }}
              <b-tooltip class="pl-1" v-if="item.workOrder.isPrevailingWage" label="Prevailing Wage">
                <b-icon icon="money-bill-wave" size="is-medium" type="is-success"></b-icon>
              </b-tooltip>
            </div>
            <div class="column">
              {{ item.costCode ? `Cost Code: ${item.costCode.name}` : `Job Info: ${item.workOrder.jobInfo}` }}
            </div>
            <div class="column is-1">
              <b-button
                class="mr-5 is-small is-danger is-pulled-right"
                icon-right="trash"
                @click="removeSelfLineItem(index)"
              ></b-button>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="box">
      <div class="title is-4">For Team</div>
      <dfr-line-item
        class="mt-4"
        :key="`team-${lineItems.length}`"
        :line-item="lineItem"
        :is-self-report="false"
        @input="updatedItem"
      ></dfr-line-item>
      <b-button class="mt-5 mb-5 is-fullwidth" type="is-primary" @click="addLineItem" :disabled="!lineItemIsSet">
        Add Member Report
      </b-button>
      <template v-if="lineItems.length > 0">
        <div class="my-4 is-size-5">Team Reports</div>
        <div v-for="(item, index) in lineItems" :key="`team-view-${index}`">
          <div class="columns">
            <div class="column is-2">Report {{ index + 1 }}: {{ item.type }}</div>
            <div class="column">Employee: {{ item.employee.lastName }} {{ item.employee.firstName }}</div>
            <div class="column is-2">Reported Time: {{ formatTime(item) }}</div>
            <div class="column is-2">
              {{
                item.costCode
                  ? `Cost Code Id: ${item.costCode.id}`
                  : `Work Order Number: ${item.workOrder.workOrderNumber}`
              }}
              <b-tooltip class="pl-1" v-if="item.workOrder.isPrevailingWage" label="Prevailing Wage">
                <b-icon icon="money-bill-wave" size="is-medium" type="is-success"></b-icon>
              </b-tooltip>
            </div>
            <div class="column">
              {{ item.costCode ? `Cost Code: ${item.costCode.name}` : `Job Info: ${item.workOrder.jobInfo}` }}
            </div>
            <div class="column is-1">
              <b-button
                class="mr-5 is-small is-danger is-pulled-right"
                icon-right="trash"
                @click="removeLineItem(index)"
              ></b-button>
            </div>
          </div>
        </div>
      </template>
    </div>
    <b-button class="mt-5 is-fullwidth" type="is-success" @click="save" :disabled="!canCreate">Save</b-button>
    <b-button class="mt-5 is-fullwidth" @click="goBack">Cancel</b-button>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { identity, pick } from 'lodash';
import { mapGetters } from 'vuex';

import DfrService from '@/service/dfr.service';
import { getLogger } from '@/service/logger.service';
import EmployeesService from '@/service/employees.service';
import ProjectService from '@/service/project.service';

import DfrLineItem from '@/components/dfr/DFRLineItem.vue';

const log = getLogger('DFREntry');

export default {
  name: 'DFREditPage',
  components: {
    DfrLineItem,
  },
  data() {
    return {
      lineItem: DfrService.newInstance(),
      selfLineItem: DfrService.newInstance(),
      lineItems: [],
      selfLineItems: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    canCreate() {
      return [...this.selfLineItems, ...this.lineItems].length > 0;
    },
    canDoAnything() {
      return ['hr', 'developer', 'global administrator', 'administrator'].includes(
        this.loggedInUser.workflowFunction?.name?.toLowerCase()
      );
    },
    loggedInUser() {
      return this.user?.employee ?? null;
    },
    canBypassDate() {
      return this.canDoAnything || this.loggedInUser.manager;
    },
    lineItemIsSet() {
      return this.isLineItemValid(this.lineItem);
    },
    selfLineItemIsSet() {
      return this.isLineItemValid(this.selfLineItem);
    },
  },
  methods: {
    isLineItemValid(lineItem) {
      return !!(
        (lineItem.type === 'Project' ? lineItem.costCode?.id : true) &&
        lineItem.employee?.id &&
        lineItem.company?.id &&
        lineItem.workOrder?.id &&
        lineItem.startDate &&
        lineItem.endDate
      );
    },
    formatTime(item) {
      const start = dayjs(item.startDate);
      const end = dayjs(item.endDate);
      return `${start.format('M/D/YYYY')}, ${start.format('h:mm A')} - ${end.format('h:mm A')}`;
    },
    addSelfLineItem() {
      this.addItem('Similar report already exists. Are you sure you want to add a related self report?');
    },
    addLineItem() {
      this.addItem('Similar report already exists. Are you sure you want to add a related team member report?', false);
    },
    async addItem(baseWarning, isSelf = true) {
      let item = isSelf ? this.selfLineItem : this.lineItem;
      let list = isSelf ? this.selfLineItems : this.lineItems;
      const existingReport = list.filter(
        it =>
          it.employee?.id === item.employee?.id &&
          it.workOrder?.id === item.workOrder?.id &&
          it.costCode?.id === item.costCode?.id
      );
      const existingInDb = await DfrService.dbRef
        .where('employee.id', '==', item.employee.id)
        .where('workOrder.id', '==', item.workOrder.id)
        .where(item.costCode ? 'costCode.id' : 'costCode', '==', item.costCode?.id ?? null)
        .get()
        .then(r => r.docs.map(DfrService.mapDocWithDates));

      const addFunction = () => {
        const isValidTimeEntry = item => {
          if (this.canBypassDate) return true;
          const twoDaysAgo = dayjs().startOf('day').subtract(2, 'days');

          const { startDate, endDate } = item;

          return (
            twoDaysAgo.isBefore(startDate) &&
            dayjs(startDate).isBefore(dayjs(new Date())) &&
            twoDaysAgo.isBefore(dayjs(endDate)) &&
            dayjs(endDate).isBefore(dayjs(new Date())) &&
            dayjs(startDate).isBefore(dayjs(endDate))
          );
        };

        const isValidTimeInterval = item => {
          const { startDate, endDate } = item;
          const start = dayjs(startDate);
          const end = dayjs(endDate);
          return start.minute() % 15 === 0 && end.minute() % 15 === 0;
        };

        if (!isValidTimeEntry(item)) {
          const twoDaysAgo = dayjs().startOf('day').subtract(2, 'days');
          const message = `You must select a start and end time between ${twoDaysAgo.toDate().toDateString()} and now`;

          this.$buefy.notification.open({
            type: 'is-danger',
            message,
            closable: true,
            indefinite: true,
          });
          return;
        }

        if (!isValidTimeInterval(item)) {
          const message = `You must select a start and end time on a 15 minute interval e.g. 0, 15, 30, 45`;

          this.$buefy.notification.open({
            type: 'is-danger',
            message,
            closable: true,
            indefinite: true,
          });
          return;
        }
        const exploded = DfrService.explodeIfCrossesDayBoundry(item);
        if (isSelf) {
          this.selfLineItem = DfrService.newInstance();
          this.selfLineItems = [...this.selfLineItems, ...exploded];
        } else {
          this.lineItem = { ...item, employee: null };
          this.lineItems = [...this.lineItems, ...exploded];
        }
      };

      if (existingReport.length > 0 || existingInDb.length > 0) {
        this.$buefy.dialog.confirm({
          message: `${baseWarning} Found ${existingReport.length + existingInDb.length} duplicates.`,
          onConfirm: addFunction,
        });
      } else {
        addFunction();
      }
    },
    updatedItem(value) {
      this.lineItem = value;
    },
    updatedSelfItem(value) {
      this.selfLineItem = value;
    },
    removeLineItem(index) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete a team member time report?',
        onConfirm: () => {
          this.lineItems.splice(index, 1);
          this.lineItem = DfrService.newInstance();
        },
      });
    },
    removeSelfLineItem(index) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete a self time report?',
        onConfirm: () => {
          this.selfLineItems.splice(index, 1);
          this.selfLineItem = DfrService.newInstance();
        },
      });
    },
    async create() {
      const reports = [...this.selfLineItems, ...this.lineItems];

      const filledReports = await Promise.all(
        reports.map(async report => {
          const ownershipIds = [report.employee.id, report.reportedBy.id].filter(identity);
          const employee = await getEmployee(report);
          const projectCode = await getProjectCode(report);

          return {
            ...report,
            ownershipIds,
            employee,
            workOrder: {
              ...report.workOrder,
              projectCode,
            },
          };
        })
      );

      await DfrService.createMultipleInTransaction(filledReports);

      this.$buefy.notification.open({
        message: `Successfully submitted ${filledReports.length} report(s).`,
        type: 'is-success',
      });

      await this.$router.push({ name: 'dfr' });

      //---------------------------------------------------------

      async function getEmployee(dfr) {
        return EmployeesService.get(dfr.employee.id)
          .then(r =>
            pick(r, [
              'id',
              'firstName',
              'lastName',
              'email',
              'code',
              'manager',
              'isForeman',
              'workflowFunction',
              'payrollSystem',
            ])
          )
          .catch(e => {
            log.error(`Failed to pull most recent employee data, ${e.message}`);
            return dfr.employee;
          });
      }

      async function getProjectCode(dfr) {
        if (dfr.workOrder?.project?.code) return dfr.workOrder.project.code;
        if (dfr.workOrder?.project?.id) {
          return await ProjectService.dbRef
            .doc(dfr.workOrder.project.id)
            .get()
            .then(r => r.data()?.code);
        }
        return null;
      }
    },
    async save() {
      try {
        this.$buefy.dialog.confirm({
          message: `Are you sure that you want to create ${this.selfLineItems.length + this.lineItems.length} reports?`,
          onConfirm: async () => await this.create(),
        });
      } catch (e) {
        log.error(`Failed to create dfrs, ${e.message}`);
      }
    },
    async goBack() {
      this.$buefy.dialog.confirm({
        title: 'Are you sure you want to cancel?',
        message: 'All the unsaved changes will be lost!',
        closeOnConfirm: true,
        canCancel: true,
        type: 'is-warning',
        onConfirm: async () => await this.$router.push({ name: 'dfr' }),
      });
    },
  },
};
</script>
