<template>
  <div class="gantt-task-map">
    <div class="task-wrapper day-time" v-if="type === 'day-time'">
      <div
        class="task"
        v-for="hour in hoursInDay"
        :key="hour.index"
        :class="{ isWeekend: isWeekend, isInSchedule: isInSchedule(hour) }"
      >
        <record-tooltip v-for="(task, idx) in hour.tasks" :key="idx" :record="task">
          <div class="gantt-bar" :style="{ ...task.style }">
            <span>{{ task.label }}</span>
          </div>
        </record-tooltip>
      </div>
    </div>
    <div class="task-wrapper month-year" v-else-if="type === 'month-year'">
      <div class="task" v-for="d in daysInMonth" :key="d" :class="{ isWeekend: isWeekend(d) }"></div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DateService } from '@newmoon-org/shared';
import { mapGetters } from 'vuex';
import { range } from 'lodash';

import RecordTooltip from '@/components/timeCommitment/ganttchart/RecordTooltip.vue';

const { getNumberOfHoursBetweenDates } = DateService;
export default {
  name: 'GanttTaskMap',
  components: {
    RecordTooltip,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    row: {
      type: Object,
      required: true,
    },
    year: {
      type: Number,
      default: null,
    },
    month: {
      type: Number,
      default: null,
    },
    day: {
      type: Date,
      default: null,
    },
    workDay: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
    tasksForDay() {
      return this.row.tasks.filter(t => {
        const currentDay = dayjs(this.day);
        const startTime = dayjs(new Date(t.time.start));
        const endTime = dayjs(new Date(t.time.end));
        return (
          currentDay.isSame(startTime, 'day') ||
          (startTime.isSameOrBefore(currentDay, 'day') && endTime.isSameOrAfter(currentDay, 'day'))
        );
      });
    },
    daysInMonth() {
      return new Date(this.year, this.month + 1, 0).getDate();
    },
    hoursInDay() {
      const adjusted = this.tasksForDay.map(it => {
        const start = dayjs(it.time.start).isSame(dayjs(this.day), 'day')
          ? new Date(it.time.start)
          : dayjs(this.day).startOf('day').toDate();
        const end = dayjs(it.time.end).isSame(dayjs(this.day), 'day')
          ? new Date(it.time.end)
          : dayjs(this.day).endOf('day').toDate();
        const duration = getNumberOfHoursBetweenDates(start, end);
        return {
          ...it,
          time: {
            start,
            end,
            duration,
          },
        };
      });

      const multiplier = 35;
      return range(24).map(i => {
        const tasks = adjusted.filter(
          t =>
            dayjs(t.time.start).isSameOrAfter(dayjs(this.day).add(i, 'h')) &&
            dayjs(t.time.start).isBefore(dayjs(this.day).add(i + 1, 'h'))
        );

        return {
          index: i,
          tasks: tasks.map(it => {
            const minuteFraction = dayjs(it.time.start).minute() / 60;
            return {
              ...it,
              style: {
                width: `${it.time.duration * multiplier}px`,
                transform: `translateX(${minuteFraction * multiplier}px)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: `${it.color}aa`,
              },
            };
          }),
        };
      });
    },
  },
  created() {
    dayjs.extend(isSameOrAfter);
  },
  methods: {
    isInSchedule(hour) {
      if (this.workDay) {
        const start = hour.index;
        return !!this.workDay.timeSlots?.find(it => start >= it.startHour && start <= it.endHour);
      }

      return false;
    },
    isWeekend(day) {
      if (this.type === 'day-time') {
        return [0, 6].includes(new Date(this.day).getDay());
      }

      return [0, 6].includes(new Date(this.year, this.month + 1, day).getDay());
    },
  },
};
</script>

<style scoped lang="scss">
.gantt-task-map {
  box-sizing: border-box;
  border-right: 2px solid #33333399;
  width: 100%;

  .task-wrapper {
    display: grid;
    grid-auto-flow: column;
    background-color: white;
    color: #333;
    position: relative;
    z-index: 1;

    &.day-time {
      grid-auto-columns: 35px;
    }

    &.month-year {
      grid-auto-columns: minmax(35px, 1fr);
    }

    & > .task {
      min-height: 50px;
      border: 1px solid #004e7c33;

      .gantt-bar {
        padding: 0 4px;
        box-shadow: 3px 3px 2px rgba(0.12, 0.12, 0.12, 0.62);
        box-sizing: border-box;
        top: 5%;
        height: 25px;
        border-radius: 6px;
        z-index: 12;
        position: absolute;

        &:hover {
          cursor: pointer;
          border: 2px dashed #333333;
        }

        & > span {
          color: #fff;
          width: 100%;
          height: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding-left: 6px;
        }

        &.isWeekend {
          background-color: #969696;
          color: #fff;
        }
      }

      &.isInSchedule {
        background-color: rgba(0, 78, 124, 0.15);
      }
    }
  }
}
</style>
