import CustomersPage from '@/pages/CustomersPage';
import CustomerEditPage from '@/pages/CustomerEditPage';

export default [
  {
    component: CustomersPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Customer List Page',
    },
    name: 'customers',
    path: '/customers',
  },
  {
    component: CustomerEditPage,
    meta: {
      requiresAuth: true,
      featureLabel: 'Customer Edit Page',
    },
    name: 'customer-edit',
    path: '/customers/:customerId',
  },
];
