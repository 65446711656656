<template>
  <div class="employee-profile-photo">
    <div class="profile-img" v-if="profileImgUrl" :style="profileImageStyle"></div>
    <div v-else class="no-profile-img">
      <div class="icon-img">
        <vue-fontawesome :icon="['fas', 'user']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeProfilePhoto',
  props: {
    profileImgUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    profileImageStyle() {
      return {
        background: `url(${this.profileImgUrl}) center/cover`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.employee-profile-photo {
  .no-profile-img {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    position: relative;
    z-index: 2;

    .icon-img {
      width: 30px;
      height: 30px;
      display: flex;
      pointer-events: none;

      svg {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  .profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #33333333;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }
}
</style>
