import { WorkOrder } from '@newmoon-org/types';

import { getApi } from '@/service/Api';
import SocketService, { ListenerChannel } from '@/service/websocket.service';

import { PaginatedResponse, PaginationOptions } from '@/types/Pagination';

export type Tech = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isPrimary: boolean;
};

export type OnCallClaimRequest = {
  tech: Tech;
};

export async function listClaimed({ page, size }: PaginationOptions): Promise<PaginatedResponse<WorkOrder>> {
  const response = await getApi().get<PaginatedResponse<WorkOrder>>('/v3/on-calls', {
    params: { page, size, claimed: true },
  });
  return response.data;
}

export async function listUnClaimed({ page, size }: PaginationOptions): Promise<PaginatedResponse<WorkOrder>> {
  const response = await getApi().get<PaginatedResponse<WorkOrder>>('/v3/on-calls', {
    params: { page, size },
  });
  return response.data;
}

export async function claim(id: string, data: OnCallClaimRequest): Promise<void> {
  await getApi().put<string>(`/v3/on-calls/${id}/claim`, data);
}

export async function unclaim(id: string): Promise<void> {
  await getApi().put<string>(`/v3/on-calls/${id}/unclaim`);
}

export function joinChannel(): ListenerChannel {
  return SocketService.join('work-orders');
}

export async function convert(id: string): Promise<void> {
  await getApi().put<string>(`/v3/on-calls/${id}/convert`);
}

export async function finalize(id: string): Promise<void> {
  await getApi().put<string>(`/v3/on-calls/${id}/finalize`);
}
