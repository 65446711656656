<template>
  <div class="mt-4" id="comments-view">
    <div class="my-3 is-size-4">{{ title }}</div>
    <div class="well">
      <b-field v-if="!viewOnly" label="Comment">
        <b-input
          type="textarea"
          v-model="newComment"
          :loading="savingComment"
          :disabled="savingComment || disabled"
        ></b-input>
      </b-field>
      <div class="actions" v-if="!viewOnly">
        <b-button class="is-fullwidth" @click="saveComment" :loading="savingComment" :disabled="disabled">
          Post
        </b-button>
      </div>
      <div class="is-full has-text-centered" v-if="loadingComments || comments.length === 0">
        <div class="subtitle is-5">No comments yet...</div>
      </div>
      <template v-else>
        <div class="column is-full" v-for="(comment, idx) in comments" :key="idx">
          <b-message :title="buildTitle(comment)" :type="idx % 2 === 0 ? 'is-dark' : 'is-black'" :closable="false">
            {{ comment.value }}
          </b-message>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import { ConversionService as conversionServiceShared } from '@newmoon-org/shared';
import { first } from 'lodash';

import CommentsService from '@/service/comments.service';

export default {
  name: 'CommentsView',
  props: {
    associationId: {
      type: String,
      required: true,
    },
    displayableTypes: {
      type: Array,
      required: true,
    },
    commentType: {
      type: String,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Comments',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comments: [],
      newComment: null,
      savingComment: false,
      loadingComments: false,
    };
  },
  watch: {
    associationId() {
      this.loadComments();
    },
  },
  mounted() {
    this.loadComments();
  },
  methods: {
    ...mapActions('workAssignment', ['addComment']),
    buildTitle(comment) {
      const workflowFunction = first(comment?.audit)?.userWorkflowFunction
        ? `${first(comment.audit).userWorkflowFunction}, `
        : comment?.createdBy?.workflowFunction?.name
        ? `${comment.createdBy.workflowFunction.name} `
        : '';
      const userName =
        (first(comment?.audit)?.userName ??
          `${comment?.createdBy?.lastName} ${comment?.createdBy?.firstName}`.trim()) ||
        '';
      const userCode = first(comment?.audit)?.userCode ?? comment?.createdBy?.code ?? '';
      const name = `${userName}(${userCode}), `;
      return `${workflowFunction}${name}${this.buildCommentDate(comment)}`;
    },
    loadComments() {
      this.loadingComments = true;
      CommentsService.dbRef
        .where('associationId', '==', this.associationId)
        .where('type', 'in', this.displayableTypes)
        .orderBy('createdAt', 'desc')
        .onSnapshot(docs => {
          this.comments = docs.docs
            .map(conversionServiceShared.mapDoc)
            .map(it => conversionServiceShared.mapDates(it, CommentsService.DATE_FIELDS));
          this.loadingComments = false;
        });
    },
    buildCommentDate(comment) {
      return dayjs(comment.createdAt).format('MMM D YYYY, h:mm a');
    },
    async saveComment() {
      this.savingComment = true;
      try {
        const comment = {
          value: this.newComment,
          type: this.commentType,
          associationId: this.associationId,
        };
        await CommentsService.create(comment);
        this.addComment({ comment });
        this.newComment = null;
      } catch (error) {
        this.$buefy.notification.open({
          message: `Failed to save comment, ${error.message}`,
          type: 'is-danger',
        });
      } finally {
        this.savingComment = false;
      }
    },
  },
};
</script>

<style lang="scss">
#comments-view {
  .well {
    display: flex;
    flex-direction: column;
    background-color: #dddddd33;
    padding: 16px 16px 32px;
    border: 1px solid #33333333;
  }

  .title {
    font-weight: 500;
    font-size: 1.3rem;
  }
  .actions {
    align-self: flex-end;
    margin-bottom: 48px;
  }
  .comments-section {
    max-height: 500px;
    overflow-y: auto;

    .message-body {
      max-height: 200px;
      overflow-y: auto;
    }
  }
}
</style>
