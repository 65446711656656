<template>
  <tr :key="getId(item)">
    <td class="checkbox-td">
      <b-checkbox v-model="selected" :disabled="!selectable"></b-checkbox>
    </td>
    <td width="200px">
      <a @click="goToApproval(getId(item))">{{ item.employee?.lastName }} {{ item.employee?.firstName }}</a>
      <span class="reported-by" v-if="item.reportedBy">
        Reported By
        <span>
          {{ isSelfUpdate ? 'Self' : `${item.reportedBy?.lastName} ${item.reportedBy?.firstName}` }}
        </span>
      </span>
      <span class="reported-by" v-if="item.company">
        Company
        <span>{{ item.company.company }}({{ item.company.code }})</span>
      </span>
    </td>
    <td>
      <b-tooltip :label="item.workOrder.jobInfo">
        {{ item.workOrder.workOrderNumber }}
      </b-tooltip>
      <span class="project-number">
        Project
        <span>{{ !!item.workOrder.projectCode ? item.workOrder.projectCode : item.workOrder.techProjectId }}</span>
      </span>
      <span class="project-number" v-if="item.costCode">
        Cost Code
        <span>{{ item.costCode.name }}</span>
      </span>
    </td>
    <td>
      {{ formatDate(item) }}
      <span class="duration" v-if="getDuration(item)">
        <b-icon icon="clock"></b-icon>
        {{ formatTime(item) }} ({{ getDuration(item) }})
      </span>
    </td>
    <td>
      <b-tooltip :label="item.hasHrApproval ? 'Approved' : 'Needs Approval'">
        <b-icon
          :icon="item.hasHrApproval ? 'check' : 'times'"
          :type="item.hasHrApproval ? 'is-success' : 'is-danger'"
        ></b-icon>
      </b-tooltip>
    </td>
    <td>
      <b-tag v-if="!saving" :type="statusTagType(item.status)">
        {{ mapStatus(item.status) | capitalize }}
      </b-tag>
      <b-tag v-else>
        <b-icon class="spinner" icon="spinner"></b-icon>
      </b-tag>
    </td>
    <td>
      <p>{{ conflicts.length }}</p>
    </td>
    <td class="row-actions" v-if="showActions">
      <template v-if="doesNotNeedRevision">
        <template v-if="canFinalize && !inFinalStatus && item.hasHrApproval">
          <b-tooltip label="Approve">
            <b-button
              :key="`${getId(item)}-approve`"
              type="is-success"
              size="is-small"
              :loading="saving"
              @click="transitionStatus()(approved)"
              icon-right="check"
            ></b-button>
          </b-tooltip>
          <b-tooltip label="Need Revision">
            <confirm-comment-button
              :key="`${getId(item)}-revision`"
              :association-id="getId(item)"
              :comment-type="commentType"
              modal-header="Confirm Revision!"
              comment-label="Reason for denial"
              :button-label="null"
              button-type="is-warning"
              button-size="is-small"
              icon-left="exclamation-triangle"
              :is-loading="saving"
              :post-confirm-action="() => transitionStatus()(needRevision)"
            ></confirm-comment-button>
          </b-tooltip>
          <b-tooltip label="Deny">
            <confirm-comment-button
              :key="`${getId(item)}-deny`"
              :association-id="getId(item)"
              :comment-type="commentType"
              modal-header="Confirm Denial!"
              comment-label="Reason for denial"
              :button-label="null"
              button-type="is-danger"
              button-size="is-small"
              icon-left="times"
              :is-loading="saving"
              :post-confirm-action="() => transitionStatus()(denied)"
            ></confirm-comment-button>
          </b-tooltip>
        </template>
        <template v-else-if="(isHr || canDoAnything) && !item.hasHrApproval && !inFinalStatus">
          <b-tooltip label="HR Deny">
            <confirm-comment-button
              :key="`${getId(item)}-hr-deny`"
              :association-id="getId(item)"
              :comment-type="commentType"
              modal-header="Confirm Denial!"
              comment-label="Reason for denial"
              :button-label="null"
              button-type="is-danger"
              button-size="is-small"
              icon-left="times"
              :is-loading="saving"
              :post-confirm-action="() => setHrApproval(false)"
            ></confirm-comment-button>
          </b-tooltip>
          <b-tooltip label="HR Approve">
            <b-button
              :key="`${getId(item)}-hr-approve`"
              type="is-success"
              size="is-small"
              :loading="saving"
              icon-right="check"
              @click="setHrApproval(true)"
            ></b-button>
          </b-tooltip>
        </template>
      </template>
    </td>
  </tr>
</template>

<script>
import { first, pick } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { CommentType } from '@newmoon-org/shared';

import DfrService from '@/service/dfr.service';

import ConfirmCommentButton from '@/components/ConfirmCommentButton.vue';

export default {
  name: 'DFRRow',
  components: {
    ConfirmCommentButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      saving: false,
      selected: false,
      canBeSelected: true,
    };
  },
  asyncComputed: {
    conflicts: {
      get() {
        return DfrService.getConflicts([this.getId(this.item)]).then(r => first(r)?.inConflictWith ?? []);
      },
      default: [],
    },
  },
  computed: {
    ...mapGetters('auth', ['user', 'canModifyAsManager', 'hasPermission']),
    doesNotNeedRevision() {
      return this.item.status !== this.needRevision;
    },
    selectable() {
      // you must have the same type as the other rows when you are selecting due to the way batching needs to work
      if (!this.canBeSelected) return false;
      // if you need HR approval you must have the hr-approve permission
      if (!this.item.hasHrApproval) return this.isHr || this.canDoAnything;
      // otherwise your in the final status
      return !this.inFinalStatus;
    },
    approved() {
      return DfrService.STATUS.APPROVED;
    },
    needRevision() {
      return DfrService.STATUS.NEEDS_REVISION;
    },
    denied() {
      return DfrService.STATUS.DENIED;
    },
    commentType() {
      return CommentType.DFR;
    },
    isSelfUpdate() {
      return this.item.reportedBy?.id === this.item.employee?.id;
    },
    loggedInUser() {
      return this.user?.employee ?? null;
    },
    employee() {
      return this.pickEmployee(this.loggedInUser);
    },
    isManagerForCompany() {
      return this.canModifyAsManager(this.item?.company?.code);
    },
    canDoAnything() {
      return this.hasPermission('dfr:admin');
    },
    canFinalize() {
      return this.isManagerForCompany || this.canDoAnything;
    },
    inFinalStatus() {
      return DfrService.FINAL_STATUSES.includes(this.item.status);
    },
    isHr() {
      return this.hasPermission('dfr:hr-approve');
    },
  },
  watch: {
    'item.status': {
      handler() {
        this.saving = false;
      },
    },
    'item.hasHrApproval': {
      handler() {
        this.saving = false;
      },
    },
    selected: {
      handler() {
        if (this.selected) {
          this.addSelected(this.item);
          return;
        }
        this.removeSelected(this.item);
      },
    },
  },
  created() {
    if (this.inFinalStatus) return;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'dfr/setSelected') {
        this.selected = state.dfr.selected.some(dfr => dfr.objectID === this.item.objectID);

        if (state.dfr.selected.length > 0) {
          const [first] = state.dfr.selected;
          this.canBeSelected = first.hasHrApproval === this.item.hasHrApproval;
          return;
        }

        if (state.dfr.selected.length === 0) {
          this.canBeSelected = true;
          return;
        }
        return;
      }

      if (mutation.type === 'dfr/clearSelected') {
        this.selected = false;
        return;
      }
    });
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
  methods: {
    ...mapActions('dfr', ['addSelected', 'removeSelected']),
    async setHrApproval(hasHrApproval = true) {
      const dfr = await DfrService.get(this.getId(this.item));
      const date = new Date();
      await this.updateObj(dfr?.id, {
        hasHrApproval,
        hrFinalizedBy: { ...this.employee, date },
        status: hasHrApproval ? dfr?.status : this.denied,
        finalizedBy: hasHrApproval
          ? dfr?.finalizedBy
          : {
              ...this.employee,
              date,
            },
      });
    },
    getId(dfr) {
      return dfr.objectID ?? dfr.id;
    },
    pickEmployee(e) {
      return pick(e, [
        'id',
        'firstName',
        'lastName',
        'email',
        'code',
        'manager',
        'isForeman',
        'workflowFunction',
        'payrollSystem',
      ]);
    },
    async goToApproval(dfrId) {
      await this.$router.push({ name: 'dfr-approval', params: { dfrId } });
    },
    statusTagType(status) {
      const statusMap = {
        approved: 'is-success is-light',
        denied: 'is-danger is-light',
        new: 'is-info is-light',
        needs_revision: 'is-warning is-light',
      };
      return statusMap[status];
    },
    mapStatus(status) {
      const statusMap = {
        approved: 'approved',
        new: 'new',
        needs_revision: 'needs revision',
        denied: 'denied',
      };
      return statusMap[status];
    },
    getDuration(item) {
      const startDate = new Date(item.startDate_timestamp);
      const endDate = new Date(item.endDate_timestamp);

      if (startDate && endDate) {
        const difference = (endDate - startDate) / 1000 / 3600;
        const label = difference === 1 ? 'hour' : 'hours';
        const [, decimals] = difference.toString().split('.');
        const numberOfDigits = decimals?.length >= 2 ? 2 : decimals?.length == 1 ? 1 : 0;
        return `${difference.toFixed(numberOfDigits)} ${label}`;
      }
      return '';
    },
    formatDate(item) {
      if (!item.startDate_timestamp) return 'Unknown';
      const startDate = new Date(item.startDate_timestamp);
      const start = dayjs(startDate);
      return `${start.format('M/D/YYYY')}`;
    },
    formatTime(item) {
      const startDate = new Date(item.startDate_timestamp);
      const endDate = new Date(item.endDate_timestamp);
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      return `${start.format('h:mm A')} - ${end.format('h:mm A')}`;
    },
    async updateObj(dfrId, obj) {
      this.saving = true;
      return Promise.all([DfrService.update(dfrId, obj, true), DfrService.waitForAlgoliaEvent()])
        .catch(e => {
          this.$buefy.notification.open({
            message: `Failed to update dfr, ${e.message}`,
            type: 'is-danger',
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async updateStatus(status) {
      const dfr = await DfrService.get(this.getId(this.item));
      await this.updateObj(dfr?.id, {
        status,
        hasHrApproval: this.needRevision === status ? false : dfr?.hasHrApproval,
        hrFinalizedBy: this.needRevision === status ? null : dfr?.hrFinalizedBy,
        finalizedBy: DfrService.FINAL_STATUSES.includes(status)
          ? {
              ...this.employee,
              date: new Date(),
            }
          : null,
      });
    },
    transitionStatus() {
      return status => {
        this.updateStatus(status);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotateZ(360deg);
  }
}

td.checkbox-td {
  text-align: center !important;
  vertical-align: middle !important;
}

.duration {
  display: flex;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.reported-by,
.project-number {
  display: block;
  font-size: 12px;
  font-weight: bold;

  & > span {
    font-weight: normal;
    margin-left: 3px;
  }
}

.spinner {
  animation: rotate 600ms infinite;
}

.row-actions {
  div {
    margin-right: 2px;
  }
}
</style>
