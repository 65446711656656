<template>
  <div class="work-assignment-cost-code-view">
    <div class="main-header">
      <div class="name">
        <p class="font-bold">{{ costCode.name }} (#{{ costCode.costCodeNumber }})</p>
        <p>{{ duration }} hour(s) duration</p>
      </div>
      <div class="status">
        <vue-fontawesome
          :icon="['fas', costCode.selected ? 'check' : 'times']"
          :style="{ color: costCode.selected ? 'green' : 'red' }"
          size="lg"
        />
        <p>{{ costCode.selected ? 'Active' : 'Inactive' }}</p>
      </div>
    </div>
    <div class="contents">
      <div class="info-slot">
        <p class="font-bold">Information</p>
        <div class="description">
          <div class="description-item">
            <p class="font-bold">Area:</p>
            <p>{{ costCode.productArea?.label || costCode.area }}</p>
          </div>
          <div class="description-item">
            <p class="font-bold">Category:</p>
            <p>{{ costCode.productCategory || costCode.category }}</p>
          </div>
          <div class="description-item">
            <p class="font-bold">Item:</p>
            <p>{{ costCode.catalogType.name }}</p>
          </div>
          <div class="description-item">
            <p class="font-bold">Start At</p>
            <p>{{ costCode.startDate | formatDate }}</p>
          </div>
          <div class="description-item">
            <p class="font-bold">End At</p>
            <p>{{ costCode.endDate | formatDate }}</p>
          </div>
        </div>
      </div>
      <div class="employee-slot">
        <p class="font-bold">Foreman</p>
        <div class="employee-info" v-if="hasForeman">
          <div class="img-info">
            <employee-profile-photo :profile-img-url="getUrl(costCode.foreman)" />
            <p>{{ costCode.foreman.firstName }} {{ costCode.foreman.lastName }}</p>
          </div>
        </div>
        <div v-else class="employee-info">
          <div class="img-info">
            <vue-fontawesome :icon="['fas', 'users-slash']" size="lg" />
            <p>Unassigned</p>
          </div>
        </div>
      </div>
      <div class="employee-slot">
        <p class="font-bold">Technicians</p>
        <div class="employee-info" v-if="hasTechs">
          <div class="img-info" v-for="tech in costCode.assignedTechs" :key="tech.id">
            <employee-profile-photo :profile-img-url="getUrl(tech)" />
            <p>{{ tech.firstName }} {{ tech.lastName }}{{ getNamePostfixes(tech) }}</p>
          </div>
        </div>
        <div v-else class="employee-info">
          <div class="img-info">
            <vue-fontawesome :icon="['fas', 'users-slash']" size="lg" />
            <p>Unassigned</p>
          </div>
        </div>
      </div>
    </div>
    <div class="main-footer">
      <b-button class="is-small is-info" @click="$emit('clicked-edit', costCode)" icon-right="edit">Edit</b-button>
    </div>
  </div>
</template>

<script>
import { identity, keyBy, isEmpty } from 'lodash';

import WorkOrderService from '@/service/workorders.service';
import EmployeesService from '@/service/employees.service';

import EmployeeProfilePhoto from '@/components/workorders/EmployeeProfilePhoto.vue';

export default {
  name: 'WorkAssignmentCostCodeView',
  components: { EmployeeProfilePhoto },
  props: {
    costCode: {
      type: Object,
      required: true,
    },
  },
  asyncComputed: {
    employeeMap: {
      get() {
        const ids = [...(this.costCode.assignedTechs?.map(it => it.id) ?? []), this.costCode.foreman?.id].filter(
          identity
        );
        return !isEmpty(ids) ? EmployeesService.getByIds(ids).then(r => keyBy(r, 'id')) : {};
      },
      default: {},
    },
  },
  computed: {
    hasForeman() {
      return !!this.costCode.foreman?.id;
    },
    hasTechs() {
      return !!this.costCode.assignedTechs?.length;
    },
    duration() {
      return WorkOrderService.getApproximateCostCodesDuration(this.costCode);
    },
  },
  methods: {
    getUrl(employee) {
      return this.employeeMap[employee.id]?.profileImgUrl ?? employee.profileImgUrl;
    },
    getNamePostfixes(tech) {
      const postfixes = [tech?.manager ? 'Mgr' : null, tech?.isForeman ? 'Fman' : null].filter(it => it);
      return postfixes.length > 0 ? ` (${postfixes.join(', ')})` : '';
    },
  },
};
</script>

<style scoped lang="scss">
.work-assignment-cost-code-view {
  display: flex;
  flex-direction: column;
  border: solid 1px lightgray;

  .main-header {
    display: flex;
    flex-direction: row;
    padding: 20px 20px 0 20px;

    .name {
      display: inherit;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1 1 0;
    }

    .status {
      display: inherit;
      justify-content: flex-end;
      column-gap: 10px;
      flex: 1 1 0;
    }
  }

  .contents {
    padding: 5px 20px 0 20px;
    height: 180px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 30px;

    .employee-slot {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      flex: 1 1 0;

      .employee-info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        overflow-y: scroll;

        .img-info {
          display: flex;
          flex-direction: row;
          column-gap: 20px;
          align-items: flex-end;
        }
      }
    }

    .info-slot {
      display: inherit;
      flex-direction: column;
      row-gap: 10px;
      flex: 1 1 0;

      .description {
        display: inherit;
        flex-direction: inherit;
        overflow-y: scroll;

        .description-item {
          display: inherit;
          flex-direction: row;
          justify-content: space-between;
          column-gap: 30px;
        }
      }
    }
  }

  .main-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 20px 20px 20px;
  }
}
</style>
