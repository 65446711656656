<template>
  <div id="time-off-page">
    <div class="header-container">
      <div class="header">Time Off Requests</div>
      <div class="buttons">
        <b-tooltip class="mr-2" label="Create a new Time Off Request" position="is-left">
          <b-button class="is-primary" icon-right="plus" @click="newTimeOff"></b-button>
        </b-tooltip>
      </div>
    </div>
    <search-list
      index-name="timeOffs"
      :facets="facets"
      :search-function="searchFunction"
      :attributes="attributes"
      :transform="transform"
      :is-reportable="true"
      :actions="actions"
      :numeric-menu-attributes="numericMenuAttributes"
    >
      <template #custom-date="{ props }">
        <div class="date-header">Start Date</div>
        <ul class="ais-NumericMenu-list">
          <li
            class="ais-NumericMenu-item"
            v-for="(item, index) in props.items"
            :key="item.label"
            :class="{ 'ais-NumericMenu-item--selected': index === 0 }"
          >
            <label class="ais-NumericMenu-label">
              <input
                class="ais-NumericMenu-radio"
                type="radio"
                name="NumericMenu"
                :checked="index === 0"
                @change="refineCustom(props.refine, item)"
              />
              <span class="ais-NumericMenu-labelText">{{ item.label }}</span>
            </label>
            <template v-if="item.label === 'Date Range'">
              <b-datepicker
                v-model="dates"
                :date-formatter="formatDateRange"
                :disabled="customDatesDisabled"
                :show-week-number="false"
                placeholder="Choose range"
                :icon-right="dates ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDates"
                trap-focus
                range
              ></b-datepicker>
            </template>
          </li>
        </ul>
      </template>
    </search-list>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { DateService as dateServiceShared } from '@newmoon-org/shared';
import { mapGetters } from 'vuex';

import { getDateItems, getDateValues } from '@/service/dateFilter.service';
import EmployeesService from '@/service/employees.service';

import SearchList from '@/components/list/searchList.vue';

const defaultNumSearch = '%7B%7D';

export default {
  name: 'TimeOffPage',
  components: {
    SearchList,
  },
  data() {
    const today = new Date();
    const rangeStart = Math.ceil(today.setDate(today.getDate() + 1));
    const rangeEnd = Math.ceil(today.setDate(today.getDate() + 2));
    return {
      facets: [
        { facet: 'employeeName', displayName: 'Employee' },
        { facet: 'employeeReason', displayName: 'Employee Reason' },
        { facet: 'officialReason', displayName: 'Official Reason' },
        { facet: 'status', displayName: 'Status' },
      ],
      dates: [],
      startDateRange: rangeStart,
      endDateRange: rangeEnd,
      customDateRefine: null,
      customDatesDisabled: true,
      numericMenuAttributes: [
        {
          displayName: 'Start Date',
          attribute: 'startDate_timestamp',
          values: getDateValues(),
          items: [
            { label: 'No date filter' },
            ...getDateItems(new Date()),
            {
              label: 'Date Range',
              start: this.startDateRange,
              end: this.endDateRange,
            },
          ],
        },
      ],
      transform(items) {
        return items.map(i => ({
          ...i,
          startDate: dateServiceShared.convertToDate(i.startDate_timestamp),
          createdAt: dateServiceShared.convertToDate(i.createdAt_timestamp),
        }));
      },
      attributes: [
        {
          displayName: 'Time Off Number',
          key: 'timeOffNumber',
          hightlight: false,
        },
        {
          displayName: 'Employee',
          key: 'employeeName',
          hightlight: true,
        },
        {
          displayName: 'Employee Reason',
          key: 'employeeReason',
          hightlight: false,
        },
        {
          displayName: 'Creation Date',
          key: 'createdAt',
          hightlight: false,
        },
        {
          displayName: 'Start Date',
          key: 'startDate',
          hightlight: false,
        },
        {
          displayName: 'Status',
          key: 'status',
          hightlight: true,
        },
      ],
      actions: [
        {
          tooltip: 'Edit',
          tooltipPosition: 'is-left',
          icon: 'edit',
          click: async item => {
            await this.$router.push({
              name: 'time-off-edit',
              params: {
                timeOffId: item.objectID,
              },
            });
          },
          classes: ['is-primary'],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    searchQuery() {
      return this.$route.query.search;
    },
    loggedInAsHR() {
      return this.user?.employee?.role?.name === 'HR';
    },
  },
  watch: {
    searchQuery: {
      immediate: true,
      handler(value) {
        this.search = value;
      },
    },
    dates(newVal) {
      if (newVal.length) {
        const [start, end] = newVal;
        const startString = Math.floor(start);
        const endString = Math.ceil(end);
        if (this.customDateRefine) {
          //%7B%22start%22:1660390089471,%22end%22:1662982089471%7D - format of algolia numeric filter
          const formatted = `%7B%22start%22:${startString},%22end%22:${endString}%7D`;
          this.customDateRefine(formatted);
        }
      }
    },
  },
  asyncComputed: {
    employees: {
      async get() {
        return EmployeesService.getEmployees().then(r =>
          keyBy(
            r.map(it => ({
              id: it.id,
              name: `${it.lastName} ${it.firstName}`,
            })),
            'id'
          )
        );
      },
      default: {},
    },
  },
  methods: {
    searchFunction(helper) {
      const page = helper.getPage();
      helper.setPage(page);
      helper.search();
    },
    refineCustom(refine, item) {
      this.customDateRefine = refine;
      const isDateRange = item.label === 'Date Range';
      if (!isDateRange) {
        this.customDatesDisabled = true;
        this.clearDates();
        return refine(item.value);
      }

      this.customDatesDisabled = !isDateRange;
      refine(defaultNumSearch);
    },
    clearDates() {
      this.dates = [];
    },
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    getEmployeeById(employeeId) {
      return this.employees[employeeId]?.name || '';
    },
    async newTimeOff() {
      await this.goToTimeOffEdit({ id: 'new' });
    },
    async goToTimeOffEdit(timeOff) {
      await this.$router.push({
        name: 'time-off-edit',
        params: {
          timeOffId: timeOff.id,
        },
        query: {
          searchQuery: this.search,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.skeletons {
  margin: 30px 0;
}

.space {
  margin-right: 10px;
}
</style>
