<template>
  <footer class="footer">
    <div class="container">
      <div class="content"></div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NewmoonFooter',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
};
</script>

<style scoped>
.footer {
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fafafa;
  height: 25px; */
  display: none;
}
</style>
