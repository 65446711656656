<template>
  <div id="dfr-company-aggregate">
    <div class="mb-5 is-size-4">Dfr Company Report</div>
    <div class="is-flex is-flex-direction-row">
      <div class="is-flex-grow-1">
        <b-field label="Company">
          <b-autocomplete
            :data="companyList"
            :loading="fetchingCompanies"
            field="company"
            @typing="fetchCompany"
            @select="option => (company = option)"
            clearable
            open-on-focus
          ></b-autocomplete>
        </b-field>
      </div>
      <div class="is-flex-grow-1">
        <b-field class="ml-2" label="Date Range">
          <b-datepicker
            v-model="dates"
            :date-formatter="formatDateRange"
            :disabled="false"
            :show-week-number="false"
            placeholder="Choose range"
            :icon-right="dates ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="dates = []"
            trap-focus
            range
          ></b-datepicker>
        </b-field>
      </div>
    </div>
    <div v-if="companyAggregate">
      <div class="my-3 is-size-5">Company Total {{ companyAggregate.total }}</div>
      <dfr-report-table :aggregate-data="companyAggregate.employeeDfrAggs"></dfr-report-table>
    </div>
  </div>
</template>

<script>
import { capitalize, debounce } from 'lodash';
import dayjs from 'dayjs';

import DfrService from '@/service/dfr.service';
import CompanyService from '@/service/company.service';

import DfrReportTable from '@/components/dfr/DfrReportTable.vue';

export default {
  name: 'DfrCompanyAggregate',
  components: {
    DfrReportTable,
  },
  data() {
    return {
      companyList: [],
      fetchingCompanies: false,
      fetchCompany: debounce(this.fetchCompanyData, 300),
      company: null,
      dates: [],
      companyAggregate: null,
      capitalize,
    };
  },
  computed: {
    canAggregateEmployee() {
      return this.dates.length === 2 && this.company;
    },
  },
  watch: {
    company() {
      this.getAggData();
    },
    dates() {
      this.getAggData();
    },
  },
  async mounted() {
    await this.fetchCompanyData('');
  },
  methods: {
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    async getAggData() {
      if (!this.canAggregateEmployee) return;
      this.companyAggregate = await DfrService.getCompanyAggregate({
        companyId: this.company.id,
        startDate: this.dates[0],
        endDate: this.dates[1],
      });
    },
    async fetchCompanyData(name) {
      this.fetchingCompanies = true;
      const params = { pageSize: 40 };
      if (name?.length) params.search = name;

      this.companyList = await CompanyService.list(params)
        .then(r =>
          r.data.map(e => ({
            ...e,
          }))
        )
        .finally(() => (this.fetchingCompanies = false));
    },
  },
};
</script>

<style scoped></style>
