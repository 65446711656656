<template>
  <div id="dfr-report">
    <div class="is-flex is-flex-direction-row is-justify-content-center">
      <b-button @click="openModal" :class="classes" :loading="isReportGenerating">Generate Payroll Export</b-button>
      <div class="ml-2" v-if="reportIsDownloadable && !isReportGenerating">
        <a class="button" download :href="reportHref">Download</a>
      </div>
    </div>
    <b-modal v-model="isModalOpen" aria-role="dialog">
      <template #default="props">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">DFR Report</p>
            <button class="delete" type="button" @click="props.close"></button>
          </header>
          <section class="modal-card-body">
            <b-field>
              <b-switch v-model="isPrevailingWage">Prevailing Wage</b-switch>
            </b-field>
            <b-field class="my-5" label="Date Range">
              <b-datepicker
                v-model="dateRange"
                :date-formatter="formatDateRange"
                :disabled="false"
                :show-week-number="false"
                placeholder="Choose range"
                :icon-right="dateRange ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDateRange"
                trap-focus
                range
                append-to-body
              ></b-datepicker>
            </b-field>
            <b-field label="Payroll System">
              <b-select v-model="payrollSystem" expanded>
                <option v-for="(ps, i) in payrollSystems" :value="ps.value" :key="i">
                  {{ ps.displayName }}
                </option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot is-flex is-flex-direction-row is-justify-content-end">
            <b-button label="Close" @click="props.close"></b-button>
            <b-button label="Generate" type="is-primary" @click="generateReport"></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { first, last } from 'lodash';

import EmployeesService from '@/service/employees.service';
import TaskService from '@/service/task.service';

export default {
  name: 'DFRReport',
  props: {
    classes: {
      type: String,
      default: 'is-primary',
    },
  },
  data() {
    return {
      isModalOpen: false,
      reportStarted: false,
      reportIsDownloadable: false,
      reportHref: null,
      isReportGenerating: false,
      dateRange: [],
      payrollSystem: null,
      isPrevailingWage: false,
      payrollSystems: [...EmployeesService.PAYROLL_SYSTEMS],
      report: {},
    };
  },
  methods: {
    clearDateRange() {
      this.dateRange = [];
    },
    openModal() {
      this.isModalOpen = true;
    },
    formatDateRange(dates) {
      return dates.map(d => dayjs(d).format('M/D/YY')).join(' - ');
    },
    async generateReport() {
      this.isModalOpen = false;

      try {
        this.isReportGenerating = true;
        const { doc, getDownloadUrl } = await TaskService.generateDfrReport({
          isPrevailingWage: this.isPrevailingWage,
          startDate: first(this.dateRange),
          endDate: last(this.dateRange),
          payrollSystem: this.payrollSystem,
        });

        const unsubscribe = doc.onSnapshot(async doc => {
          this.report = doc.data();
          if (this.report.status === 'new') return;

          if (this.report.status === 'error') {
            this.$buefy.notification.open({
              message: this.report.error,
              type: 'is-danger',
            });
          } else if (this.report.status === 'complete') {
            this.reportHref = await getDownloadUrl();
            this.reportIsDownloadable = true;
          }
          this.isReportGenerating = false;
          unsubscribe();
        });

        this.reportStarted = true;
      } catch (e) {
        console.log(e);
        this.$buefy.notification.open({
          message: `Failed to start report.`,
          type: 'is-danger',
          indefinite: true,
          closable: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
