import { DateService as dateServiceShared } from '@newmoon-org/shared';
import { omit } from 'lodash';

import CentralSchedulerService from '@/service/centralScheduler.service';

let startEventListener,
  endEventListener = null;

export default {
  loadEvents: ({ commit, state }, { startDate, endDate, employeeId }) => {
    const startOfTheDay = dateServiceShared.getStartOfDay(startDate);
    const startOfTomorrow = dateServiceShared.getStartOfTomorrow(endDate);

    if (
      state.startDate?.getTime() === startDate?.getTime() &&
      state.endDate?.getTime() === endDate?.getTime() &&
      state.employeeId === employeeId
    ) {
      return;
    }

    commit('setStartEvents', []);
    commit('setEndEvents', []);
    commit('setStartDate', startDate);
    commit('setEndDate', endDate);
    commit('setEmployeeId', employeeId);
    commit('setActiveEventsModel');

    if (startEventListener) {
      startEventListener();
    }

    if (endEventListener) {
      endEventListener();
    }

    if (!startDate || !endDate) {
      return;
    }

    const filterFunction = it => dateServiceShared.hasTimeConflict(it.startTime, it.endTime, startDate, endDate);
    const mapEvent = it => omit(CentralSchedulerService.mapDocWithDates(it), ['audit', 'email', 'createdOn']);

    const startQuery = CentralSchedulerService.dbRef
      .where('startTime', '>=', startOfTheDay)
      .where('startTime', '<=', startOfTomorrow);
    const endQuery = CentralSchedulerService.dbRef
      .where('endTime', '>=', startOfTheDay)
      .where('endTime', '<=', startOfTomorrow);

    startEventListener = (employeeId ? startQuery.where('employeeId', '==', employeeId) : startQuery).onSnapshot(
      r => {
        commit('setStartEvents', r.docs.map(it => mapEvent(it)).filter(filterFunction));
        commit('setActiveEventsModel');
      },
      e => {
        this.$buefy.notification.open({
          message: `Failed to pull schedules with start date parameters, ${e.message}`,
          type: 'is-danger',
        });
      }
    );

    endEventListener = (employeeId ? endQuery.where('employeeId', '==', employeeId) : endQuery).onSnapshot(
      r => {
        commit('setEndEvents', r.docs.map(it => mapEvent(it)).filter(filterFunction));
        commit('setActiveEventsModel');
      },
      e => {
        this.$buefy.notification.open({
          message: `Failed to pull schedules with end date parameters, ${e.message}`,
          type: 'is-danger',
          indefinite: true,
          closable: true,
        });
      }
    );
  },
};
