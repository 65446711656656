<template>
  <div class="nwm-panel">
    <b-sidebar
      v-model="model"
      :type="type"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      :can-cancel="false"
    >
      <slot name="content"></slot>
      <slot name="bottom"></slot>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    model: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'is-light',
    },
    fullheight: {
      type: Boolean,
      default: true,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.nwm-panel {
}
</style>
