
import { mapGetters } from 'vuex';
import { get, isEmpty } from 'lodash';

import NewmoonNavbar from '@/components/NewmoonNavbar.vue';
import NewmoonFooter from '@/components/NewmoonFooter.vue';
import NewmoonSidebar from '@/components/NewmoonSidebar.vue';
import AppRefresh from '@/components/AppRefresh.vue';

export default {
  name: 'App',
  components: { AppRefresh, NewmoonSidebar, NewmoonFooter, NewmoonNavbar },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'user']),
    hasEmployeeRecord() {
      return !isEmpty(get(this.user, 'employee'));
    },
    backgroundClasses() {
      return {
        'is-logged-out': !this.isLoggedIn,
      };
    },
  },
};
