<template>
  <div class="tech-display">
    <div class="hidden lg:block" v-if="profileImg" :style="profileImg"></div>
    <div class="hidden profile-empty lg:block" v-else></div>
    <div class="details">
      <div class="hidden name md:flex">
        {{ label }}
      </div>
      <div class="md:hidden lg:hidden xl:hidden name">
        {{ mobileLabel }}
      </div>
      <div class="hidden lg:flex">
        <div v-for="(skill, index) in skills" :key="index">
          <b-icon v-if="skill.toLowerCase() === 'electrical'" icon="bolt"></b-icon>
          <b-icon v-if="skill.toLowerCase() === 'plumbing'" icon="tint"></b-icon>
          <b-icon v-if="skill.toLowerCase() === 'rescon'" icon="hard-hat"></b-icon>
          <b-icon v-if="skill.toLowerCase() === 'hvac'" icon="snowflake"></b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TechDisplay',
  props: {
    tech: {
      type: Object,
      required: true,
    },
  },
  computed: {
    skills() {
      return this.tech.skills.slice().sort();
    },
    label() {
      return this.tech.label;
    },
    profileImg() {
      return this.tech.profileImgPath || this.tech.profileImgUrl
        ? { background: `url(${this.tech.profileImgUrl}) center/cover` }
        : null;
    },
    mobileLabel() {
      const [lastName, firstName] = this.tech.label.split(' ');
      return `${firstName[0]}. ${lastName}`;
    },
  },
};
</script>

<style scoped lang="scss">
.tech-display {
  position: relative;
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-left: 3px;

  .profile {
    height: 22px;
    width: auto;
    min-width: 24px;
    border-radius: 50%;
    margin-right: 4px;
    border: 1px solid #33333366;
  }

  .profile-empty {
    height: 22px;
    width: 22px;
    min-width: 26px;
  }

  .details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;

    .name {
      font-size: 13px;
      font-weight: bold;
    }

    .nickname {
      font-size: 12px;
      font-style: italic;
      font-weight: normal;
      margin-left: 4px;
    }
  }

  .icon {
    font-size: 12px;
    padding: 0 1px;
  }
}
</style>
