<template>
  <div class="work-order-products">
    <div class="work-order-products-header" v-if="showHeader">
      <div class="my-4 is-size-4">Products</div>
      <product-browser
        class="mt-2 ml-2"
        v-if="isAddable"
        @onProductAdd="addProduct"
        :products="products"
        :catalog="catalog"
        :ignore-taxes="workOrder.isTaxOverride"
      ></product-browser>
    </div>
    <div v-if="!hasProducts">
      <b-message>No Products</b-message>
    </div>
    <div class="products" v-else>
      <product
        v-for="(p, idx) in products"
        :key="idx"
        :line-item="p"
        :class="{ 'is-even': idx % 2 === 0, 'is-odd': idx % 2 !== 0 }"
        :is-addable="isAddable"
        :is-editable="isEditable"
        :is-view-only="isViewOnly"
        :show-product-price="showProductPrice"
        @quantityChange="onQuantityChange"
        @remove="removeProduct"
        :ignore-taxes="workOrder.isTaxOverride"
      ></product>
    </div>
    <work-order-totals class="my-3" :work-order="workOrder" v-if="!showCostCodes && showTotals"></work-order-totals>
    <div class="my-2 is-size-3" v-else-if="showCostCodes">Cost Codes</div>
    <div class="columns">
      <div class="column">
        <section class="cost-codes-list">
          <div class="cost-code" v-for="c in costCodes" :key="c">
            <b-field>
              <b-checkbox v-model="workOrder.costCodes" :native-value="c">{{ c }}</b-checkbox>
            </b-field>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Product from '@/components/workorders/Product.vue';
import WorkOrderTotals from '@/components/workorders/WorkOrderTotals.vue';
import ProductBrowser from '@/components/workorders/ProductBrowser.vue';

export default {
  name: 'WorkOrderProducts',
  components: {
    ProductBrowser,
    Product,
    WorkOrderTotals,
  },
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
    showProductPrice: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showTotals: {
      type: Boolean,
      default: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isAddable: {
      type: Boolean,
      default: true,
    },
    catalog: {
      type: String,
      default: null,
    },
    costCodes: {
      type: Array,
      default: () => [],
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    showCostCodes() {
      return this.costCodes.length > 0;
    },
    hasProducts() {
      return this.products && this.products?.length !== 0;
    },
  },
  watch: {
    workOrder: {
      immediate: true,
      handler(val) {
        this.products = [...(val?.lineItems ?? [])];
      },
    },
    catalog: {
      handler() {
        this.searchProducts = '';
      },
      immediate: true,
    },
  },
  methods: {
    removeProduct(product) {
      const newProducts = this.products.filter(p => p.productId !== product.productId);
      this.products = [...newProducts];
      this.workOrder.lineItems = [...newProducts];
      this.$emit('productUpdate', newProducts);
    },
    async addProduct(product) {
      const doesProductExist = id => this.products.some(p => p.productId === id);
      if (doesProductExist(product.id)) {
        this.$buefy.toast.open({
          message: `Product ${product.name} already added.`,
          type: 'is-warning',
        });
        return;
      }
      const newProducts = [...this.products, { product, quantity: 1, productId: product.id }];
      this.products = [...newProducts];
      this.workOrder.lineItems = [...newProducts];
      this.$emit('productUpdate', this.products);
      this.$buefy.toast.open({
        message: `Added Product ${product.name}`,
        type: 'is-success',
      });
    },
    onQuantityChange() {
      this.workOrder.lineItems = [...this.products];
      this.$emit('productUpdate', [...this.products]);
    },
  },
};
</script>

<style scoped lang="scss">
.work-order-products {
  .work-order-products-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .products {
    border: #ddd solid 1px;
    border-radius: 5px;

    .is-odd {
      background-color: #eff1fa;
    }
  }

  .cost-codes-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .cost-code {
      width: 450px;
    }
  }
}
</style>
