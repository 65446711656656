<template>
  <div id="project-mlh-report">
    <div class="title is-5">MLH Budget Report</div>
    <div class="columns is-variable is-2">
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="user-clock" type="is-info"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Reported</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ reported }}</div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="hourglass"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Total</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ budgetSafe }}</div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="hourglass-half"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Committed</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ committedSafe }}</div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="hourglass-start"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Planned</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ plannedSafe }}</div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="box-title has-text-centered">
            <b-icon class="is-inline-block" icon="hourglass-end"></b-icon>
            <div class="ml-1 title is-5 is-inline-block">Remaining</div>
          </div>
          <div class="box-content has-text-centered">
            <div class="subtitle is-4">{{ remaining }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sum } from 'lodash';
import { DateService } from '@newmoon-org/shared';

import ProjectService from '@/service/project.service';
import WorkOrderService from '@/service/workorders.service';
import DfrService from '@/service/dfr.service';

export default {
  name: 'ProjectMlhReport',
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      planned: 0,
      committed: 0,
      budget: 0,
      reportedNew: 0,
      reportedLegacy: 0,
    };
  },
  computed: {
    budgetSafe() {
      return this.budget?.toFixed(2) ?? Number(0).toFixed(2);
    },
    committedSafe() {
      return this.committed?.toFixed(2) ?? Number(0).toFixed(2);
    },
    plannedSafe() {
      return this.planned?.toFixed(2) ?? Number(0).toFixed(2);
    },
    remaining() {
      return (this.budget - this.planned - this.committed).toFixed(1);
    },
    reported() {
      return (this.reportedNew + this.reportedLegacy).toFixed(1);
    },
  },
  async mounted() {
    this.pullProject();
    this.pullWorkordersData();
    this.pullDfrs();
  },
  methods: {
    pullDfrs() {
      const getDuration = it => {
        const dfr = DfrService.mapDocWithDates(it);
        return DateService.getNumberOfHoursBetweenDates(dfr.startDate, dfr.endDate);
      };
      DfrService.dbRef
        .where('workOrder.project.id', '==', this.projectId)
        .where('status', '==', DfrService.STATUS.APPROVED)
        .onSnapshot(r => {
          this.reportedNew = sum(r.docs.map(it => getDuration(it)));
        });

      DfrService.dbRef
        .where('workOrder.projectId', '==', this.projectId)
        .where('status', '==', DfrService.STATUS.APPROVED)
        .onSnapshot(r => {
          this.reportedLegacy = sum(r.docs.map(it => getDuration(it)));
        });
    },
    pullProject() {
      ProjectService.dbRef.doc(this.projectId).onSnapshot(r => {
        this.budget = Number(r.data()?.mlhBudget ?? 0);
      });
    },
    pullWorkordersData() {
      WorkOrderService.dbRef
        .where('project.id', '==', this.projectId)
        .where('deleted', '==', false)
        .onSnapshot(r => {
          this.planned = 0;
          this.committed = 0;
          r.docs
            .map(d => WorkOrderService.mapWorkOrderFromResponse(d))
            .forEach(it => {
              const total = WorkOrderService.computeWorkAssignmentMLH(it);
              if (it.date) {
                this.committed += total;
              } else {
                this.planned += total;
              }
            });
        });
    },
  },
};
</script>
