import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/auth';
import pageActions from '@/store/pageActions';
import features from '@/store/features';
import centralScheduler from '@/store/centralScheduler';
import dfr from '@/store/dfr';
import workAssignment from '@/store/workAssignment';

Vue.use(Vuex);

const modules = {
  auth,
  pageActions,
  features,
  centralScheduler,
  dfr,
  workAssignment,
};

const plugins = [];

const store = new Vuex.Store({
  modules,
  plugins,
  strict: false,
});

export const useStore = () => store;

export default store;
