import { debounce } from 'lodash';

export default {
  data() {
    return {
      activityEvents: ['click', 'mousemove', 'keydown', 'scroll'],
      disableUser: debounce(() => {
        this.userIsActive = false;
      }, 5 * 60 * 1000),
      userIsActive: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.activityEvents.forEach(it => document.addEventListener(it, this.updateActivityFlag));
    });
  },
  destroyed() {
    this.activityEvents.forEach(it => document.removeEventListener(it, this.updateActivityFlag));
    this.disableUser.cancel();
  },
  methods: {
    updateActivityFlag() {
      this.userIsActive = true;
      this.disableUser();
    },
  },
};
