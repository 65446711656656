import { init } from '@/service/generic.service';
import { db } from '@/service/firebase';

const methods = init({
  collectionPath: 'roles',
});

const DEFAULT_ROLE = {
  id: 'new',
  name: null,
  status: 'active',
  noViewPages: [],
  noCreatePages: [],
  noUpdatePages: [],
  noDeletePages: [],
};

export default {
  DEFAULT_ROLE,
  dbRef: methods.dbRef,
  getAllActive: methods.getAllActive,
  getById: methods.getById,
  create: methods.create,
  update: methods.update,
  remove: methods.remove,
};

export function listenToRole(role, callback) {
  return db.collection('roles').doc(role.id).onSnapshot(callback);
}
