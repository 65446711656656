<template>
  <div class="holidays-admin-page">
    <div class="columns">
      <div class="column">
        <h2 class="title">Holidays</h2>
      </div>
      <div class="column is-narrow">
        <b-tooltip label="Add">
          <b-button type="is-primary" size="is-small" @click="toggleModal"><b-icon icon="plus"></b-icon></b-button>
        </b-tooltip>
      </div>
    </div>
    <div>
      <b-table :data="holidays">
        <template #empty>
          <div class="has-text-centered">No Holidays Found</div>
        </template>
        <b-table-column label="Name" field="name" v-slot="props">{{ props.row.name }}</b-table-column>
        <b-table-column label="Year" field="year" v-slot="props">
          {{ formatDate(props.row.date, 'YYYY') }}
        </b-table-column>
        <b-table-column label="Date" field="date" v-slot="props">
          {{ formatDate(props.row.date, 'M/D/YYYY') }}
        </b-table-column>
        <b-table-column label="Actions" field="actions" v-slot="props" width="150">
          <div class="buttons">
            <b-tooltip label="Edit">
              <b-button class="mr-1 is-small is-primary" icon-right="edit" @click="edit(props.row)"></b-button>
            </b-tooltip>
            <b-tooltip label="Delete" type="is-danger">
              <b-button class="mr-1 is-small is-danger" icon-right="trash" @click="confirmRemove(props.row)"></b-button>
            </b-tooltip>
          </div>
        </b-table-column>
      </b-table>
    </div>
    <b-modal v-model="modalActive" trap-focus aria-role="dialog" aria-modal :destroy-on-hide="false">
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">
            {{ isNew ? 'New Holiday' : 'Edit Holiday' }}
          </header>
          <section class="modal-card-body">
            <b-field label="Name">
              <b-input v-model="selectedHoliday.name"></b-input>
            </b-field>
            <b-field label="Date">
              <b-datepicker v-model="selectedHoliday.date" :append-to-body="true"></b-datepicker>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <div class="is-pulled-right">
              <b-button size="is-small" label="Close" type="is-danger" @click="toggleModal"></b-button>
              <b-button size="is-small" label="Save" type="is-success" @click="upsert(selectedHoliday)"></b-button>
            </div>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import HolidayService from '@/service/holiday.service';

export default {
  name: 'HolidaysAdminPage',
  data() {
    return {
      modalActive: false,
      selectedHoliday: {},
    };
  },
  computed: {
    isNew() {
      return !this.selectedHoliday.id;
    },
  },
  asyncComputed: {
    holidays: {
      async get() {
        return await HolidayService.list();
      },
      default: [],
    },
  },
  methods: {
    formatDate(date, format) {
      return dayjs(date).format(format);
    },
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    edit(holiday) {
      this.selectedHoliday = { ...holiday };
      this.toggleModal();
    },
    confirmRemove(holiday) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to remove: ${holiday.name}?`,
        onConfirm: () => this.remove(holiday),
      });
    },
    async remove(holiday) {
      try {
        await HolidayService.remove(holiday.id);
      } catch (e) {
        console.log(e);
      }
      await this.$asyncComputed.holidays.update();
    },
    async upsert(holiday) {
      try {
        await HolidayService.upsert(holiday);
      } catch (e) {
        console.log(e);
      }
      this.edit({}); // reset: clear selected and toggle modal
      await this.$asyncComputed.holidays.update();
    },
  },
};
</script>
