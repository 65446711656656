<template>
  <div class="product-browser">
    <b-tooltip label="Add a Product" position="is-left">
      <b-button class="is-small is-primary" @click="openModal" icon-right="plus" :disabled="disabled"></b-button>
    </b-tooltip>
    <b-modal
      v-model="isModalOpen"
      has-modal-card
      trap-focus
      auto-focus
      scroll="keep"
      aria-role="dialog"
      aria-modal
      width="960"
    >
      <div class="card">
        <div class="card-content">
          <div class="content" v-if="!isLoading">
            <div class="header-row">
              <div class="header">Add a Product</div>
              <b-button class="is-primary" @click="isModalOpen = false">DONE</b-button>
            </div>
            <div class="search">
              <search-list
                index-name="catalog"
                :facets="facets"
                :actions="actions"
                :attributes="attributes"
                :transform="transform"
                :search-function="searchFunction"
                :current-product-list="products"
              ></search-list>
            </div>
          </div>
          <div class="loading" v-if="isLoading">
            <div class="loading-content">Loading</div>
            <b-progress></b-progress>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { get, identity, map } from 'lodash';

import CatalogService from '@/service/catalog.service';
import { getLogger } from '@/service/logger.service';

import SearchList from '@/components/list/searchList.vue';

const log = getLogger('ProductBrowser');

export default {
  name: 'ProductBrowser',
  components: {
    SearchList,
  },
  props: {
    ignoreTaxes: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
    catalog: {
      type: String,
      default: '',
    },
    singleAdd: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalOpen: false,
      transform(items) {
        const moneyTransforms = [
          'priceToCustomerCost.markupTaxable',
          'priceToCustomerCost.markupNonTaxable',
          'priceToCustomerCost.total',
        ];
        return map(items, item => ({
          ...item,
          ...moneyTransforms.reduce(
            (acc, key) => ({
              ...acc,
              [key]: this.$options.filters.money(parseInt(get(item, key, 0), 10) / 100),
            }),
            {}
          ),
        }));
      },
      facets: [
        { facet: 'catalogTypeName', displayName: 'Catalog' },
        { facet: 'status', displayName: 'Status' },
        { facet: 'area', displayName: 'Area' },
        { facet: 'category', displayName: 'Category' },
        { facet: 'specification', displayName: 'Specification' },
      ],
      actions: this.getActions(),
      isLoading: false,
    };
  },
  computed: {
    attributes() {
      return [
        {
          displayName: 'Name',
          key: 'name',
          hightlight: true,
        },
        {
          displayName: 'Status',
          key: 'status',
          hightlight: true,
        },
        {
          displayName: 'Catalog',
          key: 'catalogTypeName',
          hightlight: true,
        },
        {
          displayName: 'Area',
          key: 'productArea.label',
          hightlight: true,
        },
        {
          displayName: 'Category',
          key: 'productCategory',
          hightlight: true,
        },
        this.ignoreTaxes
          ? null
          : {
              displayName: 'Taxable',
              key: 'priceToCustomerCost.markupTaxable',
              hightlight: false,
            },
        this.ignoreTaxes
          ? null
          : {
              displayName: 'Non Taxable',
              key: 'priceToCustomerCost.markupNonTaxable',
              hightlight: false,
            },
        {
          displayName: 'Price To Customer',
          key: 'priceToCustomerCost.total',
          hightlight: false,
        },
      ].filter(identity);
    },
  },
  methods: {
    searchFunction(helper) {
      this.isLoading = true;
      const page = helper.getPage();
      if (!helper.state.disjunctiveFacets.includes('specification')) {
        helper.state.facets.push('specification');
      }
      if (this.catalog && !helper.state.facets.includes('catalogTypeName')) {
        helper.state.facets.push('catalogTypeName');
      }
      if (!helper.state.facets.includes('status')) {
        helper.state.facets.push('status');
      }

      if (this.catalog) helper.addFacetRefinement('catalogTypeName', this.catalog);

      helper
        .addFacetRefinement('status', 'active')
        .addDisjunctiveFacetRefinement('specification', 'common')
        .addDisjunctiveFacetRefinement('specification', 'warranty-discount')
        .addDisjunctiveFacetRefinement('specification', 'discount')
        .addDisjunctiveFacetRefinement('specification', 'assessment-fee')
        .setPage(page)
        .search();

      this.isLoading = false;
    },
    getActions() {
      return [
        {
          icon: 'plus',
          click: item => {
            this.addProduct(item);
          },
          classes: ['is-info'],
        },
      ];
    },
    openModal() {
      this.isModalOpen = true;
    },
    async addProduct(item) {
      this.isLoading = true;
      try {
        const product = await CatalogService.getById(item.objectID);
        this.$emit('onProductAdd', { ...product });
        if (this.singleAdd) {
          this.isModalOpen = false;
        }
      } catch (e) {
        log.error(e.message);
        this.$buefy.toast.open({
          message: `Unable to Add Product ${item.name}`,
          type: 'is-danger',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-browser {
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;

    .loading-content {
      padding: 3px 0 3px 5px;
    }
  }

  .card {
    width: 100%;
    min-width: 80vw;
    max-width: 80vw;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    .header-row {
      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 22px;
        padding: 5px;
      }

      .close-button {
        display: none;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 765px) and (min-width: 400px),
  screen and (max-device-width: 765px) and (min-device-width: 400px) {
  .product-browser {
    .card {
      width: 100%;
      min-width: 100vw;
      max-width: 100vw;
      max-height: 84vh;
      min-height: 83vh;
      overflow-y: auto;
      overflow-x: hidden;

      .header-row {
        .close-button {
          display: inherit;
        }
      }
    }
  }
}

@media screen and (max-width: 399px), screen and (max-device-width: 399px) {
  .product-browser {
    .card {
      box-sizing: border-box;
      width: 100%;
      max-width: 100vw;
      overflow-y: auto;
      max-height: 83vh;
      top: -15px;
      padding: 0;

      .card-content {
        padding: 8px;
        box-sizing: border-box;
        position: relative;
        width: 100%;
      }
    }
  }
}
</style>
